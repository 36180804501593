<div class="start-content-section">
  <div class="title">
    <ng-content select="[primary-title]"></ng-content>
  </div>
  <div class="description">
    <ng-content select="[primary-description]"></ng-content>
  </div>
  <!-- content setup for the image comparison slider -->
  <div>
    <div class="img-comparison">
      <ng-content select="[image-comparison]"></ng-content>
    </div>
    <div class="caption image-comparison-caption">
      <ng-content select="[image-caption]"></ng-content>
    </div>
  </div>
  <div class="title" >
    <ng-content select="[secondary-title]"></ng-content>
  </div>
  <div class="description">
    <ng-content select="[secondary-description]"></ng-content>
  </div>
</div>
