import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

// Special for components & co
import { LegalDocumentsComponent } from './legal-documents/legal-documents.component';
import { HelpPageComponent } from './help-page/help-page.component';
import { AboutUsComponent } from './about-us.component';

// From shared folder
import { RoutePathes } from '../../shared/enums/route-pathes.enum';

const routes: Routes = [
  {
    path: RoutePathes.AboutUs,
    component: AboutUsComponent
  },
  {
    path: RoutePathes.AboutUs_Faq,
    component: HelpPageComponent
  },
  {
    path: RoutePathes.AboutUs_LegalDocuments,
    component: LegalDocumentsComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AboutUsRoutingModule {}
