import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output
} from '@angular/core';
import { Store } from '@ngrx/store';
import { delay, of, take } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { CloseDialogContext } from '../close-dialog/close-dialog-context.enum';

// Why this is needet?
import { AcuityActions } from '../../../acuity-test/store';

import { fadeInAnimation } from '../../../shared/animations';
import { HeaderLabelEnum } from '../../../shared/enums/header.enum';
import {
  GA4_EventDetail,
  GA4_EventAction,
  GA4_EventName,
  GA4_EventValue
} from '../../../shared/enums/ga4.enum';
import { Eyes } from '../../../shared/enums';
import { IndicatorEnum } from '../../../shared/enums/indicator.enum';
import { AnalyticsService } from '../../../services/analytics.service';

@Component({
  selector: 'zat-prepare-next-test',
  templateUrl: './prepare-next-test.component.html',
  styleUrls: ['./prepare-next-test.component.scss'],
  animations: [fadeInAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrepareNextTestComponent {
  Eyes = Eyes;
  headerLabelEnum = HeaderLabelEnum;
  indicatorDots: IndicatorEnum[] = [IndicatorEnum.Active];

  @Input('label') label: string = null;
  @Input('checkType') checkType: GA4_EventDetail = null;

  @Output() public next = new EventEmitter();
  @Output() public clickCross = new EventEmitter();

  // trigger close warning dialog for checks when browser back button is clicked
  @HostListener('window:popstate', ['$event'])
  onPopState() {
    if (!environment.webcomponent) {
      history.pushState(null, null, location.href);
      this.cancel();
    }
  }

  constructor(
    private store: Store,
    private locationService: Location,
    private analytics: AnalyticsService
  ) {
    // set pushState to be able to route back here again when cancelling the close-warning via browser back button
    history.pushState(null, null, location.href);
  }

  // only used for browser-bakc button - warning
  cancel(): void {
    of(null)
      .pipe(delay(0), take(1))
      .subscribe(() => {
        this.store.dispatch(
          AcuityActions.ShowCloseWarningAction({
            context: CloseDialogContext.Check,
            previousPath: this.locationService.path()
          })
        );
      });
  }

  handleNext(): void {
    this.analytics.createCustomEvent({
      event: 'event',
      eventName: `${GA4_EventName.CTA}`,
      eventAction: `${GA4_EventAction.Click}`,
      eventType: `${this.checkType} Test`,
      eventValue: `${GA4_EventValue.SwitchEye}`,
      eventDetail: `${GA4_EventDetail.Clear}`
    });

    this.next.emit();
  }

  handleCancelEmit(): void {
    this.analytics.createCustomEvent({
      event: 'event',
      eventName: `${GA4_EventName.CTA}`,
      eventAction: `${GA4_EventAction.Click}`,
      eventType: `${this.checkType} Test`,
      eventValue: `${GA4_EventValue.SwitchEyeNot}`,
      eventDetail: `${GA4_EventDetail.Clear}`
    });

    this.clickCross.emit();
  }
}
