import { animate, state, style, transition, trigger } from '@angular/animations';

// From shared folder
import { TIMING_FUNCTION_FORWARD, TIMING_FUNCTION_BACKWARD_DELAYED } from '../../shared/config';

export const landoltCAnimation = trigger('landoltCAnimation', [
  state('init', style({ opacity: 0 })),
  state('idle', style({ opacity: 1 })),
  transition('init => idle', animate(TIMING_FUNCTION_FORWARD)),
  transition('idle => init', animate(TIMING_FUNCTION_BACKWARD_DELAYED))
]);
