<div class="how-it-works" *transloco="let t">
  <zat-ovs-header [label]="headerConfig.label" [left]="headerConfig.left" [right]="headerConfig.right"
    [theme]="headerConfig.theme" (clickLeft)="closeHint()"></zat-ovs-header>

  <div #scrollableElement class="how-it-works-content" tabindex="0">
    <div class="headline text-headline">
      <span class="headline-label">
        {{ t('howItWorks.title') }}
      </span>
    </div>

    <div class="check-hint">
      <ng-container [ngSwitch]="(activeEye$ | async)">
        <zat-how-it-works-checks-general [coverEye]="instructions.COVER_RIGHT_EYE" [distance]="instructions.KEEP_AN_ARM"
          *ngSwitchCase="Eyes.LEFT">
        </zat-how-it-works-checks-general>
        <zat-how-it-works-checks-general [coverEye]="instructions.COVER_LEFT_EYE" [distance]="instructions.KEEP_AN_ARM"
          *ngSwitchCase="Eyes.RIGHT">
        </zat-how-it-works-checks-general>
      </ng-container>

      <!-- Specify for aciuty-check -->
      <div class="hint">
        <div class="media-space">
          <app-icon id="astigmatism" name="astigmatism"  alt="" [width]="96" [height]="96">
          </app-icon>
        </div>
        <div class="text-space">
          <span class="text-highlight">3) </span>
          <span>
            {{ t('astigmatismTest.focusOnCenter') }}
          </span><br>

          <span class="text-highlight">Q) </span>
          <span>
            {{ t('astigmatismTest.doYouSeeAllLinesInBlack') }}
          </span>
        </div>
      </div>
    </div>

    <zat-how-it-works-general></zat-how-it-works-general>
  </div>
</div>
