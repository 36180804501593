<div class="help-general" *transloco="let t">
  <!-- Title -->
  <div class="hint">
    <label class="text-title" [innerHtml]="t('howItWorks.accurateResults')"></label>
  </div>

  <!-- Wear your glases -->
  <div class="hint">
    <div class="media-space">
      <app-icon id="wear_your_glasses" name="wear_your_glasses" [width]="96" [height]="29" alt=""></app-icon>
    </div>
    <div class="text-space">
      <label [innerHtml]="t('howItWorks.wearYourGlasses')"></label>
    </div>
  </div>

  <!-- Maximize brightness -->
  <div class="hint">
    <div class="media-space center-element">
      <app-icon id="preparation_brightness" name="preparation_brightness" [width]="47" [height]="113" alt=""></app-icon>
    </div>
    <div class="text-space">
      <label [innerHtml]="t('setup.brightness.maximizeBrightness')"></label>
    </div>
  </div>

  <!-- Calibrate sreen with card -->
  <div class="hint">
    <div class="media-space">
      <div class="card" alt="">
        <div class="card-line"></div>
      </div>
    </div>
    <div class="text-space">
      <label [innerHtml]="t('setup.calibration.resizeCreditCard')"></label>
    </div>
  </div>
</div>
