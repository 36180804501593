import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, from, of, switchMap, take } from 'rxjs';

// Stuff for general
import { AuthorizationService } from '../services/authorization.service';
import { environment } from '../../environments/environment';
import { RoutePathes } from '../shared/enums/route-pathes.enum';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationGuard  {

  constructor(private router: Router, private authorizationService: AuthorizationService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // ONLY on Stage/Prod
    // Activate this when whitelist contains correct customer entries
    console.log(`##### auth guard -> isWebComp: ${environment.webcomponent}, isProd: ${environment.production}, isStage: ${environment.pageDevEnvironment === 'stage'}`);
    if (environment.webcomponent) {
      return this.authorizationService.isAuthorized$()
        .pipe(
          take(1),
          switchMap((access) => {
            console.log('##### whitelist -> access: ',access);
            if (!access) {
              return from(this.router.navigate([RoutePathes.Not_Authorized]));
            }
            return of(access);
          })
        );
    }

    return true;
  }
}
