import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';

// Stuff for general
import { environment } from '../../../../environments/environment';
import { goToStoreLocatorAction } from '../../../store/actions/general.actions';

// From shared folder
import {
  GA4_EventDetail,
  GA4_EventAction,
  GA4_EventName,
  GA4_EventType,
  GA4_EventValue
} from '../../../shared/enums/ga4.enum';
import { isOnline } from '../../../shared/utils';
import { AnalyticsService } from '../../../services/analytics.service';

@Component({
  selector: 'zat-result-call-to-action',
  templateUrl: './result-call-to-action.component.html',
  styleUrls: ['./result-call-to-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResultCallToActionComponent {
  @Input() isUSChannel = false;
  @Input() isFRChannel = false;

  constructor(private store: Store, private analytics: AnalyticsService) {}

  goToUrl(url: string): void {
    window.open(url, '_blank', 'noopener');
  }

  goToStoreLocator(): void {
    this.analytics.createCustomEvent({
      event: 'event',
      eventName: `${GA4_EventName.CTA}`,
      eventAction: `${GA4_EventAction.Click}`,
      eventType: `${GA4_EventType.Internal}`,
      eventValue: `${GA4_EventValue.ShowOpticans}`,
      eventDetail: `${GA4_EventDetail.ResultPage}`
    });

    if (environment.webcomponent) {
      window
        .open(
          'https://www.zeiss.com/vision-care/us/search/find-an-eye-doctor-near-you.html',
          '_blank',
          'noopener'
        )
        .focus();
    } else {
      this.store.dispatch(goToStoreLocatorAction());
    }
  }

  isCurrentlyOnline(): boolean {
    return isOnline();
  }
}
