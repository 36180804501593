import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  OnDestroy,
  OnInit
} from '@angular/core';
import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, combineLatest, map } from 'rxjs';
import { AnimationOptions } from 'ngx-lottie';
import { Location } from '@angular/common';

// Stuff for general
import {
  CalibrationBrightnessSuccesAction,
  CalibrationWearYourGlassesSuccesAction,
  FinishInstructionsAction,
  NextInstructionAction,
  OpenHomePageAction,
  PreviousInstructionAction,
  SetScreenCalibrationAction
} from '../../../store/actions/general.actions';

// Special for checks
import { ShowCloseWarningAction } from '../../../acuity-test/store/actions/acuity.actions';

// From shared folder
import { IndicatorEnum } from '../../enums/indicator.enum';
import { Instruction } from '../../enums';
import { TEST_TYPES } from '../../enums/test-types';
import { CloseDialogContext } from '../../components/close-dialog/close-dialog-context.enum';
import {
  GA4_EventDetail,
  GA4_EventAction,
  GA4_EventName,
  GA4_EventType,
  GA4_EventValue
} from '../../enums/ga4.enum';
import {
  getActiveTest,
  instructionsSet,
  getActiveInstruction
} from '../../../store/selectors/app.selectors';
import { AnalyticsService } from '../../../services/analytics.service';
import { SliderPosition } from '../../enums/slider-position.enum';
import { Router } from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'zat-instructions-page',
  templateUrl: './instructions-page.component.html',
  styleUrls: ['./instructions-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InstructionsPageComponent implements OnInit, OnDestroy {
  activeTest$: Observable<TEST_TYPES> = this.store.select(getActiveTest);
  activeTest: TEST_TYPES;
  instructions = Instruction;
  headerLabel: string;
  lastSlide: boolean = false;

  activeInstructionIndex$: Observable<number> =
    this.store.select(getActiveInstruction);
  activeInstructionIndex: number = 0;
  instructionsSet$: Observable<Instruction[]> =
    this.store.select(instructionsSet);
  instructionsSet: Instruction[];

  indicatorDots$: BehaviorSubject<IndicatorEnum[]> = new BehaviorSubject<
    IndicatorEnum[]
  >([]);
  showCardWarning$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  isSliderMoved: boolean = false;
  trackSliderPosition: SliderPosition;
  pixelPitchValue: number;
  calibDevice: string;

  optionsAcuity: AnimationOptions = null;

  optionsContrast: AnimationOptions = null;

  scaleCoverEye$: BehaviorSubject<number> = new BehaviorSubject<number>(1);

  // public isNavigating = false;
  private timeout: any;

  constructor(
    private store: Store,
    private locationService: Location,
    private analytics: AnalyticsService,
    private router: Router
  ) {}

  ngOnInit(): void {
    //virutal page view analytics event handling
    combineLatest([
      this.instructionsSet$,
      this.activeInstructionIndex$
    ]).pipe(
      map(([instructionSet, activeInstructionIndex])=>{
        let currentInstruction:Instruction = instructionSet[activeInstructionIndex];
        let baseUrl = this.router.url;
        let pageString = baseUrl+':'+currentInstruction;
        //analytics page change
        this.analytics.trackVirtualPage(pageString);
      }),
      untilDestroyed(this)
    ).subscribe();



    this.activeTest$.pipe(untilDestroyed(this)).subscribe((type) => {
      this.activeTest = type;
    });
    this.instructionsSet$.pipe(untilDestroyed(this)).subscribe((set) => {
      this.instructionsSet = set;
    });
    this.activeInstructionIndex$
      .pipe(untilDestroyed(this))
      .subscribe((index) => {
        this.activeInstructionIndex = index;

      this.defineIndicatorDots();
    });
  }

  ngOnDestroy(): void {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  @HostListener('window:popstate', ['$event'])
  onPopState() {
    // push instrction-page
    this.previous();
  }

  defineIndicatorDots(): void {
    let indicatorDots: IndicatorEnum[] = [];
    for (let i = 0; i < this.instructionsSet.length; i++) {
      // Check if current instruction
      if (i === this.activeInstructionIndex) {
        indicatorDots.push(IndicatorEnum.Active);
      }
      // Allready viewed instructions
      else if (i < this.activeInstructionIndex) {
        indicatorDots.push(IndicatorEnum.Clickable);
      }
      // Not clickable instructions
      else {
        indicatorDots.push(IndicatorEnum.Dot);
      }
    }
    this.indicatorDots$.next(indicatorDots);

    if (this.activeInstructionIndex === this.instructionsSet.length - 1) {
      this.lastSlide = true;
    }
  }

  next(): void {
    if (this.activeInstructionIndex === this.instructionsSet.length - 1) {
      this.done();
    } else {
      switch (this.instructionsSet[this.activeInstructionIndex]) {
        case Instruction.SETUP_BRIGHTNESS:
          // in order to prevent actual (routing) back navigation we push a dummy history event
          // which can then get popped
          history.pushState(null, '');
          this.store.dispatch(CalibrationBrightnessSuccesAction());
          break;
        case Instruction.SETUP_CARD_SLIDER:
          if (!this.isSliderMoved) {
            this.showCardWarning$.next(true);

            this.analytics.createCustomEvent({
              event: 'event',
              eventName: `${GA4_EventName.FUNCTIONAL}`,
              eventAction: `${GA4_EventAction.View}`,
              eventType: `${GA4_EventType.Warning}`,
              eventValue: `${GA4_EventValue.ScreenSizeCalibrationNotSuccessful}`,
              eventDetail: `${GA4_EventDetail.Clear}`
            });
          } else {
            this.cardCalibrationDone();
          }
          break;
        case Instruction.WEAR_YOUR_GLASSES:
          history.pushState(null, '');
          this.store.dispatch(CalibrationWearYourGlassesSuccesAction());
          break;
        default:
          history.pushState(null, '');
          this.store.dispatch(NextInstructionAction());
          break;
      }
    }
  }

  previous(): void {
    // here we navigate back normally since we are on the 0th instruction
    if (this.activeInstructionIndex === 0) {
      return;
    }

    // if warning is shown and user clicks back button -> close warning
    if(this.showCardWarning$.getValue()){
      this.closeWarning();
      return;
    }

    // get the active instruction ENUM from the instrctionSet array using the actionInstrctionIndex
    if (
      this.instructionsSet[this.activeInstructionIndex] ==
      Instruction.SETUP_CARD_SLIDER
    ) {
      // dispatch an action to reset the store calibration/slider value
      // here we are navigation back to the card slider and want to reset the cardCalibration
      this.store.dispatch(
        PreviousInstructionAction({ shouldResetCalibration: true })
      );
    } else {
      this.store.dispatch(
        PreviousInstructionAction({ shouldResetCalibration: false })
      );
    }
  }

  setSliderMoved(): void {
    this.isSliderMoved = true;
  }

  setSliderPosition(sliderPosition: SliderPosition): void {
    this.trackSliderPosition = sliderPosition;
  }

  setDevice(device: string): void {
    this.calibDevice = device;
  }

  setPixelPitch(pixelPitch: number): void {
    this.pixelPitchValue = pixelPitch;
  }

  closeWarning(): void {
    this.analytics.createCustomEvent({
            eventValue: 'calibrationwarning',
            eventDetail: 'gobacktocalibration'
    });
    this.showCardWarning$.next(false);
  }

  // TODO: Perhaps move it to calibrate-screen-with-card.component
  cardCalibrationDone(): void {
    this.showCardWarning$.next(false);
    // push history state for instruction page back navigation; see also this.next()
    history.pushState(null, '');

    this.store.dispatch(
      SetScreenCalibrationAction({
        screenCalibration: this.pixelPitchValue,
        calibDevice: this.calibDevice
      })
    );

    if (
      !this.trackSliderPosition ||
      (this.trackSliderPosition as any) == '' ||
      (this.trackSliderPosition as any) == 0
    ) {
      this.trackSliderPosition = SliderPosition.DEFAULT;
    }
    this.analytics.createCustomEvent({
      eventValue: `${GA4_EventValue.ScreenDimensionSetupCompleted}`, //prev. 'creditcardcalibration'
      eventDetail: `${GA4_EventDetail.Sliderposition} - ${this.trackSliderPosition}`,
      eventName: `${GA4_EventName.CTA}`,
      eventAction: `${GA4_EventAction.Click}`,
      eventType: `${GA4_EventType.Internal}`
    });
  }

  done(): void {
    this.timeout = setTimeout(() => {
      this.store.dispatch(FinishInstructionsAction());

      this.analytics.createCustomEvent({
        event: 'event',
        eventName: `${GA4_EventName.CTA}`,
        eventAction: `${GA4_EventAction.Click}`,
        eventType: `${GA4_EventType.Internal}`,
        eventValue: `${GA4_EventValue.Instructions}`,
        eventDetail: `${this.activeInstructionIndex + 1}`
      });
    }, 100);
  }

  clickCross(): void {
    if (
      this.instructionsSet[this.activeInstructionIndex] ==
      Instruction.SETUP_BRIGHTNESS
    ) {
      this.backBrightness();
    } else {
      this.backDefaultInstructions();
    }
  }

  backBrightness(): void {
    this.store.dispatch(OpenHomePageAction());

    this.analytics.createCustomEvent({
      event: 'event',
      eventName: `${GA4_EventName.CTA}`,
      eventAction: `${GA4_EventAction.Click}`,
      eventType: `${GA4_EventType.Internal}`,
      eventValue: `${GA4_EventValue.BrightnessSetupNotCompleted}`,
      eventDetail: `${GA4_EventDetail.Clear}`
    });
  }

  backDefaultInstructions(): void {
    this.store.dispatch(
      ShowCloseWarningAction({
        context: CloseDialogContext.Check,
        previousPath: this.locationService.path()
      })
    );

    this.analytics.createCustomEvent({
      event: 'event',
      eventName: `${GA4_EventName.CTA}`,
      eventAction: `${GA4_EventAction.Click}`,
      eventType: `${GA4_EventType.Internal}`,
      eventValue: `${GA4_EventValue.CancelInstructions}`,
      eventDetail: `${GA4_EventDetail.Clear}`
    });
  }
}
