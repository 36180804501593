import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

// Stuff for general
import { GeneralService } from '../../../services/general.service';

// Special for checks
import { OpenAcuityDetailsPageAction } from '../../../acuity-test/store/actions/acuity.actions';
import { OpenContrastDetailsPageAction } from '../../../contrast-test/store/actions/contrast.actions';
import { OpenColorDetailsPageAction } from '../../../color-test/store/actions/color.actions';
import { OpenAstigmatismDetailsPageAction } from '../../../astigmatism-test/store/actions/astigmatism.actions';
import { OpenAmslerDetailsPageAction } from '../../../amsler-test/store/actions/amsler.actions';

// From shared folder
import { GA4_EventDetail, GA4_EventAction, GA4_EventName, GA4_EventType, GA4_EventValue } from '../../../shared/enums/ga4.enum';
import { TEST_TYPES } from '../../../shared/enums/test-types';
import { ShowImportantNotesAction } from '../../../store/actions/check-calibration.actions';
import { TestTypesForImportantNotes } from '../../enums/test-types-important-notes.enum';
import { AnalyticsService } from '../../../services/analytics.service';

@Component({
  selector: 'zat-test-card',
  templateUrl: './test-card.component.html',
  styleUrls: ['./test-card.component.scss']
})
export class TestCardComponent implements OnInit, OnDestroy {
  @Input() test: TEST_TYPES;

  public TEST_TYPES = TEST_TYPES;
  testCode: string = '';

  // For Quad-Image
  dimension: number = 80;

  constructor(
    private store: Store,
    private generalService: GeneralService,
    private analytics: AnalyticsService
  ) { }

  ngOnInit(): void {
    switch (this.test) {
      case TEST_TYPES.ACUITY:
        this.testCode = 'acuityTest';
        break;
      case TEST_TYPES.CONTRAST:
        this.testCode = 'contrastTest';
        break;
      case TEST_TYPES.COLOR:
        this.testCode = 'colorTest';
        break;
      case TEST_TYPES.ASTIGMATISM:
        this.testCode = 'astigmatismTest';
        break;
      case TEST_TYPES.AMSLER:
        this.testCode = 'amslerTest';
        break;
    }
  }

  ngOnDestroy(): void { }

  checkTheKey(event: any): void {
    if (event.code === 'Space' || event.code === 'Enter') {
      this.detail();
    }
  }

  detail(): void {
    let eventDetail: string = '';
    switch (this.test) {
      case TEST_TYPES.ACUITY:
        this.store.dispatch(OpenAcuityDetailsPageAction());
        eventDetail = GA4_EventDetail.Acuity;
        break;
      case TEST_TYPES.CONTRAST:
        this.store.dispatch(OpenContrastDetailsPageAction());
        eventDetail = GA4_EventDetail.Contrast;
        break;
      case TEST_TYPES.COLOR:
        this.store.dispatch(OpenColorDetailsPageAction());
        eventDetail = GA4_EventDetail.Color;
        break;
      case TEST_TYPES.ASTIGMATISM:
        this.store.dispatch(OpenAstigmatismDetailsPageAction());
        eventDetail = GA4_EventDetail.Astigmatism;
        break;
      case TEST_TYPES.AMSLER:
        this.store.dispatch(OpenAmslerDetailsPageAction());
        eventDetail = GA4_EventDetail.Amsler;
        break;
    }

    this.analytics.createCustomEvent({
      event: 'event',
      eventName: `${GA4_EventName.CTA}`,
      eventAction: `${GA4_EventAction.Click}`,
      eventType: `${GA4_EventType.Internal}`,
      eventValue: `${GA4_EventValue.Details}`,
      eventDetail: `${eventDetail}`
    });

    this.generalService.routeToNextScreen(`/${this.test}`);
  }

  startSingleTest(): void {
    // check value in storage on native devices
    switch (this.test) {
      case TEST_TYPES.ACUITY:
        this.store.dispatch(ShowImportantNotesAction({ testType: TestTypesForImportantNotes.ACUITY }));
        break;
      case TEST_TYPES.CONTRAST:
        this.store.dispatch(ShowImportantNotesAction({ testType: TestTypesForImportantNotes.CONTRAST }));
        break;
      case TEST_TYPES.COLOR:
        this.store.dispatch(ShowImportantNotesAction({ testType: TestTypesForImportantNotes.COLOR }));
        break;
      case TEST_TYPES.ASTIGMATISM:
        this.store.dispatch(ShowImportantNotesAction({ testType: TestTypesForImportantNotes.ASTIGMATISM }));
        break;
      case TEST_TYPES.AMSLER:
        this.store.dispatch(ShowImportantNotesAction({ testType: TestTypesForImportantNotes.AMSLER }));
        break;
    }
  }

  mapTestString(testString: string): string {
    return this.generalService.mapTestString(testString);
  }
}
