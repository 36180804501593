export enum LanguagesEnum {
  EN = 'en-INT',
  ES = 'es-ES',
  DE = 'de-DE',
  PT = 'pt-BR',
  ZH = 'zh-CN',
  FR = 'fr-FR',
  TR = 'tr-TR',
  IT = 'it-IT',
  NL = 'nl-NL',
  RO = 'ro-RO',
  SV = 'sv-SE',
  RU = 'ru-RU',
  PL = 'pl-PL',
  KO = 'ko-KR',
  HU = 'hu-HU',
  CS = 'cs-CZ',
  DA = 'da-DK',
  FI = 'fi-FI',
  NO = 'no-NO',
  SK = 'sk-SK',
  JA = 'ja-JP',
  BG = 'bg-BG',
  HR = 'hr-HR'
}
