import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

// Special for checks
import {
  getContinueWithoutCheck
} from '../../../../store/selectors/app.selectors';

// From shared folder
import { DEFAULT_PIXEL_PITCH_IN_CM } from '../../../config';
import { SliderPosition } from '../../../enums/slider-position.enum';

@UntilDestroy()
@Component({
  selector: 'app-calibrate-screen-with-card',
  templateUrl: './calibrate-screen-with-card.component.html',
  styleUrls: ['./calibrate-screen-with-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalibrateScreenWithCardComponent implements OnInit, OnDestroy, AfterViewInit {

  /*
   * dimensions of real-world objects in centimeters unit
   * NOTE: The width in px in the browser seems to be 356,
   * but with 270 we get similar results to the old implementation that got already verified.
   */
  CREDIT_CARD_WIDTH = 5.398;
  CREDIT_CARD_WIDTH_PX = 270;
  NORMAL_SPACING = 24; // like the scss-variable $normal-spacing

  readonly MIN:number = 0.4;
  MAX_MOBILE = 1.8;
  MAX_DESKTOP = 1.4;
  INITIAL_VALUE_MOBILE = 1.1;
  INITIAL_VALUE_DESKTOP = 0.9;

  // create dynamic properties which are instantiated at runtime
  MAX!: number;
  initialValue!: number;
  scale!: number;
  //CARE!!! input type="range" silder returns a string!
  sliderValue!:any;

  isDesktop$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private pixelPitchValue: number;

  @ViewChild('slidingContent') slidingContent: ElementRef;
  @ViewChild('slidingCard') slidingCard: ElementRef;
  @ViewChild('diameter') diameter: ElementRef;
  cardTextOffset$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  sliderElement: any;

  @Output('isSliderMoved') isSliderMoved: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output('sliderPosition') sliderPosition: EventEmitter<SliderPosition> =
    new EventEmitter<SliderPosition>();
  @Output('device') device: EventEmitter<string> = new EventEmitter<string>();
  @Output('pixelPitch') pixelPitch: EventEmitter<number> =
    new EventEmitter<number>();

  nextWithoutCheck$: Observable<boolean> = this.store.select(
    getContinueWithoutCheck
  );

  // rootRef references this exact component
  constructor(
    private store: Store,
    private rootRef: ElementRef,
    private zone: NgZone
  ) {
    this.MAX = this.MAX_DESKTOP;
    this.initialValue = this.INITIAL_VALUE_DESKTOP;
    this.scale = this.INITIAL_VALUE_DESKTOP;
    this.sliderValue = this.INITIAL_VALUE_DESKTOP;
  }

  ngOnInit(): void {
    this.pixelPitch.emit(DEFAULT_PIXEL_PITCH_IN_CM);
  }

  ngAfterViewInit(): void {
    // resize observer to determine isDesktop
    // (previously done via break-point observer but that odes not work for embedded webComponent)
    let rootSize$: Observable<{ width: number; height: number }> =
      new Observable((observer) => {
        const resizeObserver = new ResizeObserver((entries) => {
          this.zone.run(() => {
            observer.next({
              width: entries[0].contentRect.width,
              height: entries[0].contentRect.height
            });
          });
        });
        resizeObserver.observe(this.rootRef?.nativeElement);
        return () => {
          resizeObserver.disconnect();
          observer.complete();
        };
      });

    // we can only instantiate this in ngAfterViewInit() because we need the view element for the resizeObs above first
    // but we also want to subscribe to isDesktop$ form the start (in html), thus we need to next on it
    rootSize$
      .pipe(
        map((elementSize) => {
          return elementSize.width >= 768;
        }),
        tap((isDesktop) => {
          if(!isDesktop){
            this.MAX = this.MAX_MOBILE;
            this.initialValue = this.INITIAL_VALUE_MOBILE;
            this.scale = this.INITIAL_VALUE_MOBILE;
            this.sliderValue = this.INITIAL_VALUE_MOBILE;
            this.device.emit('Mobile');
          }
          else{
            this.MAX = this.MAX_DESKTOP;
            this.initialValue = this.INITIAL_VALUE_DESKTOP;
            this.scale = this.INITIAL_VALUE_DESKTOP;
            this.sliderValue = this.INITIAL_VALUE_DESKTOP;
            this.device.emit('Desktop');
          }
          this.isDesktop$.next(isDesktop);
        })
      )
      .subscribe();

    // resize observers for card/card-container
    let containerSize$: Observable<{ width: number; height: number }> =
      new Observable((observer) => {
        const resizeObserver = new ResizeObserver((entries) => {
          this.zone.run(() => {
            observer.next({
              width: entries[0].contentRect.width,
              height: entries[0].contentRect.height
            });
          });
        });
        resizeObserver.observe(this.slidingContent?.nativeElement);
        return () => {
          resizeObserver.disconnect();
          observer.complete();
        };
      });

    let cardSize$: Observable<{ width: number; height: number }> =
      new Observable((observer) => {
        const resizeObserver = new ResizeObserver((entries) => {
          this.zone.run(() => {
            observer.next({
              width: entries[0].contentRect.width,
              height: entries[0].contentRect.height
            });
          });
        });
        resizeObserver.observe(this.slidingCard?.nativeElement);
        return () => {
          resizeObserver.disconnect();
          observer.complete();
        };
      });

    combineLatest([cardSize$, containerSize$])
      .pipe(
        map(([cardSize, containerSize]) => {
          // 140 is an experimental value and should be something like the min-right offset of the card
          let cardTextOffset = Math.max(
            this.NORMAL_SPACING,
            cardSize.width + 140 - containerSize.width
          );
          return cardTextOffset;
        }),
        tap((cardTextOffset) => {
          this.cardTextOffset$.next(cardTextOffset);
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  // DO NOT DELETE
  // this method needs to be here for `untilDestroyed()` to work
  ngOnDestroy(): void {}

  onSliderChange(event: any): void {
    this.isSliderMoved.emit(true);
    this.calculatePixelPitch(event.target.value);

    // Set the styling for the slider
    let gradientValue: number =
      ((event.target.value - event.target.min) /
        (event.target.max - event.target.min)) *
      100;
    this.diameter.nativeElement.style.background =
      'linear-gradient(to right, var(--primary-blue) 0%, var(--primary-blue) ' +
      gradientValue +
      '%, var(--fill-color-inactive) ' +
      gradientValue +
      '%, var(--fill-color-inactive) 100%)';
  }

  private calculatePixelPitch(value: number): void {
    this.sliderValue = value;

    this.scale = value;

    const pxValue = this.sliderValue * this.CREDIT_CARD_WIDTH_PX;
    const newPixelPitch = parseFloat(
      (this.CREDIT_CARD_WIDTH / pxValue).toFixed(4)
    );

    this.pixelPitchValue = newPixelPitch;
    this.pixelPitch.emit(this.pixelPitchValue);
    this.trackSliderPosition();
  }

  // care: this is only called when the slider is moved
  private trackSliderPosition(): SliderPosition {
    let eventLabel: SliderPosition;
    if (this.sliderValue == this.initialValue) {
      eventLabel = SliderPosition.DEFAULT;
    } else if (this.sliderValue == this.MIN) {
      eventLabel = SliderPosition.MIN;
    } else if (this.sliderValue == this.MAX) {
      eventLabel = SliderPosition.MAX;
    } else {
      eventLabel = SliderPosition.MOVED;
    }
    this.sliderPosition.emit(eventLabel);
    return eventLabel;
  }
}
