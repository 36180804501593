<div class="indicator-dots">
  <ng-container *ngFor="let item of indicatorDots; let i = index">
    <div
      class="dot-space"
      (click)="click(i)"
      [ngClass]="{
        active: item === IndicatorEnum.Active,
        clickable: item === IndicatorEnum.Clickable
      }"
    >
      <ng-container [ngSwitch]="item">
        <div *ngSwitchCase="IndicatorEnum.Active" class="dot active"></div>
        <div
          *ngSwitchCase="IndicatorEnum.Clickable"
          class="dot"
        ></div>
        <div *ngSwitchCase="IndicatorEnum.Dot" class="dot"></div>
      </ng-container>
    </div>
  </ng-container>
</div>
