import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { RouterTestingModule } from '@angular/router/testing';

// Stuff for general
import { environment } from '../environments/environment';
import { AmslerTestGuard } from './guards/amsler-test.guard';
import { LocaleGuard } from './guards/locale.guard';
import { MainVersionGuard } from './guards/main-version.guard';
import { ActiveTestResolver } from './resolvers/active-test.resolver';
import { AuthorizationGuard } from './guards/authorization.guard';

// From shared folder
import { AppWrapperComponent } from './shared/components/app-wrapper/app-wrapper.component';
import { ImportantNotePageComponent } from './shared/components/dialog-switch-wrapper/dialogs/important-note-page/important-note-page.component';
import { RoutePathes } from './shared/enums/route-pathes.enum';
import { CloseDialogComponent } from './shared/components/close-dialog/close-dialog.component';
import { NotAuthorizedComponent } from './shared/components/not-authorized/not-authorized.component';
import { TEST_TYPES } from './shared/enums/test-types';
import { DEFAULT_LOCALE } from './shared/app-channels';
import { ChubResultCodeScreenComponent } from './chub-result-code-screen/chub-result-code-screen.component';

const childRoutes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./portal/portal.module').then((m) => m.PortalModule),
    data: {
      animation: 'PortalPage'
    }
  },
  {
    path: 'chubResultCode',
    component: ChubResultCodeScreenComponent
  },
  {
    path: 'close-warning',
    component: CloseDialogComponent
  },
  {
    path: RoutePathes.PreparationImportant_Notes,
    component: ImportantNotePageComponent
  },
  {
    path: TEST_TYPES.ACUITY,
    data: {
      activeTest: TEST_TYPES.ACUITY
    },
    resolve: {
      active: ActiveTestResolver
    },
    loadChildren: () =>
      import('./acuity-test/acuity-test.module').then((m) => m.AcuityTestModule)
  },
  {
    path: TEST_TYPES.CONTRAST,
    data: {
      activeTest: TEST_TYPES.CONTRAST
    },
    resolve: {
      active: ActiveTestResolver
    },
    loadChildren: () =>
      import('./contrast-test/contrast-test.module').then(
        (m) => m.ContrastTestModule
      )
  },
  {
    path: TEST_TYPES.ASTIGMATISM,
    data: {
      activeTest: TEST_TYPES.ASTIGMATISM
    },
    resolve: {
      active: ActiveTestResolver
    },
    loadChildren: () =>
      import('./astigmatism-test/astigmatism-test.module').then(
        (m) => m.AstigmatismTestModule
      )
  },
  {
    path: TEST_TYPES.AMSLER,
    data: {
      activeTest: TEST_TYPES.AMSLER
    },
    resolve: {
      active: ActiveTestResolver
    },
    loadChildren: () =>
      import('./amsler-test/amsler-test.module').then(
        (m) => m.AmslerTestModule
      ),
    canActivate: [AmslerTestGuard]
  },
  {
    path: TEST_TYPES.COLOR,
    data: {
      activeTest: TEST_TYPES.COLOR
    },
    resolve: {
      active: ActiveTestResolver
    },
    loadChildren: () =>
      import('./color-test/color-test.module').then((m) => m.ColorTestModule)
  },
  {
    path: 'default',
    loadChildren: () =>
      import('./mega-test/mega-test.module').then((m) => m.MegaTestModule)
  },
  {
    path: 'store-locator/:locator-params',
    loadChildren: () =>
      import('./store-locator/store-locator.module').then(
        (m) => m.StoreLocatorModule
      )
  }
];

const routes: Routes = [
  {
    path: '',
    redirectTo: `/${DEFAULT_LOCALE}`,
    pathMatch: 'full'
  },
  {
    path: RoutePathes.Not_Authorized,
    component: NotAuthorizedComponent
  },
  {
    path: ':locale',
    component: AppWrapperComponent,
    data: {
      animation: 'AppWrapper'
    },
    canActivate: [MainVersionGuard, LocaleGuard, AuthorizationGuard],
    children: [
      ...childRoutes,
      {
        path: '**',
        redirectTo: '',
        pathMatch: 'full'
      }
    ]
  }
];

@NgModule({
  // environment.webcomponent
  imports: environment.webcomponent
    ? [
        RouterTestingModule.withRoutes(routes, {
          useHash: true,
          scrollPositionRestoration: 'disabled'
        })
      ]
    : [
        RouterModule.forRoot(routes, {
          scrollPositionRestoration: 'disabled',
          preloadingStrategy: PreloadAllModules
        })
      ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
