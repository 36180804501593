import { Component, Input } from '@angular/core';

// From shared folder
import { Instruction } from '../../../../../../shared/enums';

@Component({
  selector: 'zat-how-it-works-checks-general',
  templateUrl: './how-it-works-checks-general.component.html',
  styleUrls: ['./how-it-works-checks-general.component.scss']
})
export class HowItWorksChecksGeneralComponent {
  @Input('coverEye') coverEye: string = Instruction.KEEP_BOTH_EYES;
  @Input('distance') distance: string = Instruction.KEEP_AN_ARM;

  instructions = Instruction;
}
