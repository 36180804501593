import { createSelector } from '@ngrx/store';

// Special for checks
import { getTestResult, logcs2decimal } from '../../shared';
import { fromContrast } from '../reducers';

// From shared folder
import { resultImageConverter } from '../../../shared/utils';
import { Eyes, TEST_RESULT } from '../../../shared/enums';

export const selectContrast = (state: {
  contrast: fromContrast.ContrastState;
}) => state.contrast;

// TODO: *REDUX* create proper feature selector for contrast check
//export const selectContrast = createFeatureSelector<ContrastState>('contrastState');

export const getActiveEye = createSelector(
  selectContrast,
  (state: fromContrast.ContrastState) => {
    return state.tests.activeEye;
  }
);

export const getActiveEyeFinished = createSelector(
  selectContrast,
  (state: fromContrast.ContrastState) => {
    if (state.tests.activeEye === Eyes.RIGHT) {
      return state?.rightEyeContrast?.finished;
    }

    return state?.leftEyeContrast?.finished;
  }
);

export const getBothEyesFinished = createSelector(
  selectContrast,
  (state: fromContrast.ContrastState) => {
    return state?.rightEyeContrast?.finished && state?.leftEyeContrast?.finished;
  }
);

export const getTestCount = createSelector(
  selectContrast,
  (state: fromContrast.ContrastState) => {
    return state.tests.count;
  }
);

export const getLandoltCRotationAngle = createSelector(
  selectContrast,
  (state: fromContrast.ContrastState) => {
    return state.tests.angle;
  }
);

export const getLandoltCContrast = createSelector(
  selectContrast,
  (state: fromContrast.ContrastState) => {
    return state.tests.contrast;
  }
);

export const getLandoltCOpacity = createSelector(
  selectContrast,
  (state: fromContrast.ContrastState) => {
    const contrastInLogCs = state.tests.contrast;
    return logcs2decimal(contrastInLogCs);
  }
);

export const getRawEyeContrasts = createSelector(
  selectContrast,
  (state: fromContrast.ContrastState) => {
    return {
      right: state?.rightEyeContrast?.value,
      left: state?.leftEyeContrast?.value
    };
  }
);

export const getEyeContrastsResult = createSelector(
  selectContrast,
  (state: fromContrast.ContrastState) => {
    const right = getTestResult(state?.rightEyeContrast?.value);
    const left = getTestResult(state?.leftEyeContrast?.value);
    const max = Math.max(right, left);
    const min = Math.min(right, left);
    let sumResult = max;
    let eyesResult;

    if (max === min) {
      eyesResult = TEST_RESULT.BOTHEYES;
    }
    else {
      eyesResult = TEST_RESULT.ONEEYE;
      sumResult = min;
    }

    return {
      sumResult,
      eyesResult,
      right,
      left
    };
  }
);

export const getResultImage = createSelector(
  selectContrast,
  (state: fromContrast.ContrastState) => {
    const right = getTestResult(state?.rightEyeContrast?.value);
    const left = getTestResult(state?.leftEyeContrast?.value);
    const leftEye = resultImageConverter(left);
    const rightEye = resultImageConverter(right);

    return {
      leftEye,
      rightEye
    };
  }
);

// get the summarized result for displaying one icon (if there are two different results for
// both eyes, then take the lower result score)
export const getResultImageLowerScore = createSelector(
  selectContrast,
  (state: fromContrast.ContrastState) => {
    const right = getTestResult(state?.rightEyeContrast?.value);
    const left = getTestResult(state?.leftEyeContrast?.value);

    let lowerScore;
    if(right < left) {
      lowerScore = right;
    } else {
      lowerScore = left;
    }

    const summarizedResult = resultImageConverter(lowerScore);
    return summarizedResult;
  }
);

export const getActiveEyeDirectionChanges = createSelector(
  selectContrast,
  (state: fromContrast.ContrastState) => {
    const activeEye = state.tests.activeEye;
    if (activeEye === Eyes.RIGHT) {
      return state?.rightEyeContrast?.directionChangeCounter;
    }

    return state?.leftEyeContrast?.directionChangeCounter;
  }
);

export const getEyesFinished = createSelector(
  selectContrast,
  (state: fromContrast.ContrastState) => {
    return {
      finishedLeft: state.leftEyeContrast.finished,
      finishedRight: state.rightEyeContrast.finished
    };
  }
);
