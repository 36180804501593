import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { LottieModule } from 'ngx-lottie';
import { LottieOptions } from 'ngx-lottie/lib/symbols';
import { NgLetModule } from 'ng-let';

// Stuff for general
import { AppIconsModule } from '../../app-icons/app-icons.module';

// Special for checks
import { SharedModule } from '../shared/shared.module';
import { AcuityCheckPageComponent } from './acuity-check-page/acuity-check-page.component';
import { AcuityCheckRoutingModule } from './acuity-check-routing/acuity-check-routing.module';
import { AcuityPrepareCheckPageComponent } from './acuity-prepare-check-page/acuity-prepare-check-page.component';
import { HowItWorksAcuityPageComponent } from './acuity-check-page/how-it-works-acuity-page/how-it-works-acuity-page.component';
import { AcuityResultPageComponent } from './acuity-result-page/acuity-result-page.component';

// From shared folder
import { AppMaterialModule } from '../../shared/app-material/app-material.module';
import { AppSharedModule } from '../../shared/app-shared.module';

export function playerFactory() {
  return import('lottie-web');
}

@NgModule({
  declarations: [
    AcuityCheckPageComponent,
    AcuityResultPageComponent,
    AcuityPrepareCheckPageComponent,
    HowItWorksAcuityPageComponent
  ],
  imports: [
    CommonModule,
    AcuityCheckRoutingModule,
    SharedModule,
    AppMaterialModule,
    TranslocoModule,
    AppSharedModule,
    AppIconsModule,
    NgLetModule,
    LottieModule.forRoot({ player: playerFactory, useCache: true } as LottieOptions),
  ]
})
export class AcuityCheckModule {}
