import { Component } from '@angular/core';
import { AppSharedModule } from '../shared/app-shared.module';
import { TranslocoModule } from '@ngneat/transloco';
import { CommonModule } from '@angular/common';
import { Observable, delay, of, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import { getResultCode } from '../store/selectors/app.selectors';
import { environment } from '../../environments/environment';
import { ModalController } from '@ionic/angular';
import { AnalyticsService } from '../services/analytics.service';
import {
  GA4_EventAction,
  GA4_EventDetail,
  GA4_EventName,
  GA4_EventType,
  GA4_EventValue
} from '../shared/enums/ga4.enum';

@Component({
  standalone: true,
  imports: [AppSharedModule, CommonModule, TranslocoModule],
  selector: 'zat-chub-result-code-screen',
  templateUrl: './chub-result-code-screen.component.html',
  styleUrls: ['./chub-result-code-screen.component.scss']
})
export class ChubResultCodeScreenComponent {
  constructor(
    private store: Store,
    private modalController: ModalController,
    private analytics: AnalyticsService
  ) {}
  // subscribed in html
  resultCode$: Observable<string> = this.store.select(getResultCode).pipe(
    tap((code) => {
      this.resultCode = code;
    })
  );
  resultCode: string;
  signUpUrl = environment.chubBaseUrl;

  isToastVisible: boolean = false;

  handleClose() {
    // navigate back to result-page
    this.modalController.dismiss();
  }

  handleCopyToClipboard() {
    // copy code to clipboard
    navigator.clipboard.writeText(this.resultCode);

    //analytics event
    this.analytics.createCustomEvent({
      event: 'event',
      eventName: `${GA4_EventName.CTA}`,
      eventAction: `${GA4_EventAction.Copy}`,
      eventType: `${GA4_EventType.ResultCode}`,
      eventValue: `${GA4_EventValue.ChubCopyResultCode}`,
      eventDetail: `${GA4_EventDetail.CHubResultCodeModal}`
    });

    // toast shows via absolutly positioned div (see this component html) so iit works in webComp
    of(null)
      .pipe(
        tap(() => {
          this.isToastVisible = !this.isToastVisible;
        }),
        delay(2000),
        tap(() => {
          this.isToastVisible = !this.isToastVisible;
        })
      )
      .subscribe();
  }

  handleSignUp() {
    this.analytics.createCustomEvent({
      event: 'event',
      eventName: `${GA4_EventName.CTA}`,
      eventAction: `${GA4_EventAction.Click}`,
      eventType: `${GA4_EventType.Outbound}`,
      eventValue: `${GA4_EventValue.SignUpMyZeissVision}`,
      eventDetail: `${GA4_EventDetail.CHubResultCodeModal}`
    });

    window.open(this.signUpUrl, '_blank');
  }

  handleSignInAnalytics() {
    this.analytics.createCustomEvent({
      event: 'event',
      eventName: `${GA4_EventName.CTA}`,
      eventAction: `${GA4_EventAction.Click}`,
      eventType: `${GA4_EventType.Outbound}`,
      eventValue: `${GA4_EventValue.SignUpMyZeissVision}`,
      eventDetail: `${GA4_EventDetail.CHubResultCodeModal}`
    });
  }
}
