import { createAction, props } from '@ngrx/store';

// Stuff for general
import { Eyes, Instruction } from '../../../shared/enums';
import { CloseDialogContext } from '../../../shared/components/close-dialog/close-dialog-context.enum';

const ContrastTest = 'Contrast Test';

export const Increase = createAction(
  `[${ContrastTest}] Increase`,
);

export const Decrease = createAction(
  `[${ContrastTest}] Decrease`,
);

export const IncreaseTestCount = createAction(
  `[${ContrastTest}] IncreaseTestCount`,
);

export const ChangeCAngle = createAction(
  `[${ContrastTest}] ChangeCAngle`,
);

export const StoreValue = createAction(
  `[${ContrastTest}] StoreContrastValue`,
  props<{
    eye: Eyes;
    value: number;
  }>(),
);

export const SetActiveEye = createAction(
  `[${ContrastTest}] SetActiveEye`,
  props<{
    eye: Eyes;
  }>(),
);

export const IncreaseMaxContrastStepCounter = createAction(
  `[${ContrastTest}] IncreaseMaxContrastStepCounter`,
  props<{
    eye: Eyes;
  }>(),
);

export const IncreaseMinContrastStepCounter = createAction(
  `[${ContrastTest}] IncreaseMinContrastStepCounter`,
  props<{
    eye: Eyes;
  }>(),
);

export const ResetMaxContrastStepCounter = createAction(
  `[${ContrastTest}] ResetMaxContrastStepCounter`,
  props<{
    eye: Eyes;
  }>(),
);

export const ResetMinContrastStepCounter = createAction(
  `[${ContrastTest}] ResetMinContrastStepCounter`,
  props<{
    eye: Eyes;
  }>(),
);

export const IncreaseDirectionChangeCounter = createAction(
  `[${ContrastTest}] IncreaseDirectionChangeCounter`,
  props<{
    eye: Eyes;
  }>(),
);

export const ResetTests = createAction(
  `[${ContrastTest}] ResetTests`,
);

export const ResetEyeContrast = createAction(
  `[${ContrastTest}] ResetEyeContrast`,
);

export const OpenContrastDetailsPageAction = createAction(`[${ContrastTest}] Open contrast details page`);

export const ShowCloseWarningAction = createAction(
  `[${ContrastTest}] ShowCloseWarning`,
  props<{
    context: CloseDialogContext;
    previousPath?: string;
  }>()
);

export const StartContrastSingleCheckAction = createAction(
  `[${ContrastTest}] Start contrast single check`
);

export const StartContrastSingleCheckSuccededAction = createAction(
  `[${ContrastTest}] Start contrast single check succeded`,
  props<{
    instructions: Array<Instruction>;
  }>()
);

export const StartContrastMegaTestAction = createAction(
  `[${ContrastTest}] Start contrast mega test`
);

export const StartContrastMegaTestSuccedAction = createAction(
  `[${ContrastTest}] Start contrast mega test succed`,
  props<{
    instructions: Array<Instruction>;
  }>()
);
