import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { delay, take } from 'rxjs/operators';
import { Location } from '@angular/common';

// Stuff for general
import { environment } from '../../../environments/environment';
import { OpenHomePageAction } from '../../store/actions/general.actions';
import { AppSelectors } from '../../store/selectors';
import { GeneralService } from '../../services/general.service';
import { AppLocalizeRouterService } from '../../services/app-localize-router.service';
import { TestTypeActions } from '../../store/actions';

// Special for checks
import { getCurrentTest } from '../store/selectors/amsler.selectors';
import { AmslerActions, AmslerSelectors } from '../store';
import { AmslerTests } from '../shared';

// From shared folder
import {
  GA4_EventDetail,
  GA4_EventAction,
  GA4_EventName,
  GA4_EventType,
  GA4_EventValue
} from '../../shared/enums/ga4.enum';
import { CloseDialogContext } from '../../shared/components/close-dialog/close-dialog-context.enum';
import {
  HeaderColorEnum,
  HeaderLabelEnum,
  HeaderEnum
} from '../../shared/enums/header.enum';
import { HeaderConfig } from '../../shared/interfaces/header-config.interface';
import { RoutePathes } from '../../shared/enums/route-pathes.enum';
import { TEST_TYPES } from '../../shared/enums/test-types';
import { Eyes } from '../../shared/enums';
import { fadeInAnimation } from '../../shared/animations';
import { AnalyticsService } from '../../services/analytics.service';

@UntilDestroy()
@Component({
  selector: 'zat-amsler-test-page',
  templateUrl: './amsler-test-page.component.html',
  styleUrls: ['./amsler-test-page.component.scss'],
  animations: [fadeInAnimation]
})
export class AmslerTestPageComponent implements OnInit, OnDestroy {
  megaTestActive$: Observable<boolean>;
  debug$: Observable<boolean> = this.store.select(AppSelectors.getDebug);

  activeEye: Eyes;
  megaTestActive: boolean;

  private timeout: number;

  headerConfig: HeaderConfig = {
    label: HeaderLabelEnum.AmslerCheck,
    right: HeaderEnum.Close,
    left: HeaderEnum.Info,
    theme: HeaderColorEnum.LightMode
  } as HeaderConfig;

  toggleHint$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  amslerTest$: Observable<AmslerTests> = this.store.select(getCurrentTest);
  AmslerTests = AmslerTests;
  Eyes = Eyes;
  pixelPitch: number;
  gapSize: number;

  // trigger close warning dialog for checks when browser back button is clicked
  @HostListener('window:popstate', ['$event'])
  onPopState() {
    if (!environment.webcomponent) {
      history.pushState(null, null, location.href);
      this.cross();
    }
  }

  constructor(
    private store: Store,
    private router: Router,
    private localize: AppLocalizeRouterService,
    private generalService: GeneralService,
    private locationService: Location,
    private analytics: AnalyticsService
  ) {
    history.pushState(null, null, location.href);
  }

  ngOnInit(): void {
    this.megaTestActive$ = this.store.select(AppSelectors.getMegaTestActive);

    this.store
      .select(AmslerSelectors.getActiveEye)
      .pipe(untilDestroyed(this))
      .subscribe((activeEye) => (this.activeEye = activeEye));

    this.megaTestActive$
      .pipe(untilDestroyed(this))
      .subscribe((active) => (this.megaTestActive = active));

    let amslerGridSize = this.generalService.getAmslerGridSize();
    this.pixelPitch = amslerGridSize.pixelPitch;
    this.gapSize = amslerGridSize.gapSize;
  }

  ngOnDestroy(): void {
    window.clearTimeout(this.timeout);
  }

  cancelTest(): void {
    this.store.dispatch(OpenHomePageAction());
  }

  onComplete(activeEye: Eyes): void {
    // finish right eye
    if (activeEye === Eyes.RIGHT) {
      this.store.dispatch(AmslerActions.SetActiveEye({ eye: Eyes.LEFT }));
      let rightPath = `${TEST_TYPES.AMSLER}${RoutePathes.CheckPrepare}`;
      if (this.megaTestActive) {
        rightPath = `${RoutePathes.Default}/${rightPath}`;
      }

      this.generalService.routeToNextScreen(rightPath);
      return;
    }

    this.goToResults();
  }

  private goToResults(): void {
    // finish left eye
    let leftPath = `/${TEST_TYPES.AMSLER}/check/result`;

    if (this.megaTestActive) {
      this.store.dispatch(
        TestTypeActions.SetTestInMegaTest({ testType: TEST_TYPES.AMSLER })
      );
      leftPath = `default/${leftPath}`;
    }
    const url = this.localize.translateRoute([leftPath]) as any;

    this.timeout = window.setTimeout(() => {
      this.router.navigate(url);
    }, 300);
  }

  openInfo(): void {
    this.toggleHint$.next(true);

    this.analytics.createCustomEvent({
      event: 'event',
      eventName: `${GA4_EventName.CTA}`,
      eventAction: `${GA4_EventAction.Click}`,
      eventType: `${GA4_EventType.Internal}`,
      eventValue: `${GA4_EventValue.Amsler}`,
      eventDetail: `${GA4_EventDetail.HowItWorks}`
    });
  }

  closeHint(): void {
    this.toggleHint$.next(false);
  }

  cross(): void {
    this.analytics.createCustomEvent({
      event: 'event',
      eventName: `${GA4_EventName.CTA}`,
      eventAction: `${GA4_EventAction.Click}`,
      eventType: `${GA4_EventType.Amsler}`,
      eventValue: `${GA4_EventValue.ExitCheck}`,
      eventDetail: `${GA4_EventDetail.Clear}`
    });

    of(null)
      .pipe(delay(0), take(1))
      .subscribe(() => {
        this.store.dispatch(
          AmslerActions.ShowCloseWarningAction({
            context: CloseDialogContext.Check,
            previousPath: this.locationService.path()
          })
        );
      });
  }
}
