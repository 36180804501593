import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

// Stuff for general
import { environment } from '../../../environments/environment';
import { AppSelectors } from '../../store/selectors';
import { AppCookiesService } from '../../services/app-cookies.service';
import { AppLocalizeRouterService } from '../../services/app-localize-router.service';
import { GeneralService } from '../../services/general.service';
import { OpenCheckSelectionPageAction } from '../../store/actions/general.actions';

// From shared folder
import { StartPageClass } from '../../shared/classes/start-page.class';
import { HeaderConfig } from '../../shared/interfaces/header-config.interface';
import { HeaderEnum, HeaderColorEnum, HeaderLabelEnum } from '../../shared/enums/header.enum';
import { fadeInAnimation } from '../../shared/animations';
import { ShowImportantNotesAction } from '../../store/actions/check-calibration.actions';
import { TestTypesForImportantNotes } from '../../shared/enums/test-types-important-notes.enum';

@UntilDestroy()
@Component({
  selector: 'zat-acuity-details-page',
  templateUrl: './acuity-details-page.component.html',
  styleUrls: ['./acuity-details-page.component.scss'],
  animations: [fadeInAnimation]
})
export class AcuityDetailsPageComponent
  extends StartPageClass
  implements OnInit, OnDestroy {
  semver$: Observable<string>;
  buildDate$: Observable<string>;
  hash$: Observable<string>;
  debug$: Observable<boolean>;

  debug = false;
  public environment = environment;

  srcImageNameOne:string = "img_acuity_01@2x.jpg"
  srcImageNameTwo:string = "img_acuity_02@2x.jpg"

  headerConfig: HeaderConfig = {
    label: HeaderLabelEnum.AcuityDetails,
    left: HeaderEnum.Back,
    right: HeaderEnum.Empty,
    theme: HeaderColorEnum.LightMode
  } as HeaderConfig;

  constructor(
    protected store: Store,
    protected router: Router,
    protected localize: AppLocalizeRouterService,
    protected appCookiesService: AppCookiesService,
    protected generalService: GeneralService
  ) {
    super(store, router, localize, appCookiesService);
  }

  ngOnInit(): void {
    this.debug$ = this.store.select(AppSelectors.getDebug);

    this.debug$
      .pipe(untilDestroyed(this))
      .subscribe((debug) => (this.debug = debug));
  }

  // DO NOT DELETE
  // this method needs to be here for `untilDestroyed()` to work
  ngOnDestroy(): void { }

  start(): void {
    this.store.dispatch(ShowImportantNotesAction({ testType: TestTypesForImportantNotes.ACUITY }));
  }

  removeLastLetter(title: string): string {
    return this.generalService.removeLastLetter(title);
  }

  back(): void {
    this.store.dispatch(OpenCheckSelectionPageAction());
  }
}
