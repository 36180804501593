<div class="va-content--result" *transloco="let t">
  <div class="va-description-image">
    <app-icon id="acuityTestSmall" name="acuity_test_small" [width]="30" [height]="30" alt="" class="icon-small">
    </app-icon>
    <span class="icon-title" [innerHTML]="t('acuityTest.result.testName')"></span>
  </div>

  <div class="va-description-wrapper">
    <div class="va-description text-body">
      <ng-container *ngIf="results.eyesResult === RESULT.BOTHEYES">
        <span *ngIf="results.sumResult === RESULT.EXCELLENT" [innerHTML]="t('acuityTest.result.bothEyesExcellent')">
        </span>
        <span *ngIf="results.sumResult === RESULT.FINE" [innerHTML]="t('acuityTest.result.bothEyesFine')">
        </span>
        <span *ngIf="results.sumResult === RESULT.REDUCED" [innerHTML]="t('acuityTest.result.bothEyesReduced')">
        </span>
      </ng-container>

      <ng-container *ngIf="results.eyesResult === RESULT.ONEEYE">
        <span *ngIf="results.sumResult === RESULT.FINE" [innerHTML]="t('acuityTest.result.oneEyeFine')">
        </span>
        <span *ngIf="results.sumResult === RESULT.REDUCED" [innerHTML]="t('acuityTest.result.oneEyeReduced')">
        </span>
      </ng-container>
    </div>

    <div *ngIf="image" class="va-values">
      <div class="text-center va-column"
        [attr.aria-label]="t('acuityTest.result.testName') + ' ' + t('result.eye.left') + ' ' + image.leftEye">
        <app-icon id="resultLeftEye" [name]="image.leftEye" [width]="96" [height]="56" alt="">
        </app-icon>
        <div class="va-label text-body">
          {{ t('result.eye.left') }}
        </div>
      </div>

      <div class="text-center va-column"
        [attr.aria-label]="t('acuityTest.result.testName') + ' ' + t('result.eye.right') + ' ' + image.rightEye">
        <app-icon id="acuityResultRightEye" [name]="image.rightEye" [width]="96" [height]="56" alt="">
        </app-icon>
        <div class="va-label text-body">
          {{ t('result.eye.right') }}
        </div>
      </div>
    </div>
  </div>

  <div class="save-result-cta" *ngIf="(showSaveFeature$|async) &&!(isMegaTestActive$|async)">
    {{t('result.generateYourCode')}}
    <zat-action-button (click)="handleResultCodeFlow()" [type]="'solid'">{{t('button.saveResults')}}</zat-action-button>
  </div>

  <zat-action-button *ngIf="(specialScreeningMode$ | async) === SpecialScreeningMode.ECP && !(isMegaTestActive$|async)" class="ecp-result-exit-btn"
    type="outline" size="small-medium" (click)="emitExitEcpResult()">
    {{ (buttonText$ | async) ?? t('button.ecpResultExit') }}
  </zat-action-button>
</div>
