export const enIntFallback =
{
  "acuityTest.result.bothEyesExcellent": "Your visual acuity in <strong>both eyes</strong> seems to be excellent.",
  "acuityTest.result.bothEyesFine": "Your visual acuity in <strong>both eyes</strong> seems to be fine.",
  "acuityTest.result.bothEyesReduced": "Your visual acuity in <strong>both eyes</strong> seems to be reduced.",
  "acuityTest.result.continueToContrastTest": "Next: contrast vision check",
  "acuityTest.result.imageAltLeftEye": "Your visual acuity in the left eye seems to be {{ status }}",
  "acuityTest.result.imageAltRightEye": "Your visual acuity in the right eye seems to be {{ status }}",
  "acuityTest.result.oneEyeFine": "Your visual acuity in <strong>one eye</strong> seems to be fine.",
  "acuityTest.result.oneEyeReduced": "Your visual acuity in <strong>one eye</strong> seems to be reduced.",
  "acuityTest.result.testName": "Visual acuity",
  "acuityTest.startPage.detailsText": "Check how sharp your vision is with our visual acuity check.",
  "acuityTest.startPage.detailsTitle": "Find the gap.",
  "acuityTest.startPage.howToTestDetails": "An optician will perform the visual acuity check with numbers or letters of different sizes. Our check uses a symbol similar to the letter C called the Landolt C, which is a ring with an opening at a defined position.<br><br>Simply identify the position of the gap and mark it on the corresponding ring. The Landolt Cs get smaller until we reach the smallest symbol you can recognize.",
  "acuityTest.startPage.howToTestTitle": "How do we check your visual acuity?",
  "acuityTest.startPage.imageAlt": "Visual Acuity",
  "acuityTest.startPage.imageCaptionBlurredVision": "Blurred vision",
  "acuityTest.startPage.imageCaptionNormalVision": "Normal vision",
  "acuityTest.startPage.name": "Visual acuity",
  "acuityTest.startPage.startCheck": "Start acuity vision check",
  "acuityTest.startPage.whyToTestDetails": "Visual acuity (the ability to detect small details) can change gradually, which means you may not notice small changes in your vision. That's why it's important to check your eyes regularly. You can do this with our screenings and follow up with an optician for a complete exam.",
  "acuityTest.startPage.whyToTestTitle": "Why check your visual acuity?",
  "amslerTest.doYouSeeAllSquaresRegular": "Do all lines and squares appear equal and regular?",
  "amslerTest.doYouSeeMissingParts": "Are any parts of the grid missing, distorted or darker than the rest?",
  "amslerTest.focusOnBlackDot": "Focus on the black dot in the centre.",
  "amslerTest.result.bothEyesExcellent": "Your field of vision in <strong>both eyes</strong> seems to be excellent.",
  "amslerTest.result.bothEyesReduced": "Your field of vision in <strong>both eyes</strong> seems to be reduced.",
  "amslerTest.result.imageAltLeftEye": "Your field of vision in the <strong>left eye</strong> seems to be {{ status }}",
  "amslerTest.result.imageAltRightEye": "Your field of vision in the <strong>right eye</strong> seems to be {{ status }}",
  "amslerTest.result.oneEyeReduced": "Your field of vision in <strong>one eye</strong> seems to be reduced.",
  "amslerTest.result.testName": "Visual field",
  "amslerTest.startPage.detailsText": "The visual field check can detect issues in your field of vision.",
  "amslerTest.startPage.detailsTitle": "Watch the dot.",
  "amslerTest.startPage.howToTestDetails": "Our visual field check uses a grid-like structure and is based on the clinically trusted Amsler test. Screening each eye in turn, we ask you to focus on a central point and verify if you can see all parts of the grid equally. Irregularities may appear as distorted or missing lines.<br><br>If you are using a phone or small screen, the Amsler grid may be smaller than the recommended size.",
  "amslerTest.startPage.howToTestTitle": "How do we check your field of vision?",
  "amslerTest.startPage.imageAlt": "Visual Field",
  "amslerTest.startPage.imageCaptionNormalVision": "Normal vision",
  "amslerTest.startPage.imageCaptionWithVisionFieldIssue": "Vision with visual field defect",
  "amslerTest.startPage.name": "Visual field",
  "amslerTest.startPage.startCheck": "Start visual field check",
  "amslerTest.startPage.whyToTestDetails": "The visual field is described as the space in which we perceive light and detect objects. This space can be reduced by certain eye diseases or neurological causes and may worsen over time. A normal central field of vision is important to many everyday tasks such as reading and office work. Our peripheral field of vision provides us with essential input while looking at things far away and driving a car.",
  "amslerTest.startPage.whyToTestTitle": "Why do I need to check my visual field?",
  "astigmatismTest.doYouSeeAllLinesInBlack": "Do all the lines look like they are the same shade of black?",
  "astigmatismTest.focusOnCenter": "Focus on the centre of the semicircle.",
  "astigmatismTest.result.continueToAmslerTest": "Next: visual field check",
  "astigmatismTest.result.excellent": "It seems like you aren't showing symptoms of astigmatism.",
  "astigmatismTest.result.fine": "It seems like you're noticing differences among the lines with <strong>one eye</strong>.",
  "astigmatismTest.result.imageAltLeftEye": "",
  "astigmatismTest.result.imageAltRightEye": "",
  "astigmatismTest.result.reduced": "It seems like you're noticing differences among the lines with <strong>both eyes</strong>.",
  "astigmatismTest.result.testName": "Astigmatism",
  "astigmatismTest.startPage.detailsText": "Check for signs of astigmatism.",
  "astigmatismTest.startPage.detailsTitle": "Beat the blur.",
  "astigmatismTest.startPage.howToTestDetails": "Our astigmatism check uses a semicircle of black lines designed to appear in different shades of grey if astigmatism is present. We ask you if you can see any shades of grey.<br><br>Remember: Our check does not replace a professional eye exam.",
  "astigmatismTest.startPage.howToTestTitle": "How do we check for astigmatism?",
  "astigmatismTest.startPage.imageAlt": "Astigmatism",
  "astigmatismTest.startPage.imageCaptionBlurredVision": "Vision with astigmatism",
  "astigmatismTest.startPage.imageCaptionNormalVision": "Normal vision",
  "astigmatismTest.startPage.name": "Astigmatism",
  "astigmatismTest.startPage.startCheck": "Start astigmatism check",
  "astigmatismTest.startPage.whyToTestDetails": "Astigmatism is an imperfection in the curvature of your eye's cornea or lens. It is a common condition that causes blurred or distorted vision and may worsen with age. Astigmatism can also cause eye strain, headaches and poor night vision. It can be corrected with properly fitted glasses or contact lenses.",
  "astigmatismTest.startPage.whyToTestTitle": "Why check for astigmatism?",
  "resultCodePage.toastMessage": "Code copied to clipboard",
  "resultCodePage.header": "Save your results in MyZEISS Vision",
  "resultCodePage.resultCodeHeader": "Your code",
  "resultCodePage.subHeader": "What’s next?",
  "resultCodePage.codeExplanation": "Copy this code and sign up for MyZEISS Vision for free or login to your existing account. You can then save your check results by entering the code. Your code is valid for 14 days.*",
  "resultCodePage.signInButton": "Sign up for MyZEISS Vision",
  "resultCodePage.signInCtaText": "Already have an account?",
  "resultCodePage.signInLink": "Sign in",
  "resultCodePage.footnote":"*Please note that if you won’t save your check results within these 14 days in MyZEISS Vision, your code will expire and your check results cannot be recovered.",
  "button.saveResults": "Save results",
  "button.agree": "I agree",
  "button.cancel": "Cancel",
  "button.close": "Close",
  "button.continue": "Continue check",
  "button.continueWithoutCalibration": "Continue without calibration",
  "button.details": "Details",
  "button.ecpResultExit": "Book an appointment",
  "button.goBack": "Go back",
  "button.gotIt": "Got it",
  "button.hideAll": "Hide all",
  "button.next": "Next step",
  "button.no": "No",
  "button.ready": "I'm ready",
  "button.save": "Save results",
  "button.sendViaMail": "Send results via email",
  "button.showAll": "Show all",
  "button.showAllResults": "Show all results",
  "button.showNearbyExpert": "Show nearby experts",
  "button.summarize": "Quit",
  "button.summarizeSave": "Quit & save results",
  "button.testYourVision": "Check your vision",
  "button.visitOurWebsite": "Back to our website",
  "button.yes": "Yes",
  "check.hint.bothEyesOpen": "Keep <strong>both eyes open.</strong>",
  "check.hint.eyeCover.left": "Cover your <strong>left</strong> eye.",
  "check.hint.eyeCover.right": "Cover your <strong>right</strong> eye.",
  "check.hint.keep30cmDistance": "Move your device a little closer - <strong>half an arm's length or 30 cm.</strong>",
  "check.hint.keepArmsLength": "Keep your device at <strong>arm's length</strong>.",
  "check.hint.markTheGap": "See the top ring? Mark the corresponding gap on the lower ring.",
  "closeWarning.cancelCheck.headline": "Do you really want to end this check?",
  "closeWarning.cancelCheck.message": "Your check is not completed. By closing this check, all data will be lost.",
  "closeWarning.cancelResultECP.headline": "Do you really want to close your results?",
  "closeWarning.cancelResultECP.message": "By closing your results, all data will be lost.",
  "closeWarning.cancelResultNotECP.headline": "Do you really want to close your results?",
  "closeWarning.cancelResultNotECP.message": "By closing your results without saving them, all data will be lost.",
  "colorTest.nothing": "Nothing",
  "colorTest.result.continueToAstimatismTest": "Next: astigmatism check",
  "colorTest.result.excellent": "Your colour vision seems to be excellent.",
  "colorTest.result.fine": "Your colour vision seems to be fine.",
  "colorTest.result.imageAlt": "",
  "colorTest.result.reduced": "Your colour vision seems to be reduced.",
  "colorTest.result.testName": "Colour vision",
  "colorTest.selectNumberYouSee": "Select the number you see in the circle.",
  "colorTest.startPage.detailsText": "Can you tell colours apart clearly? Our colour vision check will see how well.",
  "colorTest.startPage.detailsTitle": "See the rainbow.",
  "colorTest.startPage.howToTestDetails": "The colour vision check is based on the clinically trusted work of Dr Shinobu Ishihara. People with normal vision will be able to read the numbers on the colour Ishihara plates, but those with colour vision issues will not see them clearly. It is performed with both eyes simultaneously as unilateral deficiencies are rare.<br><br>Our screening is a good indicator, but no substitute for a full ophthalmological exam.",
  "colorTest.startPage.howToTestTitle": "How do we check your colour vision?",
  "colorTest.startPage.imageAlt": "Colour vision",
  "colorTest.startPage.imageCaptionColorAnomalies": "Colour anomalies (Protanopia)",
  "colorTest.startPage.imageCaptionNormalVision": "Normal colour vision",
  "colorTest.startPage.name": "Colour vision",
  "colorTest.startPage.startCheck": "Start colour vision check",
  "colorTest.startPage.whyToTestDetails": "Colour vision (the ability to detect different colours and shades) is an essential part of human vision. “Colour blindness” is often used to describe colour vision deficiencies. Gradual changes in colour vision are often due to inherited conditions. Good colour vision is important and can affect many aspects of daily life, like education, exam results and career choices.",
  "colorTest.startPage.whyToTestTitle": "Why check your colour vision?",
  "common.appName": "ZEISS Online <br/> Vision Screening",
  "common.appNameNLCampaign": "ZEISS Visual Acuity Check",
  "common.deleteResult.deleteTitle": "Do you really want to delete this result?",
  "common.furtherConsultation": "<strong>Find a ZEISS optician near you.</strong> Always go to an eye care professional for a complete eye exam.",
  "common.furtherConsultationEcp": "For further testing and advice, please visit us at:",
  "contrastTest.result.bothEyesExcellent": "Your contrast vision in <strong>both eyes</strong> seems to be excellent.",
  "contrastTest.result.bothEyesFine": "Your contrast vision in <strong>both eyes</strong> seems to be fine.",
  "contrastTest.result.bothEyesReduced": "Your contrast vision in <strong>both eyes</strong> seems to be reduced.",
  "contrastTest.result.continueToColorTest": "Next: colour vision check",
  "contrastTest.result.imageAltLeftEye": "Your contrast vision in the left eye seems to be {{ status }}",
  "contrastTest.result.imageAltRightEye": "Your contrast vision in the right eye seems to be {{ status }}",
  "contrastTest.result.oneEyeFine": "Your contrast vision in <strong>one eye</strong> seems to be fine.",
  "contrastTest.result.oneEyeReduced": "Your contrast vision in <strong>one eye</strong> seems to be reduced.",
  "contrastTest.result.testName": "Contrast vision",
  "contrastTest.startPage.detailsText": "Our contrast vision check evaluates how well you see contrasts.",
  "contrastTest.startPage.detailsTitle": "Detect differences.",
  "contrastTest.startPage.howToTestDetails": "The contrast vision check is similar to our visual acuity check. It uses a symbol called the Landolt C, which looks like the letter C. This ring has an opening at a defined position.<br><br>In the contrast check, the shade of grey becomes gradually brighter. Simply identify the position of the gap and mark it on the corresponding ring below.",
  "contrastTest.startPage.howToTestTitle": "How do we check your contrast vision?",
  "contrastTest.startPage.imageAlt": "Contrast Vision",
  "contrastTest.startPage.imageCaptionNormalContrast": "Driving at night with normal contrast vision",
  "contrastTest.startPage.imageCaptionReducedContrast": "Reduced contrast vision",
  "contrastTest.startPage.name": "Contrast vision",
  "contrastTest.startPage.startCheck": "Start contrast vision check",
  "contrastTest.startPage.whyToTestDetails": "Contrast vision (the ability to detect different shades of grey) is important for many reasons, including good night vision. It can change gradually, which means you may not notice slight changes in your vision. That's why it's important to check your eyes regularly. You can do this with our screening and follow up with an optician for a complete exam.",
  "contrastTest.startPage.whyToTestTitle": "Why check your contrast vision?",
  "defaultTest.result.noResults": "You haven't done any tests so far. Let's change that.",
  "defaultTest.result.title": "See your results.",
  "disclaimer.content": "<p>This Online Vision Screening is designed to provide a first impression about your vision at this time. It's not a medical test and no substitute for eye care by a trained professional. It isn't intended for use in diagnosing diseases or mitigating, treating or preventing diseases. This exam is only meant to give you a general idea of your visual acuity and whether a professional eye exam is recommended at this time. We recommend having your eyes checked by an eye doctor once every two years, or sooner if you notice changes in your vision. Carl Zeiss Vision Inc. and any other Carl Zeiss Group companies accept no liability for damages or consequences arising as a result of the Online Vision Screening and/or information provided.</p>",
  "disclaimer.title": "Please read and accept before you start.",
  "faq.section1.paragraph1": "Position your phone an arm’s length away from your eyes. It helps to place your arm on a table to stay relaxed during the check.",
  "faq.section1.paragraph2": "If you have glasses or contact lenses, please wear them the entire time.",
  "faq.section1.question": "How do I use the Online Vision Screening?",
  "faq.section2.paragraph1": "We need to calibrate your phone screen to get the most correct results. It’s easy to do – just use the slider to resize the credit card to its original size.",
  "faq.section2.question": "Why do I need to resize the credit card?",
  "faq.section3.paragraph1": "The distance is important to obtain consistent, accurate results. The Online Vision Screening recommends that you keep the phone about an arm’s length away.",
  "faq.section3.question": "What happens if I move my phone and change the distance?",
  "faq.section4.paragraph1": "The Online Vision Screening is free of charge.",
  "faq.section4.question": "How much does the Online Vision Screening cost?",
  "faq.section5.paragraph1": "We’d love to hear from you. Please use our contact form to let us know what ideas and feedback you have. Your comments and concerns will help us make future versions of the Online Vision Screening even better.",
  "faq.section5.question": "Where can I share my feedback?",
  "faq.section6.paragraph1": "The Online Vision Screening gives you an idea of how well you can see right now and might point out some potential issues with your vision. You can take these results and share them with your optician. Please note: The Online Vision Screening is not a substitute for a professional eye exam.",
  "faq.section6.question": "How can I use the results of the Online Vision Screening?",
  "help.contactUrl": "https://www.zeiss.com/vision-care/en/contact-zeiss-vision-care.html",
  "home.about.contact": "Contact",
  "home.about.contact.text": "Let us know what you think!",
  "home.about.faqs.header": "FAQs",
  "home.about.faqs.text": "Questions? We have answers.",
  "home.about.legalDocuments.generalInformation": "General information",
  "home.about.legalDocuments.imprintText": "Publishing details",
  "home.about.legalDocuments.legalNotice.label": "Legal information",
  "home.about.legalDocuments.legalNotice.text": "Our General Terms & Conditions",
  "home.about.legalDocuments.licence": "Licences",
  "home.about.legalDocuments.licenceUrl": "https://visionscreening.zeiss.com/fds.txt",
  "home.about.legalDocuments.privacyNotice.text": "How we collect and process your personal information",
  "home.about.legalDocuments.privacyNotice.title": "Privacy notice",
  "home.about.legalDocuments.text": "Read the fine print.",
  "home.about.legalDocuments.title": "Legal documents",
  "home.about.ovs": "About OVS",
  "home.selectCheck.button": "Or select a specific check",
  "home.selectCheck.header": "All vision checks",
  "home.selectCheck.title": "Choose one of our vision checks to start.",
  "home.whatWeTest": "What our screening includes",
  "howItWorks.accurateResults": "For accurate results.",
  "howItWorks.alwaysFollowInstruction": "Always follow our instructions when it comes to which eye you have to close.",
  "howItWorks.checkYourEyesForSignsOfAstigmatism": "We will show you a sunburst dial to check your eyes for signs of astigmatism. Please take a close look at the illustration and answer the question below.",
  "howItWorks.checkYourVisualField": "We will show you an Amsler Grid to check your visual field. Please take a close look at the illustration and answer the question below.",
  "howItWorks.determineYourColorVision": "We will show you different Ishihara plates to determine your colour vision. Please select the number shown in the illustration by clicking on the respective button below.",
  "howItWorks.keepBothEyesOpen": "Keep both eyes open during the entire check.",
  "howItWorks.markGapPosition": "We will show you two rings. The smaller black ring always has an open gap at different positions. Mark the same position of that gap on the big ring by tapping the according segment.",
  "howItWorks.positionYourPhone": "Position your phone an arm's length away from your eyes. Place your arm on a table to keep it relaxed while you do the check.",
  "howItWorks.positionYourPhone30cm": "Position your phone 30cm away from your eyes. Place your arm on a table to keep it relaxed while you do the check.",
  "howItWorks.title": "How it works.",
  "howItWorks.wearYourGlasses": "Wear your glasses or contacts if you have any.",
  "instructions.coverLeftEye": "Cover your left eye.",
  "instructions.coverRightEye": "Cover your right eye.",
  "instructions.eyesReady.title": "Eyes ready?",
  "instructions.howTestWorks": "How the test works",
  "instructions.keep30cmDistance": "Move your device a little closer - half an arm's length or 30 cm.",
  "instructions.keep30cmDistance.short": "Keep a distance of half an arm's length or 30 cm.",
  "instructions.keep30cmDistance.title": "Move it closer.",
  "instructions.keepArmsLength": "Keep your device at arm's length during the check.",
  "instructions.keepAway.title": "Keep away.",
  "instructions.keepBothEyesOpen": "Keep both eyes open.",
  "instructions.markTheSpot.text": "See the top ring? Mark the corresponding spot on the lower ring.",
  "instructions.markTheSpot.title": "Mark the spot.",
  "instructions.title": "Instructions",
  "menu.cookiesPreferences": "Cookies preferences",
  "menu.imprint.text": "Imprint",
  "menu.imprint.url": "https://www.zeiss.com/vision-care/en/legal-information/company-information.html",
  "menu.legal.text": "Legal information",
  "menu.legal.url": "https://www.zeiss.com/vision-care/en/legal-information/legal-notice-and-general-terms-and-conditions.html",
  "menu.privacy.url": "https://www.zeiss.com/data-protection/en/home.html",
  "metaTags.description": "Check your eyes and receive initial insights about your visual performance.",
  "metaTags.image.alt": "My Vision Screening icon",
  "metaTags.image.height": "1080",
  "metaTags.image.url": "assets/img/va-check-icon.png",
  "metaTags.image.width": "1080",
  "metaTags.title": "My Vision Screening",
  "result.checksCompleted": "check(s) completed",
  "result.eye.left": "left",
  "result.eye.right": "right",
  "result.header": "How do your eyes look?",
  "result.minutesLeft": "minute(s) left",
  "result.generateYourCode": "Generate your code to save your check results in MyZEISS Vision.",
  "setup.brightness.maximizeBrightness": "Maximize the brightness of your screen in order to get accurate results.",
  "setup.brightness.text": "In your device settings, <strong>turn your screen brightness all the way up</strong> for the most accurate results.",
  "setup.calibration.cardText": "Any standard ID, credit or bank card will do. This adjusts your screen size for our check. Don’t worry, this screening is free. We won’t collect any info here.",
  "setup.calibration.resizeCreditCard": "Use the slider to resize the image until it's the same size as your card.",
  "setup.calibration.resizeCreditCard.dontWorry": "Don't worry, this eye check is free and no data will be requested.",
  "setup.calibration.text": "<strong>To adjust the screen size</strong>, place a card on the frame and use the slider to line them up.",
  "setup.calibration.warning.text": "Unfortunately, you didn't do a size calibration with a standard card. This will negatively affect the accuracy of the results.",
  "setup.calibration.warning.title": "Are you sure you don't want to calibrate your screen?",
  "setup.wearYourGlasses.text": "Wear your glasses or contacts<br>(if you have any).",
  "setup.wearYourGlasses.title": "Be prepared.",
  "storeLocator.title": "Find a ZEISS optician near you"
}
