export enum Instruction {
  ACUITY_RING = 'acuity ring',
  CONTRAST_RING = 'conrast ring',
  COVER_LEFT_EYE = 'cover left eye',
  COVER_RIGHT_EYE = 'cover right eye',
  KEEP_BOTH_EYES = 'keep both eye',
  WEAR_YOUR_GLASSES = 'wear your glasses',
  KEEP_AN_ARM = 'keep an arm',
  KEEP_30_CM = 'keep 30 cm',
  SETUP_BRIGHTNESS = 'setup brightness',
  SETUP_CARD_SLIDER = 'setup card slider',
}

export enum TestTypesTranslocoEnum {
  Acuity = 'acuityTest.result.testName',
  Contrast = 'contrastTest.result.testName',
  Color = 'colorTest.result.testName',
  Astigmatism = 'astigmatismTest.result.testName',
  Amsler = 'amslerTest.result.testName',
}
