import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

// Stuff for general
import { OpenFaqPageAction, OpenHomePageAction, OpenLegalDocumentsPageAction } from '../../store/actions/general.actions';

// From shared folder
import { HeaderConfig } from '../../shared/interfaces/header-config.interface';
import { HeaderEnum, HeaderColorEnum, HeaderLabelEnum } from '../../shared/enums/header.enum';
import { GA4_EventDetail, GA4_EventAction, GA4_EventName, GA4_EventType, GA4_EventValue } from '../../shared/enums/ga4.enum';
import { AnalyticsService } from '../../services/analytics.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'zat-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  headerConfig: HeaderConfig = {
    label: HeaderLabelEnum.AboutUs,
    left: HeaderEnum.Back,
    right: HeaderEnum.Empty,
    theme: HeaderColorEnum.LightMode
  } as HeaderConfig;

  isWebComponent = environment.webcomponent;
  semanticVersion = environment.semanticVersion;

  constructor(
    private store: Store,
    private analytics: AnalyticsService
  ) { }

  ngOnInit(): void { }

  openLegalDocuments(): void {
    this.store.dispatch(OpenLegalDocumentsPageAction());
  }

  openFAQs(): void {
    this.store.dispatch(OpenFaqPageAction());

    this.analytics.createCustomEvent({
      event: 'event',
      eventName: `${GA4_EventName.CTA}`,
      eventAction: `${GA4_EventAction.Click}`,
      eventType: `${GA4_EventType.Internal}`,
      eventValue: `${GA4_EventValue.FAQ}`,
      eventDetail: `${GA4_EventDetail.AboutOVS}`
    });
  }

  openContact(url: string): void {
    window.open(url, '_blank');
  }

  back() {
    this.store.dispatch(OpenHomePageAction());
  }
}
