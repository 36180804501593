<div class="contrast-check" *transloco="let t">
  <!-- ! The check-screen -->
  <div class="the-check" *ngIf="!(toggleHint$ | async)">
    <zat-ovs-header [label]="headerConfig.label" [left]="headerConfig.left" [right]="headerConfig.right"
      [theme]="headerConfig.theme" (clickLeft)="openInfo()" (clickRight)="cross()"></zat-ovs-header>

    <div class="check-introduction">
      <ng-container *ngIf="activeEye===Eyes.LEFT; else rightEyeText">
        <label class="number">1) </label><label [innerHtml]="t('check.hint.eyeCover.right')"></label><br>
      </ng-container>
      <ng-template #rightEyeText>
        <label class="number">1) </label><label [innerHtml]="t('check.hint.eyeCover.left')"></label><br>
      </ng-template>
      <label class="number">2) </label><label [innerHtml]="t('check.hint.keepArmsLength')"></label><br>
      <label class="number">3) </label><label [innerHtml]="t('check.hint.markTheGap')"></label>
    </div>

    <div class="check-card-spacing">
      <div class="check-card">
        <div class="check-controls">
          <div class="landolt-c" @fadeIn>
            <svg id="contrastTestImg" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" stroke-linejoin="round"
              stroke-miterlimit="2" clip-rule="evenodd" viewBox="0 0 3 3"
              [style.fill]="'rgba(0,0,0,' + (opacity$ | async) + ')'"
              [style.transform]="'rotate(' + (rotationAngle$ | async) + 'deg)'" [style.width]="width + 'px'"
              [@landoltCAnimation]="landoltCAnimationState" (@landoltCAnimation.done)="onLandoltCAnimationDone($event)" alt="landolt ring">
              <path
                d="M1.785,0.015c0.684,0.139 1.2,0.745 1.2,1.47c0,0.828 -0.672,1.5 -1.5,1.5c-0.828,0 -1.5,-0.672 -1.5,-1.5c0,-0.725 0.516,-1.331 1.2,-1.47l0,0.69c-0.321,0.119 -0.55,0.424 -0.55,0.78c0,0.46 0.381,0.833 0.85,0.833c0.469,0 0.85,-0.373 0.85,-0.833c0,-0.356 -0.229,-0.661 -0.55,-0.78l0,-0.69Z" />
            </svg>
          </div>
        </div>

        <div class="pizza-control">
          <zat-pizza-control class="user-control" [angle]="cAngle" [checkType]="checkType"
            (idle)="landoltCAnimationState = 'idle'" (init)="landoltCAnimationState = 'init'"
            (done)="onComplete($event)">
          </zat-pizza-control>
        </div>
      </div>
    </div>
  </div>

  <!-- ! The check-hint-screen -->
  <zat-how-it-works-contrast-page (clickClose)="closeHint()" class="the-check-hint" *ngIf="toggleHint$ | async">
  </zat-how-it-works-contrast-page>

  <div class="debug" *ngIf="debug$ | async">
    <span>Test no: <strong>{{ testCount }}</strong></span><br>
    <span>Current eye (Test): <strong>{{ activeEye }}</strong></span><br>
    <span>Current eye (Cover): <strong>{{ activeEye !== Eyes.LEFT ? Eyes.LEFT : Eyes.RIGHT }}</strong></span><br>
    <span>logMAR: <strong>{{ landoltCSize }}</strong></span><br>
    <span>logCS: <strong>{{ contrast$ | async }}</strong></span><br>
    <span>Real contrast: <strong>{{ opacity$ | async }}</strong></span><br>
    <span>Last answer: <strong *ngIf="lastIsCorrectValue !== undefined; else placeholder">{{
        lastIsCorrectValue ? 'Correct' : 'Wrong'
        }}</strong>
      <ng-template #placeholder>-</ng-template>
    </span><br>
    <span>Direction changes: <strong>{{ directionChanges$ | async }}</strong></span><br>
  </div>
</div>
