import { environment } from '../../environments/environment';

// max test count for acuity-test and contrast-test
export const MAX_TEST_COUNT = 24;
export const DEFAULT_DISTANCE_IN_CM = 50;
export const DEFAULT_PIXEL_PITCH_IN_CM = 0.02;

// timing functions for animations
const timings: { [key: string]: number } = {
  select: 0.15 * environment.animationDuration,
  forward: 0.3 * environment.animationDuration,
  forwardDelay: 0.4 * environment.animationDuration,
  backwardDelay: 1 * environment.animationDuration,
  beforeBackward: 0.4 * environment.animationDuration,
  beforeBackwardDelay: 0.8 * environment.animationDuration
};

export const TIMING_FUNCTION_SELECT = `${timings.select}s ease-out`;
export const TIMING_FUNCTION_FORWARD = `${timings.forward}s ${timings.forwardDelay}s ease-out`;
export const TIMING_FUNCTION_BACKWARD_DELAYED = `${timings.select}s ${timings.backwardDelay}s ease-out`;
export const TIMING_FUNCTION_BEFORE_BACKWARD = `${timings.beforeBackward}s ${timings.beforeBackwardDelay}s ease-out`;
