<div class="va-content--result" *transloco="let t">
  <div class="va-description-image">
    <app-icon id="colorTestSmall" name="color_test_small" [width]="30" [height]="30" alt="" class="icon-small">
    </app-icon>
    <span class="icon-title text-headline" [innerHTML]="t('colorTest.result.testName')"></span>
  </div>

  <div class="va-description-wrapper">
    <div class="va-description text-body">
      <div class="percent-with-level">
        <div *ngIf="level === ColorResultLevels.EXCELLENT" class="level none"
          [innerHTML]="t('colorTest.result.excellent')">
        </div>
        <div *ngIf="level === ColorResultLevels.FINE" class="level weak" [innerHTML]="t('colorTest.result.fine')">
        </div>
        <div *ngIf="level === ColorResultLevels.REDUCED" class="level moderate"
          [innerHTML]="t('colorTest.result.reduced')">
        </div>
      </div>
    </div>

    <div class="va-values">
      <div class="text-center va-column eye"
        [attr.aria-label]="t('colorTest.result.testName') + ' ' + t('result.eye.left') + ' ' + image">
        <app-icon [id]="'result'+ image" [name]="image" [width]="96" [height]="56" alt="">
        </app-icon>
        <label class="va-label text-body"> {{ t('result.eye.left') }} </label>
      </div>

      <div class="text-center va-column eye"
        [attr.aria-label]="t('colorTest.result.testName') + ' ' + t('result.eye.right') + ' ' + image">
        <app-icon [id]="'result'+ image" [name]="image" [width]="96" [height]="56" alt="">
        </app-icon>
        <label class="va-label text-body"> {{ t('result.eye.right') }} </label>
      </div>
    </div>
  </div>

  <div class="save-result-cta" *ngIf="(showSaveFeature$|async) &&!(isMegaTestActive$|async)">
    {{t('result.generateYourCode')}}
    <zat-action-button (click)="handleResultCodeFlow()" [type]="'solid'">{{t('button.saveResults')}}</zat-action-button>
  </div>

  <!-- ecp-mode conditional call-to-action button on result screen -->
  <zat-action-button *ngIf="(specialScreeningMode$ | async) === SpecialScreeningMode.ECP && !(isMegaTestActive$|async)" class="ecp-result-exit-btn"
    type="outline" size="small-medium" (click)="emitExitEcpResult()">
    {{ (buttonText$ | async) ?? t('button.ecpResultExit') }}
  </zat-action-button>
</div>
