import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

// Stuff for general
import { environment } from '../../../../environments/environment';
import { OpenAboutUsPageAction } from '../../../store/actions/general.actions';

// From shared folder
import { HeaderEnum, HeaderColorEnum, HeaderLabelEnum } from '../../../shared/enums/header.enum';
import { HeaderConfig } from '../../../shared/interfaces/header-config.interface';

@Component({
  selector: 'zat-legal-documents',
  templateUrl: './legal-documents.component.html',
  styleUrls: ['./legal-documents.component.scss']
})
export class LegalDocumentsComponent implements OnInit {
  environment = environment;

  headerConfig: HeaderConfig = {
    label: HeaderLabelEnum.LegalDocuments,
    left: HeaderEnum.Back,
    right: HeaderEnum.Empty,
    theme: HeaderColorEnum.LightMode
  } as HeaderConfig;

  constructor(private store: Store) {}

  ngOnInit(): void { }

  openDocument(imprintUrl: string): void {
    window.open(imprintUrl, '_blank');
  }

  back(): void {
    this.store.dispatch(OpenAboutUsPageAction());
  }
}
