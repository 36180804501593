import { Component, Input } from '@angular/core';

// Special for checks
import { ColorResultLevels } from '../../../color-test/shared';
import { Store } from '@ngrx/store';
import { ecpResultButtonText, getMegaTestActive, getSpecialScreeningMode } from '../../../store/selectors/app.selectors';
import { SpecialScreeningMode } from '../../enums/special-screening-mode.enum';
import { ExitEcpResultAction, SaveAndGenerateResultCodeAction } from '../../../store/actions/general.actions';
import { Observable, map } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
import { environment } from '../../../../environments/environment';
import { GeneralService } from '@app-services/general.service';
import { ColorActions } from '../../../color-test/store';

@Component({
  selector: 'zat-color-test-result',
  templateUrl: './color-test-result.component.html',
  styleUrls: ['./color-test-result.component.scss']
})
export class ColorTestResultComponent {
  @Input() image: any;
  @Input() level: ColorResultLevels;
  @Input() isFRChannel: boolean;

  public ColorResultLevels = ColorResultLevels;
  public SpecialScreeningMode = SpecialScreeningMode;

  constructor(private store: Store, private transloco: TranslocoService, private generalService:GeneralService) {
    this.store.dispatch(ColorActions.ColorTestFinishedAction());
  }

  public specialScreeningMode$ = this.store.select(getSpecialScreeningMode);
  public buttonText$ = this.store.select(ecpResultButtonText);
  public isMegaTestActive$: Observable<boolean> = this.store.select(getMegaTestActive);
  public showSaveFeature$ = this.generalService.showImportSaveOption$();
  public env = environment;

    handleResultCodeFlow(){
    this.store.dispatch(SaveAndGenerateResultCodeAction());
  }

  emitExitEcpResult() {
    // dispatch action to notify app.component via store flag to emit Output (user clicked back)
    this.store.dispatch(ExitEcpResultAction());
  }
}
