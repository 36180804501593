<div class="how-it-works" *transloco="let t">
  <zat-ovs-header [label]="headerConfig.label" [left]="headerConfig.left" [right]="headerConfig.right"
    [theme]="headerConfig.theme" (clickLeft)="closeHint()"></zat-ovs-header>

  <div #scrollableElement class="how-it-works-content" tabindex="0">
    <div class="headline">
      {{ t('howItWorks.title') }}
    </div>

    <div class="check-hint text-body">
      <ng-container [ngSwitch]="(activeEye$ |async)">
        <zat-how-it-works-checks-general [coverEye]="instructions.COVER_RIGHT_EYE" [distance]="instructions.KEEP_30_CM"
          *ngSwitchCase="eyes.LEFT">
        </zat-how-it-works-checks-general>
        <zat-how-it-works-checks-general [coverEye]="instructions.COVER_LEFT_EYE" [distance]="instructions.KEEP_30_CM"
          *ngSwitchCase="eyes.RIGHT">
        </zat-how-it-works-checks-general>
      </ng-container>

      <!-- Specify for amlser-check -->
      <div class="hint">
        <div class="media-space">
          <app-icon id="amsler" name="amsler_grid" [height]="96" [width]="96" alt=""></app-icon>
        </div>
        <div class="text-space">
          <span class="text-highlight">3) </span>
          <span>
            {{ t('amslerTest.focusOnBlackDot') }}
          </span><br>

          <span class="text-highlight">Q) </span>
          <span>
            {{ questionString }}
          </span>
        </div>
      </div>
    </div>

    <zat-how-it-works-general></zat-how-it-works-general>
  </div>
</div>
