import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { delay, take } from 'rxjs/operators';
import { Location } from '@angular/common';

// Stuff for general
import { environment } from '../../../environments/environment';
import { AppSelectors } from '../../store/selectors';
import { TestTypeActions } from '../../store/actions';
import { OpenHomePageAction } from '../../store/actions/general.actions';
import { GeneralService } from '../../services/general.service';

// Special for checks
import { ColorActions, ColorSelectors } from '../store';

// From shared folder
import { HeaderConfig } from '../../shared/interfaces/header-config.interface';
import { fadeInAnimation } from '../../shared/animations';
import { TEST_TYPES } from '../../shared/enums/test-types';
import { Eyes } from '../../shared/enums/eyes.enum';
import { RoutePathes } from '../../shared/enums/route-pathes.enum';
import {
  HeaderColorEnum,
  HeaderLabelEnum,
  HeaderEnum
} from '../../shared/enums/header.enum';
import { CloseDialogContext } from '../../shared/components/close-dialog/close-dialog-context.enum';
import {
  GA4_EventDetail,
  GA4_EventAction,
  GA4_EventName,
  GA4_EventType,
  GA4_EventValue
} from '../../shared/enums/ga4.enum';
import { AnalyticsService } from '../../services/analytics.service';

@UntilDestroy()
@Component({
  selector: 'zat-color-test-page',
  templateUrl: './color-test-page.component.html',
  styleUrls: ['./color-test-page.component.scss'],
  animations: [fadeInAnimation]
})
export class ColorTestPageComponent implements OnInit, OnDestroy {
  megaTestActive$: Observable<boolean>;

  activeEye: Eyes = Eyes.BOTH;
  megaTestActive: boolean;

  Eyes = Eyes;
  headerConfig: HeaderConfig = {
    label: HeaderLabelEnum.ColorCheck,
    left: HeaderEnum.Info,
    right: HeaderEnum.Close,
    theme: HeaderColorEnum.LightMode
  } as HeaderConfig;

  toggleHint$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private timeout: number;

  private destroy$: Subject<void> = new Subject<void>();

  // trigger close warning dialog for checks when browser back button is clicked
  @HostListener('window:popstate', ['$event'])
  onPopState() {
    if (!environment.webcomponent) {
      history.pushState(null, null, location.href);
      this.clickCancel();
    }
  }

  constructor(
    private store: Store,
    private generalService: GeneralService,
    private locationService: Location,
    private analytics: AnalyticsService
  ) {
    history.pushState(null, null, location.href);
  }

  ngOnInit(): void {
    this.megaTestActive$ = this.store.select(AppSelectors.getMegaTestActive);
    this.megaTestActive$
      .pipe(untilDestroyed(this))
      .subscribe((active) => (this.megaTestActive = active));

    this.checkTests();
  }

  ngOnDestroy(): void {
    window.clearTimeout(this.timeout);
    this.destroy$.next();
    this.destroy$.complete();
  }

  checkTests(): void {
    this.store
      .select(ColorSelectors.getTests)
      .subscribe((tests) => {
        const isNoTests = tests === undefined;
        if (isNoTests) {
          this.store.dispatch(ColorActions.GenerateTests());
        }
      })
      .unsubscribe();
  }

  cancelTest(): void {
    this.store.dispatch(OpenHomePageAction());
  }

  onComplete(): void {
    this.goToResults();
  }

  private goToResults(): void {
    let path = `${TEST_TYPES.COLOR}${RoutePathes.CheckResult}`;

    if (this.megaTestActive) {
      this.store.dispatch(
        TestTypeActions.SetTestInMegaTest({ testType: TEST_TYPES.COLOR })
      );
      path = `${RoutePathes.Default}/${path}`;
    }

    // delay navigation for smoother transition between pages
    this.timeout = window.setTimeout(() => {
      this.generalService.routeToNextScreen(path);
    }, 500);
  }

  clickInfo(): void {
    this.toggleHint$.next(true);

    this.analytics.createCustomEvent({
      event: 'event',
      eventName: `${GA4_EventName.CTA}`,
      eventAction: `${GA4_EventAction.Click}`,
      eventType: `${GA4_EventType.Internal}`,
      eventValue: `${GA4_EventValue.Color}`,
      eventDetail: `${GA4_EventDetail.HowItWorks}`
    });
  }

  closeInfo(): void {
    this.toggleHint$.next(false);
  }

  clickCancel(): void {
    this.analytics.createCustomEvent({
      event: 'event',
      eventName: `${GA4_EventName.CTA}`,
      eventAction: `${GA4_EventAction.Click}`,
      eventType: `${GA4_EventType.Color}`,
      eventValue: `${GA4_EventValue.ExitCheck}`,
      eventDetail: `${GA4_EventDetail.Clear}`
    });

    of(null)
      .pipe(delay(0), take(1))
      .subscribe(() => {
        this.store.dispatch(
          ColorActions.ShowCloseWarningAction({
            context: CloseDialogContext.Check,
            previousPath: this.locationService.path()
          })
        );
      });
  }
}
