export enum LocalesEnum {
  BG_BG = 'bg-BG',
  CS_CZ = 'cs-CZ',
  DA_DK = 'da-DK',

  DE_AT = 'de-AT',
  DE_CH = 'de-CH',
  DE_DE = 'de-DE',

  EN_AU = 'en-AU',
  EN_CA = 'en-CA',
  EN_GB = 'en-GB',
  EN_HK = 'en-HK',
  EN_IL = 'en-IL',
  EN_IN = 'en-IN',
  EN_INT = 'en-INT',
  EN_MY = 'en-MY',
  EN_SG = 'en-SG',
  EN_US = 'en-US',
  EN_ZA = 'en-ZA',

  ES_AR = 'es-AR',
  ES_CO = 'es-CO',
  ES_EC = 'es-EC',
  ES_ES = 'es-ES',
  ES_MX = 'es-MX',
  ES_PA = 'es-PA',
  ES_PE = 'es-PE',
  ES_VE = 'es-VE',

  FI_FI = 'fi-FI',
  FR_BE = 'fr-BE',
  FR_CA = 'fr-CA',
  FR_CH = 'fr-CH',
  FR_FR = 'fr-FR',

  HR_HR = 'hr-HR',
  HU_HU = 'hu-HU',
  IT_IT = 'it-IT',
  JA_JP = 'ja-JP',
  KO_KR = 'ko-KR',

  NL_BE = 'nl-BE',
  NL_NL = 'nl-NL',
  NO_NO = 'no-NO',

  PL_PL = 'pl-PL',
  PT_BR = 'pt-BR',
  PT_PT = 'pt-PT',

  RO_RO = 'ro-RO',
  RU_RU = 'ru-RU',
  SK_SK = 'sk-SK',
  SV_SE = 'sv-SE',
  TR_TR = 'tr-TR',
  UK_UA = 'uk-UA',

  ZH_CN = 'zh-CN',
  ZH_HK = 'zh-HK',
  ZH_TW = 'zh-TW',
}
