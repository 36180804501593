<ng-container *transloco="let t">
  <div class="important-notes">
    <div class="right-icon">
      <zat-action-button (click)="handleCancel()" ariaLabel="{{ 'button.cancel' | transloco }}">
        <app-icon id="cancel" name="header_close" data-testid="header-cancel-button"
          [alt]="t('button.cancel')">
        </app-icon>
      </zat-action-button>
    </div>

    <div class="central-container">
      <div class="important-notes-content text-headline">
        <h1 class="title">{{ t('disclaimer.title') }}</h1>

        <div class="text">
          <div [innerHTML]="t('disclaimer.content')"></div>
        </div>

        <div class="actions">
          <zat-action-button type="outline" color="primary" size="small" (click)="agree()"
            data-testid="button-accept-disclaimers">
            {{ t('button.agree') }}
          </zat-action-button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
