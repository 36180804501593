import { ColorResults } from '../enums';
import { ColorTestRow } from '../interfaces';

export const getWrongResultPercentage = (datasource: ColorTestRow[]) => {
  if (!datasource) { return; }

  const totalWrongTests = datasource.filter(test => test.result !== ColorResults.CORRECT).length;
  const totalTests = datasource.length;
  const percent = totalWrongTests / totalTests;
  return percent;
};

export const getResultPercentage = (datasource: ColorTestRow[]) => {
  if (!datasource) { return; }

  const totalCorrectTests = datasource.filter(test => test.result === ColorResults.CORRECT).length;
  const totalTests = datasource.length;
  const percent = totalCorrectTests / totalTests;
  return percent;
};
