<div class="details-page-root" *transloco="let t;">
  <zat-ovs-header [label]="headerConfig.label" [left]="headerConfig.left" [right]="headerConfig.right"
    [theme]="headerConfig.theme" (clickLeft)="back()"></zat-ovs-header>
  <div class="details-page-content-main">
    <div class="details-page-content-limited">
      <div class="headline">
        {{ t('contrastTest.startPage.detailsTitle') }}
      </div>
      <div class="subline">
        {{ t('contrastTest.startPage.detailsText') }}
      </div>
      <zat-action-button class="btn-start-test" color="primary" type="solid" size="medium-big"
        (click)="start()" data-testid="button-start-test">
        {{ t('contrastTest.startPage.startCheck') }}
      </zat-action-button>

      <div class="details-description">
        <zat-start-content-section>
          <ng-container primary-title>
            <div class="sub-headline">
              {{ t('contrastTest.startPage.whyToTestTitle') }}
            </div>
          </ng-container>
          <ng-container primary-description>
            <div class="text">
              {{ t('contrastTest.startPage.whyToTestDetails') }}
            </div>
          </ng-container>
          <ng-container image-comparison>
            <app-ovs-comparison-slider 
              [srcImageNameOne]="srcImageNameOne" [srcImageNameTwo]="srcImageNameTwo"
              [altTextOne]="t('contrastTest.startPage.imageCaptionNormalContrast')"
              [altTextOne]="t('contrastTest.startPage.imageCaptionReducedContrast')">
            </app-ovs-comparison-slider>
          </ng-container>
          <ng-container image-caption>
            <div class="text">
              {{ t('contrastTest.startPage.imageCaptionNormalContrast') }} vs. {{
              t('contrastTest.startPage.imageCaptionReducedContrast')}}
            </div>
          </ng-container>
          <ng-container secondary-title>
            <div class="sub-headline">
              {{ t('contrastTest.startPage.howToTestTitle') }}
            </div>
          </ng-container>
          <ng-container secondary-description>
            <div [innerHtml]="t('contrastTest.startPage.howToTestDetails')" class="text"></div>
          </ng-container>
        </zat-start-content-section>
      </div>
    </div>
  </div>
</div>
