<!--
  DESKTOP: Card is anchored in lower right corner
  MOBILE:  Card is anchored in upper right corner
-->
<div class="calibrate-ground" *transloco="let t">
  <div class="calibrate-ground-content" *ngLet="(isDesktop$ | async) as isDesktop">
    <div class="calibrate-screen">

      <div #slidingContent class="sliding-content" [ngClass]="{'desktop': isDesktop}">
        <div class="credit-card-wrapper">
          <div #slidingCard class="sliding-card" [ngClass]="{'desktop': isDesktop}"
            [style.width.mm]="85.6 * scale * 1.05" [style.height.mm]="53.98 * scale * 1.05">
            <div class="card-text" *ngIf="scale >= 0.85">
              <div class="small" [style.margin-left.px]="cardTextOffset$ | async"
                [style.margin-right.px]="NORMAL_SPACING">
                <span>{{ t('setup.calibration.cardText') }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="slider-container" [ngClass]="{ 'desktop': isDesktop }">
          <input #diameter data-testid="diameter" class="slider" [ngClass]="{ 'desktop': isDesktop }" type="range" [min]="MIN" [max]="MAX"
            step="0.005" [value]="sliderValue" (input)="onSliderChange($event)">
        </div>
      </div>

      <div class="info-text">
        <div class="text-info">
          <span [innerHTML]="t('setup.calibration.text')"></span>
        </div>
      </div>
    </div>
  </div>
</div>
