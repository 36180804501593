// From shared folder
import { TEST_RESULT, TEST_RESULT_STRING } from '../../shared/enums';

export function resultImageConverter(value: number) {
  if (value === TEST_RESULT.EXCELLENT) {
    return TEST_RESULT_STRING.EXCELLENT;
  }

  if (value === TEST_RESULT.FINE) {
    return TEST_RESULT_STRING.FINE;
  }

  if (value === TEST_RESULT.REDUCED || value === undefined) {
    return TEST_RESULT_STRING.REDUCED;
  }
}
