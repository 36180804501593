import { Component, EventEmitter, Input, Output } from '@angular/core';

// From shared folder
import { IndicatorEnum } from '../../../shared/enums/indicator.enum';

@Component({
  selector: 'zat-ovs-indicator-dots',
  templateUrl: './ovs-indicator-dots.component.html',
  styleUrls: ['./ovs-indicator-dots.component.scss']
})
export class OvsIndicatorDotsComponent {
  @Input('indicatorDots') indicatorDots: Array<IndicatorEnum>;

  // currently not used
  @Output('clickDot') clickDot = new EventEmitter<number>();

  IndicatorEnum = IndicatorEnum;

  /**
   * click handler for click on indicator dots
   * @param index index of the dot that was clicked on
   */
  click(index: number): void {
    this.clickDot.emit(index);
  }
}
