<div class="color-check" *transloco="let t">
  <!-- ! The check -->
  <div class="color-check-content" *ngIf="!(toggleHint$ | async)">
    <zat-ovs-header [label]="headerConfig.label" [left]="headerConfig.left" [right]="headerConfig.right"
      [theme]="headerConfig.theme" (clickLeft)="clickInfo()" (clickRight)="clickCancel()"></zat-ovs-header>

    <div class="check-instruction">
      <label class="number">1) </label><label [innerHtml]="t('check.hint.bothEyesOpen')"></label><br>
      <label class="number">2) </label><label [innerHtml]="t('check.hint.keepArmsLength')"></label><br>
      <label class="number">3) </label><label [innerHtml]="t('colorTest.selectNumberYouSee')"></label>
    </div>

    <div class="check-card-spacing">
      <zat-color-test (done)="onComplete()"></zat-color-test>
    </div>
  </div>

  <!-- ! Hint -->
  <zat-how-it-works-color-page (clickClose)="closeInfo()" class="the-check-hint" *ngIf="(toggleHint$ | async)">
  </zat-how-it-works-color-page>
</div>
