import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';

// Stuff for general
import { AppLocalizeRouterService } from './app-localize-router.service';
import {
  contrastAnimation,
  eyeAnimation,
  vaAnimation
} from '../lottie/lottie-animation';
import { AppCookiesService } from './app-cookies.service';
import { SelectScreeningModeAction } from '../store/actions/general.actions';

// Special for checks
import { AMSLER_GRID_SIZE_IN_CM } from '../amsler-test/shared';

// From shared folder
import { LocalesEnum } from '../shared/enums/locales.enum';
import { TestTypesForImportantNotes } from '../shared/enums/test-types-important-notes.enum';
import { TestpathInput } from '../shared/enums/route-pathes.enum';
import { LanguagesEnum } from '../shared/enums/languages.enum';
import { LottieAnimationEnum } from '../shared/enums/lottie.enum';
import { SpecialScreeningMode } from '../shared/enums/special-screening-mode.enum';
import { TEST_TYPES } from '../shared/enums/test-types';
import { AppInsightsService } from './app-insights.service';
import { Observable, combineLatest } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AppSelectors } from '../store';
import { environment } from '../../environments/environment';
import { MyZeissCountrySetting } from '../shared/interfaces/my-zeiss-country-setting.interface';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {
  constructor(
    private store: Store,
    private logger: AppInsightsService,
    protected localize: AppLocalizeRouterService,
    protected router: Router,
    private appCookiesService: AppCookiesService
  ) {}

  //TODO: refactor: why do we need 2 enums that are almost the same -.-'
  mapTestTypeForImportantNotes(
    type: string | TEST_TYPES
  ): TestTypesForImportantNotes {
    let testCode: TestTypesForImportantNotes = null;
    let testCodes: TestTypesForImportantNotes[] = Object.values(
      TestTypesForImportantNotes
    );
    for (let i: number = 0; i < testCodes.length; i++) {
      if (testCodes[i] == type) {
        testCode = testCodes[i];
      }
    }
    return testCode;
  }

  //TODO: refactor: why do we need 2 enums that are almost the same -.-'
  mapTestTypeFromImportantNotes(
    type: TestTypesForImportantNotes | string
  ): TEST_TYPES {
    let testCode: TEST_TYPES = null;
    let testCodes: TEST_TYPES[] = Object.values(TEST_TYPES);
    for (let i: number = 0; i < testCodes.length; i++) {
      if (testCodes[i] == type) {
        testCode = testCodes[i];
      }
    }

    return testCode;
  }

  /**
   * Checks if the input matches one of our locales.
   * @param locale Input from webComponent (String)
   * @returns Also a string, but it is save a locale-string, that we can match.
   */
  //TODO: use this function wherever we decide to show/not show the save-chub feature
  checkInputLocale(locale: string): string {
    let locales: LocalesEnum[] = Object.values(LocalesEnum);
    let languages: LanguagesEnum[] = Object.values(LanguagesEnum);
    let localeUse: string;
    if (locale.length === 2) {
      let localeMatch = languages.filter((key) =>
        key.toLowerCase().includes(locale)
      );

      // If we have a match then always use the first (and only) array element,
      // otherwise use the en-INT fallback.
      localeUse = localeMatch ? localeMatch[0] : LocalesEnum.EN_INT;
    } else {
      localeUse = locales.find(
        (value) => value.toLowerCase() === locale.toLowerCase()
      )
        ? locale
        : LocalesEnum.EN_INT;
    }
    // too early for appInsights to work initally
    console.log('### input locale to use', localeUse);
    return localeUse;
  }

  /**
   * Function to determine and validate the different ECP S&S variations.
   * @param screeningMode Input from the webcomponent (string)
   */
  checkInputSpecialScreeningMode(screeningMode: string) {
    switch (screeningMode) {
      case 'nl_campaign':
        this.store.dispatch(
          SelectScreeningModeAction({
            screeningMode: SpecialScreeningMode.NL_CAMPAIGN
          })
        );
        break;
      case 'ecp':
        this.store.dispatch(
          SelectScreeningModeAction({ screeningMode: SpecialScreeningMode.ECP })
        );
        break;
      case 'default':
      default:
        this.store.dispatch(
          SelectScreeningModeAction({
            screeningMode: SpecialScreeningMode.MY_ZEISS
          })
        );
        break;
    }
  }

  /**
   * Perhaps a customer will start with a specify CHECK, so we can validate the given input.
   * @param testpath Input from webComponent (String)
   * @returns The start-path of the webComponent
   */
  checkInputTestpath(testpath: string): string {
    switch (testpath) {
      case TestpathInput.Acuity:
      case TestpathInput.Contrast:
      case TestpathInput.Color:
      case TestpathInput.Astigmatism:
      case TestpathInput.Amsler:
        // too early for appInsights to work initally
        console.log('### input testpath', testpath);
        return testpath;
      case TestpathInput.Entry:
      default:
        // too early for appInsights to work initally
        console.log('### input testpath', TestpathInput.Default);
        return TestpathInput.Default;
    }
  }

  /**
   * this allows us to not have to concatenate the route including i18n local (e.g. en-INT)
   * where we want to call the navigation
   */
  routeToNextScreen(route: string): void {
    this.logger.info('### route', route);
    this.router.navigate(this.localize.translateRoute([route]));
  }

  removeLastLetter(title: string): string {
    return title.slice(0, -1);
  }

  getAmslerGridSize(): { pixelPitch: number; gapSize: number } {
    let pixelPitch: number;
    let gapSize: number;

    let pixelPitchCookie = this.appCookiesService.getPixelPitch();
    gapSize = AMSLER_GRID_SIZE_IN_CM / pixelPitchCookie;

    return { pixelPitch: pixelPitch, gapSize: gapSize };
  }

  getLottieOptions(animation: LottieAnimationEnum): AnimationOptions {
    let data: any;

    switch (animation) {
      case LottieAnimationEnum.AnimatedEye:
        data = eyeAnimation;
        break;
      case LottieAnimationEnum.VaCheck:
        data = vaAnimation;
        break;
      case LottieAnimationEnum.ContrastCheck:
        data = contrastAnimation;
        break;
    }

    let options: AnimationOptions = {
      animationData: data,
      autoplay: true
    };
    return options;
  }

  // ! Need to be here to be able to create a mock-funktion for it
  // TODO: Rework this if the webComponent could be used as a widget as well
  centerCCM(): void {
    const isLocalhost =
      location.hostname === 'localhost' || location.hostname === '127.0.0.1';
    const oneTrustBannerClosed =
      this.appCookiesService.hasClosedOneTrustBanner();
    console.log(
      '#### [GENERAL SERVICE] centerCCM() --> oneTrustBannerClosed: ',
      oneTrustBannerClosed
    );

    if (!isLocalhost && !oneTrustBannerClosed) {
      const interval = setInterval(() => {
        console.log(
          '#### [GENERAL SERVICE] centerCCM() --> window.OneTrust: ',
          window.OneTrust
        );
        if (typeof window.OneTrust !== 'undefined') {
          let element: HTMLElement = document.getElementById(
            'onetrust-banner-sdk'
          );
          console.log(
            '#### [GENERAL SERVICE] centerCCM() --> onetrust-sdk-banner: ',
            element
          );
          element.setAttribute(
            'style',
            'top: 50%; -ms-transform: translateY(-50%); transform: translateY(-50%);'
          );
          clearInterval(interval);
        }
      }, 1000);
    }
  }

  // ! Need to be here to be able to create a mock-funktion for it
  mapTestString(testString: string): string {
    let testStringArray: string[] = testString.split('-');
    return testStringArray[0];
  }

  hideLastTest$(): Observable<boolean> {
    return combineLatest([
      this.store.select(AppSelectors.getSpecialScreeningMode),
      this.store.select(AppSelectors.getMyZeissCountrySetting),
      this.store.select(AppSelectors.getLocale)
    ]).pipe(
      map(([screenMode, settings, lang ]:[SpecialScreeningMode, MyZeissCountrySetting, string]) => {
        if(environment.webcomponent){
          return screenMode === SpecialScreeningMode.MY_ZEISS && !settings.isAmslerTestEnabled
        }
        else{
          //TODO: should we fetch settings from language-region code instead?
          // for now keep old solution for web implementation
          return lang.toLowerCase().includes('us');
        }
      }),
      tap((hideLastTest) => {
        this.logger.info('should hide last Test?: ', hideLastTest);
      })
    );
  }

  /**determine wether we should show the save button which leads
   * to cHub import feature entry point
   *
   * this should we only in web (not webcomp) and only for the countries this
   * setting is enabled for in AppConfig
   *
   * we are going to use the same CountrySetting object that we use to determine
   * number of tests (4 or 5) in webcomponent, @see this.hideLastTest$()
   */
  showImportSaveOption$():Observable<boolean>{
    return combineLatest([
      this.store.select(AppSelectors.getMyZeissCountrySetting),
      this.store.select(AppSelectors.getLocale)
    ]).pipe(
      map(([settings, lang ]:[MyZeissCountrySetting, string]) => {
        if(environment.webcomponent){
          return false;
        }
        else{
          let saveFeatureEnabled = !!settings.isSaveWebFeatureEnabled;
          return environment.chubGoLiveToggle && saveFeatureEnabled;
        }
      }),
      tap((hideLastTest) => {
        this.logger.info('should hide last Test?: ', hideLastTest);
      })
    );
  }
}
