import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

// Stuff for general
import { environment } from '../../../environments/environment';
import { OpenCheckSelectionPageAction } from '../../store/actions/general.actions';
import { AppCookiesService } from '../../services/app-cookies.service';
import { AppLocalizeRouterService } from '../../services/app-localize-router.service';
import { GeneralService } from '../../services/general.service';

// From shared folder
import { HeaderConfig } from '../../shared/interfaces/header-config.interface';
import {
  HeaderColorEnum,
  HeaderEnum,
  HeaderLabelEnum
} from '../../shared/enums/header.enum';
import { fadeInAnimation } from '../../shared/animations';
import { StartPageClass } from '../../shared/classes/start-page.class';
import { TestTypesForImportantNotes } from '../../shared/enums/test-types-important-notes.enum';
import { ShowImportantNotesAction } from '../../store/actions/check-calibration.actions';

@Component({
  selector: 'zat-contrast-details-page',
  templateUrl: './contrast-details-page.component.html',
  styleUrls: ['./contrast-details-page.component.scss'],
  animations: [fadeInAnimation]
})
export class ContrastDetailsPageComponent
  extends StartPageClass
  implements OnDestroy
{
  public environment = environment;

  srcImageNameOne:string = "img_contrast_01@2x.jpg"
  srcImageNameTwo:string = "img_contrast_02@2x.jpg"

  headerConfig: HeaderConfig = {
    label: HeaderLabelEnum.ContrastDetails,
    left: HeaderEnum.Back,
    right: HeaderEnum.Empty,
    theme: HeaderColorEnum.LightMode
  } as HeaderConfig;

  constructor(
    protected store: Store,
    protected router: Router,
    protected localize: AppLocalizeRouterService,
    protected appCookiesService: AppCookiesService,
    protected generalService: GeneralService
  ) {
    super(store, router, localize, appCookiesService);
  }

  ngOnDestroy(): void {}

  start(): void {
    this.store.dispatch(
      ShowImportantNotesAction({
        testType: TestTypesForImportantNotes.CONTRAST
      })
    );
  }

  removeLastLetter(title: string): string {
    return this.generalService.removeLastLetter(title);
  }

  back(): void {
    this.store.dispatch(OpenCheckSelectionPageAction());
  }
}
