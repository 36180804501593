import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

// Stuff for general
import { AppSelectors } from '../store/selectors';

@Injectable({
  providedIn: 'root'
})
export class AppLocalizeRouterService {
  private locale: any;

  constructor(private store: Store) {
    this.store
      .select(AppSelectors.getLocale)
      .subscribe(locale => this.locale = locale);
  }

  // return type `any[]` to keep it compatible with `router.navigate()`
  translateRoute(path: string[]): any[] {
    const locale = this.locale;
    return [`/${locale}/${path[0]}`];
  }
}
