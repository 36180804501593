<div *transloco="let t" class="ovs-header">
  <div class="empty-icon"></div>
    
  <div class="title-instructions">
    <span class="check text-headline">{{ t(labelString) }}</span><br>
  </div>

  <div class="header-icon" (click)="onClickCross()">
    <zat-action-button type="focus" ariaLabel="{{ 'button.cancel' | transloco }}">
      <app-icon id="cancel" name="header_close" data-testid="instructions-header-cancel-button" [width]="24"
        [height]="24" alt="{{ 'button.cancel' | transloco }}">
      </app-icon>
    </zat-action-button>
  </div>
</div>
