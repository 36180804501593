<div class="astigmatism-check" *transloco="let t">
  <!-- ! The check-hint-screen -->
  <div class="astigmatism-check-content" *ngIf="!(toggleHint$ | async)">
    <zat-ovs-header [label]="headerConfig.label" [left]="headerConfig.left" [right]="headerConfig.right"
      [theme]="headerConfig.theme" (clickLeft)="openInfo()" (clickRight)="cross()"></zat-ovs-header>

    <div class="check-instruction">
      <ng-container *ngIf="activeEye===Eyes.LEFT; else rightEyeText">
        <label class="number">1) </label><label [innerHtml]="t('check.hint.eyeCover.right')"></label><br>
      </ng-container>
      <ng-template #rightEyeText>
        <label class="number">1) </label><label [innerHtml]="t('check.hint.eyeCover.left')"></label><br>
      </ng-template>
      <label class="number">2) </label><label [innerHtml]="t('check.hint.keepArmsLength')"></label><br>
      <label class="number">3) </label><label [innerHtml]="t('astigmatismTest.focusOnCenter') + ' '"></label>
      <label class="number" [innerHtml]="t('astigmatismTest.doYouSeeAllLinesInBlack')"></label>
    </div>

    <div class="check-card-spacing">
      <div class="check-card">
        <div class="test-figure text-center" *ngIf="svgScaleRatio">
          <svg xmlns="http://www.w3.org/2000/svg" width="335" height="177.5" viewBox="0 0 335 177.5"
            [style.transform]="'scale(' + svgScaleRatio + ')'" alt="astigmatism image">
            <g transform="translate(-707 -1705.5)">
              <rect width="110" height="4" transform="translate(707 1879)" />
              <rect width="110" height="4" transform="translate(707 1871)" />
              <rect width="110" height="4" transform="translate(707 1863)" />
            </g>
            <g transform="translate(-482 -1705.5)">
              <rect width="110" height="4" transform="translate(707 1879)" />
              <rect width="110" height="4" transform="translate(707 1871)" />
              <rect width="110" height="4" transform="translate(707 1863)" />
            </g>
            <g transform="rotate(22.5 4808.063 -756.916)">
              <rect width="110" height="4" transform="translate(707 1879)" />
              <rect width="110" height="4" transform="translate(707 1871)" />
              <rect width="110" height="4" transform="translate(707 1863)" />
            </g>
            <g transform="rotate(45 2579.72 166.825)">
              <rect width="110" height="4" transform="translate(707 1879)" />
              <rect width="110" height="4" transform="translate(707 1871)" />
              <rect width="110" height="4" transform="translate(707 1863)" />
            </g>
            <g transform="rotate(90 1373.75 666.75)">
              <rect width="110" height="4" transform="translate(707 1879)" />
              <rect width="110" height="4" transform="translate(707 1871)" />
              <rect width="110" height="4" transform="translate(707 1863)" />
            </g>
            <g transform="rotate(67.5 1797.23 491.2)">
              <rect width="110" height="4" transform="translate(707 1879)" />
              <rect width="110" height="4" transform="translate(707 1871)" />
              <rect width="110" height="4" transform="translate(707 1863)" />
            </g>
            <g transform="rotate(-22.5 -3878.565 2231.839)">
              <rect width="110" height="4" transform="translate(707 1879)" />
              <rect width="110" height="4" transform="translate(707 1871)" />
              <rect width="110" height="4" transform="translate(707 1863)" />
            </g>
            <g transform="rotate(-45 -1650.221 1602.077)">
              <rect width="110" height="4" transform="translate(707 1879)" />
              <rect width="110" height="4" transform="translate(707 1871)" />
              <rect width="110" height="4" transform="translate(707 1863)" />
            </g>
            <g transform="rotate(-67.5 -867.731 1380.931)">
              <rect width="110" height="4" transform="translate(707 1879)" />
              <rect width="110" height="4" transform="translate(707 1871)" />
              <rect width="110" height="4" transform="translate(707 1863)" />
            </g>
          </svg>
        </div>

        <div class="actions">
          <zat-action-button class="astigmatism-test-yes" type="check-button" [animationState]="iconState"
            (animationDone)="onAnimationDone($event)" (action)="selectAnswer(answers.YES)"
            data-testid="astigmatism-test-yes">
            {{ t('button.yes') }}
          </zat-action-button>
          <zat-action-button type="check-button" [animationState]="iconState"
            (animationDone)="onAnimationDone($event)" (action)="selectAnswer(answers.NO)"
            data-testid="astigmatism-test-no">
            {{ t('button.no') }}
          </zat-action-button>
        </div>
      </div>

    </div>
  </div>

  <!-- ! The check-hint-screen -->
  <zat-how-it-works-astigmatism-page (clickClose)="closeHint()" class="the-check-hint" *ngIf="toggleHint$ | async">
  </zat-how-it-works-astigmatism-page>

  <div class="debug" *ngIf="debug$ | async">
    <span>Current eye (Test): <strong>{{ activeEye }}</strong></span><br>
    <span>Current eye (Cover): <strong>{{ activeEye !== Eyes.LEFT ? Eyes.LEFT : Eyes.RIGHT }}</strong></span><br>
    <span>Spatial size (d): <strong>{{ gapSizeInLogMAR }} logMAR</strong></span><br>
    <span>GapSize: <strong>{{ (gapSize)?.toFixed(4) }}px</strong></span><br>
    <span>Pixel pitch: <strong>{{ pixelPitch }}cm</strong></span><br>
    <span>Distance: <strong>{{ distance }}cm</strong></span>
  </div>
</div>
