export enum RoutePathes {
  Home = '',
  Not_Authorized = 'not-authorized',
  Home_CheckSelection = 'home/check-selection',
  DefaultCheckResult = 'default/check/result',
  CheckResult = '/check/result',
  StoreLocator = 'store-locator',
  Profile = 'profile',
  CheckPrepare = '/check/prepare',
  CheckStart = '/check/start',
  CheckStartLeft = '/check/start/left',
  CalibrateBrightness = 'calibrate/brightness',
  CalibrateCredit_Card = 'calibrate/credit-card',
  CheckInstructions = '/check/instructions',
  ResultCodeScreen = '/chubResultCode',
  Default = 'default',
  Right = 'right',
  PagesHelp = '/pages/help',
  // Preparation-pages
  PreparationImportant_Notes = 'preparation/important-notes',
  AboutUs_Faq = 'about-us/faq',
  AboutUs = 'about-us',
  AboutUs_LegalDocuments = 'about-us/legal-documents',
  AcuityDetails = 'acuity-check',
  ContrastDetails = 'contrast-check',
  ColorDetails = 'color-check',
  AstigmatismDetails = 'astigmatism-check',
  AmslerDetails = 'amsler-check',
  // dialogs
  CloseWarning = 'close-warning'
}

export enum TestpathInput {
  Entry = 'entry',
  Acuity = 'acuity-check',
  Contrast = 'contrast-check',
  Color = 'color-check',
  Astigmatism = 'astigmatism-check',
  Amsler = 'amsler-check',
  Default = ''
}
