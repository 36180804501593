<ng-container *transloco="let t">
  <div class="va-description-image">
    <app-icon id="imageId" [name]="image" [width]="30" [height]="30" alt="" class="icon-small">
      <app-icon id="imageResultId" [name]="'icon_smiley_' + result" [width]="16" [height]="16"
        alt="" class="icon-small-result">
      </app-icon>
    </app-icon>
    <span class="title text-headline" [innerHTML]="t(testName)"></span>
  </div>
  <div class="icon">
    <app-icon [@openClose]="expended ? 'open' : 'closed'" class="arrow-up" id="iconArrowUp" name="icn_arrow_down"
      [width]="24" [height]="24" alt="Show / Hide">
    </app-icon>
  </div>
</ng-container>
