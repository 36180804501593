import { AnimationEvent } from '@angular/animations';
import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  OnDestroy,
  OnInit
} from '@angular/core';
import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { delay, filter, map, take, tap } from 'rxjs/operators';

// Stuff for general
import { environment } from '../../../../environments/environment';
import { TestTypeActions } from '../../../store/actions';
import { AppSelectors } from '../../../store/selectors';
import { AppCookiesService } from '../../../services/app-cookies.service';
import { AnalyticsService } from '../../../services/analytics.service';
import { AzureTableClientService } from '../../../services/azure-table-client.service';
import { OpenHomePageAction } from '../../../store/actions/general.actions';
import { GeneralService } from '../../../services/general.service';

// Special for checks
import { AcuityActions, AcuitySelectors } from '../../store';
import { MAX_VA, MIN_VA } from '../../shared/config';

// From shared folder
import { RoutePathes } from '../../../shared/enums/route-pathes.enum';
import { HeaderConfig } from '../../../shared/interfaces/header-config.interface';
import {
  HeaderColorEnum,
  HeaderLabelEnum,
  HeaderEnum
} from '../../../shared/enums/header.enum';
import { CloseDialogContext } from '../../../shared/components/close-dialog/close-dialog-context.enum';
import {
  GA4_EventDetail,
  GA4_EventAction,
  GA4_EventName,
  GA4_EventType,
  GA4_EventValue
} from '../../../shared/enums/ga4.enum';
import { calculateLandoltCWidth } from '../../../shared/utils';
import { TEST_TYPES } from '../../../shared/enums/test-types';
import { C_ANGLES, Eyes, ScaleDirections } from '../../../shared/enums';
import { fadeInAnimation, landoltCAnimation } from '../../../shared/animations';
import { AppInsightsService } from '../../../services/app-insights.service';

@UntilDestroy()
@Component({
  selector: 'zat-acuity-check-page',
  templateUrl: './acuity-check-page.component.html',
  styleUrls: ['./acuity-check-page.component.scss'],
  animations: [fadeInAnimation, landoltCAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AcuityCheckPageComponent implements OnInit, OnDestroy {
  megaTestActive$: Observable<boolean>;
  debug$: Observable<boolean>;

  width$: Observable<number>;
  rotationAngle$: Observable<C_ANGLES>;
  va$: Observable<number>;
  testCount$: Observable<number>;
  reachedMaxTests$: Observable<boolean>;
  activeEye$: Observable<Eyes>;
  lastScaleDirection$: Observable<ScaleDirections>;
  activeEyeFinished$: Observable<boolean>;

  cAngle: C_ANGLES;
  checkType: GA4_EventType = GA4_EventType.Acuity;
  activeEye: Eyes;
  currentVA: number;
  testCount: number;
  megaTestActive: boolean;

  // projected values to use in the view
  Eyes = Eyes;

  public landoltCAnimationState = 'init';

  public lastIsCorrectValue: boolean;
  private currentIsCorrectValue: boolean;
  public directionChanges$: Observable<number>;
  private activeEyeFinished: boolean;

  private isInitialPageTracked = false;

  private destroy$: Subject<void> = new Subject<void>();

  pixelPitchForDebug: number = 0;

  // VA value to track together with interaction duration and answer accuracy
  private visualAcuity: number;

  headerConfig: HeaderConfig = {
    label: HeaderLabelEnum.AcuityCheck,
    right: HeaderEnum.Close,
    left: HeaderEnum.Info,
    theme: HeaderColorEnum.LightMode
  } as HeaderConfig;

  toggleHint$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  // trigger close warning dialog for checks when browser back button is clicked
  @HostListener('window:popstate', ['$event'])
  onPopState() {
    if (!environment.webcomponent) {
      history.pushState(null, null, location.href);
      this.cross();
    }
  }

  constructor(
    private store: Store,
    private locationService: Location,
    private cookies: AppCookiesService,
    private logger: AppInsightsService,
    private analytics: AnalyticsService,
    private tableService: AzureTableClientService,
    private generalService: GeneralService
  ) {
    history.pushState(null, null, location.href);
  }

  ngOnInit(): void {
    this.megaTestActive$ = this.store.select(AppSelectors.getMegaTestActive);
    this.debug$ = this.store.select(AppSelectors.getDebug);

    this.width$ = this.store.select(AcuitySelectors.getVisualAcuity).pipe(
      map((va: number) => {
        const pixelPitch = this.cookies.getPixelPitch();
        let calcWidth = calculateLandoltCWidth(va, pixelPitch);
        this.pixelPitchForDebug = pixelPitch;
        return calcWidth;
      })
    );

    this.rotationAngle$ = this.store.select(
      AcuitySelectors.getLandoltCRotationAngle
    );
    this.va$ = this.store.select(AcuitySelectors.getVisualAcuity);
    this.va$
      .pipe(
        tap((va: number) => {
          this.visualAcuity = va;
        })
      )
      .subscribe();

    this.testCount$ = this.store.select(AcuitySelectors.getTestCount);
    this.reachedMaxTests$ = this.store.select(
      AcuitySelectors.hasReachedMaxTests
    );
    this.activeEye$ = this.store.select(AcuitySelectors.getActiveEye);
    this.lastScaleDirection$ = this.store.select(
      AcuitySelectors.getLastScaleDirection
    );
    this.activeEyeFinished$ = this.store.select(
      AcuitySelectors.getActiveEyeFinished
    );

    this.directionChanges$ = this.store.select(
      AcuitySelectors.getActiveEyeStepChangeCounter
    );

    this.store.dispatch(AcuityActions.RandomizeAngle());

    combineLatest([this.activeEye$, this.testCount$])
      .pipe(untilDestroyed(this))
      .subscribe(([activeEye, testCount]) => {
        this.activeEye = activeEye;
        this.testCount = testCount;
        this.trackInitialVirtualPage();
      });

    this.rotationAngle$.pipe(untilDestroyed(this)).subscribe((angle) => {
      this.cAngle = angle;
    });

    this.va$
      .pipe(untilDestroyed(this))
      .subscribe((value) => (this.currentVA = value));

    this.activeEyeFinished$.pipe(untilDestroyed(this)).subscribe((result) => {
      this.activeEyeFinished = result;
    });

    this.megaTestActive$.pipe(untilDestroyed(this)).subscribe((active) => {
      this.megaTestActive = active;
      this.goToResultWhenFinished(active);
    });

    this.tableService.resetInteractionStartTime();
  }

  // DO NOT DELETE
  // this method needs to be here for `untilDestroyed()` to work
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onComplete(isCorrect: boolean): void {
    this.store.dispatch(AcuityActions.IncreaseCount());

    this.currentIsCorrectValue = isCorrect;

    // check for changes of direction
    if (
      this.lastIsCorrectValue !== undefined &&
      this.lastIsCorrectValue !== isCorrect
    ) {
      this.store.dispatch(
        AcuityActions.IncreaseStepChangeCount({ eye: this.activeEye })
      );
    }

    // update VA value based on the correctness
    if (isCorrect) {
      if (this.currentVA === MIN_VA) {
        this.store.dispatch(
          AcuityActions.IncreaseSmallestStepCounter({ eye: this.activeEye })
        );
      }

      this.store.dispatch(
        AcuityActions.StoreValue({ eye: this.activeEye, value: this.currentVA })
      );
      this.store.dispatch(AcuityActions.Decrease());
    } else {
      // if current VA is already at MAX, dispatch IncreaseHighestStepCounter for this eye
      if (this.currentVA === MAX_VA) {
        this.store.dispatch(
          AcuityActions.IncreaseHighestStepCounter({ eye: this.activeEye })
        );
      }

      // reset the smallest step counter because for the smallest step,
      // it must be 3 success in a row
      this.store.dispatch(
        AcuityActions.ResetSmallestStepCounter({ eye: this.activeEye })
      );
      this.store.dispatch(AcuityActions.Increase());
    }

    // keep last `isCorrect` value to compare against the next round
    // and see if there is a change of the direction
    this.lastIsCorrectValue = isCorrect;

    this.next();
  }

  private next(): void {
    this.tableService.updateAnalysisData(
      this.activeEye,
      this.currentIsCorrectValue,
      this.visualAcuity
    );

    // manually check if the test is finished
    if (this.activeEyeFinished) {
      this.finishTest();
    } else {
      this.trackNewVirtualPage();
    }
  }

  private finishTest(): void {
    this.trackFinishVirtualPage();
    if (this.activeEye === Eyes.RIGHT) {
      // mark current eye as 'finished'
      this.store.dispatch(AcuityActions.SetActiveEye({ eye: Eyes.LEFT }));

      let rightPath = `${TEST_TYPES.ACUITY}${RoutePathes.CheckPrepare}`;
      if (this.megaTestActive) {
        rightPath = `${RoutePathes.Default}/${rightPath}`;
      }

      this.generalService.routeToNextScreen(rightPath);
      this.store.dispatch(AcuityActions.ResetTests());
      return;
    }

    this.goToResults();
  }

  private goToResults(): void {
    this.tableService.storeAnalysisData$().subscribe((response) => {
      if (response !== null) {
        this.logger.info('#### [ACUITY_CHECK] response: ', response);
      }
    });

    // save in mega test
    if (this.megaTestActive) {
      this.store.dispatch(
        TestTypeActions.SetTestInMegaTest({ testType: TEST_TYPES.ACUITY })
      );
    }

    // finish left eye
    let leftPath = `/${TEST_TYPES.ACUITY}${RoutePathes.CheckResult}`;
    if (this.megaTestActive) {
      leftPath = `${RoutePathes.Default}/${leftPath}`;
    }

    // go to the questionnaire
    this.generalService.routeToNextScreen(leftPath);
    this.store.dispatch(AcuityActions.ResetTests());
  }

  // initial tracking on the first load only.
  private trackInitialVirtualPage(): void {
    if (this.isInitialPageTracked) {
      return;
    }

    this.trackVirtualPage(this.activeEye, this.testCount);
    this.isInitialPageTracked = true;
  }

  // tracking when a new landolt-c is displayed.
  private trackNewVirtualPage(): void {
    this.trackVirtualPage(this.activeEye, this.testCount);
  }

  // tracking when the test finishes.
  private trackFinishVirtualPage(): void {
    // we have to do `testCount - 1` because the count number
    // was already increase _before_ it can know if the test is finished.
    // the number we want is from the last test's testCount.
    this.trackVirtualPage(this.activeEye, this.testCount - 1, '_end');
  }

  private trackVirtualPage(
    activeEye: Eyes,
    testCount: number,
    suffix: string = ''
  ): void {
    const basePath = this.locationService.path();
    const pagePath = `${basePath}/${activeEye}_${testCount}${suffix}`;

    this.analytics.trackVirtualPage(pagePath);
  }

  cancelTest(): void {
    // Clear visual check analysis data to avoid cluttered entities with half finished test data
    this.tableService.clearVisualCheckData();

    this.store.dispatch(OpenHomePageAction());
  }

  onLandoltCAnimationDone(event: AnimationEvent): void {
    // always set the state to 'idle' right after the 'init' state
    if (event.toState === 'init') {
      this.landoltCAnimationState = 'idle';
    }
  }

  private goToResultWhenFinished(megaTestActive: boolean): void {
    // #646214: redirect back the result screen if the user has finished tests for both eyes
    this.store
      .select(AcuitySelectors.getBothEyesFinished)
      .pipe(filter((finished) => !!finished))
      .subscribe(() => {
        let path = `${TEST_TYPES.ACUITY}${RoutePathes.CheckResult}`;
        if (megaTestActive) {
          path = `${RoutePathes.Default}/${path}`;
        }
        this.generalService.routeToNextScreen(path);
      })
      .unsubscribe();
  }

  openInfo(): void {
    this.toggleHint$.next(true);
    this.analytics.createCustomEvent({
      event: 'event',
      eventName: `${GA4_EventName.CTA}`,
      eventAction: `${GA4_EventAction.Click}`,
      eventType: `${GA4_EventType.Internal}`,
      eventValue: `${GA4_EventValue.Acuity}`,
      eventDetail: `${GA4_EventDetail.HowItWorks}`
    });
  }

  closeHint(): void {
    this.toggleHint$.next(false);
  }

  cross(): void {
    this.analytics.createCustomEvent({
      event: 'event',
      eventName: `${GA4_EventName.CTA}`,
      eventAction: `${GA4_EventAction.Click}`,
      eventType: `${GA4_EventType.Acuity}`,
      eventValue: `${GA4_EventValue.ExitCheck}`,
      eventDetail: `${GA4_EventDetail.Clear}`
    });

    of(null)
      .pipe(delay(0), take(1))
      .subscribe(() => {
        this.store.dispatch(
          AcuityActions.ShowCloseWarningAction({
            context: CloseDialogContext.Check,
            previousPath: this.locationService.path()
          })
        );
      });
  }
}
