<button
  class="action-button"
  mat-button
  disableRipple
  [disabled]="disabled"
  [color]="color"
  [attr.aria-label]="ariaLabel"
  [ngClass]="{
    'mat-flat-button': type === 'solid',
    outline: type === 'outline',
    inverted: type === 'inverted',
    'inverted-outline': type === 'inverted-outline',
    'outline-white': type === 'outline-white',
    'check-button': type === 'check-button',
    'inverted-light': type === 'inverted-light',
    'focus-button': type === 'focus',
    small: size === 'small',
    medium: size === 'medium',
    big: size === 'big',
    tiny: size === 'tiny',
    'small-medium': size === 'small-medium',
    'small-big': size === 'small-big',
    'medium-big': size === 'medium-big',
    'height-fit-content': heightFitContent
  }"
  [style.minWidth.px]="width"
  [@buttonAnimation]="buttonState"
  (click)="onAction()"
  #buttonElement
  [tabIndex]="tabValue"
>
  <ng-content></ng-content>
  <ng-container>
    <div
      id="feedbackCorrect"
      *ngIf="iconType === 'correct'"
      class="feedback-icon correct"
      [@iconAnimation]="iconState"
      (@iconAnimation.start)="onAnimationStart($event)"
      (@iconAnimation.done)="onAnimationDone($event)"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="23.404" height="22.446" viewBox="0 0 23.404 22.446" >
        <path d="M0,0H2V26l-2-.46Z" transform="matrix(0.819, 0.574, -0.574, 0.819, 21.766, 0)" />
        <path d="M0,0H2L1.992,11.463,0,12Z" transform="translate(0 13.961) rotate(-45)" />
      </svg>
    </div>
    <div
      id="feedbackIncorrect"
      *ngIf="iconType === 'incorrect'"
      class="feedback-icon incorrect"
      [@iconAnimation]="iconState"
      (@iconAnimation.start)="onAnimationStart($event)"
      (@iconAnimation.done)="onAnimationDone($event)"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="22.629" height="22.628" viewBox="0 0 22.629 22.628" >
        <rect width="2" height="30" transform="translate(21.215 0) rotate(45)" />
        <rect width="2" height="30" transform="translate(0 1.414) rotate(-45)" />
      </svg>
    </div>
    <div
      *ngIf="!iconType"
      class="feedback-icon empty"
      [@iconAnimation]="iconState"
      (@iconAnimation.start)="onAnimationStart($event)"
      (@iconAnimation.done)="onAnimationDone($event)"
    ></div>
  </ng-container>
</button>
