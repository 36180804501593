import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, tap, withLatestFrom } from 'rxjs/operators';

// Stuff for general
import { TestTypeActions } from '../../../store/actions';
import { GeneralService } from '../../../services/general.service';

// Special for checks
import { AcuityActions } from '../actions';
import {
  StartAcuityMegaTestAction,
  StartAcuityMegaTestSuccededAction,
  StartAcuitySingleCheckAction,
  StartAcuitySingleCheckSuccededAction
} from '../actions/acuity.actions';

// From shared folder
import {
  GA4_EventDetail,
  GA4_EventAction,
  GA4_EventName,
  GA4_EventType,
  GA4_EventValue
} from '../../../shared/enums/ga4.enum';
import { RoutePathes } from '../../../shared/enums/route-pathes.enum';
import {
  getActiveTest,
  isCalibrationDone
} from '../../../store/selectors/app.selectors';
import { Calibrations } from '../../../shared/interfaces/calibrations.interface';
import { Instruction } from '../../../shared';
import { TEST_TYPES } from '../../../shared/enums/test-types';
import { AnalyticsService } from '../../../services/analytics.service';
import { AppInsightsService } from '../../../services/app-insights.service';

@Injectable()
export class AcuityEffects {
  constructor(
    private actions$: Actions,
    private generalService: GeneralService,
    private logger: AppInsightsService,
    private store: Store,
    private router: Router,
    private transloco: TranslocoService,
    private analytics: AnalyticsService
  ) {}

  resetTestsOnClearAll$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TestTypeActions.ClearAllTests),
      map(() => AcuityActions.ResetTests())
    );
  });

  resetEyesOnClearAll$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TestTypeActions.ClearAllTests),
      map(() => AcuityActions.ResetsEyeAcuity())
    );
  });

  startAcuitySingleCheck$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(StartAcuitySingleCheckAction),
        withLatestFrom(this.store.select(isCalibrationDone)),
        map(([action, calibrations]: [any, Calibrations]) => {
          let instructionsSet: Array<Instruction> = [];
          if (!calibrations.all) {
            // Calibration not done
            instructionsSet = [
              Instruction.SETUP_BRIGHTNESS,
              Instruction.SETUP_CARD_SLIDER,
              Instruction.WEAR_YOUR_GLASSES,
              Instruction.COVER_LEFT_EYE,
              Instruction.KEEP_AN_ARM,
              Instruction.ACUITY_RING
            ];
          } else {
            // Calibration already done
            instructionsSet = [
              Instruction.COVER_LEFT_EYE,
              Instruction.KEEP_AN_ARM,
              Instruction.ACUITY_RING
            ];
          }
          return StartAcuitySingleCheckSuccededAction({
            instructions: instructionsSet
          });
        })
      );
    },
    { dispatch: true }
  );

  startAcuitySingleCheckSucceded$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(StartAcuitySingleCheckSuccededAction),
        withLatestFrom(this.store.select(getActiveTest)),
        tap(([action, activeTest]: [any, TEST_TYPES]) => {
          this.generalService.routeToNextScreen(
            activeTest + RoutePathes.CheckInstructions
          );

          this.analytics.createCustomEvent({
            event: 'event',
            eventName: `${GA4_EventName.CTA}`,
            eventAction: `${GA4_EventAction.Click}`,
            eventType: `${GA4_EventType.Internal}`,
            eventValue: `${GA4_EventValue.StartCheck}`,
            eventDetail: `${GA4_EventDetail.Acuity}`
          });
        })
      );
    },
    { dispatch: false }
  );

  startAcuityMegaTest$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(StartAcuityMegaTestAction),
        withLatestFrom(this.store.select(isCalibrationDone)),
        map(([action, calibrations]: [any, Calibrations]) => {
          let instructionsSet: Array<Instruction> = [];
          if (!calibrations.all) {
            // Calibration not done
            instructionsSet = [
              Instruction.SETUP_BRIGHTNESS,
              Instruction.SETUP_CARD_SLIDER,
              Instruction.WEAR_YOUR_GLASSES,
              Instruction.COVER_LEFT_EYE,
              Instruction.KEEP_AN_ARM,
              Instruction.ACUITY_RING
            ];
          } else {
            // Calibration already done
            instructionsSet = [
              Instruction.COVER_LEFT_EYE,
              Instruction.KEEP_AN_ARM,
              Instruction.ACUITY_RING
            ];
          }
          return StartAcuityMegaTestSuccededAction({
            instructions: instructionsSet
          });
        })
      );
    },
    { dispatch: true }
  );

  startAcuityMegaTestSucceded$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(StartAcuityMegaTestSuccededAction),
        withLatestFrom(this.store.select(getActiveTest)),
        tap(([action, activeTest]: [any, TEST_TYPES]) => {
          this.generalService.routeToNextScreen(
            RoutePathes.Default +
              '/' +
              activeTest +
              RoutePathes.CheckInstructions
          );

          this.analytics.createCustomEvent({
            event: 'event',
            eventName: `${GA4_EventName.CTA}`,
            eventAction: `${GA4_EventAction.Click}`,
            eventType: `${GA4_EventType.Internal}`,
            eventValue: `${GA4_EventValue.StartCheck}`,
            eventDetail: `${GA4_EventDetail.Acuity}`
          });
        })
      );
    },
    { dispatch: false }
  );

  showCloseWarning$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AcuityActions.ShowCloseWarningAction),
        tap((action) => {
          const locale: string = this.transloco.getActiveLang();
          this.router.navigate([locale, RoutePathes.CloseWarning], {
            state: {
              context: action.context,
              previousPath: action.previousPath
            }
          });
        })
      );
    },
    { dispatch: false }
  );
}
