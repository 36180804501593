import { Component, Input } from '@angular/core';
import { ODSLoadingSpinnerSizes } from '../../interfaces/ods-loading.interface';

@Component({
  selector: 'zat-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent {
  @Input() label: string = '';
  @Input() size: ODSLoadingSpinnerSizes = 'm';

  public readonly sizeMap: { [k in ODSLoadingSpinnerSizes]: number } = {
    xxxs: 12,
    xxs: 16,
    xs: 20,
    s: 32,
    m: 48,
    l: 60,
    xl: 80
  };

  public readonly cubesContainerMap: {
    [k in ODSLoadingSpinnerSizes]: number;
  } = {
    xxxs: 12,
    xxs: 16,
    xs: 20,
    s: 32,
    m: 48,
    l: 60,
    xl: 80
  };

  public readonly cubeSizeMap: { [k in ODSLoadingSpinnerSizes]: number } = {
    xxxs: 5.4,
    xxs: 7.2,
    xs: 9,
    s: 14.4,
    m: 21.6,
    l: 27,
    xl: 36
  };

  public readonly fontSizeMap: { [k in ODSLoadingSpinnerSizes]: number } = {
    xxxs: 12,
    xxs: 14,
    xs: 16,
    s: 16,
    m: 16,
    l: 20,
    xl: 20
  };
}
