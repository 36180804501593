import { Component, OnInit, OnDestroy, Output, EventEmitter, Input, ElementRef, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, tap } from 'rxjs';

// Stuff for general
import { environment } from '../../../environments/environment';

// Special for checks
import { AmslerSelectors } from '../store';

// From shared folder
import { TEST_TYPES } from '../../shared/enums/test-types';
import { HeaderConfig } from '../../shared/interfaces/header-config.interface';
import { HeaderColorEnum, HeaderLabelEnum, HeaderEnum } from '../../shared/enums/header.enum';
import { Eyes, Instruction } from '../../shared/enums';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'zat-how-it-works-amsler-page',
  templateUrl: './how-it-works-amsler-page.component.html',
  styleUrls: ['./how-it-works-amsler-page.component.scss']
})
export class HowItWorksAmslerPageComponent implements OnInit, OnDestroy {
  @ViewChild('scrollableElement') scrollableElement: ElementRef;

  @Input('question') question = 'amslerTest.doYouSeeAllSquaresRegular';
  @Output('clickClose') clickClose = new EventEmitter();
  questionString: string = '';

  Eyes = Eyes;
  TEST_TYPES = TEST_TYPES;

  activeEye$: Observable<Eyes> = this.store.select(AmslerSelectors.getActiveEye);
  eyes = Eyes;

  environment = environment;

  instructions = Instruction;

  headerConfig: HeaderConfig = {
    left: HeaderEnum.Back,
    right: HeaderEnum.Empty,
    label: HeaderLabelEnum.AmslerCheck,
    theme: HeaderColorEnum.LightMode,
  } as HeaderConfig;

  constructor(
    private store: Store,
    private transloco: TranslocoService
  ) { }

  ngOnInit(): void {
    // Removing <br /> from this specific string
    // Problem is that we are using a key here that is also used in another place -> but here we do not want
    // ! This is a quickfix before STAGE release
    // TODO: we should have a separate key here and remove this code
    let q: string = '';
    this.transloco
      .selectTranslation()
      .pipe(
        tap(() => {
          q = this.transloco.translate(this.question);
          if (q.includes('<br /> ')) {
            console.log('### Q: ', this.question, q);
            this.questionString = q.replace('<br />', '');
          }
          else {
            this.questionString = q;
          }
        })
      )
      .subscribe();
  }

  ngAfterViewInit() {
    this.scrollableElement.nativeElement.focus();
  }

  ngOnDestroy(): void { }

  closeHint(): void {
    this.clickClose.emit();
  }
}
