import { calculateGapSize } from './calculate-gap-size';

// From shared folder
import { DEFAULT_DISTANCE_IN_CM } from '../../shared/config';

export function calculateLandoltCWidth(
  visualAcuity: number,
  pixelPitch: number
): number {
  const gapSize = calculateGapSize(
    visualAcuity,
    pixelPitch,
    DEFAULT_DISTANCE_IN_CM
  );

  // the gap size of the landolt c is 1/5 of total diameter
  // so we multiply it by 5 to get the C's width.
  // also round it so that the browser doesn't do
  // weird scaling on decimal points for px unit
  const cWidth = Math.round(gapSize * 5);

  // when the calculated with is an odd number,
  // it can create a half-pixel shift of hi-res displays like on iPhones.
  // we make sure the width is always an even number.
  if (cWidth % 2 === 0) {
    return cWidth;
  }

  return cWidth + 1;
}
