<ng-container *transloco="let t">
  <!-- Init the variable test -->
  <div *ngLet="(amslerTest$ | async) as test" class="amsler-test">
    <div class="image">
      <svg class="question-image" viewBox="0 0 282 282" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd"
        clip-rule="evenodd" [style.width.px]="gapSize" alt="visual field image">
        <g transform="translate(-14.598 -14.645)" stroke="#000">
          <path d="M14.598 296.644V14.598" fill="none" stroke-width=".85" />
          <path d="M296.645 296.644H14.598" fill="none" stroke-width=".85" />
          <path d="M296.645 14.598v282.046" fill="none" stroke-width=".85" />
          <path d="M14.598 14.598h282.047" fill="none" stroke-width=".85" />
          <path d="M14.598 28.709l282.047-.003" fill="none" stroke-width=".85" />
          <path d="M14.598 42.809l282.047-.003" fill="none" stroke-width=".85" />
          <path d="M14.598 56.913l282.047-.003" fill="none" stroke-width=".85" />
          <path d="M14.598 71.013l282.047-.003" fill="none" stroke-width=".85" />
          <path d="M14.598 85.112l282.047-.003" fill="none" stroke-width=".85" />
          <path d="M14.598 99.209l282.047-.002" fill="none" stroke-width=".85" />
          <path d="M14.598 113.31l282.047-.003" fill="none" stroke-width=".85" />
          <path d="M14.598 127.414l282.047-.003" fill="none" stroke-width=".85" />
          <path d="M14.598 141.518l282.047-.003" fill="none" stroke-width=".85" />
          <path d="M14.598 155.622l282.047-.003" fill="none" stroke-width=".85" />
          <path d="M14.598 169.726l282.047-.003" fill="none" stroke-width=".85" />
          <path d="M14.598 183.824l282.047-.003" fill="none" stroke-width=".85" />
          <path d="M14.598 197.925l282.047-.002" fill="none" stroke-width=".85" />
          <path d="M14.598 212.033l282.047-.003" fill="none" stroke-width=".85" />
          <path d="M14.598 226.134l282.047-.003" fill="none" stroke-width=".85" />
          <path d="M14.598 240.232l282.047-.002" fill="none" stroke-width=".85" />
          <path d="M14.598 254.339l282.047-.003" fill="none" stroke-width=".85" />
          <path d="M14.598 268.439l282.047-.003" fill="none" stroke-width=".85" />
          <path d="M14.598 282.536l282.047-.003" fill="none" stroke-width=".85" />
          <path d="M28.709 296.641l-.003-282.043" fill="none" stroke-width=".85" />
          <path d="M42.806 296.641l-.002-282.043" fill="none" stroke-width=".85" />
          <path d="M56.906 296.641l-.003-282.043" fill="none" stroke-width=".85" />
          <path d="M71.013 296.641L71.01 14.598" fill="none" stroke-width=".85" />
          <path d="M85.112 296.641L85.11 14.598" fill="none" stroke-width=".85" />
          <path d="M99.213 296.641L99.21 14.598" fill="none" stroke-width=".85" />
          <path d="M113.321 296.641l-.003-282.043" fill="none" stroke-width=".85" />
          <path d="M127.423 296.641l-.003-282.043" fill="none" stroke-width=".85" />
          <path d="M141.521 296.641l-.002-282.043" fill="none" stroke-width=".85" />
          <path d="M155.625 296.641l-.003-282.043" fill="none" stroke-width=".85" />
          <path d="M169.73 296.641l-.003-282.043" fill="none" stroke-width=".85" />
          <path d="M183.833 296.641l-.003-282.043" fill="none" stroke-width=".85" />
          <path d="M197.935 296.641l-.002-282.043" fill="none" stroke-width=".85" />
          <path d="M212.038 296.641l-.003-282.043" fill="none" stroke-width=".85" />
          <path d="M226.135 296.641l-.003-282.043" fill="none" stroke-width=".85" />
          <path d="M240.238 296.641l-.003-282.043" fill="none" stroke-width=".85" />
          <path d="M254.335 296.641l-.003-282.043" fill="none" stroke-width=".85" />
          <path d="M268.437 296.641l-.003-282.043" fill="none" stroke-width=".85" />
          <path d="M282.54 296.641l-.003-282.043" fill="none" stroke-width=".85" />
          <circle cx="155.624" cy="155.621" stroke-width="2.18" r="6.707" />
        </g>
      </svg>
    </div>

    <div class="actions" [ngClass]="{'fade-in-actions': !(test === AmslerTests.ARE_ALL_REGULAR_AND_PERFECTLY)}">
      <zat-action-button type="check-button" (action)="answer(true)"
        data-testid="amsler-test-yes" class="amsler-test-yes">
        {{ t('button.yes') }}
      </zat-action-button>
      <zat-action-button type="check-button" (action)="answer(false)"
        data-testid="amsler-test-no">
        {{ t('button.no') }}
      </zat-action-button>
    </div>
  </div>
</ng-container>
