import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Special for components & co
import { ContrastTestPageComponent } from './contrast-test-page/contrast-test-page.component';
import { PrepareContrastTestComponent } from './prepare-contrast-test/prepare-contrast-test.component';
import { ContrastResultPageComponent } from './contrast-result-page/contrast-result-page.component';
import { ContrastDetailsPageComponent } from './contrast-details-page/contrast-details-page.component';

// From shared folder
import { InstructionsPageComponent } from '../shared/pages/instructions-page/instructions-page.component';

const routes: Routes = [
  {
    path: '',
    component: ContrastDetailsPageComponent,
    data: {
      animation: 'ContrastTestStartPage'
    }
  },
  {
    path: 'check/instructions',
    component: InstructionsPageComponent,
    data: {
      animation: 'ContrastTestInstructions'
    }
  },
  {
    path: 'check/start',
    component: ContrastTestPageComponent,
    data: {
      animation: 'ContrastTestTestPage'
    }
  },
  {
    path: 'check/prepare',
    component: PrepareContrastTestComponent,
    data: {
      animation: 'ContrastTestPrepare'
    }
  },
  {
    path: 'check/result',
    component: ContrastResultPageComponent,
    data: {
      animation: 'ContrastTestResultPage'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContrastTestRoutingModule {}
