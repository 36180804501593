import { Component, Input } from '@angular/core';

// From shared folder
import { TEST_RESULT } from '../../../shared/enums';
import { Store } from '@ngrx/store';
import { ExitEcpResultAction } from '../../../store/actions/general.actions';
import { SpecialScreeningMode } from '../../enums/special-screening-mode.enum';
import { ecpResultButtonText, getMegaTestActive, getSpecialScreeningMode } from '../../../store/selectors/app.selectors';
import { Observable } from 'rxjs';

@Component({
  selector: 'zat-amsler-test-result',
  templateUrl: './amsler-test-result.component.html',
  styleUrls: ['./amsler-test-result.component.scss']
})
export class AmslerTestResultComponent {
  @Input() results: {
    sumResult: any;
    eyesResult: any;
  };

  @Input() image: any;
  @Input() isFRChannel: boolean;

  public RESULT = TEST_RESULT;
  public SpecialScreeningMode = SpecialScreeningMode;

  constructor(private store: Store) {}

  public specialScreeningMode$ = this.store.select(getSpecialScreeningMode);
  public buttonText$ = this.store.select(ecpResultButtonText);
  public isMegaTestActive$: Observable<boolean> = this.store.select(getMegaTestActive);

  emitExitEcpResult(){
    // dispatch action to notify app.component via store flag to emit Output (user clicked back)
    this.store.dispatch(ExitEcpResultAction());
  }
}
