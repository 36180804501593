import { Store } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';

// Stuff for general
import { OpenHomePageAction } from '../../store/actions/general.actions';

// From shared folder
import { HeaderEnum, HeaderColorEnum, HeaderLabelEnum } from '../../shared/enums/header.enum';
import { HeaderConfig } from '../../shared/interfaces/header-config.interface';

@Component({
  selector: 'zat-check-selection',
  templateUrl: './check-selection.component.html',
  styleUrls: ['./check-selection.component.scss']
})
export class CheckSelectionComponent implements OnInit {

  headerConfig: HeaderConfig = {
    label: HeaderLabelEnum.CheckSelection,
    left: HeaderEnum.Back,
    right: HeaderEnum.Empty,
    theme: HeaderColorEnum.LightMode
  } as HeaderConfig;

  constructor(private store: Store) { }

  ngOnInit() {
  }

  back(): void {
    this.store.dispatch(OpenHomePageAction());
  }
}
