// unique id for each test
// also used for routing
export enum TEST_TYPES {
  ACUITY = 'acuity-check',
  CONTRAST = 'contrast-check',
  COLOR = 'color-check',
  ASTIGMATISM = 'astigmatism-check',
  AMSLER = 'amsler-check',
  NONE = 'none'
}
