import { createAction, props } from '@ngrx/store';

// From shared folder
import { TEST_TYPES } from '../../shared/enums/test-types';

const ACTION_TYPE = '[TestType]';
const ACTION_SEND = '[SendType]';

export const SetActiveTest = createAction(
  `${ACTION_TYPE} SetActiveTest`,
  props<{
    testType: TEST_TYPES;
  }>(),
);

export const SetTestInMegaTest = createAction(
  `${ACTION_TYPE} SetTestInMegaTest`,
  props<{
    testType: TEST_TYPES;
  }>(),
);

export const RunMegaTest = createAction(
  `${ACTION_TYPE} RunMegaTest`,
);

export const ClearMegaTest = createAction(
  `${ACTION_TYPE} ClearMegaTest`,
);

export const ClearAllTests = createAction(
  `${ACTION_TYPE} ClearAllTests`,
);

/**
 * This action sets the test types of the stored results
 * for the selected date
 */
export const SetTestTypeForDate = createAction(
  `${ACTION_TYPE} SetTestTypeForDate`,
  props<{
    testTypes: TEST_TYPES[];
  }>(),
);

export const ResetTestTypeForDate = createAction(
  `${ACTION_TYPE} ResetTestTypeForDate`,
);

export const SendResultsAction = createAction(
  `${ACTION_SEND} Send results`,
);

export const SendResultsCompleteAction = createAction(
  `${ACTION_SEND} Send results complete`,
);

export const ResetAllChecks = createAction(
  `${ACTION_TYPE} Reset all checks`,
);
