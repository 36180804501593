import { Action, createReducer, on } from '@ngrx/store';

// From general
import { environment } from '../../../environments/environment';
import {
  AppActions,
  SetCookiesPreferencesActions,
  SetDebugActions,
  SetLocaleActions,
  TestTypeActions,
  SetBrightnessActions,
  CalibrationActions,
  GeneralActions
} from '../../store/actions';
import {
  SetScreenCalibrationAction,
  StartCheckIntroductionsPageAction,
  SelectScreeningModeAction,
  CalibrationBrightnessSuccesAction,
  CalibrationWearYourGlassesSuccesAction,
  NextInstructionAction,
  PreviousInstructionAction
} from '../../store/actions/general.actions';
import { CloseImportantNotesAction } from '../../store/actions/check-calibration.actions';

// From checks
import {
  StartColorSingleCheckAction,
  StartColorSingleCheckSuccededAction,
  StartColorMegaTestAction,
  StartColorMegaTestSuccedAction
} from '../../color-test/store/actions/color.actions';
import {
  StartAstigmatismMegaTestSuccedAction,
  StartAstigmatismMegaTestAction,
  StartAstigmatismSingleCheckSuccededAction,
  StartAstigmatismSingleCheckAction
} from '../../astigmatism-test/store/actions/astigmatism.actions';
import {
  StartAmslerMegaTestSuccedAction,
  StartAmslerMegaTestAction,
  StartAmslerSingleCheckSuccededAction,
  StartAmslerSingleCheckAction
} from '../../amsler-test/store/actions/amsler.actions';
import {
  StartAcuityMegaTestSuccededAction,
  StartAcuityMegaTestAction,
  StartAcuitySingleCheckSuccededAction,
  StartAcuitySingleCheckAction
} from '../../acuity-test/store/actions/acuity.actions';
import {
  StartContrastMegaTestSuccedAction,
  StartContrastMegaTestAction,
  StartContrastSingleCheckSuccededAction,
  StartContrastSingleCheckAction
} from '../../contrast-test/store/actions/contrast.actions';

// From shared
import {
  Calibrations,
  CalibrationsState
} from '../../shared/interfaces/calibrations.interface';
import { SpecialScreeningMode } from '../../shared/enums/special-screening-mode.enum';
import { TEST_TYPES } from '../../shared/enums/test-types';
import { TestTypesForImportantNotes } from '../../shared/enums/test-types-important-notes.enum';
import { DEFAULT_LOCALE } from '../../shared/app-channels';
import { InstructionsSet } from '../../shared/interfaces/indicator-dots.interface';
import { CountrySettings } from '../../shared/interfaces/my-zeiss-country-setting.interface';

export const appFeatureKey = 'app';

export interface AppState {
  locale: string;
  debug: boolean;
  megaTest: {
    active: boolean;
    tests: TEST_TYPES[];
  };
  activeTest: TEST_TYPES;
  cookiesPreferencesText: string;
  previousURL: string;
  currentURL: string;
  isLoading: boolean;
  displayCloseButton: boolean;
  testsFromDate: TEST_TYPES[];
  permission: string;
  luminance: number;
  continueWithoutCheck: boolean;
  agreeImportantNotes: boolean;
  testTypeCode: TestTypesForImportantNotes;
  sendResult: boolean;
  specialScreeningMode: SpecialScreeningMode;
  screenCalibration: number;
  routeToParentComponent: boolean;
  emitEcpResultEvent: boolean; // app.comp listenes to this and dispatches output event when true, toggles this off
  ecpResultButtonText: string;
  allCalibrationsDone: Calibrations;
  instructionsSet: InstructionsSet;
  // generated form result dat avia cHub API
  resultCode: string;
  //input from app-comp, in web this should be that later half of the locale (e.g. US in en-US)
  myZeissCountryInput:string;
  // fetched from BE
  myZeissCountrySettings:CountrySettings;
}

declare const window: Window &
  typeof globalThis & {
    Cypress: unknown;
    initialState: {};
  };

export const initialState: AppState = (window.Cypress &&
  (window?.initialState as AppState)) || {
  locale: DEFAULT_LOCALE,
  debug: environment.debugMode,
  megaTest: {
    active: false,
    tests: []
  },
  //activeTest: TEST_TYPES.ACUITY,
  activeTest: TEST_TYPES.NONE,
  cookiesPreferencesText: 'Cookies Preferences',
  previousURL: '',
  currentURL: '',
  isLoading: true,
  displayCloseButton: false,
  testsFromDate: [],
  permission: 'undefined',
  luminance: undefined,
  continueWithoutCheck: false,
  agreeImportantNotes: false,
  testTypeCode: null,
  sendResult: false,
  specialScreeningMode: environment.webcomponent
    ? SpecialScreeningMode.ECP
    : SpecialScreeningMode.MY_ZEISS,
  screenCalibration: 0,
  routeToParentComponent: false,
  emitEcpResultEvent: false,
  ecpResultButtonText: null, // when this is null the UI will simple use the default values from i18n
  allCalibrationsDone: {
    all: false,
    brightness: {
      ui: true,
      calibrationState: false
    } as CalibrationsState,
    cardCalibration: {
      ui: false,
      calibrationState: false
    } as CalibrationsState,
    glasses: {
      ui: false,
      calibrationState: false
    } as CalibrationsState
  } as Calibrations,
  instructionsSet: {
    activeInstruction: 0,
    instructions: []
  } as InstructionsSet,
  resultCode: undefined,
  myZeissCountryInput:'INT',
  myZeissCountrySettings:undefined
};

const appReducer = createReducer(
  initialState,

  on(SetDebugActions.SetDebugMode, (state, { debug }) => ({
    ...state,
    debug
  })),

  on(TestTypeActions.RunMegaTest, (state) => ({
    ...state,
    megaTest: {
      active: true,
      tests: []
    }
  })),

  on(TestTypeActions.ClearMegaTest, (state) => ({
    ...state,
    megaTest: {
      active: false,
      tests: []
    }
  })),

  on(TestTypeActions.SetTestInMegaTest, (state, { testType }) => ({
    ...state,
    megaTest: {
      active: true,
      tests: [...state.megaTest.tests, testType]
    }
  })),

  on(TestTypeActions.SetActiveTest, (state, { testType }) => ({
    ...state,
    activeTest: testType
  })),

  on(SetLocaleActions.SetLocale, (state, { locale }) => ({
    ...state,
    locale
  })),

  on(
    SetCookiesPreferencesActions.SetCookiesPreferencesText,
    (state, { text }) => ({
      ...state,
      cookiesPreferencesText: text
    })
  ),

  on(AppActions.SetCurrentURL, (state, { currentURL }) => ({
    ...state,
    previousURL: state.currentURL,
    currentURL
  })),

  on(AppActions.HideSpinner, (state) => ({
    ...state,
    isLoading: false
  })),

  on(AppActions.ShowSpinner, (state) => ({
    ...state,
    isLoading: true
  })),

  on(SetBrightnessActions.SetBrightnessCalibrationStateDone, (state) => ({
    ...state,
    allCalibrationsDone: {
      ...state.allCalibrationsDone,
      brightness: {
        ...state.allCalibrationsDone.brightness,
        calibrationState: true
      },
      cardCalibration: {
        ...state.allCalibrationsDone.cardCalibration,
        ui: true
      }
    }
  })),

  on(AppActions.SetCloseButton, (state, { mode }) => ({
    ...state,
    displayCloseButton: mode
  })),

  on(GeneralActions.OpenHomePageAction, (state) => ({
    ...state,
    displayCloseButton: false,
    activeTest: TEST_TYPES.NONE,
    megaTest: {
      active: false,
      tests: []
    },
    routeToParentComponent: false
  })),

  on(TestTypeActions.ResetAllChecks, (state) => ({
    ...state,
    displayCloseButton: false,
    activeTest: TEST_TYPES.NONE,
    megaTest: {
      active: false,
      tests: []
    }
  })),

  on(TestTypeActions.SetTestTypeForDate, (state, { testTypes }) => ({
    ...state,
    testsFromDate: testTypes
  })),

  on(TestTypeActions.ResetTestTypeForDate, (state) => ({
    ...state,
    testsFromDate: []
  })),

  on(CalibrationActions.ContinueWithoutCardCheckAction, (state, action) => {
    return {
      ...state,
      continueWithoutCheck: true
    };
  }),
  on(CalibrationActions.ShowImportantNotesAction, (state, action) => {
    return {
      ...state,
      testTypeCode: action.testType,
      activeTest: null,
      megaTest: {
        active: false,
        tests: []
      }
    };
  }),
  on(CloseImportantNotesAction, (state, action) => {
    return {
      ...state,
      testTypeCode: null
    };
  }),
  on(CalibrationActions.AgreeImportantNotesAction, (state, action) => {
    return {
      ...state,
      agreeImportantNotes: true
    };
  }),
  on(CalibrationActions.ResetImportantNotesAction, (state, action) => {
    return {
      ...state,
      agreeImportantNotes: false
    };
  }),

  on(TestTypeActions.SendResultsAction, (state, action) => {
    return {
      ...state,
      sendResult: true
    };
  }),
  on(TestTypeActions.SendResultsCompleteAction, (state, action) => {
    return {
      ...state,
      sendResult: false
    };
  }),

  on(GeneralActions.goToStoreLocatorAction, (state, action): AppState => {
    return {
      ...state,
      displayCloseButton: false
    };
  }),

  // for routing externally on the parent website of the OVS webcomponent
  on(GeneralActions.RouteToParentComponentAction, (state, action): AppState => {
    return {
      ...state,
      routeToParentComponent: true
    };
  }),

  on(
    GeneralActions.RouteToParentComponentSucceededAction,
    (state, action): AppState => {
      return {
        ...state,
        routeToParentComponent: false
      };
    }
  ),

  // for routing externally on the parent website of the OVS webcomponent,
  // when the user clicks the call-to-action button on the result screen (only availabe in ecp mode)
  on(GeneralActions.ExitEcpResultAction, (state, action): AppState => {
    console.log('[app.reducer] set State.emitEcpResultEvent to true');
    return {
      ...state,
      emitEcpResultEvent: true
    };
  }),

  on(GeneralActions.ExitEcpResultSuccessAction, (state, action): AppState => {
    return {
      ...state,
      emitEcpResultEvent: false
    };
  }),

  on(GeneralActions.SetResultButtonTextAction, (state, action): AppState => {
    return {
      ...state,
      ecpResultButtonText: action.text
    };
  }),

  on(GeneralActions.SetMyZeissCountryAction, (state, action): AppState => {
    return {
      ...state,
      myZeissCountryInput: action.country
    };
  }),

  on(GeneralActions.SetMyZeissCountrySettingsAction, (state, action): AppState => {
    return {
      ...state,
      myZeissCountrySettings: action.countrySettings
    };
  }),

  on(GeneralActions.SaveAndGenerateResultCodeSuccessAction, (state, action): AppState => {
    return {
      ...state,
      resultCode: action.resultCode
    };
  }),

  on(SelectScreeningModeAction, (state, action): AppState => {
    return {
      ...state,
      specialScreeningMode: action.screeningMode
    };
  }),

  on(SetScreenCalibrationAction, (state, action): AppState => {
    console.log(
      '##### SetScreenCalibrationAction instr: ',
      state.instructionsSet
    );
    return {
      ...state,
      screenCalibration: action.screenCalibration,
      continueWithoutCheck: false,
      allCalibrationsDone: {
        ...state.allCalibrationsDone,
        cardCalibration: {
          ...state.allCalibrationsDone.cardCalibration,
          calibrationState: true
        },
        glasses: {
          ...state.allCalibrationsDone.glasses,
          ui: true,
          calibrationState: true
        }
      },
      instructionsSet: {
        ...state.instructionsSet,
        activeInstruction: state.instructionsSet.activeInstruction + 1
      }
    };
  }),

  on(StartCheckIntroductionsPageAction, (state, action): AppState => {
    return {
      ...state,
      allCalibrationsDone: { ...state.allCalibrationsDone, all: true }
    };
  }),

  // Single checks
  on(StartAcuitySingleCheckAction, (state, action): AppState => {
    return {
      ...state,
      megaTest: {
        active: false,
        tests: []
      },
      activeTest: TEST_TYPES.ACUITY,
      agreeImportantNotes: true
    };
  }),
  on(StartAcuitySingleCheckSuccededAction, (state, action): AppState => {
    return {
      ...state,
      instructionsSet: {
        activeInstruction: 0,
        instructions: action.instructions
      } as InstructionsSet
    };
  }),

  on(StartContrastSingleCheckAction, (state, action): AppState => {
    return {
      ...state,
      megaTest: {
        active: false,
        tests: []
      },
      activeTest: TEST_TYPES.CONTRAST,
      agreeImportantNotes: true
    };
  }),
  on(StartContrastSingleCheckSuccededAction, (state, action): AppState => {
    return {
      ...state,
      instructionsSet: {
        activeInstruction: 0,
        instructions: action.instructions
      } as InstructionsSet
    };
  }),

  on(StartColorSingleCheckAction, (state, action): AppState => {
    return {
      ...state,
      megaTest: {
        active: false,
        tests: []
      },
      activeTest: TEST_TYPES.COLOR,
      agreeImportantNotes: true
    };
  }),
  on(StartColorSingleCheckSuccededAction, (state, action): AppState => {
    return {
      ...state,
      instructionsSet: {
        activeInstruction: 0,
        instructions: action.instructions
      } as InstructionsSet
    };
  }),

  on(StartAstigmatismSingleCheckAction, (state, action): AppState => {
    return {
      ...state,
      megaTest: {
        active: false,
        tests: []
      },
      activeTest: TEST_TYPES.ASTIGMATISM,
      agreeImportantNotes: true
    };
  }),
  on(StartAstigmatismSingleCheckSuccededAction, (state, action): AppState => {
    return {
      ...state,
      instructionsSet: {
        activeInstruction: 0,
        instructions: action.instructions
      } as InstructionsSet
    };
  }),

  on(StartAmslerSingleCheckAction, (state, action): AppState => {
    return {
      ...state,
      megaTest: {
        active: false,
        tests: []
      },
      activeTest: TEST_TYPES.AMSLER,
      agreeImportantNotes: true
    };
  }),
  on(StartAmslerSingleCheckSuccededAction, (state, action): AppState => {
    return {
      ...state,
      instructionsSet: {
        activeInstruction: 0,
        instructions: action.instructions
      } as InstructionsSet
    };
  }),

  // Mega-Check-Actions
  on(StartAcuityMegaTestAction, (state, action) => {
    return {
      ...state,
      megaTest: {
        active: true,
        tests: []
      },
      activeTest: TEST_TYPES.ACUITY
    };
  }),
  on(StartAcuityMegaTestSuccededAction, (state, action): AppState => {
    return {
      ...state,
      instructionsSet: {
        activeInstruction: 0,
        instructions: action.instructions
      } as InstructionsSet
    };
  }),

  on(StartContrastMegaTestAction, (state, action) => {
    return {
      ...state,
      megaTest: {
        active: true,
        tests: [TEST_TYPES.ACUITY]
      },
      activeTest: TEST_TYPES.CONTRAST
    };
  }),
  on(StartContrastMegaTestSuccedAction, (state, action): AppState => {
    return {
      ...state,
      instructionsSet: {
        activeInstruction: 0,
        instructions: action.instructions
      } as InstructionsSet
    };
  }),

  on(StartColorMegaTestAction, (state, action) => {
    return {
      ...state,
      megaTest: {
        active: true,
        tests: [TEST_TYPES.ACUITY, TEST_TYPES.CONTRAST]
      },
      activeTest: TEST_TYPES.COLOR
    };
  }),
  on(StartColorMegaTestSuccedAction, (state, action): AppState => {
    return {
      ...state,
      instructionsSet: {
        activeInstruction: 0,
        instructions: action.instructions
      } as InstructionsSet
    };
  }),

  on(StartAstigmatismMegaTestAction, (state, action) => {
    return {
      ...state,
      megaTest: {
        active: true,
        tests: [TEST_TYPES.ACUITY, TEST_TYPES.CONTRAST, TEST_TYPES.COLOR]
      },
      activeTest: TEST_TYPES.ASTIGMATISM
    };
  }),
  on(StartAstigmatismMegaTestSuccedAction, (state, action): AppState => {
    return {
      ...state,
      instructionsSet: {
        activeInstruction: 0,
        instructions: action.instructions
      } as InstructionsSet
    };
  }),

  on(StartAmslerMegaTestAction, (state, action) => {
    return {
      ...state,
      megaTest: {
        active: true,
        tests: [
          TEST_TYPES.ACUITY,
          TEST_TYPES.CONTRAST,
          TEST_TYPES.COLOR,
          TEST_TYPES.ASTIGMATISM
        ]
      },
      activeTest: TEST_TYPES.AMSLER
    };
  }),
  on(StartAmslerMegaTestSuccedAction, (state, action): AppState => {
    return {
      ...state,
      instructionsSet: {
        activeInstruction: 0,
        instructions: action.instructions
      } as InstructionsSet
    };
  }),

  // Calibration actions
  on(CalibrationBrightnessSuccesAction, (state, action): AppState => {
    return {
      ...state,
      instructionsSet: {
        ...state.instructionsSet,
        activeInstruction: state.instructionsSet.activeInstruction + 1
      },
      allCalibrationsDone: {
        ...state.allCalibrationsDone,
        brightness: { ui: true, calibrationState: true }
      }
    };
  }),
  on(CalibrationWearYourGlassesSuccesAction, (state, action): AppState => {
    return {
      ...state,
      instructionsSet: {
        ...state.instructionsSet,
        activeInstruction: state.instructionsSet.activeInstruction + 1
      },
      allCalibrationsDone: {
        ...state.allCalibrationsDone,
        all: true,
        glasses: { ui: true, calibrationState: true }
      }
    };
  }),
  on(NextInstructionAction, (state, action): AppState => {
    return {
      ...state,
      instructionsSet: {
        ...state.instructionsSet,
        activeInstruction: state.instructionsSet.activeInstruction + 1
      }
    };
  }),
  on(PreviousInstructionAction, (state, action): AppState => {
    // reset calibration -> ( all: false), when going back to calibrationScreen
    if (action.shouldResetCalibration) {
      return {
        ...state,
        allCalibrationsDone: { ...state.allCalibrationsDone, all: false },
        instructionsSet: {
          ...state.instructionsSet,
          activeInstruction: state.instructionsSet.activeInstruction - 1
        }
      };
    } else {
      return {
        ...state,
        instructionsSet: {
          ...state.instructionsSet,
          activeInstruction: state.instructionsSet.activeInstruction - 1
        }
      };
    }
  })
);

(window as any).redux = appReducer;

export function reducer(state: AppState | undefined, action: Action): any {
  return appReducer(state, action);
}
