import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { LottieModule } from 'ngx-lottie';
import { LottieOptions } from 'ngx-lottie/lib/symbols';
import { NgLetModule } from 'ng-let';

// Special for components & co
import { PortalPageComponent } from '../portal-page/portal-page.component';
import { PortalRoutingModule } from './portal-routing.module';
import { AppIconsModule } from '../app-icons/app-icons.module';
import { CheckSelectionComponent } from '../portal-page/check-selection/check-selection.component';
import { AboutUsModule } from '../portal-page/about-us/about-us.module';

// From shared folder
import { AppSharedModule } from '../shared/app-shared.module';
import { AppMaterialModule } from '../shared/app-material/app-material.module';

export function playerFactory() {
  return import('lottie-web');
}

@NgModule({
  declarations: [
    PortalPageComponent,
    CheckSelectionComponent
  ],
  imports: [
    CommonModule,
    PortalRoutingModule,
    AppSharedModule,
    AppMaterialModule,
    TranslocoModule,
    AppIconsModule,
    AboutUsModule,
    NgLetModule,
    LottieModule.forRoot({ player: playerFactory, useCache: true } as LottieOptions),
  ]
})
export class PortalModule { }
