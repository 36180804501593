import {
  animate,
  AnimationEvent,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  SecurityContext
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, take } from 'rxjs';

// Stuff for general
import { environment } from '../../../environments/environment';

// Special for checks
import { ColorActions, ColorSelectors } from '../store';
import { ColorTestItem } from '../shared';

// From shared folder
import { IconState } from '../../shared/enums';
import {
  TIMING_FUNCTION_BACKWARD_DELAYED,
  TIMING_FUNCTION_FORWARD
} from '../../shared/config';
import {
  GA4_CheckAnswers,
  GA4_EventAction,
  GA4_EventName,
  GA4_EventType,
  GA4_EventValue
} from '../../shared/enums/ga4.enum';
import { AnalyticsService } from '../../services/analytics.service';

@UntilDestroy()
@Component({
  selector: 'zat-color-test',
  templateUrl: './color-test.component.html',
  styleUrls: ['./color-test.component.scss'],
  animations: [
    trigger('controlAnimation', [
      state('init', style({ opacity: 0 })),
      state('idle', style({ opacity: 1 })),
      transition('init => idle', animate(TIMING_FUNCTION_FORWARD)),
      transition('idle => init', animate(TIMING_FUNCTION_BACKWARD_DELAYED))
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColorTestComponent implements OnInit, OnDestroy {
  @Output() done = new EventEmitter();

  public colorTest$: Observable<ColorTestItem> = this.store.select(
    ColorSelectors.getCurrentTest
  );
  public controlState = 'idle';
  public iconState = IconState.IDLE;
  public answerState: number | string = undefined;
  public disabled = false;
  public testImages: any = {};
  public tests = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15];

  // a flag to prevent the user from clicking too fast
  // and cause wrong animation states of buttons
  private isAnimating = false;

  public imgName: string;
  environment = environment;

  constructor(
    private store: Store,
    private sanitizer: DomSanitizer,
    private analytics: AnalyticsService
  ) {
    // TODO: Check in refactor-story #298523 if there is a more suitable place to preload the images!
    this.preloadImages();
  }

  ngOnInit(): void {
    this.store
      .select(ColorSelectors.isFinished)
      .pipe(untilDestroyed(this))
      .subscribe((isFinished) => {
        if (isFinished) {
          this.done.emit();
        }
      });
  }

  ngOnDestroy(): void {}

  preloadImages(index: number = 0): void {
    const no = this.tests[index];
    const img = new Image();
    img.onload = () => {
      if (index < this.tests.length - 1) {
        this.preloadImages(index + 1);
      }
      this.testImages[no] = img;
    };

    if (environment.webcomponent) {
      // Different image path for webcomponent to be able to load images correctly
      img.src = `${environment.serverUrl}/assets/img/color-plates/ishihara_${no}.svg`;
    } else {
      // Keep this path for normal version, otherwise, angular service worker fails load these images
      // from cache when running progressive web app offline.
      img.src = `/assets/img/color-plates/ishihara_${no}.svg`;
    }

    // TODO: maybe add name to img instead
    this.imgName = `ishihara_${no}`;
  }

  public getSanitizeUrl(url: string) {
    if (!url) {
      return;
    }

    let sanitizedUrl = this.sanitizer.sanitize(SecurityContext.URL, url);
    return sanitizedUrl;
  }

  onAnimationStart(event: AnimationEvent) {
    if (
      event.fromState === IconState.ANSWERED &&
      event.toState === IconState.IDLE
    ) {
      this.controlState = 'init';
    }
  }

  onAnimationDone(event: AnimationEvent) {
    if (event.toState === IconState.ANSWERED) {
      this.iconState = IconState.IDLE;
    }
  }

  onControlDone(event: AnimationEvent) {
    if (event.toState === 'init') {
      this.controlState = 'idle';
      this.submitAnswer();

      this.isAnimating = false;
    }
  }

  answer(answer: number | string): void {
    if (this.isAnimating) {
      return;
    }

    this.isAnimating = true;
    this.iconState = IconState.ANSWERED;
    // this is that anwer that will be submitted in submitAnswer()
    this.answerState = answer;

    this.colorTest$.pipe(take(1)).subscribe((colorTest) => {
      // Fire the ga4-event
      let stateString: GA4_CheckAnswers =
        colorTest.correctAnswer === answer
          ? GA4_CheckAnswers.True
          : GA4_CheckAnswers.False;
      this.analytics.createCustomEvent({
        event: 'event',
        eventName: `${GA4_EventName.CTA}`,
        eventAction: `${GA4_EventAction.Click}`,
        eventType: `${GA4_EventType.Color}`,
        eventValue: `${GA4_EventValue.ColorQ} - ${colorTest.correctAnswer}`,
        eventDetail: `${answer} - ${stateString}`
      });
    });
  }

  submitAnswer(): void {
    const answerState = this.answerState;
    this.answerState = undefined;

    this.store.dispatch(ColorActions.Answer({ answer: answerState }));
  }
}
