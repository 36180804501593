import { createAction, props } from '@ngrx/store';

// From shared folder
import { Eyes, Instruction } from '../../../shared/enums';
import { CloseDialogContext } from '../../../shared/components/close-dialog/close-dialog-context.enum';

const AmslerTest = 'Amsler Test';

export const SetActiveEye = createAction(
  `[${AmslerTest}] SetActiveEye`,
  props<{
    eye: Eyes;
  }>()
);

export const SetRightEyeAmsler = createAction(
  `[${AmslerTest}] SetRightEyeAmsler`,
  props<{
    answer: boolean;
  }>()
);

export const SetLeftEyeAmsler = createAction(
  `[${AmslerTest}] SetLeftEyeAmsler`,
  props<{
    answer: boolean;
  }>()
);

export const ResetEyeAmsler = createAction(
  `[${AmslerTest}] ResetEyeAmsler`,
);

/**
 * This action sets the amsler answers with values
 * taken from the storage service
 */
export const SetAmslerAnswersFromDate = createAction(
  `[${AmslerTest}] SetAmslerAnswersFromDate`,
  props<{
    leftEyeAnswer: boolean[];
    rightEyeAnswer: boolean[];
  }>()
);

export const OpenAmslerDetailsPageAction = createAction(`[${AmslerTest}] Open amsler details page`);

export const ShowCloseWarningAction = createAction(
  `[${AmslerTest}] ShowCloseWarning`,
  props<{
    context: CloseDialogContext;
    previousPath?: string;
  }>()
);

export const StartAmslerSingleCheckAction = createAction(
  `[${AmslerTest}] Start amsler single check`
);

export const StartAmslerSingleCheckSuccededAction = createAction(
  `[${AmslerTest}] Start amsler single check succeded`,
  props<{
    instructions: Array<Instruction>;
  }>()
);

export const StartAmslerMegaTestAction = createAction(
  `[${AmslerTest}] Start amsler mega test`
);

export const StartAmslerMegaTestSuccedAction = createAction(
  `[${AmslerTest}] Start amsler mega test succed`,
  props<{
    instructions: Array<Instruction>;
  }>()
);
