import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Special for components & co
import { ColorTestPageComponent } from './color-test-page/color-test-page.component';
import { ColorResultPageComponent } from './color-result-page/color-result-page.component';
import { ColorDetailsPageComponent } from './color-details-page/color-details-page.component';

// From shared folder
import { InstructionsPageComponent } from '../shared/pages/instructions-page/instructions-page.component';

const routes: Routes = [
  {
    path: '',
    component: ColorDetailsPageComponent,
    data: {
      animation: 'ColorTestStartPage'
    }
  },
  {
    path: 'check/instructions',
    component: InstructionsPageComponent,
    data: {
      animation: 'ColorTestInstructions'
    }
  },
  {
    path: 'check/start',
    component: ColorTestPageComponent,
    data: {
      animation: 'ColorTestTestPage'
    }
  },
  {
    path: 'check/result',
    component: ColorResultPageComponent,
    data: {
      animation: 'ColorTestResult'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ColorTestRoutingModule {}
