<div class="general-instructions" *transloco="let t" #generalInstructions>
  <ng-container [ngSwitch]="instruction" *ngLet="(isDesktop$ | async) as isDesktop">
    <!-- Eyes -->
    <!-- Cover your left eye -->
    <ng-container *ngSwitchCase="instructions.COVER_LEFT_EYE">
      <div class="instruction-container">
        <div class="info-box-picture">
          <zat-animate-cover-eye [coverEye]="Eyes.LEFT" alt="">
          </zat-animate-cover-eye>
        </div>

        <div class="info-box-text">
          <span class="info-title text-headline" [innerHTML]="t('instructions.eyesReady.title')"></span>
          <span class="info-description text-body" [innerHTML]="t('instructions.coverLeftEye')"></span>
        </div>
      </div>
    </ng-container>

    <!-- Cover your right eye -->
    <ng-container *ngSwitchCase="instructions.COVER_RIGHT_EYE" alt="">
      <div class="info-box-picture">
        <zat-animate-cover-eye [coverEye]="Eyes.RIGHT"></zat-animate-cover-eye>
      </div>

      <div class="info-box-text">
        <span class="info-title" [innerHTML]="t('instructions.eyesReady.title')"></span>
        <span class="info-description text-body" [innerHTML]="t('instructions.coverRightEye')">
        </span>
      </div>
    </ng-container>

    <!-- Both eyes open -->
    <ng-container *ngSwitchCase="instructions.KEEP_BOTH_EYES">
      <div class="instruction-container">
        <div class="info-box-picture">
          <zat-animate-cover-eye alt=""></zat-animate-cover-eye>
        </div>

        <div class="info-box-text">
          <span class="info-title text-headline" [innerHTML]="t('instructions.eyesReady.title')"></span>
          <span class="info-description text-body" [innerHTML]="t('instructions.keepBothEyesOpen')">
          </span>
        </div>
      </div>
    </ng-container>

    <!-- Distance -->
    <!-- Keep Distance (arm) -->
    <ng-container *ngSwitchCase="instructions.KEEP_AN_ARM">
      <div class="instruction-container">
        <div class="info-box-picture">
          <app-icon id="keepDistanceDesktop" name="keep_distance_desktop" alt=""
            [width]="264" [height]="172" *ngIf="isDesktop; else keepDistanceMobile">
          </app-icon>
          <ng-template #keepDistanceMobile>
            <app-icon id="keepDistanceMobile" name="keep_distance_mobile" alt=""
              [width]="264" [height]="172">
            </app-icon>
          </ng-template>
        </div>

        <div class="info-box-text">
          <span class="info-title text-headline" [innerHTML]="t('instructions.keepAway.title')"></span>
          <span class="info-description text-body" [innerHTML]="t('instructions.keepArmsLength')">
          </span>
        </div>
      </div>

    </ng-container>

    <!-- Keep Distance (30cm) -->
    <ng-container *ngSwitchCase="instructions.KEEP_30_CM">
      <div class="instruction-container">
        <div class="info-box-picture">
          <app-icon id="keepDistanceDesktop" name="keep_distance_desktop" alt=""
            [width]="264" [height]="172" *ngIf="isDesktop; else keepDistanceMobile">
          </app-icon>
          <ng-template #keepDistanceMobile>
            <app-icon id="keepDistanceMobile" name="keep_distance_mobile" alt=""
              [width]="264" [height]="172">
            </app-icon>
          </ng-template>
        </div>

        <div class="info-box-text">
          <span class="info-title text-headline" [innerHTML]="t('instructions.keep30cmDistance.title')"></span>
          <span class="info-description text-body" [innerHTML]="t('instructions.keep30cmDistance')">
          </span>
        </div>
      </div>
    </ng-container>

    <!-- Checks -->
    <!-- Acuity-check -->
    <ng-container *ngSwitchCase="instructions.ACUITY_RING">
      <div class="instruction-container">
        <div class="info-box-picture animation">
          <div id="tutorial_animation_acuity" class="animation-wrapper">
            <ng-lottie [options]="optionsAcuity" class="animation" alt=""></ng-lottie>
          </div>
        </div>

        <div class="info-box-text">
          <span class="info-title text-headline" [innerHTML]="t('instructions.markTheSpot.title')"></span>
          <span class="info-description text-body" [innerHTML]="t('instructions.markTheSpot.text')">
          </span>
        </div>
      </div>
    </ng-container>

    <!-- Contrast-check -->
    <ng-container *ngSwitchCase="instructions.CONTRAST_RING">
      <div class="instruction-container">
        <div class="info-box-picture animation">
          <div id="tutorial_animation_contrast" class="animation-wrapper">
            <ng-lottie [options]="optionsContrast" class="animation" alt=""></ng-lottie>
          </div>
        </div>

        <div class="info-box-text">
          <span class="info-title text-headline" [innerHTML]="t('instructions.markTheSpot.title')"></span>
          <span class="info-description text-body" [innerHTML]="t('instructions.markTheSpot.text')">
          </span>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
