import { ContrastMapItem } from './interfaces';

// note: values in logCS are inverted with opacity value
// reference: 210128_Leu_Bre_CS_norms.xlsx document
export const contrastMap: ContrastMapItem[] = [
  {
    logCs: 0,
    opacity: 1
  },
  {
    logCs: 0.023,
    opacity: 0.949
  },
  {
    logCs: 0.047,
    opacity: 0.898
  },
  {
    logCs: 0.07,
    opacity: 0.851
  },
  {
    logCs: 0.097,
    opacity: 0.8
  },
  {
    logCs: 0.126,
    opacity: 0.749
  },
  {
    logCs: 0.156,
    opacity: 0.698
  },
  {
    logCs: 0.186,
    opacity: 0.651
  },
  {
    logCs: 0.222,
    opacity: 0.6
  },
  {
    logCs: 0.26,
    opacity: 0.549
  },
  {
    logCs: 0.303,
    opacity: 0.498
  },
  {
    logCs: 0.346,
    opacity: 0.451
  },
  {
    logCs: 0.398,
    opacity: 0.4
  },
  {
    logCs: 0.457,
    opacity: 0.349
  },
  {
    logCs: 0.526,
    opacity: 0.298
  },
  {
    logCs: 0.6,
    opacity: 0.251
  },
  {
    logCs: 0.699,
    opacity: 0.2
  },
  {
    logCs: 0.827,
    opacity: 0.149
  },
  {
    logCs: 1.009,
    opacity: 0.098
  },
  {
    logCs: 1.293,
    opacity: 0.051
  },
  {
    logCs: 1.407,
    opacity: 0.039
  },
  {
    logCs: 1.503,
    opacity: 0.031
  },
  {
    logCs: 1.708,
    opacity: 0.02
  },
  {
    logCs: 1.929,
    opacity: 0.012
  }
];

// the landolt-c in the test has a fixed size of 1.3logMAR
export const LANDOLT_C_SIZE_IN_LOGMAR = 1.3;

export const MAX_CONTRAST_TEST_REPEATS = 3;
export const MAX_CONTRAST_TEST_DIRECTION_CHANGES = 3;

export const MAX_CS = contrastMap[0].logCs;
export const MIN_CS = contrastMap[contrastMap.length - 1].logCs;

export const INITIAL_CONTRAST_IN_LOGCS = contrastMap[10].logCs;

export const EXCELLENT_ZONE = 1.407;
export const FINE_ZONE = 1.009;
