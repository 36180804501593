import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';

// Stuff for general
import { environment } from '../../../../environments/environment';
import { CloseDialogContext } from './close-dialog-context.enum';

@Component({
  selector: 'zat-close-dialog',
  templateUrl: './close-dialog.component.html',
  styleUrls: ['./close-dialog.component.scss']
})
export class CloseDialogComponent {
  context!: CloseDialogContext;
  previousPath: string;

  CloseDialogContext = CloseDialogContext;

  constructor(
    private location: Location,
    private router: Router,
    private transloco: TranslocoService
  ) {
    // check for navigation state to retrieve close warning context
    const state = this.router.getCurrentNavigation()?.extras?.state;
    if (state?.context) {
      this.context = state.context;
      if (
        this.context == CloseDialogContext.ResultNotECP &&
        !environment.webcomponent
      ) {
        // if we are in web we want to show the same message as in web-component ECP case
        // so only for this component we pretend ECP true
        this.context = CloseDialogContext.ResultECP;
      }

      this.previousPath = state.previousPath;
    } else {
      console.warn(
        'Cannot retrieve close dialog context. Redirecting to home page by default.'
      );
      this.router.navigate([this.transloco.getActiveLang()]);
    }
  }

  goToHomePage(): void {
    this.router.navigate([this.transloco.getActiveLang()]);
  }

  returnToPreviousPage(): void {
    if (this.previousPath && environment.webcomponent) {
      this.router.navigate([this.previousPath]);
    } else {
      this.location.back();
    }
  }
}
