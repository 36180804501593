import { FINE_ZONE, REDUCE_ZONE } from '../config';
import { ColorResultLevels } from '../enums';

export const getResultLevel = (percent: number) => {
  if (percent <= REDUCE_ZONE) {
    return ColorResultLevels.REDUCED;
  }

  if (percent <= FINE_ZONE) {
    return ColorResultLevels.FINE;
  }

  return ColorResultLevels.EXCELLENT;
};
