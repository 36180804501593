import { Injectable } from '@angular/core';
import { of, Observable, take, tap, switchMap } from 'rxjs';
import { LoggingServiceConfiguration } from 'ionic-logging-service';

// Stuff for general
import { environment } from '../../environments/environment';
import { AppInsightsService } from './app-insights.service';
import { ContentDownloadService } from './content-download.service';
import { CountrySettings } from '../shared/interfaces/my-zeiss-country-setting.interface';
import { Store } from '@ngrx/store';
import { SetMyZeissCountrySettingsAction } from '../store/actions/general.actions';

@Injectable({
  providedIn: 'root'
})
export class AppInitializerService {
  constructor(
    private appInsightsService: AppInsightsService,
    private contentDownloadService: ContentDownloadService,
    private store: Store
  )
  {}

  private isInitalized:boolean = false;

  public isAppInitalized():boolean {
    return this.isInitalized;
  }

  public initApp$(): Observable<any> {
    return of(null).pipe(
      tap(() => console.log('>>> observable: initApp...')),
      switchMap(() => {
        return this.initializeLoggingServices$(
          environment.logging,
          environment.appInsights.instrumentationKey
        );
      }),
      switchMap(()=>{
        return this.contentDownloadService.getMyZeissCountrySetting$();
      }),
      tap((countrySettings: CountrySettings)=>{
        return this.store.dispatch(SetMyZeissCountrySettingsAction({countrySettings}))
      }),
      tap(() => {
        this.isInitalized = true;
      }),
      // might be unnecessary with the new shared lib version, but keep it for now to be on the safe side
      take(1)
    );
  }

  private initializeLoggingServices$(configuration: LoggingServiceConfiguration, appInsightInstrumentationKey: string) {
    return of(null).pipe(
      switchMap(() => {
        return this.appInsightsService.init$(appInsightInstrumentationKey, true, configuration);
      }),
      take(1)
    );
  }
}
