// unique id for each test
// also used for routing
export enum TestTypesForImportantNotes {
  ACUITY = 'acuity-check',
  CONTRAST = 'contrast-check',
  COLOR = 'color-check',
  ASTIGMATISM = 'astigmatism-check',
  AMSLER = 'amsler-check',
  ALL = 'all-checks'
}
