import {
  animate,
  AnimationEvent,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';

// From shared folder
import {
  TIMING_FUNCTION_BACKWARD_DELAYED,
  TIMING_FUNCTION_FORWARD,
  TIMING_FUNCTION_SELECT
} from '../../../shared/config';
import { C_ANGLES } from '../../../shared/enums';
import {
  GA4_CheckAnswers,
  GA4_EventAction,
  GA4_EventName,
  GA4_EventType,
  GA4_EventValue,
  GA4_PizzaContollerAngles
} from '../../../shared/enums/ga4.enum';
import { AnalyticsService } from '../../../services/analytics.service';

@Component({
  selector: 'zat-pizza-control',
  templateUrl: './pizza-control.component.html',
  styleUrls: ['./pizza-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('sliceAnimation', [
      state('init', style({ opacity: 0 })),
      state('idle', style({ opacity: 1 })),
      state('selected', style({ opacity: 0 })),
      transition('idle => selected', animate(TIMING_FUNCTION_SELECT)),
      transition('* => idle', animate(TIMING_FUNCTION_SELECT))
    ]),
    trigger('correctIconAnimation', [
      state('*', style({ opacity: 0 })),
      state('init', style({ opacity: 0 })),
      state('correct', style({ opacity: 1 })),
      state('incorrect', style({ opacity: 0 })),
      transition('* => correct', animate(TIMING_FUNCTION_FORWARD)),
      transition('* => incorrect', animate(TIMING_FUNCTION_FORWARD)),
      transition('* => init', animate(TIMING_FUNCTION_BACKWARD_DELAYED))
    ]),
    trigger('incorrectIconAnimation', [
      state('*', style({ opacity: 0 })),
      state('init', style({ opacity: 0 })),
      state('correct', style({ opacity: 0 })),
      state('incorrect', style({ opacity: 1 })),
      transition('* => correct', animate(TIMING_FUNCTION_FORWARD)),
      transition('* => incorrect', animate(TIMING_FUNCTION_FORWARD)),
      transition('* => init', animate(TIMING_FUNCTION_BACKWARD_DELAYED))
    ]),
    trigger('controlAnimations', [
      state(
        'init',
        style({
          fill: 'rgb(0,0,0)',
          opacity: 1
        })
      ),
      state(
        'idle',
        style({
          fill: 'rgb(0,0,0)',
          opacity: 1
        })
      ),
      state(
        'correct',
        style({
          fill: 'rgb(0,0,0)',
          opacity: 1
        })
      ),
      state(
        'incorrect',
        style({
          fill: 'rgb(0,0,0)',
          opacity: 1
        })
      ),
      transition('init => idle', animate(TIMING_FUNCTION_FORWARD)),
      transition('idle => *', animate(TIMING_FUNCTION_FORWARD)),
      transition(
        'incorrect => init',
        animate(TIMING_FUNCTION_BACKWARD_DELAYED)
      ),
      transition('correct => init', animate(TIMING_FUNCTION_BACKWARD_DELAYED))
    ])
  ]
})
export class PizzaControlComponent {
  @Input() angle: C_ANGLES;
  @Input('checkType') checkType: GA4_EventType;

  @Output() done = new EventEmitter<boolean>();
  @Output() init = new EventEmitter<boolean>();
  @Output() idle = new EventEmitter<boolean>();

  @ViewChild('topSlice') topSlice: ElementRef;
  @ViewChild('topRightSlice') topRightSlice: ElementRef;
  @ViewChild('rightSlice') rightSlice: ElementRef;
  @ViewChild('bottomRightSlice') bottomRightSlice: ElementRef;
  @ViewChild('bottomSlice') bottomSlice: ElementRef;
  @ViewChild('bottomLeftSlice') bottomLeftSlice: ElementRef;
  @ViewChild('leftSlice') leftSlice: ElementRef;
  @ViewChild('topLeftSlice') topLeftSlice: ElementRef;

  isCorrect: boolean;
  isAnimating = false;

  angles = C_ANGLES;
  selectedAngle: C_ANGLES;

  controlState = 'init';

  isKey: boolean = false;

  slices: {
    [angle: string]: {
      state: string;
    };
  } = {
    TOP: {
      state: 'idle'
    },
    TOPLEFT: {
      state: 'idle'
    },
    TOPRIGHT: {
      state: 'idle'
    },
    BOTTOM: {
      state: 'idle'
    },
    BOTTOMLEFT: {
      state: 'idle'
    },
    BOTTOMRIGHT: {
      state: 'idle'
    },
    LEFT: {
      state: 'idle'
    },
    RIGHT: {
      state: 'idle'
    }
  };

  // a boolean flag to workaround anition.done event
  // emits twice for 1 event on Safari/Mobile Safari/Edge
  // https://github.com/angular/angular/issues/24084#issuecomment-425578965
  unfinishedAnimation = false;

  constructor(private analytics: AnalyticsService) {}

  select(selectedAngle: C_ANGLES) {
    // prevent double-tapping when animation is not done
    if (this.isAnimating) {
      return;
    }

    // Unfocus if click
    if (!this.isKey) {
      let pathElement = this.mapToElement(selectedAngle);
      // NOTE: getElementById() does not work in shadowDom, thus changed this to ViewChild
      // keep this comment and old code for now for reference (noted 10.08.2023)
      //document.getElementById(this.mapToId(selectedAngle));
      pathElement.blur();
    }

    // Fire the ga4-event
    let stateString: GA4_CheckAnswers =
      selectedAngle === this.angle
        ? GA4_CheckAnswers.True
        : GA4_CheckAnswers.False;
    this.analytics.createCustomEvent({
      event: 'event',
      eventName: `${GA4_EventName.CTA}`,
      eventAction: `${GA4_EventAction.Click}`,
      eventType: `${this.checkType}`,
      eventValue: `${GA4_EventValue.VaContrastQ} - ${this.mapAngleToString(
        this.angle
      )}`,
      eventDetail: `${this.mapAngleToString(selectedAngle)} - ${stateString}`
    });

    this.isAnimating = true;
    this.selectedAngle = selectedAngle;
    const angleString = C_ANGLES[selectedAngle];
    Object.keys(this.slices).forEach((angle: string) => {
      if (angle === angleString) {
        this.slices[angle].state = 'selected';
      } else {
        this.slices[angle].state = 'idle';
      }
    });
  }

  mapAngleToString(angle: C_ANGLES): GA4_PizzaContollerAngles {
    switch (angle) {
      case 0:
        return GA4_PizzaContollerAngles.A_000;
      case 45:
        return GA4_PizzaContollerAngles.A_045;
      case 90:
        return GA4_PizzaContollerAngles.A_090;
      case 135:
        return GA4_PizzaContollerAngles.A_135;
      case 180:
        return GA4_PizzaContollerAngles.A_180;
      case 225:
        return GA4_PizzaContollerAngles.A_225;
      case 270:
        return GA4_PizzaContollerAngles.A_270;
      case 315:
        return GA4_PizzaContollerAngles.A_315;
    }
  }

  onSliceAnimationDone(event: AnimationEvent) {
    if (event.phaseName === 'start') {
      this.unfinishedAnimation = true;
    }

    if (this.unfinishedAnimation && event.phaseName === 'done') {
      this.unfinishedAnimation = false;
      this.setControlAnimationStates(event);
    }
  }

  private setControlAnimationStates(event: AnimationEvent) {
    // the callback is called after it displays the results,
    // we disable double-animations here
    if (event.fromState === 'selected') {
      this.emitCompleteEvent();
      return;
    }

    // initial state events - we set the control state to idle.
    // we would need to allow C_ANGLES.TOP which has a numeric value of `0` too
    if (!this.selectedAngle && this.selectedAngle !== C_ANGLES.TOP) {
      this.controlState = 'idle';
      return;
    }

    if (this.selectedAngle === this.angle) {
      this.controlState = 'correct';
      this.isCorrect = true;
    } else {
      this.controlState = 'incorrect';
      this.isCorrect = false;
    }
  }

  onRoundAnimationDone(event: AnimationEvent) {
    // transitioning back to idle after result is checked
    if (event.toState === 'correct' || event.toState === 'incorrect') {
      this.controlState = 'init';
      return;
    }

    if (event.toState === 'init') {
      this.controlState = 'idle';
      this.idle.emit();
      return;
    }

    // no selected angle (initial state)
    // we would need to allow C_ANGLES.TOP which has a numeric value of `0` too
    if (!this.selectedAngle && this.selectedAngle !== C_ANGLES.TOP) {
      return;
    }
  }

  // restore the pizza the full circle
  // at the same time when control goes back to the idle state
  restorePizza(event: AnimationEvent) {
    // skip initial load
    if (!this.selectedAngle && this.selectedAngle !== C_ANGLES.TOP) {
      return;
    }

    if (event.toState === 'init') {
      this.init.emit();
    }

    if (event.toState === 'idle') {
      const angleString = C_ANGLES[this.selectedAngle];
      this.slices[angleString].state = 'idle';
    }
  }

  private emitCompleteEvent() {
    this.isAnimating = false;
    this.done.emit(this.isCorrect);
  }

  clickOnSegment(selectedAngle: C_ANGLES): void {
    this.isKey = false;
    this.select(selectedAngle);
  }

  checkTheKey(event: any, selectedAngle: C_ANGLES): void {
    if (event.code === 'Space' || event.code === 'Enter') {
      this.isKey = true;
      this.select(selectedAngle);
    }
  }

  mapToElement(angle: C_ANGLES): HTMLOrSVGElement {
    switch (angle) {
      case C_ANGLES.TOP:
        return this.topSlice.nativeElement;
      case C_ANGLES.TOPRIGHT:
        return this.topRightSlice.nativeElement;
      case C_ANGLES.RIGHT:
        return this.rightSlice.nativeElement;
      case C_ANGLES.BOTTOMRIGHT:
        return this.bottomRightSlice.nativeElement;
      case C_ANGLES.BOTTOM:
        return this.bottomSlice.nativeElement;
      case C_ANGLES.BOTTOMLEFT:
        return this.bottomLeftSlice.nativeElement;
      case C_ANGLES.LEFT:
        return this.leftSlice.nativeElement;
      case C_ANGLES.TOPLEFT:
        return this.topLeftSlice.nativeElement;
    }
  }

  mapToId(angle: C_ANGLES): string {
    switch (angle) {
      case C_ANGLES.TOP:
        return 'TOP';
      case C_ANGLES.TOPRIGHT:
        return 'TOPRIGHT';
      case C_ANGLES.RIGHT:
        return 'RIGHT';
      case C_ANGLES.BOTTOMRIGHT:
        return 'BOTTOMRIGHT';
      case C_ANGLES.BOTTOM:
        return 'BOTTOM';
      case C_ANGLES.BOTTOMLEFT:
        return 'BOTTOMLEFT';
      case C_ANGLES.LEFT:
        return 'LEFT';
      case C_ANGLES.TOPLEFT:
        return 'TOPLEFT';
    }
  }
}
