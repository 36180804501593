import { random, range } from 'lodash-es';
import { ColorTestItem } from '../interfaces';

export const randomNumberChoice = (
  test: ColorTestItem,
  exceptChoices: (number | string)[],
): number | string => {
  let randomRange = range(0, 99);
  const correctAnswerIsNumber = typeof test.correctAnswer === 'number';
  const confusionNumberIsNumber = typeof test.confusionNumber === 'number';

  if (correctAnswerIsNumber || confusionNumberIsNumber) {
    const refChoice = correctAnswerIsNumber ? test.correctAnswer : test.confusionNumber;
    const refChoiceAsString = refChoice + '';
    const refChoiceIsOnes = refChoiceAsString.length === 1;

    if (refChoiceIsOnes) {
      randomRange = range(10, 99);
    } else {
      const tensUnit = refChoiceAsString[0];
      randomRange = range(0, 99).filter(no => {
        return tensUnit !== no.toString()[0];
      });
    }
  }

  const exceptNumberChoices = exceptChoices.filter(choice => typeof choice === 'number') as number[];
  const randomChoices = randomRange.filter((choice: number) => !exceptNumberChoices.includes(choice));
  const maxIndex = randomChoices.length - 1;
  const randomIndex = random(0, maxIndex);
  const randomChoice = randomChoices[randomIndex];

  return randomChoice;
};
