<ng-container>
  <div class="cover-next-eye" *transloco="let t" @fadeIn>
    <div class="cover-next-eye-content">
      <zat-ovs-header-instructions [labelString]="label" [modeSetup]="false" (clickCross)="handleCancelEmit()"></zat-ovs-header-instructions>

      <div class="info-box">
        <div class="info-container">
          <div class="info-box-picture">
            <zat-animate-cover-eye [coverEye]="Eyes.RIGHT" alt="">
            </zat-animate-cover-eye>
          </div>

          <div class="info-box-text">
            <span class="info-title" [innerHTML]="t('instructions.eyesReady.title')"></span>
            <span [innerHTML]="t('instructions.coverRightEye')" class="info-description"></span>
          </div>
        </div>
      </div>

      <div class="actions">
        <zat-action-button type="solid" (click)="handleNext()" data-testid="button-instructions-next">
          {{ t('button.ready') }}
        </zat-action-button>
      </div>
    </div>
  </div>
</ng-container>
