import { MAX_VA } from '../config';

interface LogMARScale {
  [key: string]: number;
}

export function logmar2decimal(logmar: number = MAX_VA, locale?: string): string {
  const key = `${logmar}`;
  // https://en.wikipedia.org/wiki/LogMAR_chart
  const map: LogMARScale = {
    '-0.3': 2,
    '-0.2': 1.6,
    '-0.1': 1.26,
    0: 1,
    0.1: 0.8,
    0.2: 0.63,
    0.3: 0.5,
    0.4: 0.4,
    0.5: 0.32,
    0.6: 0.25,
    0.7: 0.2,
    0.8: 0.16,
    0.9: 0.13,
    1: 0.1,
    1.1: 0.08,
    1.2: 0.06,
    1.3: 0.05,
    1.4: 0.04,
    1.5: 0.032
  };

  const valueInPercentage = map[key] * 100;

  if (locale === 'tr-TR') {
    return `%${valueInPercentage}`;
  }

  return `${valueInPercentage}%`;
}
