import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { translocoConfig, TranslocoLoader, TranslocoModule, TRANSLOCO_CONFIG, TRANSLOCO_LOADER } from '@ngneat/transloco';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';

// Stuff for general
import { environment } from '../../environments/environment';

// Special for checks
import { enIntFallback } from './enInt-fallback';

// From shared folder
import { allLocales, DEFAULT_LOCALE } from '../shared/app-channels';
import { AppInsightsService } from '../services/app-insights.service';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  // DO NOT LEAVE THIS ON WHEN PUSHING PR!
  private useLocalI18n = false;

  constructor(
    private http: HttpClient,
    private appInsights: AppInsightsService
  ) {
    // ! reaktivate this, if content-service could not connect (ONLY FOR LOCALHOST)
    // this.useLocalI18n = true;
  }

  getTranslation(lang: string) {
    // TODO: consider cache busting form i18n cached files
    const url = `${environment.baseUrl}/content`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept-Language': lang,
        'EsbApi-Subscription-Key': environment.subscriptionKey
      })
    };

    if (this.useLocalI18n) {
      return of(enIntFallback);
    }

    return this.http.get<any>(url, httpOptions).pipe(
      map((response) => response.content),
      catchError((e) => this.handleError(lang, e))
    );
  }

  private handleError(lang: string, e: HttpErrorResponse) {
    this.appInsights.error(
      'Cannot get content from ContentService API.',
      e.error
    );

    console.warn('using INTERNAL i18n FALLBACK!!!');
    return of(enIntFallback);
  }
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: allLocales,
        defaultLang: DEFAULT_LOCALE,
        reRenderOnLangChange: true,
        prodMode: environment.production,
        fallbackLang: DEFAULT_LOCALE,
        failedRetries: 0,
        missingHandler: {
          logMissingKey: false,
          useFallbackTranslation: false,
          allowEmpty: true
        }
      })
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader }
  ]
})
export class TranslocoRootModule { }
