<div class="test-group" *transloco="let t;">
  <div class="test-group-content">
    <div class="test-group-title">
      {{ t('home.selectCheck.title') }}
    </div>
    <div class="test-group-checks">
      <zat-test-card *ngFor="let test of (availableTests$|async)" [test]="test"></zat-test-card>
    </div>
  </div>
</div>
