import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Special for checks
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { AcuityDetailsPageComponent } from './acuity-details-page/acuity-details-page.component';

const routes: Routes = [
  {
    path: '',
    component: AcuityDetailsPageComponent,
    pathMatch: 'full',
    data: {
      animation: 'AcuityTestStartPage'
    }
  },
  {
    path: 'check',
    loadChildren: () =>
      import('./acuity-check/acuity-check.module').then(
        (m) => m.AcuityCheckModule
      )
  },
  {
    path: '**',
    component: NotFoundPageComponent,
    data: {
      animation: 'AcuityTestNotFoundPage'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AcuityTestRoutingModule {}
