import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgLetModule } from 'ng-let';

// Components / modules
import { AmslerDetailsPageComponent } from './amsler-details-page/amsler-details-page.component';
import { AmslerTestPageComponent } from './amsler-test-page/amsler-test-page.component';
import { PrepareAmslerTestComponent } from './prepare-asmler-test/prepare-asmler-test.component';
import { AmslerResultPageComponent } from './amsler-result-page/amsler-result-page.component';
import { AmslerTestComponent } from './amsler-test/amsler-test.component';
import { HowItWorksAmslerPageComponent } from './how-it-works-amsler-page/how-it-works-amsler-page.component';
import { AppIconsModule } from '../app-icons/app-icons.module';
import { AmslerTestRoutingModule } from './amsler-test-routing.module';

// Special for checks
import { fromAmsler } from './store';
import { AmslerEffects } from './store/effects/amsler.effect';

// From shared folder
import { AppMaterialModule } from '../shared/app-material/app-material.module';
import { AppSharedModule } from '../shared/app-shared.module';

import { OvsComparisonSliderComponent } from '../ovs-comparison-slider/ovs-comparison-slider.component';

@NgModule({
  declarations: [
    AmslerTestComponent,
    AmslerDetailsPageComponent,
    AmslerTestPageComponent,
    PrepareAmslerTestComponent,
    AmslerResultPageComponent,
    HowItWorksAmslerPageComponent
  ],
  imports: [
    CommonModule,
    AmslerTestRoutingModule,
    AppSharedModule,
    AppMaterialModule,
    TranslocoModule,
    StoreModule.forFeature('amsler', fromAmsler.reducer),
    EffectsModule.forFeature([ AmslerEffects ]),
    NgLetModule,
    AppIconsModule,
    OvsComparisonSliderComponent
  ]})
export class AmslerTestModule {}
