import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { NgLetModule } from 'ng-let';

// Special for components & co
import { AboutUsComponent } from './about-us.component';
import { LegalDocumentsComponent } from './legal-documents/legal-documents.component';
import { HelpPageComponent } from './help-page/help-page.component';
import { AppIconsModule } from '../../app-icons/app-icons.module';
import { AboutUsRoutingModule } from './about-us-routing.module';

// From shared folder
import { AppMaterialModule } from '../../shared/app-material/app-material.module';
import { AppSharedModule } from '../../shared/app-shared.module';

@NgModule({
  imports: [
    CommonModule,
    AboutUsRoutingModule,
    AppSharedModule,
    AppMaterialModule,
    TranslocoModule,
    NgLetModule,
    AppIconsModule
  ],
  declarations: [
    AboutUsComponent,
    LegalDocumentsComponent,
    HelpPageComponent
  ],
})
export class AboutUsModule { }
