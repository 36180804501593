import {
  Component,
  OnDestroy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

// Stuff for general
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'zat-ovs-header-instructions',
  templateUrl: './ovs-header-instructions.component.html',
  styleUrls: ['./ovs-header-instructions.component.scss']
})
export class OvsHeaderInstructionsComponent implements OnDestroy {
  // currently only used for empty string, but keep the component capability to change the header text
  // this is used in prepare-next-test.component (string '', but might change, we have an upcomming header story)
  @Input('labelString') labelString: string = '';

  @Output('clickCross') clickCross = new EventEmitter();

  environment = environment;

  ngOnDestroy(): void {}

  onClickCross(): void {
    this.clickCross.emit();
  }
}
