import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'zat-result-panel-title',
  templateUrl: './result-panel-title.component.html',
  styleUrls: ['./result-panel-title.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          transform: 'rotate(0deg)'
        })
      ),
      state(
        'closed',
        style({
          transform: 'rotate(180deg)'
        })
      ),
      transition('open => closed', [animate('.4s ease')]),
      transition('closed => open', [animate('.4s ease')])
    ])
  ]
})
export class ResultPanelTitleComponent {
  @Input() testName: string;
  @Input() image: string;
  @Input() expended: boolean;
  @Input() imageId: string;
  @Input() result: string;

  public resultIcon: string;
}
