export enum GA4_EventName {
  CTA = 'CTA',
  FUNCTIONAL = 'Functional',
}

export enum GA4_EventAction {
  Click = 'Click',
  View = 'View',
  Copy = 'Copy'
}

export enum GA4_EventType {
  Internal = 'Internal',
  Warning = 'Warning',
  Outbound = 'Outbound',
  ResultCode = 'OVS Result Code',
  // Stuff for checks
  StartCheck = 'Start check',
  Acuity = 'Visual acuity',
  Contrast = 'Contrast vision',
  Color = 'Colour vision',
  Astigmatism = 'Astigmatism',
  Amsler = 'Visual field',
}

export enum GA4_EventValue {
  // Home page (portal-page)
  SelectASpecificCheck = 'Select a specific test',
  CheckYourVision = 'Check your vision',
  InfoButton = 'Info Button',
  // Single stuff
  FAQ = 'FAQs',
  Warning = 'Warning',
  Details = 'Details',
  ShowOpticans = 'Show nearby opticians',
  // Disclamer (important-notes)
  AcceptDisclaimer = 'Accept Disclaimer',
  RejectDisclaimer = 'Reject Disclaimer',
  // Switch eye
  SwitchEye = 'Switch Eye',
  SwitchEyeNot = 'Switch eye not completed',
  // Stuff for checks
  StartCheck = 'Start check',
  Acuity = 'Visual acuity',
  Contrast = 'Contrast vision',
  Color = 'Colour vision',
  Astigmatism = 'Astigmatism',
  Amsler = 'Visual field',
  ExitCheck = 'Exit Test',
  VaContrastQ = 'Using the top ring as reference, mark the correlating gap on the lower ring',
  ColorQ = 'Select the number you see in the circle',
  AstigmatismQ = 'Do all the lines appear in the same shade of black',
  AmslerQ1 = 'Do all lines and squares appear equal and regular',
  AmslerQ2 = 'Are any parts of the grid missing, distorted or darker than the rest',
  // Stuff for Results
  Result = 'Result',
  ExitResultPage = 'Exit Result Page',
  SaveResults = 'Save results',
  SaveResultMyZeissVision = "Save Result in MyZEISS Vision",
  ChubCopyResultCode = "Copy Code",
  SignUpMyZeissVision = "Sign up for MyZEISS Vision / Sign In",
  ContinueTo = 'Continue to ',
  ShowResults = 'Summarize results',
  // Setup / instructions
  BrightnessSetupNotCompleted = 'Brightness setup not completed',
  BrightnessSetupCompleted = 'Brightness setup completed',
  ScreenSizeCalibrationNotSuccessful = 'Screen size calibration not successful',
  ScreenDimensionSetupNotCompleted = 'Screen Dimension setup not completed',
  ScreenDimensionSetupCompleted = 'Screen Dimension setup completed',
  WearGlassesOrContactsNotCompleted = 'Wear glasses or contacts not completed',
  WearGlassesOrContactsCompleted = 'Wear glasses or contacts completed',
  Instructions = 'Instructions',
  CancelInstructions = 'Cancel Instructions',
}

export enum GA4_EventDetail {
  Clear = '',
  Homepage = 'Homepage',
  AboutOVS = 'About OVS',
  Warning = 'Warning',
  OnlineVisionScreeningDisclaimer = 'Online Vision Screening Disclaimer',
  HowItWorks = 'How it works',
  Sliderposition = 'Sliderposition',
  // For the checks
  Acuity = 'Visual acuity',
  Contrast = 'Contrast vision',
  Color = 'Colour vision',
  Astigmatism = 'Astigmatism',
  Amsler = 'Visual field',
  // Result
  ResultPage = 'Result Page',
  Summary = 'Summary',
  CHubResultCodeModal = "OVS MyZEISS Vision Overlay"
}

export enum GA4_PizzaContollerAngles {
  A_000 = 'top',
  A_045 = 'top-right',
  A_090 = 'right',
  A_135 = 'bottom-right',
  A_180 = 'bottom',
  A_225 = 'bottom-left',
  A_270 = 'left',
  A_315 = 'top-left',
}

export enum GA4_CheckAnswers {
  True = 'correct',
  False = 'incorrect',
}
