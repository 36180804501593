<div class="root" *transloco="let t">
  <zat-ovs-header [label]="headerConfig.label" [left]="headerConfig.left" [right]="headerConfig.right"
    [theme]="headerConfig.theme" (clickLeft)="back()" (clickRight)="openInfo()"></zat-ovs-header>
  <div class="entry-content" *ngLet="(specialScreeningMode$ | async) as specialScreeningMode">
    <app-icon name="ovs_eye"  alt="" [width]="168" [height]="87">
    </app-icon>
    <div class="entry-title">
      <div [innerHTML]="specialScreeningMode !== SpecialScreeningMode.NL_CAMPAIGN ?
        t('common.appName') : t('common.appNameNLCampaign')">
      </div>
    </div>
    <div class="entry-actions">
      <zat-action-button class="ovs-home-spacing-down" data-testid="button-start-test" color="primary" type="solid"
        (click)="clickCheckYourVision()">
        {{ t('button.testYourVision') }}
      </zat-action-button>
      <zat-action-button *ngIf="specialScreeningMode !== SpecialScreeningMode.NL_CAMPAIGN"
        data-testid="button-select-check" color="primary" type="outline-white" (click)="clickSelectASpecificCheck()">
        {{ t('home.selectCheck.button') }}
      </zat-action-button>
    </div>
  </div>
</div>
