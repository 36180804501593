import { Injectable } from '@angular/core';
import {
  ApplicationInsights,
  IEventTelemetry,
  IMetricTelemetry,
  IPageViewTelemetry,
  ITraceTelemetry,
  SeverityLevel
} from '@microsoft/applicationinsights-web';
import { Guid } from 'guid-typescript';
import { Logger, LoggingService, LoggingServiceConfiguration } from 'ionic-logging-service';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

/**
 * This is the primary logging service
 * mostly used for this.logger.info('bla')
 * This will log to web console but also to native log
 */
@Injectable({
  providedIn: 'root'
})
export class AppInsightsService {
  appInsights: ApplicationInsights;
  uuid: string;
  logger: Logger;
  useConsole = false;
  methodDurations: { [key: string]: number } = {};

  constructor(private loggingService: LoggingService) {
    this.logger = this.loggingService.getLogger('AppInsightsService');
  }

  private userAgent = navigator.userAgent;

  // wrapper so we can change the implementation later
  getUserAgent(): string {
    return this.userAgent;
  }

  getDeviceUuid(): string {
    return this.uuid;
  }

  init$(
    appInsightsKey: string,
    appInsightsAutotrackPageVisit: boolean = true,
    configuration?: LoggingServiceConfiguration
  ): Observable<void> {
    const method = '[app-insights.service] init$()';
    return of(null).pipe(
      map(() => {
        this.logger.entry(method, 'Initialising AppInsightsService');
        return this.loggingService.configure(configuration);
      }),
      map(() => {
        // we know from service injection that we have an instance of the loggingService at this point
        // we don't know, if there is also an instance of deviceID available yet
        return this.getDeviceUuid();

        // to simulate not being able to get a uuid from loggingService
        // return this.uuid;
      }),
      map((uuid) => {
        // see if uuid is already loaded, if not -> see if there is one already defined in settings
        console.log('uuid: ', uuid);
        if (!uuid) {
          return this.loadUUID();
        } else {
          return uuid;
        }
      }),
      map((storageUUID) => {
        this.uuid = storageUUID;
        // Do we have a uuid from either Device or localStorage at this point?
        if (!this.uuid) {
          console.log(
            'still no uuid -> generate a new one and save to localStorage'
          );
          // no uuid saved, so generate new uuid and save it in localStorage
          this.uuid = Guid.create().toJSON().value;
          return this.saveUUID(this.uuid);
        }

        // But do we REALLY have a uuid?
        if (!this.uuid) {
          this.logger.error('Cannot load or generate uuid', method);
        }
      }),
      map(() => {
        if (appInsightsKey && appInsightsKey !== '') {
          this.appInsights = new ApplicationInsights({
            config: {
              instrumentationKey: appInsightsKey,
              autoTrackPageVisitTime: appInsightsAutotrackPageVisit,
              accountId: this.uuid
            }
          });
        }

        this.appInsights.loadAppInsights();

        window.onerror = (message: string, source, lineno, colno, error) => {
          this.error(message, source, lineno, colno, error);
        };

        this.info('AppInsightsService initialized!');
        this.info('User-Agent', this.getUserAgent());
      }),
      tap(() => this.logger.exit(method)),
      catchError((e) => {
        // NOTE: this should remain console.error rather then logger.error as
        // if something goes wrong with the init the later might not work
        console.error('error during app-insights.service initialization: ', e);
        return of(e);
      })
    );
  }


  public info(msg: string, ...params: any[]) {
    try {
      this.logger.info(msg + params);
    } catch (e) {
      console.log(e);
    }
    // fail gracefully if not initiialized - should not impeded app behaviour even if no-init
    if (this.appInsights) {
      this.appInsights.trackTrace({
        message: msg,
        properties: { params, deviceId: this.getDeviceUuid() },
        severityLevel: SeverityLevel.Information
      } as ITraceTelemetry);
    } else {
      // if undefined
      console.error(
        '[app-insights.service] not initialized. info(...) logs will only be logged locally.'
      );
    } // else if null -> do nothing
    if (this.useConsole) {
      console.log('[LOG] ' + msg, params);
    }
  }

  public warn(msg: string, ...params: any[]) {
    try {
      this.logger.warn(msg + params);
    } catch (e) {
      console.log(e);
    }
    if (this.appInsights) {
      this.appInsights.trackTrace({
        message: msg,
        properties: { params, deviceId: this.getDeviceUuid() },
        severityLevel: SeverityLevel.Warning
      } as ITraceTelemetry);
    } else {
      console.error(
        '[app-insights.service] not initialized. warn(...) logs will only be logged locally.'
      );
    }
    if (this.useConsole) {
      console.warn('[LOG] ' + msg, params);
    }
  }

  public error(msg: string, ...params: (string | number | Error)[]) {
    try {
      this.logger.error(msg + params);
    } catch (e) {
      console.log(e);
    }

    if (this.appInsights) {
      this.appInsights.trackTrace({
        message: msg,
        properties: { params, deviceId: this.getDeviceUuid() },
        severityLevel: SeverityLevel.Error
      } as ITraceTelemetry);
    } else {
      console.error(
        '[app-insights.service] not initialized. error(...) logs will only be logged locally.'
      );
    }
    if (this.useConsole) {
      console.error('[LOG] ' + msg, params);
    }
  }

  /**
   * dispatch custom appInsights event
   */
  public custom(eventName: string, customProperties: { [key: string]: any }) {
    if (this.appInsights) {
      // always add device id and userAgent for better filter options in appInsights
      customProperties['userAgent'] = this.getUserAgent();
      customProperties['deviceId'] = this.getDeviceUuid();
      this.appInsights.trackEvent({
        name: eventName,
        properties: customProperties
      } as IEventTelemetry);
    } else {
      this.error(
        '[app-insights.service] You need to initialize this service with init$() first, if oyu want to use appInsight functionality!'
      );
    }

    if (this.useConsole) {
      console.log(`[LOG] customEvent: ${eventName}`, customProperties);
    }
    try {
      this.logger.info(`[LOG] customEvent: ${eventName}`, customProperties);
    } catch (e) {
      console.log(e);
    }
  }

  public entry(methodName: string, ...params: any[]) {
    this.methodDurations[methodName] = performance.now();

    try {
      this.logger.entry(methodName, params);
    } catch (e) {
      console.log(e);
    }
    if (this.appInsights) {
      this.appInsights.trackTrace({
        message: `log.methodEntry: ${methodName}`,
        properties: { methodName, params, deviceId: this.getDeviceUuid() },
        severityLevel: SeverityLevel.Verbose
      } as ITraceTelemetry);
    } else {
      console.error(
        '[app-insights.service] not initialized. entry(...) logs will only be logged locally.'
      );
    }

    if (this.useConsole) {
      console.log(`[LOG] methodEntry: ${methodName}`, params);
    }
  }

  private getFormattedDuration(ms: number): string {
    return Math.floor(ms) + 'ms';
  }

  private getMethodDuration(methodName: string) {
    const durationMS = performance.now() - this.methodDurations[methodName];
    const durationStr = this.getFormattedDuration(durationMS);
    return { durationMS, durationStr };
  }

  public exit(methodName: string, ...params: any[]) {
    const duration = this.getMethodDuration(methodName);

    try {
      this.logger.exit(methodName, params);
    } catch (e) {
      console.log(e);
    }

    if (this.appInsights) {
      this.appInsights.trackTrace({
        message: `log.methodExit: ${methodName}`,
        properties: {
          methodName,
          params,
          deviceId: this.getDeviceUuid(),
          durationMS: duration.durationMS,
          durationStr: duration.durationStr
        },
        severityLevel: SeverityLevel.Verbose
      } as ITraceTelemetry);

      this.appInsights.trackMetric({
        name: methodName,
        average: duration.durationMS,
        sampleCount: 1
      } as IMetricTelemetry);
    } else {
      console.error(
        '[app-insights.service] not initialized. exit(...) logs will only be logged locally.'
      );
    }

    if (this.useConsole) {
      console.log(`[LOG] methodExit: ${methodName}`, params, duration);
    }
  }

  public trackPageView(params: IPageViewTelemetry) {
    try {
      this.logger.info(`PageView: ${params.name}`, params);
    } catch (e) {
      console.error(e);
    }
    this.appInsights.trackPageView(params);
    if (this.useConsole) {
      console.log(`[LOG] PageView:`, params);
    }
  }

  public trackEvent(params: IEventTelemetry) {
    try {
      this.logger.info(`Event: ${params.name}`, params);
    } catch (e) {
      console.error(e);
    }
    this.appInsights.trackEvent(params);
    if (this.useConsole) {
      console.log(`[LOG] Event:`, params);
    }
  }

  public trackMetric(params: IMetricTelemetry) {
    try {
      this.logger.info(`Metric: ${params.name}`, params);
    } catch (e) {
      console.error(e);
    }
    this.appInsights.trackMetric(params);
    if (this.useConsole) {
      console.log(`[LOG] Metric:`, params);
    }
  }

  /**
   * Manually trigger an immediate send of all telemetry still in the buffer.
   */
  public flush(async: boolean = true): void {
    this.appInsights.flush(async);
  }

  /**
   * Save uuid to localStorage to ensure a consistent string in the web version as well as
   * in the native version.
   * @param uuid
   */
  private saveUUID(uuid: string) {
    // TODO: maybe add catchError to make it more robust
    localStorage.setItem('deviceUUID', JSON.stringify(uuid));
  }

  /**
   * Load uuid from localStorage, if it has been saved before.
   * @returns
   */
  private loadUUID(): string {
    console.log('try to load uuid from localStorage');
    return localStorage.getItem('deviceUUID');
  }
}
