import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

// Stuff for general
import { environment } from '../../environments/environment';
import { SetLocaleActions } from '../store/actions';
import { isInStandaloneMode } from '../shared/utils';
import { AppCookiesService } from '../services/app-cookies.service';
import { GeneralService } from '../services/general.service';

@Injectable({
  providedIn: 'root'
})
export class MainVersionGuard  {
  constructor(
    private transloco: TranslocoService,
    private store: Store,
    private appCookiesService: AppCookiesService,
    private generalService: GeneralService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {

    let locale = route.paramMap.get('locale');

    // Only set active locale here, if we are not in a webcomponent
    if(!environment.webcomponent) {
      if (isInStandaloneMode()) {
        // If running as standalone PWA, use the language that was selected the last time.
        locale = this.generalService.checkInputLocale(this.appCookiesService.getLocale());
      } else {
        // Set the selected language in the cookies, so it can be determined what the latest selected language was, when running it as standalone PWA.
        this.appCookiesService.setLocale(locale);
      }

      this.transloco.setActiveLang(locale);
    }

    this.store.dispatch(SetLocaleActions.SetLocale({ locale }));

    return true;
  }
}
