import { Injectable } from '@angular/core';
import { TableTransactionResponse } from '@azure/data-tables';
import { differenceInMilliseconds } from 'date-fns';
import { from, iif, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

// Stuff for general
import { AppCookiesService } from './app-cookies.service';

// From shared folder
import { Eyes } from '../shared/enums/eyes.enum';
import { isOnline } from '../shared/utils';
import { AppInsightsService } from './app-insights.service';

export interface AnalysisData {
  domain: string,
  partitionKey: string,
  rowKey: string,
  screenMode: string,
  calibSliderValue: number,
  activeEye: Eyes,
  landoltSize?: number,
  contrastValue?: number,
  interactionTime: number,
  isCorrect: boolean,
}

//DEPRECATED: currently disabled; keep for now but ask Simon if we still need in half a year or so (28.11.23)
/**
 * This service is used to collect interaction data of the user during the
 * visual checks and to compile said data to upload it to the Azure Table storage.
 */
@Injectable({
  providedIn: 'root'
})
export class AzureTableClientService {
  //private tableClient: TableClient;
  private TABLE_NAME = `VisualCheckData`;

  // data management properties
  private interactionStartTime: Date;
  public mlData: AnalysisData[] = [];

  private partitionKey: string;
  private calibrationScreenMode: string;
  private calibrationSliderValue: number;

  private appInsightsCookie: string = 'V85';

  constructor(
    private logger: AppInsightsService,
    private appCookiesService: AppCookiesService
  ) {
    // disabled upload for now by commenting out tableClient stuff, keep for reference
    /*
    this.tableClient = new TableClient(
      `https://${environment.azureAccount}.table.core.windows.net${environment.azureSas}&ngsw-bypass=true`,
      this.TABLE_NAME
    );
    */
  }

  /**
   * Create the table, if it doesn't exist already and upload the gathered data as a table entity.
   */
  private uploadVisualCheckAnalysisData(checkData: AnalysisData[]): Promise<TableTransactionResponse | void> {
    // don't do anything
    return new Promise(()=>{return null});
    // disabled upload for now by commenting out tableClient stuff, keep for reference
    /*
    const transaction = new TableTransaction();

    checkData.forEach((data) => {
      transaction.createEntity(data);
    })

    return this.tableClient.submitTransaction(transaction.actions);
    */
  }

  /**
   * Resets the interaction start time.
   */
  public resetInteractionStartTime(): void {
    this.interactionStartTime = new Date();
  }

  public initAnalysisData(partitionKey: string) {
    this.clearVisualCheckData();
    this.partitionKey = partitionKey;
    this.calibrationScreenMode = this.appCookiesService.getScreenMode();
    this.calibrationSliderValue = this.appCookiesService.getPixelPitch();
  }

  /**
   * This function is for tracking the time interval between user interactions.
   */
  private getInteractionTime(): number {
    const now = new Date();

    const interactionTime: number = differenceInMilliseconds(now, this.interactionStartTime) / 1000;
    this.interactionStartTime = now;

    return interactionTime;
  }

  public updateAnalysisData(eye: Eyes, isCorrect: boolean, visualAcuity?:number, contrast?: number) {
    if (!this.uploadAllowed() || !this.partitionKey) {
      return;
    }

    let data: AnalysisData;
    const now = new Date();

    data = {
      domain: window.location.hostname,
      partitionKey: this.partitionKey,
      rowKey: `${now.getTime()}`,
      screenMode: this.calibrationScreenMode,
      calibSliderValue: this.calibrationSliderValue,
      activeEye: eye,
      landoltSize: visualAcuity,
      contrastValue: contrast,
      interactionTime: this.getInteractionTime(),
      isCorrect: isCorrect,
    };

    this.mlData.push(data);
    // disabled log so there isn't the false assumption data-upload were still active
    // this.logger.info('#### [DATA ANALYSIS SERVICE] mlData: ', this.mlData);
  }

  public clearVisualCheckData() {
    this.mlData = [];
  }

  public storeAnalysisData$(): Observable<void> {
    if (!this.uploadAllowed()) {
      return of(null);
    }

    return of(null).pipe(
      switchMap(() =>
        iif(() => this.partitionKey !== null,
          from(this.uploadVisualCheckAnalysisData(this.mlData)),
          of(null)
        )
      ),
      map((response) => {
        this.clearVisualCheckData();
        return response;
      }),
      catchError((error) => {
        this.logger.error(error);
        return of(error);
      })
    );
  }

  private uploadAllowed(): boolean {
    if (!isOnline()) {
      return false;
    }

    const activeGroups = (window as any).OnetrustActiveGroups;

    if (activeGroups) {
      this.logger.info('#### Active Groups: ', activeGroups);
      return activeGroups.includes(this.appInsightsCookie);
    } else {
      // keep for upload testing in DEV/TEST
      // return environment.debugMode;
      return false;
    }
  }
}
