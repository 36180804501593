import { Instruction } from './../../../shared/enums/instructions.enum';
import { createAction, props } from '@ngrx/store';

// From shared folder
import { C_ANGLES, Eyes, ScaleDirections } from '../../../shared/enums';
import { CloseDialogContext } from '../../../shared/components/close-dialog/close-dialog-context.enum';

const AcuityTest = 'Acuity Test';
const EyeAcuity = 'EyeAcuity';
const LandoltC = 'LandoltC';
const Questionnaire = 'Questionnaire';
const VisualAcuity = 'Visual Acuity';

export const CheckAnswer = createAction(`[${AcuityTest}] Check Answer`);

export const ConfirmAnswer = createAction(`[${AcuityTest}] Confirm `);

export const IncreaseStepChangeCount = createAction(
  `[${EyeAcuity}] IncreaseStepChangeCount`,
  props<{
    eye: Eyes;
  }>()
);

export const DecreaseStepChangeCount = createAction(
  `[${EyeAcuity}] DecreaseStepChangeCount`,
  props<{
    eye: Eyes;
  }>()
);

export const IncreaseHighestStepCounter = createAction(
  `[${EyeAcuity}] IncreaseHighestStepCounter`,
  props<{
    eye: Eyes;
  }>()
);

export const IncreaseSmallestStepCounter = createAction(
  `[${EyeAcuity}] IncreaseSmallestStepCounter`,
  props<{
    eye: Eyes;
  }>()
);

export const ResetSmallestStepCounter = createAction(
  `[${EyeAcuity}] ResetSmallestStepCounter`,
  props<{
    eye: Eyes;
  }>()
);

export const IncreaseCount = createAction(`[${AcuityTest}] Increase count`);

export const RandomizeAngle = createAction(`[${LandoltC}] RandomizeAngle`);

export const ResetsEyeAcuity = createAction(
  `[${AcuityTest}] Reset Eyes Acuity`
);

export const ResetTests = createAction(`[${AcuityTest}] Reset Tests`);

export const SetActiveEye = createAction(
  `[${AcuityTest}] Set active eye`,
  props<{
    eye: Eyes;
  }>()
);

export const SetAngle = createAction(
  `[${AcuityTest}] Set wheel angle`,
  props<{
    angle: C_ANGLES;
  }>()
);

export const SetIsAngleSelected = createAction(
  `[${AcuityTest}] Set isAngleSelected status`,
  props<{
    status: boolean;
  }>()
);

export const Increase = createAction(`[${VisualAcuity}] Increase`);

export const Decrease = createAction(`[${VisualAcuity}] Decrease`);

export const StoreValue = createAction(
  `[${VisualAcuity}] StoreValue`,
  props<{
    eye: Eyes;
    value: number;
  }>()
);

export const SetScaleDirection = createAction(
  `[${VisualAcuity}] SetScaleDirection`,
  props<{
    direction: ScaleDirections;
  }>()
);

export const ShowCloseWarningAction = createAction(
  `[${VisualAcuity}] ShowCloseWarning`,
  props<{
    context: CloseDialogContext;
    previousPath?: string;
  }>()
);

export const StartAcuitySingleCheckAction = createAction(
  `[${AcuityTest}] Start acuity single check`
);

export const StartAcuitySingleCheckSuccededAction = createAction(
  `[${AcuityTest}] Start acuity single check succeded`,
  props<{
    instructions: Array<Instruction>;
  }>()
);

export const StartAcuityMegaTestAction = createAction(
  `[${AcuityTest}] Start acuity mega test check`
);

export const StartAcuityMegaTestSuccededAction = createAction(
  `[${AcuityTest}] Start acuity mega test succeded`,
  props<{
    instructions: Array<Instruction>;
  }>()
);

export const OpenAcuityDetailsPageAction = createAction(
  `[${AcuityTest}] Open acuity details page`
);
