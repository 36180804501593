import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

// Stuff for general
import { environment } from '../../../environments/environment';
import { AppLocalizeRouterService } from '../../services/app-localize-router.service';
import { AppCookiesService } from '../../services/app-cookies.service';
import { GeneralService } from '../../services/general.service';
import { OpenCheckSelectionPageAction } from '../../store/actions/general.actions';

// From shared folder
import { HeaderEnum, HeaderColorEnum, HeaderLabelEnum } from '../../shared/enums/header.enum';
import { fadeInAnimation } from '../../shared/animations';
import { StartPageClass } from '../../shared/classes/start-page.class';
import { HeaderConfig } from '../../shared/interfaces/header-config.interface';
import { TestTypesForImportantNotes } from '../../shared/enums/test-types-important-notes.enum';
import { ShowImportantNotesAction } from '../../store/actions/check-calibration.actions';

@Component({
  selector: 'zat-color-details-page',
  templateUrl: './color-details-page.component.html',
  styleUrls: ['./color-details-page.component.scss'],
  animations: [fadeInAnimation]
})
export class ColorDetailsPageComponent extends StartPageClass implements OnInit, OnDestroy {
  public environment = environment;

  srcImageNameOne:string = "img_color_01@2x.jpg"
  srcImageNameTwo:string = "img_color_02@2x.jpg"

  headerConfig: HeaderConfig = {
    label: HeaderLabelEnum.ColorDetails,
    left: HeaderEnum.Back,
    right: HeaderEnum.Empty,
    theme: HeaderColorEnum.LightMode
  } as HeaderConfig;

  constructor(
    protected store: Store,
    protected router: Router,
    protected localize: AppLocalizeRouterService,
    protected appCookiesService: AppCookiesService,
    protected generalService: GeneralService,
  ) {
    super(store, router, localize, appCookiesService);
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void { }

  start(): void {
    this.store.dispatch(ShowImportantNotesAction({ testType: TestTypesForImportantNotes.COLOR }));
  }

  removeLastLetter(title: string): string {
    return this.generalService.removeLastLetter(title);
  }

  back(): void {
    this.store.dispatch(OpenCheckSelectionPageAction());
  }
}
