import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { delay, map, take, tap } from 'rxjs/operators';
import { Location } from '@angular/common';

// Stuff for general
import { environment } from '../../../../environments/environment';
import { ShowMegaTestResultsAction } from '../../../store/actions/general.actions';
import { SendResultsAction } from '../../../store/actions/test-type.actions';
import { AppSelectors } from '../../../store/selectors';
import {
  getSpecialScreeningMode,
  getMegaTestActive
} from '../../../store/selectors/app.selectors';

// Special for checks
import { AcuityActions, AcuitySelectors } from '../../store';
import { StartContrastMegaTestAction } from '../../../contrast-test/store/actions/contrast.actions';

// From shared folder
import { AcuityValues } from '../../shared/interfaces';
import { CloseDialogContext } from '../../../shared/components/close-dialog/close-dialog-context.enum';
import { AppChannel, TEST_RESULT } from '../../../shared/enums';
import { HeaderConfig } from '../../../shared/interfaces/header-config.interface';
import {
  HeaderColorEnum,
  HeaderEnum,
  HeaderLabelEnum
} from '../../../shared/enums/header.enum';
import { fadeInAnimation } from '../../../shared/animations';
import {
  GA4_EventDetail,
  GA4_EventAction,
  GA4_EventName,
  GA4_EventType,
  GA4_EventValue
} from '../../../shared/enums/ga4.enum';
import { SpecialScreeningMode } from '../../../shared/enums/special-screening-mode.enum';
import { TranslocoService } from '@ngneat/transloco';
import { AnalyticsService } from '../../../services/analytics.service';
import { GeneralService } from '@app-services/general.service';
import { AppInsightsService } from '../../../services/app-insights.service';

@Component({
  selector: 'zat-acuity-result-page',
  templateUrl: './acuity-result-page.component.html',
  styleUrls: ['./acuity-result-page.component.scss'],
  animations: [fadeInAnimation]
})
export class AcuityResultPageComponent implements OnInit, OnDestroy {
  @ViewChild('content') content: ElementRef<HTMLDivElement>;

  specialScreeningMode$: Observable<SpecialScreeningMode> = this.store.select(
    getSpecialScreeningMode
  );
  SpecialScreeningMode = SpecialScreeningMode;

  vaResult$: Observable<any>;
  vaValues$: Observable<AcuityValues>;
  resultString: string = null;
  image$: Observable<any> = this.store
    .select(AcuitySelectors.getResultImage)
    .pipe(
      take(1),
      tap((img) => {
        this.resultString = `Left: ${img.leftEye} - Right: ${img.rightEye}`;
      })
    );

  RESULT = TEST_RESULT;
  AppChannel = AppChannel;
  public isWebcomponent: boolean = environment.webcomponent;
  public isChubGoLive:boolean = environment.chubGoLiveToggle;
  isMegaTestActive$: Observable<boolean> = this.store.select(getMegaTestActive);
  locale$: Observable<string> = this.store.select(AppSelectors.getLocale);
  public showSaveFeature$ = this.generalService.showImportSaveOption$();
  public isUSMode$: Observable<boolean> = this.generalService.hideLastTest$().pipe(
    tap((isUS) => {
      let maxTestAmount = isUS ? 4 : 5;
      this.megaTestList$.next(maxTestAmount);
    }));
  public megaTestList$: BehaviorSubject<number> = new BehaviorSubject<number>(undefined);

  headerConfig: HeaderConfig = {
    label: HeaderLabelEnum.SingleCheckResult,
    left: HeaderEnum.Empty,
    right: HeaderEnum.Close,
    theme: HeaderColorEnum.LightMode
  } as HeaderConfig;

  // trigger close warning dialog when browser back button is clicked
  @HostListener('window:popstate', ['$event'])
  onPopState() {
    if (!environment.webcomponent) {
      history.pushState(null, null, location.href);
      this.triggerCloseWarning();
    }
  }

  constructor(
    private store: Store,
    private logger: AppInsightsService,
    private locationService: Location,
    private transloco: TranslocoService,
    private analytics: AnalyticsService,
    private generalService: GeneralService
  ) {
    history.pushState(null, null, location.href);
  }

  ngOnInit(): void {
    this.vaResult$ = this.store.select(
      AcuitySelectors.getVisualAcuityEyesResult
    );
    this.vaValues$ = this.store.select(AcuitySelectors.getVisualAcuityValues);
  }

  ngAfterViewInit(): void {
    // Fire the event
    this.analytics.createCustomEvent({
      event: 'event',
      eventName: `${GA4_EventName.CTA}`,
      eventAction: `${GA4_EventAction.View}`,
      eventType: `${GA4_EventType.Acuity} Test`,
      eventValue: `${GA4_EventValue.Result}`,
      eventDetail: `${this.resultString}`
    });
  }

  // DO NOT DELETE
  // this method needs to be here for `untilDestroyed()` to work
  ngOnDestroy(): void {}

  goToResultSummary(): void {
    this.analytics.createCustomEvent({
      event: 'event',
      eventName: `${GA4_EventName.CTA}`,
      eventAction: `${GA4_EventAction.Click}`,
      eventType: `${GA4_EventType.Internal}`,
      eventValue: `${GA4_EventValue.ShowResults}`,
      eventDetail: `${GA4_EventDetail.ResultPage} - ${GA4_EventDetail.Acuity}`
    });

    this.store.dispatch(ShowMegaTestResultsAction());
  }

  nextTest(): void {
    this.analytics.createCustomEvent({
      event: 'event',
      eventName: `${GA4_EventName.CTA}`,
      eventAction: `${GA4_EventAction.Click}`,
      eventType: `${GA4_EventType.Internal}`,
      eventValue: `${GA4_EventValue.ContinueTo}${GA4_EventValue.Contrast}`,
      eventDetail: `${GA4_EventDetail.ResultPage} - ${GA4_EventDetail.Acuity}`
    });

    this.store.dispatch(StartContrastMegaTestAction());
  }

  sendResults(): void {
    this.logger.info('### Send result', 'Acuity');

    this.analytics.createCustomEvent({
      event: 'event',
      eventName: `${GA4_EventName.CTA}`,
      eventAction: `${GA4_EventAction.Click}`,
      eventType: `${GA4_EventType.Internal}`,
      eventValue: `${GA4_EventValue.SaveResults}`,
      eventDetail: `${GA4_EventDetail.ResultPage} - ${GA4_EventDetail.Acuity}`
    });

    this.store.dispatch(SendResultsAction());
  }

  triggerCloseWarning(): void {
    this.analytics.createCustomEvent({
      event: 'event',
      eventName: `${GA4_EventName.CTA}`,
      eventAction: `${GA4_EventAction.Click}`,
      eventType: `${GA4_EventType.Internal}`,
      eventValue: `${GA4_EventValue.ExitResultPage}`,
      eventDetail: `${GA4_EventDetail.ResultPage} - ${GA4_EventDetail.Acuity}`
    });

    this.specialScreeningMode$
      .pipe(delay(0), take(1))
      .subscribe((mode: SpecialScreeningMode) => {
        this.store.dispatch(
          AcuityActions.ShowCloseWarningAction({
            context:
              mode === SpecialScreeningMode.ECP
                ? CloseDialogContext.ResultECP
                : CloseDialogContext.ResultNotECP,
            previousPath: this.locationService.path()
          })
        );
      });
  }
}
