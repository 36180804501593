import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Special for components & co
import { AstigmatismTestPageComponent } from './astigmatism-test-page/astigmatism-test-page.component';
import { ActiveEyeGuard } from '../guards/active-eye.guard';
import { PrepareAstigmatismTestComponent } from './prepare-astigmatism-test/prepare-astigmatism-test.component';
import { AstigmatismResultPageComponent } from './astigmatism-result-page/astigmatism-result-page.component';
import { AstigmatismDetailsPageComponent } from './astigmatism-details-page/astigmatism-details-page.component';

// From shared folder
import { InstructionsPageComponent } from '../shared/pages/instructions-page/instructions-page.component';

const routes: Routes = [
  {
    path: '',
    component: AstigmatismDetailsPageComponent,
    data: {
      animation: 'AstigmatismTestStartPage'
    }
  },
  {
    path: 'check/instructions',
    component: InstructionsPageComponent,
    data: {
      animation: 'AstigmatismTestInstructions'
    }
  },
  {
    path: 'check/start/:eye',
    component: AstigmatismTestPageComponent,
    data: {
      animation: 'AstigmatismTestTestPage'
    },
    canActivate: [ActiveEyeGuard]
  },
  {
    path: 'check/prepare',
    component: PrepareAstigmatismTestComponent,
    data: {
      animation: 'AstigmatismTestPrepareTest'
    }
  },
  {
    path: 'check/result',
    component: AstigmatismResultPageComponent,
    data: {
      animation: 'AstigmatismTestResult'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AstigmatismTestRoutingModule {}
