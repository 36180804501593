<div class="instruction-page" *ngLet="{
    instructionsSet: instructionsSet$ | async,
    activeInstruction: activeInstructionIndex$ | async,
    indicatorDots: indicatorDots$ | async
  } as obs">
  <zat-ovs-header-instructions [modeSetup]="false" (clickCross)="clickCross()">
  </zat-ovs-header-instructions>

  <div class="instruction-page-content" *ngIf="!(showCardWarning$ | async);else cardWarning">
    <!-- All instructions -->
    <div class="instruction-space" [ngSwitch]="obs.instructionsSet[obs.activeInstruction]">
      <!-- Setup brightness -->
      <app-calibrate-brightness *ngSwitchCase="instructions.SETUP_BRIGHTNESS"></app-calibrate-brightness>
      <!-- Setup screen-calibration -->
      <app-calibrate-screen-with-card *ngSwitchCase="instructions.SETUP_CARD_SLIDER" (isSliderMoved)="setSliderMoved()"
        (sliderPosition)="setSliderPosition($event)" (device)="setDevice($event)"
        (pixelPitch)="setPixelPitch($event)"></app-calibrate-screen-with-card>
      <!-- Wear your glasses -->
      <app-wear-your-glasses *ngSwitchCase="instructions.WEAR_YOUR_GLASSES"></app-wear-your-glasses>

      <!-- Start general instructions section -->
      <!-- Cover left eye -->
      <app-general-instructions *ngSwitchCase="instructions.COVER_LEFT_EYE"
        [instruction]="instructions.COVER_LEFT_EYE"></app-general-instructions>
      <!-- Cover right eye -->
      <app-general-instructions *ngSwitchCase="instructions.COVER_RIGHT_EYE"
        [instruction]="instructions.COVER_RIGHT_EYE"></app-general-instructions>
      <!-- Cover left eye -->
      <app-general-instructions *ngSwitchCase="instructions.KEEP_BOTH_EYES"
        [instruction]="instructions.KEEP_BOTH_EYES"></app-general-instructions>

      <!-- Distance -->
      <!-- Keep an arm -->
      <app-general-instructions *ngSwitchCase="instructions.KEEP_AN_ARM"
        [instruction]="instructions.KEEP_AN_ARM"></app-general-instructions>
      <!-- Keep an arm -->
      <app-general-instructions *ngSwitchCase="instructions.KEEP_30_CM"
        [instruction]="instructions.KEEP_30_CM"></app-general-instructions>

      <!-- Checks -->
      <!-- Acuity -->
      <app-general-instructions *ngSwitchCase="instructions.ACUITY_RING"
        [instruction]="instructions.ACUITY_RING"></app-general-instructions>
      <!-- Contrast -->
      <app-general-instructions *ngSwitchCase="instructions.CONTRAST_RING"
        [instruction]="instructions.CONTRAST_RING"></app-general-instructions>
    </div>

    <!-- Actions -->
    <div class="actions">
      <!-- Indicator dots -->
      <zat-ovs-indicator-dots [indicatorDots]="obs.indicatorDots"
        *ngIf="!(obs.indicatorDots.length === 1)"></zat-ovs-indicator-dots>
      <!-- Button -->
      <zat-action-button *ngIf="!lastSlide" type="outline" (click)="next()" data-testid="button-instructions-next">
        {{ 'button.next' | transloco }}
      </zat-action-button>

      <zat-action-button *ngIf="lastSlide" type="solid" (click)="next()" data-testid="button-instructions-next">
        {{ 'button.ready'| transloco }}
      </zat-action-button>
    </div>
  </div>

  <ng-template #cardWarning>
    <div class="calibrate-warning" data-testid="calibration-warning-dialog">
      <div class="warning-content">
        <h1 class="headline" [innerHTML]="'setup.calibration.warning.title' | transloco"></h1>
        <p [innerHTML]="'setup.calibration.warning.text' | transloco" class="text"></p>
      </div>

      <div class="actions">
        <zat-action-button class="info-button" color="primary" type="solid"
          data-testid="calibration-warning-close" (click)="closeWarning()">
          {{ 'button.goBack' | transloco }}
        </zat-action-button>
        <zat-action-button type="outline" class="spacing-to-upper continue-button"
          data-testid="calibration-warning-continue" (click)="cardCalibrationDone()">
          {{ 'button.continueWithoutCalibration' | transloco }}
        </zat-action-button>
      </div>
    </div>
  </ng-template>
</div>
