import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Special for checks
import { ActiveEyeGuard } from '../guards/active-eye.guard';
import { AmslerTestPageComponent } from './amsler-test-page/amsler-test-page.component';
import { PrepareAmslerTestComponent } from './prepare-asmler-test/prepare-asmler-test.component';
import { AmslerResultPageComponent } from './amsler-result-page/amsler-result-page.component';
import { AmslerDetailsPageComponent } from './amsler-details-page/amsler-details-page.component';

// From shared folder
import { InstructionsPageComponent } from '../shared/pages/instructions-page/instructions-page.component';

const routes: Routes = [
  {
    path: '',
    component: AmslerDetailsPageComponent,
    data: {
      animation: 'AmslerTestStartPage'
    }
  },
  {
    path: 'check/instructions',
    component: InstructionsPageComponent,
    data: {
      animation: 'AmslerTestInstructions'
    }
  },
  {
    path: 'check/prepare',
    component: PrepareAmslerTestComponent,
    data: {
      animation: 'AmslerTestPrepare'
    }
  },
  {
    path: 'check/start/:eye',
    component: AmslerTestPageComponent,
    data: {
      animation: 'AmslerTestTestPage'
    },
    canActivate: [ActiveEyeGuard]
  },
  {
    path: 'check/result',
    component: AmslerResultPageComponent,
    data: {
      animation: 'AmslerTestResult'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AmslerTestRoutingModule {}
