<div class="test-card" *transloco="let t">
  <!-- Image -->
  <div class="image">
    <div [ngSwitch]="test">
      <!-- ACUITY -->
      <div *ngSwitchCase="TEST_TYPES.ACUITY">
        <div>
          <app-icon id="acuityTestThick" name="acuity_thick" [width]="dimension" [height]="dimension"
            [alt]=""></app-icon>
        </div>
      </div>

      <!-- CONTRAST -->
      <div *ngSwitchCase="TEST_TYPES.CONTRAST">
        <div class="desktop">
          <app-icon id="contrastTestThick" name="contrast_thick" [width]="dimension" [height]="dimension"
            [alt]=""></app-icon>
        </div>
      </div>

      <!-- ASTIGMATISM -->
      <div *ngSwitchCase="TEST_TYPES.ASTIGMATISM">
        <div class="desktop">
          <app-icon id="astigmatismTestThick" name="astigmatism_thick" [width]="dimension" [height]="dimension"
            [alt]=""></app-icon>
        </div>
      </div>

      <!-- COLOR -->
      <div *ngSwitchCase="TEST_TYPES.COLOR">
        <div>
          <app-icon id="colorTestThick" name="color_thick" [width]="dimension" [height]="dimension" [alt]=""></app-icon>
        </div>
      </div>

      <!-- AMSLER -->
      <div *ngSwitchCase="TEST_TYPES.AMSLER">
        <div>
          <app-icon id="amslerTestThick" name="amsler_thick" [width]="dimension" [height]="dimension"
            [alt]=""></app-icon>
        </div>
      </div>
    </div>
  </div>

  <!-- Title -->
  <span class="title">
    {{ t(mapTestString(test) +'Test.startPage.detailsTitle') }}
  </span>

  <!-- Description -->
  <div class="description">
    {{ t(mapTestString(test) + 'Test.startPage.detailsText') }}
  </div>

  <!-- Actions -->
  <div class="actions">
    <a [tabIndex]="0" [attr.data-testid]="'test-card-detail-' + test" (click)="detail()" (keyup)="checkTheKey($event)"
      class="details" [attr.aria-label]="t(testCode + '.result.testName') + ' ' + t('button.details')">
      {{ t('button.details') }}
    </a>

    <!-- Start-Buttons: Custom String for every TestType -->
    <zat-action-button type="solid" size="small-medium" color="primary" (click)="startSingleTest()"
      [attr.data-testid]="'test-card-start-' + test" class="spacing-to-top">
      {{ t(mapTestString(test) + 'Test.startPage.startCheck') }}
    </zat-action-button>
  </div>
</div>
