import { MAX_VA } from '../config';
import { EyeAcuityValue } from '../interfaces';

// return visual acuity value in Feet unit for US market.
export function logmar2feet(logmar: number = MAX_VA): EyeAcuityValue {
  const map: { [value: number]: number } = {
    '-0.3': 10,
    '-0.2': 12.5,
    '-0.1': 16,
    0: 20,
    0.1: 25,
    0.2: 32,
    0.3: 40,
    0.4: 50,
    0.5: 63,
    0.6: 80,
    0.7: 100,
    0.8: 125,
    0.9: 160,
    1: 200,
    1.1: 250,
    1.2: 300
  };

  return {
    x: 20,
    y: map[logmar]
  };
}
