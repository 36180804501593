import { Component, OnDestroy, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';

// Stuff for general
import { environment } from '../../../../environments/environment';

// From shared folder
import { HeaderColorEnum, HeaderLabelEnum, HeaderEnum } from '../../../shared/enums/header.enum';
import { HeaderConfig } from '../../../shared/interfaces/header-config.interface';
import { Instruction } from '../../../shared/enums';

@Component({
  selector: 'zat-how-it-works-color-page',
  templateUrl: './how-it-works-color-page.component.html',
  styleUrls: ['./how-it-works-color-page.component.scss']
})
export class HowItWorksColorPageComponent implements OnDestroy {
  @ViewChild('scrollableElement') scrollableElement: ElementRef;

  @Output('clickClose') clickClose = new EventEmitter();

  environment = environment;

  instructions = Instruction;

  headerConfig: HeaderConfig = {
    left: HeaderEnum.Back,
    right: HeaderEnum.Empty,
    label: HeaderLabelEnum.ColorCheck,
    theme: HeaderColorEnum.LightMode,
  } as HeaderConfig;

  constructor() { }

  ngOnDestroy(): void { }

  ngAfterViewInit() {
    this.scrollableElement.nativeElement.focus();
  }

  closeHint(): void {
    this.clickClose.emit();
  }
}
