<zat-ovs-header [label]="headerConfig.label" [left]="headerConfig.left" [right]="headerConfig.right"
  [theme]="headerConfig.theme" (clickRight)="triggerCloseWarning()"></zat-ovs-header>
<div *ngLet="(isMegaTestActive$ | async) as isMegaTestActive" class="result-content">
  <ng-container content *transloco="let t">
    <div class="va-content" #content *ngLet="(vaValues$ | async) as vaValues">

      <div class="va-content-container" [ngClass]="{ 'single-row': isMegaTestActive , 'webcomponent': isWebcomponent }"
        *ngLet="(specialScreeningMode$ | async) as specialScreeningMode">

        <div class="va-result-content">
          <zat-acuity-test-result [results]="vaResult$ | async" [image]="image$ | async" [vaValues]="vaValues$ | async"
            [isFRChannel]="((locale$ | async) === 'fr-FR') && !isMegaTestActive">
          </zat-acuity-test-result>

          <div *ngIf="isMegaTestActive" class="result-status-mega-test">
            <div class="result-status-mega-test-stats">
              <span class="stats-title">1/{{ megaTestList$ | async }}</span>
              <span class="va-description">{{ t('result.checksCompleted') }}</span>
            </div>
            <div class="result-status-mega-test-stats" *ngLet="(isUSMode$|async) as isUSMode">
              <span *ngIf="!isUSMode" class="stats-title">4</span>
              <span *ngIf="isUSMode" class="stats-title">3</span>
              <span class="va-description">{{ t('result.minutesLeft') }}</span>
            </div>
          </div>

          <div *ngIf="isMegaTestActive">
            <div class="va-content--mega-test">
              <zat-action-button class="result-button" color="primary" type="solid"
                (click)="nextTest()" data-testid="button-acuity-result-next">
                {{ t('acuityTest.result.continueToContrastTest') }}
              </zat-action-button>

              <zat-action-button class="result-button"
                *ngIf="((specialScreeningMode$ | async) === SpecialScreeningMode.ECP) || !isWebcomponent"
                color="primary" type="inverted" (click)="goToResultSummary()"
                data-testid="button-acuity-result-summarize">
                <!--button says quit & save when (en-us && web)-combo && cHubGoLiveToggle-->
                {{
                  (showSaveFeature$|async) ? t('button.summarizeSave') : t('button.summarize')
                }}
              </zat-action-button>
              <zat-action-button class="result-button"
                *ngIf="((specialScreeningMode$ | async) === SpecialScreeningMode.MY_ZEISS) && isWebcomponent"
                color="primary" type="inverted" (click)="sendResults()"
                data-testid="button-acuity-result-summarize-and-save">
                {{ t('button.summarizeSave') }}
              </zat-action-button>
            </div>
          </div>

          <!-- Send Result-Button -- Only for the Webcomponent Single Journey -->
          <div *ngIf="isWebcomponent && specialScreeningMode !== SpecialScreeningMode.ECP && !isMegaTestActive"
            class="result-btn">
            <zat-action-button *ngIf="specialScreeningMode === SpecialScreeningMode.MY_ZEISS" color="primary"
              type="solid" (click)="sendResults()" data-testid="button-acuity-result-save">
              {{ t('button.save') }}
            </zat-action-button>

            <!-- Send via Mail -- Only for the webcomponent NL Campaign-->
            <zat-action-button class="result-button" *ngIf="specialScreeningMode === SpecialScreeningMode.NL_CAMPAIGN"
              color="primary" type="solid" (click)="sendResults()"
              data-testid="button-acuity-result-save">
              {{ t('button.sendViaMail') }}
            </zat-action-button>
          </div>
        </div>

        <zat-result-call-to-action
          *ngIf="!isWebcomponent && ((specialScreeningMode$ | async) === SpecialScreeningMode.MY_ZEISS) && !isMegaTestActive"
          [isFRChannel]="(locale$ | async) === 'fr-FR'">
        </zat-result-call-to-action>
      </div>
    </div>
  </ng-container>
</div>
