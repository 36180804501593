// From shared folder
import { TEST_RESULT } from '../../../shared/enums';

export function getTestResult(value: number): TEST_RESULT {
    const EXCELLENT_ZONE = value >= 1.407;
    const FINE_ZONE = value >= 1.009 && value <= 1.293;

    if (EXCELLENT_ZONE) {
        return TEST_RESULT.EXCELLENT;
    }
    if (FINE_ZONE) {
        return TEST_RESULT.FINE;
    }

    return TEST_RESULT.REDUCED;
}
