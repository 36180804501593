import { Injectable } from '@angular/core';
import { formatISO } from 'date-fns';

// From shared folder
import { TEST_RESULT, TEST_RESULT_STRING } from '../shared/enums';
import { TEST_TYPES } from '../shared/enums/test-types';
import { CheckResultsForSend, EyeResult, EyeResultsForSend, ResultsForSend } from '../shared/interfaces/send-check-results.interface';

@Injectable({
  providedIn: 'root'
})
export class WebResultsService {

  convertResultStringToNumber(resultString: string): number {
    switch (resultString) {
      case TEST_RESULT_STRING.EXCELLENT:
        return TEST_RESULT.EXCELLENT;
      case TEST_RESULT_STRING.FINE:
        return TEST_RESULT.FINE;
      case TEST_RESULT_STRING.REDUCED:
        return TEST_RESULT.REDUCED;
    }
  }

  generateResultSet(
    acuityImage: EyeResult,
    contrastImage: EyeResult,
    colorImage: EyeResult,
    astigmatismImage: EyeResult,
    amslerImage: EyeResult,
    // Finish-Flags
    isAcuityFinish: boolean,
    isContrastFinish: boolean,
    isColorFinish: boolean,
    isAstigmatismFinish: boolean,
    isAmslerFinish: boolean
  ): CheckResultsForSend {

    // Prepare the result-object
    let result: CheckResultsForSend = {
      datetime: formatISO(new Date()),
      results: []
    } as CheckResultsForSend;

    // ACUITY-Check
    if (isAcuityFinish) {
      result.results.push({
        type: TEST_TYPES.ACUITY,
        eyeResults: {
          left: this.convertResultStringToNumber(acuityImage.leftEye),
          right: this.convertResultStringToNumber(acuityImage.rightEye)
        } as EyeResultsForSend
      } as ResultsForSend);
    }

    // ASTIGMATISM-Check
    if (isAstigmatismFinish) {
      result.results.push({
        type: TEST_TYPES.ASTIGMATISM,
        eyeResults: {
          left: this.convertResultStringToNumber(astigmatismImage.leftEye),
          right: this.convertResultStringToNumber(astigmatismImage.rightEye)
        } as EyeResultsForSend
      } as ResultsForSend);
    }

    // AMSLER-Check
    if (isAmslerFinish) {
      result.results.push({
        type: TEST_TYPES.AMSLER,
        eyeResults: {
          left: this.convertResultStringToNumber(amslerImage.leftEye),
          right: this.convertResultStringToNumber(amslerImage.rightEye)
        } as EyeResultsForSend
      } as ResultsForSend);
    }

    // COLOR-Check
    if (isColorFinish) {
      result.results.push({
        type: TEST_TYPES.COLOR,
        eyeResults: {
          left: this.convertResultStringToNumber(colorImage.leftEye),
          right: this.convertResultStringToNumber(colorImage.rightEye)
        } as EyeResultsForSend
      } as ResultsForSend);
    }

    // CONTRAST-Check
    if (isContrastFinish) {
      result.results.push({
        type: TEST_TYPES.CONTRAST,
        eyeResults: {
          left: this.convertResultStringToNumber(contrastImage.leftEye),
          right: this.convertResultStringToNumber(contrastImage.rightEye)
        } as EyeResultsForSend
      } as ResultsForSend);
    }

    return result;
  }
}
