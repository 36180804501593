import { createSelector } from '@ngrx/store';
import { fromAcuity } from '../reducers';

// Stuff for general
import { fromApp } from '../../../store/reducers';

// Special for checks
import { getTestResult, logmar2decimal, logmar2feet, logmar2metric } from '../../shared';

// From shared folder
import { appChannelMap } from '../../../shared/app-channels';
import { MAX_TEST_COUNT } from '../../../shared/config';
import { EyeVisionValues } from '../../../shared/interfaces';
import { AppChannel, Eyes, TEST_RESULT } from '../../../shared/enums';
import { resultImageConverter } from '../../../shared/utils';

export const selectState = (state: {
  app: fromApp.AppState;
  acuity: fromAcuity.AcuityState;
}) => state;

export const selectAcuity = (state: { acuity: fromAcuity.AcuityState }) =>
  state.acuity;

// TODO: *REDUX* create proper feature selector for acuity check
//export const selectAcuity = createFeatureSelector<AcuityState>('acuityState');

export const getVisualAcuity = createSelector(
  selectAcuity,
  (state: fromAcuity.AcuityState) => {
    return state.tests.visualAcuity;
  }
);

export const getRawVisualAcuityValue = createSelector(
  selectAcuity,
  (state: fromAcuity.AcuityState): EyeVisionValues => {
    return {
      right: state.rightEyeAcuity.value,
      left: state.leftEyeAcuity.value
    };
  }
);

export const getVisualAcuityEyesResult = createSelector(
  selectAcuity,
  (state: fromAcuity.AcuityState) => {
    const right = getTestResult(state.rightEyeAcuity.value);
    const left = getTestResult(state.leftEyeAcuity.value);
    const max = Math.max(right, left);
    const min = Math.min(right, left);
    let sumResult = max;
    let eyesResult;

    if (max === min) {
      eyesResult = TEST_RESULT.BOTHEYES;
    }
    else {
      eyesResult = TEST_RESULT.ONEEYE;
      sumResult = min;
    }

    return {
      sumResult,
      eyesResult
    };
  }
);

export const getResultImage = createSelector(
  selectAcuity,
  (state: fromAcuity.AcuityState) => {
    const right = getTestResult(state.rightEyeAcuity.value);
    const left = getTestResult(state.leftEyeAcuity.value);
    const leftEye = resultImageConverter(left);
    const rightEye = resultImageConverter(right);

    return {
      leftEye,
      rightEye
    };
  }
);

// get the summarized result for displaying one icon (if there are two different results for
// both eyes, then take the lower result score)
export const getResultImageLowerScore = createSelector(
  selectAcuity,
  (state: fromAcuity.AcuityState) => {
    const right = getTestResult(state.rightEyeAcuity.value);
    const left = getTestResult(state.leftEyeAcuity.value);

    let lowerScore;
    if(right < left) {
      lowerScore = right;
    } else {
      lowerScore = left;
    }

    const summarizedResult = resultImageConverter(lowerScore);
    return summarizedResult;
  }
);

export const getVisualAcuityInFeet = createSelector(
  selectAcuity,
  (state: fromAcuity.AcuityState) => {
    const right = logmar2feet(state.rightEyeAcuity.value);
    const left = logmar2feet(state.leftEyeAcuity.value);

    return {
      right: `${right.x}/${right.y}`,
      left: `${left.x}/${left.y}`
    };
  }
);

export const getVisualAcuityValues = createSelector(
  selectState,
  //selectAcuity,
  ({ app, acuity }) => {
    const channel = appChannelMap.get(app.locale);

    // acuity values in metric are needed for both METRIC and DECIMAL channels
    const acuityValuesInMetric = {
      right: logmar2metric(acuity.rightEyeAcuity.value),
      left: logmar2metric(acuity.leftEyeAcuity.value)
    };

    // METRIC channels: displays acuity values `6/xx` format
    if (channel === AppChannel.METRIC) {
      return {
        right: acuityValuesInMetric.right,
        left: acuityValuesInMetric.left
      };
    }

    // METRIC channels: displays acuity values `xx%` (percentage) format
    // but we still need x/y values to display in the info tooltip of the results
    if (channel === AppChannel.DECIMAL) {
      return {
        right: {
          ...acuityValuesInMetric.right,
          percent: logmar2decimal(acuity.rightEyeAcuity.value, app.locale)
        },
        left: {
          ...acuityValuesInMetric.left,
          percent: logmar2decimal(acuity.leftEyeAcuity.value, app.locale)
        }
      };
    }

    // FOOT or US channels
    return {
      right: logmar2feet(acuity.rightEyeAcuity.value),
      left: logmar2feet(acuity.leftEyeAcuity.value)
    };
  }
);

export const getActiveEyeStepChangeCounter = createSelector(
  selectAcuity,
  (state: fromAcuity.AcuityState) => {
    const activeEye = state.tests.activeEye;

    if (activeEye === Eyes.RIGHT) {
      return state.rightEyeAcuity.directionChangeCounter;
    }

    return state.leftEyeAcuity.directionChangeCounter;
  }
);

export const getLandoltCRotationAngle = createSelector(
  selectAcuity,
  (state: fromAcuity.AcuityState) => {
    return state.tests.angle;
  }
);

export const getTestCount = createSelector(
  selectAcuity,
  (state: fromAcuity.AcuityState) => {
    return state.tests.count;
  }
);

export const hasReachedMaxTests = createSelector(
  selectAcuity,
  (state: fromAcuity.AcuityState) => {
    return state.tests.count > MAX_TEST_COUNT;
  }
);

export const getActiveEyeFinished = createSelector(
  selectAcuity,
  (state: fromAcuity.AcuityState) => {
    if (state.tests.activeEye === Eyes.RIGHT) {
      return state.rightEyeAcuity.finished;
    }

    return state.leftEyeAcuity.finished;
  }
);

export const getBothEyesFinished = createSelector(
  selectAcuity,
  (state: fromAcuity.AcuityState) => {
    return state.rightEyeAcuity.finished && state.leftEyeAcuity.finished;
  }
);

export const getActiveEye = createSelector(
  selectAcuity,
  (state: fromAcuity.AcuityState) => {
    return state.tests.activeEye;
  }
);

export const getLastScaleDirection = createSelector(
  selectAcuity,
  (state: fromAcuity.AcuityState) => {
    return state.tests.lastScaleDirection;
  }
);

export const getEyesFinished = createSelector(
  selectAcuity,
  (state: fromAcuity.AcuityState) => {
    return {
      finishedLeft: state.leftEyeAcuity.finished,
      finishedRight: state.rightEyeAcuity.finished
    };
  }
);
