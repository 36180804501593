import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, share, take } from 'rxjs';

// Stuff for general
import { ContentDownloadService } from './content-download.service';

// From shared folder
import { AppInsightsService } from './app-insights.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import slugify from 'slugify';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {
  // default is local so we can debug locally on stage/prod webcomp
  customerId: string = "local";

  constructor(
    private contentDownloadService: ContentDownloadService,
    private http: HttpClient,
    private logger: AppInsightsService) {
  }

  public isAuthorized$(): Observable<boolean> {
    const url = `${environment.baseUrl}/content`;

    return this.http.get(url, {
      headers: new HttpHeaders({
        //'Content-Type': 'application/json',
        'EsbApi-Subscription-Key': environment.subscriptionKey
      }), 
      params: new HttpParams().set('resource-name', 'whitelist').set('customerId',this.customerId),
      responseType:'json'
    }).pipe(
      take(1),
      map((value: Array<string>)=> {

        if (this.inIFrame()) {
          let iFrameUrlArray: Array<string> = [];

          value.forEach(function (url) {
              slugify.extend({'.': '-'});
              let iFrameURL = 'www-' + slugify(url) + '.filesusr.com'; // '.filesusr.com' is an automatically created suffix of Wix websites
              iFrameUrlArray.push(iFrameURL);
          });

          return (iFrameUrlArray.indexOf(window.location.hostname) > -1);
        }

        return (value.indexOf(window.location.hostname) > -1);
      }),
      catchError((error) => {
        this.logger.error("Authorization failed");
        this.logger.error(JSON.stringify(error));
        return of(false);
      })
    );
  }
  
  inIFrame(): boolean {
    try {
      return window.top !== window.self;
    } catch {
      return false;
    }
  }
}