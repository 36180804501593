import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import {completeIconSet } from './app-icon.model';
import { AppIconsRegistryService } from './app-icons-registry.service';
import { AppIconComponent } from './app-icon.component';

@NgModule({
  declarations: [AppIconComponent],
  exports: [AppIconComponent],
  imports: [CommonModule]
})
export class AppIconsModule {
  constructor(private appIconsRegistry: AppIconsRegistryService) {
    this.appIconsRegistry.registerIcons(completeIconSet);
  }
}
