import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';

// Stuff for general
import { AppSelectors } from '../../store/selectors';
import { GeneralService } from '../../services/general.service';

// Special for checks
import { ShowCloseWarningAction } from '../store/actions/amsler.actions';

// From shared folder
import { RoutePathes } from '../../shared/enums/route-pathes.enum';
import { TEST_TYPES } from '../../shared/enums/test-types';
import { HeaderLabelEnum } from '../../shared/enums/header.enum';
import { CloseDialogContext } from '../../shared/components/close-dialog/close-dialog-context.enum';
import { GA4_EventDetail } from '../../shared/enums/ga4.enum';

@UntilDestroy()
@Component({
  selector: 'zat-prepare-amsler-test',
  templateUrl: './prepare-asmler-test.component.html',
  styleUrls: ['./prepare-asmler-test.component.scss']
})
export class PrepareAmslerTestComponent implements OnInit, OnDestroy {
  megaTestActive$: Observable<boolean>;

  label: HeaderLabelEnum = HeaderLabelEnum.Setup;
  checkType: GA4_EventDetail = GA4_EventDetail.Amsler;

  private isMegaTestActive: boolean;
  constructor(
    private generalService: GeneralService,
    private store: Store,
    private locationService: Location
  ) { }

  ngOnInit(): void {
    this.megaTestActive$ = this.store.select(AppSelectors.getMegaTestActive);
    this.megaTestActive$
      .pipe(untilDestroyed(this))
      .subscribe((isActive) => (this.isMegaTestActive = isActive));
  }

  ngOnDestroy(): void { }

  next() {
    let path = `${TEST_TYPES.AMSLER}${RoutePathes.CheckStartLeft}`;
    if (this.isMegaTestActive) {
      path = `${RoutePathes.Default}/${path}`;
    }
    this.generalService.routeToNextScreen(path);
  }

  cancelTest(): void {
    this.store.dispatch(ShowCloseWarningAction({ context: CloseDialogContext.Check, previousPath: this.locationService.path() }));
  }
}
