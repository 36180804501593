import { createAction, props } from '@ngrx/store';

// From shared folder
import { Answer, Eyes, Instruction } from '../../../shared/enums';
import { CloseDialogContext } from '../../../shared/components/close-dialog/close-dialog-context.enum';

const AstigmatismTest = 'AstigmatismTest';

export const ResetEyeAstigamatism = createAction(
  `[${AstigmatismTest}] ResetEyeAstigamatism`,
);

export const ResetTests = createAction(
  `[${AstigmatismTest}] ResetTests`,
);

export const SubmitAnswer = createAction(
  `[${AstigmatismTest}] SubmitAnswer`,
  props<{
    answer: Answer;
  }>()
);

export const SetActiveEye = createAction(
  `[${AstigmatismTest}] SetActiveEye`,
  props<{
    eye: Eyes;
  }>()
);

/**
 * This action sets astigmatism answers with values taken
 * from the storage service
 */
export const SetAstiAnswersFromDate = createAction(
  `[${AstigmatismTest}] SetAstiAnswersFromDate`,
  props<{
    leftEyeAnswer: Answer;
    rightEyeAnswer: Answer;
  }>()
);

export const OpenAstigmatismDetailsPageAction = createAction(`[${AstigmatismTest}] Open astigmatism details page`);

export const ShowCloseWarningAction = createAction(
  `[${AstigmatismTest}] ShowCloseWarning`,
  props<{
    context: CloseDialogContext;
    previousPath?:string;
  }>()
);

export const StartAstigmatismSingleCheckAction = createAction(
  `[${AstigmatismTest}] Start astigmatism single check`
);

export const StartAstigmatismSingleCheckSuccededAction = createAction(
  `[${AstigmatismTest}] Start astigmatism single check succeded`,
  props<{
    instructions: Array<Instruction>;
  }>()
);

export const StartAstigmatismMegaTestAction = createAction(
  `[${AstigmatismTest}] Start astigmatism mega test`
);

export const StartAstigmatismMegaTestSuccedAction = createAction(
  `[${AstigmatismTest}] Start astigmatism mega test succed`,
  props<{
    instructions: Array<Instruction>;
  }>()
);
