<div class="about-us" *transloco="let t">
  <zat-ovs-header [label]="headerConfig.label" [left]="headerConfig.left" [right]="headerConfig.right"
    [theme]="headerConfig.theme" (clickLeft)="back()"></zat-ovs-header>
  <div class="info-tile-set" *ngLet="(t('help.contactUrl')) as contactUrl">
    <!-- Contact -->
    <zat-action-button (click)="openContact(contactUrl)" type="focus"
      ariaLabel="{{ 'home.about.contact' | transloco }}">
      <div class="tile">
        <div class="headline">
          <div class="icon-tile left">
            <app-icon id="contact" name="contact" [width]="24" [height]="17" alt="">
            </app-icon>
          </div>
          <div class="tile-text">
            <span class="info-headline">{{ t('home.about.contact') }}</span>
            <div class="info-text">
              {{ t('home.about.contact.text') }}
            </div>
          </div>
          <div class="icon-tile right">
            <app-icon id="arrow_right" name="arrow_right" [width]="7" [height]="11" alt="">
            </app-icon>
          </div>
        </div>
      </div>
    </zat-action-button>

    <!-- FAQs -->
    <zat-action-button class="top-spacing" (click)="openFAQs()" type="focus"
      ariaLabel="{{ 'home.about.faqs.header' | transloco }}">
      <div class="tile">
        <div class="headline">
          <div class="icon-tile left">
            <app-icon id="btn_help" name="btn_help" [width]="19" [height]="19" alt="">
            </app-icon>
          </div>
          <div class="tile-text">
            <span class="info-headline">{{ t('home.about.faqs.header') }}</span>
            <div class="info-text">
              {{ t('home.about.faqs.text') }}
            </div>
          </div>
          <div class="icon-tile right">
            <app-icon id="arrow_right" name="arrow_right" [width]="7" [height]="11" alt="">
            </app-icon>
          </div>
        </div>
      </div>
    </zat-action-button>

    <!-- Documents -->
    <zat-action-button *ngIf="!isWebComponent" class="top-spacing" (click)="openLegalDocuments()" type="focus"
      ariaLabel="{{ 'home.about.legalDocuments.title' | transloco }}">
      <div class="tile">
        <div class="headline">
          <div class="icon-tile left">
            <app-icon id="document" name="document" [width]="15" [height]="19" alt="">
            </app-icon>
          </div>
          <div class="tile-text">
            <span class="info-headline">{{ t('home.about.legalDocuments.title') }}</span>
            <div class="info-text">
              {{ t('home.about.legalDocuments.text') }}
            </div>
          </div>
          <div class="icon-tile right">
            <app-icon id="arrow_right" name="arrow_right" [width]="7" [height]="11" alt="">
            </app-icon>
          </div>
        </div>
      </div>
    </zat-action-button>

  </div>
  <div class="semantic-version">Version {{semanticVersion}}</div>
</div>
