import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, Observable, tap } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

// Stuff for general
import { OpenHomePageAction } from '../../store/actions/general.actions';
import { AppCookiesService } from '../../services/app-cookies.service';
import { ResetImportantNotesAction, ShowImportantNotesAction } from '../../store/actions/check-calibration.actions';
import { getAgreeImportantNotes } from '../../store/selectors/app.selectors';
import { AppLocalizeRouterService } from '../../services/app-localize-router.service';

// From shared folder
import { TEST_TYPES } from '../../shared/enums/test-types';
import { TestTypesForImportantNotes } from '../../shared/enums/test-types-important-notes.enum';

@UntilDestroy()
export class StartPageClass {
  public TEST_TYPES = TEST_TYPES;

  nextWithoutCheck$: Observable<boolean> = this.store.select(getAgreeImportantNotes);

  constructor(
    protected store: Store,
    protected router: Router,
    protected localize: AppLocalizeRouterService,
    protected appCookiesService: AppCookiesService
  ) {
    this.nextWithoutCheck$.pipe(
      filter((value) => value === true),
      tap(() => {
        this.store.dispatch(ResetImportantNotesAction());
      }),
      untilDestroyed(this)
    ).subscribe();
  }

  back(): void {
    this.store.dispatch(OpenHomePageAction());
  }

  start(): void {
    this.store.dispatch(ShowImportantNotesAction({ testType: TestTypesForImportantNotes.ALL }));
  }
}
