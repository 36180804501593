<zat-ovs-header [label]="headerConfig.label" [left]="headerConfig.left" [right]="headerConfig.right"
  [theme]="headerConfig.theme" (clickRight)="triggerCloseWarning()"></zat-ovs-header>
<div *ngLet="(isMegaTestActive$ | async)  as isMegaTestActive" class="result-content">
  <ng-container content *transloco="let t">
    <div class="va-content" #content *ngLet="(specialScreeningMode$ | async) as specialScreeningMode">
      <div class="va-content-container" [ngClass]="{
          'single-row': isMegaTestActive,
          webcomponent: isWebcomponent
        }">
        <div class="va-result-content">
          <zat-astigmatism-test-result [results]="result$ | async" [image]="image$ | async" [isFRChannel]="
              (locale$ | async) === 'fr-FR' && !isMegaTestActive
            ">
          </zat-astigmatism-test-result>

          <div *ngIf="isMegaTestActive" class="result-status-mega-test text-body">
            <div class="result-status-mega-test-stats">
              <span class="stats-title">4/{{ megaTestList$ | async }}</span>
              <span class="va-description">{{
                t('result.checksCompleted')
                }}</span>
            </div>
            <div class="result-status-mega-test-stats" *ngLet="(isUSMode$|async) as isUSMode">
              <span *ngIf="!isUSMode" class="stats-title">1</span>
              <span *ngIf="isUSMode" class="stats-title">0</span>
              <span class="va-description">{{ t('result.minutesLeft') }}</span>
            </div>
          </div>

          <div *ngIf="isMegaTestActive">
            <div class="va-content--mega-test">
              <zat-action-button *ngIf="((isUSMode$ | async))" color="primary" type="solid"
                (click)="goToResultSummary()">
                {{ t('button.showAllResults') }}
              </zat-action-button>

              <div *ngIf="!((isUSMode$ | async))">
                <zat-action-button class="result-button" color="primary" type="solid" (click)="nextTest()"
                  data-testid="button-astigmatism-result-next">
                  {{ t('astigmatismTest.result.continueToAmslerTest') }}
                </zat-action-button>

                <zat-action-button class="result-button"
                  *ngIf="(specialScreeningMode === SpecialScreeningMode.ECP) || !isWebcomponent" color="primary"
                  type="inverted" (click)="goToResultSummary()" data-testid="button-astigmatism-result-summarize">
                  <!--button says quit & save when (en-us && web)-combo && cHubGoLiveToggle-->
                  {{
                  (showSaveFeature$|async) ? t('button.summarizeSave') :
                  t('button.summarize')
                  }}
                </zat-action-button>
                <zat-action-button class="result-button"
                  *ngIf="(specialScreeningMode === SpecialScreeningMode.MY_ZEISS) && isWebcomponent" color="primary"
                  type="inverted" (click)="sendResults()" data-testid="button-astigmatism-result-summarize-and-save">
                  {{ t('button.summarizeSave') }}
                </zat-action-button>
              </div>
            </div>
          </div>

          <!-- Send Result-Button -->
          <div *ngIf="
              isWebcomponent && (specialScreeningMode === SpecialScreeningMode.MY_ZEISS) && !isMegaTestActive"
            class="result-btn">
            <zat-action-button color="primary" type="solid" (click)="sendResults()"
              data-testid="button-astigmatism-result-next">
              {{ t('button.save') }}
            </zat-action-button>
          </div>
        </div>

        <zat-result-call-to-action
          *ngIf="!isWebcomponent && (specialScreeningMode === SpecialScreeningMode.MY_ZEISS) && !isMegaTestActive"
          [isFRChannel]="(locale$ | async) === 'fr-FR'">
        </zat-result-call-to-action>
      </div>
    </div>
  </ng-container>
</div>
