import { TEST_RESULT } from '../../../shared/enums';
import { isEqual } from 'lodash-es';

export const getResult = (results: boolean[]) => {
  const EXCELLENT_ZONE = [true, false];
  const equal = isEqual(results, EXCELLENT_ZONE);

  if (equal) {
    return TEST_RESULT.EXCELLENT;
  }

  return TEST_RESULT.REDUCED;
};
