import { ColorTestItem } from './interfaces';

export const START_INDEX = 0;
export const MIN_CHOICE = 4;

export const COLOR_TEST_01: ColorTestItem = {
  no: 1,
  choices: [12, 'nothing'],
  correctAnswer: 12,
};

export const COLOR_TEST_02: ColorTestItem = {
  no: 2,
  choices: [8, 3, 'nothing'],
  correctAnswer: 8,
  confusionNumber: 3,
};

export const COLOR_TEST_03: ColorTestItem = {
  no: 3,
  choices: [29, 70, 'nothing'],
  correctAnswer: 29,
  confusionNumber: 70,
};

export const COLOR_TEST_04: ColorTestItem = {
  no: 4,
  choices: [5, 2, 'nothing'],
  correctAnswer: 5,
  confusionNumber: 2,
};

export const COLOR_TEST_05: ColorTestItem = {
  no: 5,
  choices: [3, 5, 'nothing'],
  correctAnswer: 3,
  confusionNumber: 5,
};

export const COLOR_TEST_06: ColorTestItem = {
  no: 6,
  choices: [15, 17, 'nothing'],
  correctAnswer: 15,
  confusionNumber: 17,
};

export const COLOR_TEST_07: ColorTestItem = {
  no: 7,
  choices: [74, 21, 'nothing'],
  correctAnswer: 74,
  confusionNumber: 21,
};

export const COLOR_TEST_08: ColorTestItem = {
  no: 8,
  choices: [6, 'nothing'],
  correctAnswer: 6,
};

export const COLOR_TEST_09: ColorTestItem = {
  no: 9,
  choices: [45, 'nothing'],
  correctAnswer: 45,
};

export const COLOR_TEST_10: ColorTestItem = {
  no: 10,
  choices: [5, 'nothing'],
  correctAnswer: 5,
};

export const COLOR_TEST_11: ColorTestItem = {
  no: 11,
  choices: [7, 'nothing'],
  correctAnswer: 7,
};

export const COLOR_TEST_12: ColorTestItem = {
  no: 12,
  choices: [16, 'nothing'],
  correctAnswer: 16,
};

export const COLOR_TEST_13: ColorTestItem = {
  no: 13,
  choices: [73, 'nothing'],
  correctAnswer: 73,
};

export const COLOR_TEST_14: ColorTestItem = {
  no: 14,
  choices: [5, 'nothing'],
  correctAnswer: 'nothing',
  confusionNumber: 5,
};

export const COLOR_TEST_15: ColorTestItem = {
  no: 15,
  choices: [45, 'nothing'],
  correctAnswer: 'nothing',
  confusionNumber: 45,
};

export const colorTestItems: ColorTestItem[][] = [
  [COLOR_TEST_01],
  [COLOR_TEST_02, COLOR_TEST_03],
  [COLOR_TEST_04, COLOR_TEST_05, COLOR_TEST_06, COLOR_TEST_07],
  [COLOR_TEST_08, COLOR_TEST_09],
  [COLOR_TEST_10],
  [COLOR_TEST_15],
];

export const FINE_ZONE = 5 / 6;
export const REDUCE_ZONE = 4 / 6;
