import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LottieModule } from 'ngx-lottie';
import { LottieOptions } from 'ngx-lottie/lib/symbols';

// Components & co
import { AppMaterialModule } from './app-material/app-material.module';
import { ActionButtonComponent } from './components/action-button/action-button.component';
import { AnimateCoverEyeComponent } from './components/animate-cover-eye/animate-cover-eye.component';
import { PizzaControlComponent } from './components/pizza-control/pizza-control.component';
import { PrepareNextTestComponent } from './components/prepare-next-test/prepare-next-test.component';
import { TestInstructionTextComponent } from './components/test-instruction-text/test-instruction-text.component';
import { StartContentSectionComponent } from './components/start-content-section/start-content-section.component';
import { TestCardComponent } from './components/test-card/test-card.component';
import { TestGroupComponent } from './components/test-group/test-group.component';
import { AppChannelDirective } from './directives/app-channel.directive';
import { AppChannelUnlessDirective } from './directives/app-channel-unless.directive';
import { AppWrapperComponent } from './components/app-wrapper/app-wrapper.component';
import { AcuityTestResultComponent } from './components/acuity-test-result/acuity-test-result.component';
import { ContrastTestResultComponent } from './components/contrast-test-result/contrast-test-result.component';
import { ColorTestResultComponent } from './components/color-test-result/color-test-result.component';
import { AstigmatismTestResultComponent } from './components/astigmatism-test-result/astigmatism-test-result.component';
import { AmslerTestResultComponent } from './components/amsler-test-result/amsler-test-result.component';
import { ResultCallToActionComponent } from './components/result-call-to-action/result-call-to-action.component';
import { ResultPanelTitleComponent } from './components/result-panel-title/result-panel-title.component';
import { ResultPanelDescriptionComponent } from './components/result-panel-description/result-panel-description.component';
import { CloseButtonComponent } from './components/close-button/close-button.component';
import { AppIconsModule } from '../app-icons/app-icons.module';
import { ImportantNotePageComponent } from './components/dialog-switch-wrapper/dialogs/important-note-page/important-note-page.component';
import { OvsHeaderComponent } from './components/ovs-header/ovs-header.component';
import { HowItWorksChecksGeneralComponent } from './components/dialog-switch-wrapper/dialogs/how-it-works-modal/how-it-works-checks-general/how-it-works-checks-general.component';
import { HowItWorksGeneralComponent } from './components/dialog-switch-wrapper/dialogs/how-it-works-modal/how-it-works-general/how-it-works-general.component';
import { CloseDialogComponent } from './components/close-dialog/close-dialog.component';
import { OvsHeaderInstructionsComponent } from './components/ovs-header-instructions/ovs-header-instructions.component';
import { OvsIndicatorDotsComponent } from './components/ovs-indicator-dots/ovs-indicator-dots.component';
import { NotAuthorizedComponent } from './components/not-authorized/not-authorized.component';
import { InstructionsPageComponent } from './pages/instructions-page/instructions-page.component';
import { CalibrateBrightnessComponent } from './pages/instructions-page/calibrate-brightness/calibrate-brightness.component';
import { CalibrateScreenWithCardComponent } from './pages/instructions-page/calibrate-screen-with-card/calibrate-screen-with-card.component';
import { WearYourGlassesComponent } from './pages/instructions-page/wear-your-glasses/wear-your-glasses.component';
import { GeneralInstructionsComponent } from './pages/instructions-page/general-instructions/general-instructions.component';
import { NgLetModule } from 'ng-let';

export function playerFactory() {
  return import('lottie-web');
}

@NgModule({
  imports: [
    CommonModule,
    AppMaterialModule,
    TranslocoModule,
    RouterModule,
    AppIconsModule,
    NgLetModule,
    LottieModule.forRoot({ player: playerFactory, useCache: true } as LottieOptions),
  ],
  declarations: [
    PizzaControlComponent,
    ActionButtonComponent,
    TestInstructionTextComponent,
    PrepareNextTestComponent,
    InstructionsPageComponent,
    AnimateCoverEyeComponent,
    StartContentSectionComponent,
    TestCardComponent,
    TestGroupComponent,
    AppChannelDirective,
    AppChannelUnlessDirective,
    AppWrapperComponent,
    AcuityTestResultComponent,
    ContrastTestResultComponent,
    ColorTestResultComponent,
    AstigmatismTestResultComponent,
    AmslerTestResultComponent,
    ResultCallToActionComponent,
    ResultPanelTitleComponent,
    ResultPanelDescriptionComponent,
    CloseButtonComponent,
    ImportantNotePageComponent,
    OvsHeaderComponent,
    HowItWorksChecksGeneralComponent,
    HowItWorksGeneralComponent,
    CloseDialogComponent,
    HowItWorksGeneralComponent,
    OvsHeaderInstructionsComponent,
    OvsIndicatorDotsComponent,
    NotAuthorizedComponent,
    CalibrateBrightnessComponent,
    CalibrateScreenWithCardComponent,
    WearYourGlassesComponent,
    GeneralInstructionsComponent,
  ],
  exports: [
    PizzaControlComponent,
    ActionButtonComponent,
    TestInstructionTextComponent,
    PrepareNextTestComponent,
    InstructionsPageComponent,
    AnimateCoverEyeComponent,
    StartContentSectionComponent,
    TestCardComponent,
    TestGroupComponent,
    AppChannelDirective,
    AppChannelUnlessDirective,
    AppWrapperComponent,
    AcuityTestResultComponent,
    ContrastTestResultComponent,
    ColorTestResultComponent,
    AstigmatismTestResultComponent,
    AmslerTestResultComponent,
    ResultCallToActionComponent,
    ResultPanelTitleComponent,
    ResultPanelDescriptionComponent,
    CloseButtonComponent,
    ImportantNotePageComponent,
    OvsHeaderComponent,
    HowItWorksChecksGeneralComponent,
    HowItWorksGeneralComponent,
    OvsHeaderInstructionsComponent,
    OvsIndicatorDotsComponent,
    NotAuthorizedComponent,
    CalibrateBrightnessComponent,
    CalibrateScreenWithCardComponent,
    WearYourGlassesComponent,
    GeneralInstructionsComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppSharedModule { }
