import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Stuff for general
import { AppSelectors } from '../store/selectors';

// Special for checks
import { AppChannel } from '../shared/enums';

@Injectable({
  providedIn: 'root'
})
export class AmslerTestGuard  {
  constructor(
    private router: Router,
    private store: Store,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.store
      .select(AppSelectors.getChannel)
      .pipe(
        map((channel) => {
          if (channel === AppChannel.US) {
            return this.router.parseUrl('/');
          }
          return true;
        })
      );
  }
}
