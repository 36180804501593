<ng-container *transloco="let t">
  <div class="color-check-card" *ngLet="(colorTest$ | async) as test">

    <div class="question">
      <div [@controlAnimation]="controlState" (@controlAnimation.done)="onControlDone($event)">
        <div *ngFor="let no of tests">
          <img *ngIf="test?.no === no" class="question-image" width="262" height="262" [src]="
                  getSanitizeUrl(testImages[test?.no]?.src) ||
                  environment.serverUrl+'/assets/img/color-plates/ishihara_' + test?.no + '.svg'"
            [alt]="'ishihara no.' + test?.no" />
        </div>
      </div>
      <div class="actions" [@controlAnimation]="controlState">
        <!-- Specify buttons for check-pattern -->
        <ng-container *ngFor="let choice of test?.choices; let i = index;">
          <zat-action-button [id]="'answerButton-' + i" *ngIf="choice !== 'nothing'" class="number-button"
            type="check-button" [iconType]="
                                    test?.correctAnswer === choice ? 'correct' : 'incorrect'
                                  " [animationState]="iconState" (animationStart)="onAnimationStart($event)"
            (animationDone)="onAnimationDone($event)" (action)="answer(choice)">
            {{ choice }}
          </zat-action-button>
        </ng-container>
        <!-- Default -->
        <zat-action-button id="answerButton-3" type="check-button" [iconType]="
                                  test?.correctAnswer === 'nothing' ? 'correct' : 'incorrect'
                                " [animationState]="iconState" (animationStart)="onAnimationStart($event)"
          (animationDone)="onAnimationDone($event)" (action)="answer('nothing')">
          <span class="nothing-button">{{ t('colorTest.nothing') }}</span>
        </zat-action-button>
      </div>
    </div>
  </div>
</ng-container>
