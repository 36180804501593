import { Action, createReducer, on } from '@ngrx/store';

// Stuff for general
import { TestTypeActions } from '../../../store/actions';

// Special for checks
import { ContrastActions } from '../actions';
import { StartContrastMegaTestAction, StartContrastSingleCheckAction } from '../actions/contrast.actions';
import { EyeContrastModel, getNewContrast, INITIAL_CONTRAST_IN_LOGCS, MAX_CONTRAST_TEST_DIRECTION_CHANGES, MAX_CONTRAST_TEST_REPEATS } from '../../shared';

// From shared folder
import { MAX_TEST_COUNT } from '../../../shared/config';
import { getNewLandoltCAngle } from '../../../shared/utils';
import { CHANGE_DIRECTION, C_ANGLES, Eyes, ScaleDirections } from '../../../shared/enums';

export const contrastFeatureKey = 'contrast';

export interface ContrastState {
  tests: {
    contrast: number;
    angle: number;
    count: number;
    activeEye: Eyes;
    lastScaleDirection?: ScaleDirections;
  };
  rightEyeContrast: EyeContrastModel;
  leftEyeContrast: EyeContrastModel;
}

export const initialState: ContrastState = {
  tests: {
    contrast: INITIAL_CONTRAST_IN_LOGCS,
    angle: C_ANGLES.RIGHT,
    count: 1,
    activeEye: Eyes.RIGHT
  },
  rightEyeContrast: {
    value: 0,
    finished: false,
    directionChangeCounter: 0,
    maxContrastStepCounter: 0,
    minContrastStepCounter: 0
  },
  leftEyeContrast: {
    value: 0,
    finished: false,
    directionChangeCounter: 0,
    maxContrastStepCounter: 0,
    minContrastStepCounter: 0
  }
};

const contrastReducer = createReducer(
  initialState,

  on(ContrastActions.Increase, (state) => {
    const newContrast = getNewContrast(
      state.tests.contrast,
      CHANGE_DIRECTION.PREVIOUS,
      state.tests.count
    );
    return {
      ...state,
      tests: {
        ...state.tests,
        contrast: newContrast
      }
    };
  }),

  on(ContrastActions.Decrease, (state) => {
    const newContrast = getNewContrast(
      state.tests.contrast,
      CHANGE_DIRECTION.NEXT,
      state.tests.count
    );
    return {
      ...state,
      tests: {
        ...state.tests,
        contrast: newContrast
      }
    };
  }),

  on(ContrastActions.IncreaseTestCount, (state) => {
    const updatedCount = state.tests.count + 1;
    const activeEye = state.tests.activeEye;

    if (activeEye === Eyes.RIGHT) {
      const finished =
        updatedCount > MAX_TEST_COUNT || state.rightEyeContrast.finished;

      return {
        ...state,
        tests: {
          ...state.tests,
          count: updatedCount
        },
        rightEyeContrast: {
          ...state.rightEyeContrast,
          finished
        }
      };
    } else {
      const finished =
        updatedCount > MAX_TEST_COUNT || state.leftEyeContrast.finished;

      return {
        ...state,
        tests: {
          ...state.tests,
          count: updatedCount
        },
        leftEyeContrast: {
          ...state.leftEyeContrast,
          finished
        }
      };
    }
  }),

  on(ContrastActions.ChangeCAngle, (state) => {
    const newCAngle = getNewLandoltCAngle(state.tests.angle);
    return {
      ...state,
      tests: {
        ...state.tests,
        angle: newCAngle
      }
    };
  }),

  on(ContrastActions.StoreValue, (state, { eye, value }) => {
    if (eye === Eyes.RIGHT) {
      return {
        ...state,
        rightEyeContrast: {
          ...state.rightEyeContrast,
          value
        }
      };
    } else {
      return {
        ...state,
        leftEyeContrast: {
          ...state.leftEyeContrast,
          value
        }
      };
    }
  }),

  on(ContrastActions.SetActiveEye, (state, { eye }) => ({
    ...state,
    tests: {
      ...state.tests,
      activeEye: eye
    }
  })),

  on(ContrastActions.ResetTests, (state) => ({
    ...state,
    tests: {
      ...state.tests,
      contrast: INITIAL_CONTRAST_IN_LOGCS,
      count: 1
    }
  })),

  on(TestTypeActions.ResetAllChecks, (state) => ({
    ...initialState
  })),

  on(ContrastActions.ResetEyeContrast, (state) => ({
    ...state,
    tests: {
      ...state.tests,
      activeEye: Eyes.RIGHT
    },
    rightEyeContrast: {
      value: 0,
      finished: false,
      directionChangeCounter: 0,
      maxContrastStepCounter: 0,
      minContrastStepCounter: 0
    },
    leftEyeContrast: {
      value: 0,
      finished: false,
      directionChangeCounter: 0,
      maxContrastStepCounter: 0,
      minContrastStepCounter: 0
    }
  })),

  on(ContrastActions.IncreaseMaxContrastStepCounter, (state, { eye }) => {
    if (eye === Eyes.RIGHT) {
      const updatedCounter = state.rightEyeContrast.maxContrastStepCounter + 1;
      const hasReachedMaxFailures = updatedCounter >= MAX_CONTRAST_TEST_REPEATS;
      const finished = state.rightEyeContrast.finished || hasReachedMaxFailures;

      return {
        ...state,
        rightEyeContrast: {
          ...state.rightEyeContrast,
          maxContrastStepCounter: updatedCounter,
          finished
        }
      };
    } else {
      const updatedCounter = state.leftEyeContrast.maxContrastStepCounter + 1;
      const hasReachedMaxFailures = updatedCounter >= MAX_CONTRAST_TEST_REPEATS;
      const finished = state.leftEyeContrast.finished || hasReachedMaxFailures;

      return {
        ...state,
        leftEyeContrast: {
          ...state.leftEyeContrast,
          maxContrastStepCounter: updatedCounter,
          finished
        }
      };
    }
  }),

  on(ContrastActions.IncreaseMinContrastStepCounter, (state, { eye }) => {
    if (eye === Eyes.RIGHT) {
      const updatedCounter = state.rightEyeContrast.minContrastStepCounter + 1;
      const hasReachedMaxFailures = updatedCounter >= MAX_CONTRAST_TEST_REPEATS;
      const finished = state.rightEyeContrast.finished || hasReachedMaxFailures;

      return {
        ...state,
        rightEyeContrast: {
          ...state.rightEyeContrast,
          minContrastStepCounter: updatedCounter,
          finished
        }
      };
    } else {
      const updatedCounter = state.leftEyeContrast.minContrastStepCounter + 1;
      const hasReachedMaxFailures = updatedCounter >= MAX_CONTRAST_TEST_REPEATS;
      const finished = state.leftEyeContrast.finished || hasReachedMaxFailures;

      return {
        ...state,
        leftEyeContrast: {
          ...state.leftEyeContrast,
          minContrastStepCounter: updatedCounter,
          finished
        }
      };
    }
  }),

  on(ContrastActions.ResetMaxContrastStepCounter, (state, { eye }) => {
    if (eye === Eyes.RIGHT) {
      return {
        ...state,
        rightEyeContrast: {
          ...state.rightEyeContrast,
          maxContrastStepCounter: 0
        }
      };
    } else {
      return {
        ...state,
        leftEyeContrast: {
          ...state.leftEyeContrast,
          maxContrastStepCounter: 0
        }
      };
    }
  }),

  on(ContrastActions.ResetMinContrastStepCounter, (state, { eye }) => {
    if (eye === Eyes.RIGHT) {
      return {
        ...state,
        rightEyeContrast: {
          ...state.rightEyeContrast,
          minContrastStepCounter: 0
        }
      };
    } else {
      return {
        ...state,
        leftEyeContrast: {
          ...state.leftEyeContrast,
          minContrastStepCounter: 0
        }
      };
    }
  }),

  on(ContrastActions.IncreaseDirectionChangeCounter, (state) => {
    if (state.tests.activeEye === Eyes.RIGHT) {
      const updatedCounter = state.rightEyeContrast.directionChangeCounter + 1;
      const hasReachedLimit =
        updatedCounter >= MAX_CONTRAST_TEST_DIRECTION_CHANGES;
      const finished = hasReachedLimit || state.rightEyeContrast.finished;

      return {
        ...state,
        rightEyeContrast: {
          ...state.rightEyeContrast,
          directionChangeCounter: updatedCounter,
          finished
        }
      };
    } else {
      const updatedCounter = state.leftEyeContrast.directionChangeCounter + 1;
      const hasReachedLimit =
        updatedCounter >= MAX_CONTRAST_TEST_DIRECTION_CHANGES;
      const finished = hasReachedLimit || state.leftEyeContrast.finished;

      return {
        ...state,
        leftEyeContrast: {
          ...state.leftEyeContrast,
          directionChangeCounter: updatedCounter,
          finished
        }
      };
    }
  }),

  // SingleStartActions
  on(StartContrastSingleCheckAction, (state, action): ContrastState => {
    return {
      ...initialState
    };
  }),

  // Mega-Check-Actions
  on(StartContrastMegaTestAction, (state, action): ContrastState => {
    return {
      ...initialState
    };
  })
);

export function reducer(state: ContrastState | undefined, action: Action): any {
  return contrastReducer(state, action);
}
