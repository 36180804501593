import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  catchError,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';

// Stuff for general
import { AppLocalizeRouterService } from '../../services/app-localize-router.service';
import {
  TestTypeActions,
  GeneralActions,
  CalibrationActions
} from '../../store/actions';
import { StoreLocatorService } from '../../services/store-locator.service';
import { AnalyticsService } from '../../services/analytics.service';
import { AppCookiesService } from '../../services/app-cookies.service';
import { SendResultsCompleteAction } from '../../store/actions/test-type.actions';
import { GeneralService } from '../../services/general.service';
import { SetBrightnessCalibrationStateDone } from '../../store/actions/set-brightness.actions';
import { AzureTableClientService } from '../../services/azure-table-client.service';
import {
  OpenAboutUsPageAction,
  OpenCheckSelectionPageAction,
  OpenFaqPageAction,
  OpenHomePageAction,
  OpenLegalDocumentsPageAction,
  SetScreenCalibrationAction,
  ShowMegaTestResultsAction,
  FinishInstructionsAction,
  StartCheckIntroductionsPageAction,
  CalibrationBrightnessSuccesAction,
  SaveAndGenerateResultCodeAction,
  SaveAndGenerateResultCodeSuccessAction,
  SaveAndGenerateResultCodeFailedAction
} from '../../store/actions/general.actions';
import {
  AfterAgreedImportantNotesAction,
  CloseImportantNotesAction
} from '../actions/check-calibration.actions';
import {
  getActiveTest,
  getAgreeImportantNotes,
  getMegaTestActive,
  getResultCode,
  isCalibrationDone
} from '../selectors/app.selectors';

// Special for checks
import {
  OpenAmslerDetailsPageAction,
  StartAmslerSingleCheckAction
} from '../../amsler-test/store/actions/amsler.actions';
import {
  OpenAstigmatismDetailsPageAction,
  StartAstigmatismSingleCheckAction
} from '../../astigmatism-test/store/actions/astigmatism.actions';
import {
  OpenColorDetailsPageAction,
  StartColorSingleCheckAction
} from '../../color-test/store/actions/color.actions';
import {
  OpenAcuityDetailsPageAction,
  StartAcuityMegaTestAction,
  StartAcuitySingleCheckAction
} from '../../acuity-test/store/actions/acuity.actions';
import {
  OpenContrastDetailsPageAction,
  StartContrastSingleCheckAction
} from '../../contrast-test/store/actions/contrast.actions';
import { AmslerSelectors } from '../../amsler-test/store';

// From shared folder
import { TestTypesForImportantNotes } from '../../shared/enums/test-types-important-notes.enum';
import { RoutePathes } from '../../shared/enums/route-pathes.enum';
import { TEST_TYPES } from '../../shared/enums/test-types';
import { Calibrations } from '../../shared/interfaces/calibrations.interface';
import {
  GA4_EventAction,
  GA4_EventDetail,
  GA4_EventName,
  GA4_EventType,
  GA4_EventValue
} from '../../shared/enums/ga4.enum';
import { ContentDownloadService } from '../../services/content-download.service';
import { AppInsightsService } from '../../services/app-insights.service';
import { ModalController } from '@ionic/angular';
import { ChubResultCodeScreenComponent } from '../../chub-result-code-screen/chub-result-code-screen.component';

@Injectable()
export class MegaEffects {
  constructor(
    private actions$: Actions,
    protected appCookiesService: AppCookiesService,
    protected localize: AppLocalizeRouterService,
    protected router: Router,
    private analytics: AnalyticsService,
    private generalService: GeneralService,
    private locatorService: StoreLocatorService,
    private tableService: AzureTableClientService,
    private store: Store,
    private contentService: ContentDownloadService,
    private logger: AppInsightsService,
    private modalController: ModalController
  ) {}

  clearMegaOnClearAll$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TestTypeActions.ClearAllTests),
      map(() => TestTypeActions.ClearMegaTest())
    );
  });

  sendLoggingEventForContinueWithoutCardCheck$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CalibrationActions.ContinueWithoutCardCheckAction),
        tap(() => {
          this.analytics.createCustomEvent({
            eventValue: 'calibrationwarning',
            eventDetail: 'continuewithoutcalibration'
          });
        })
      );
    },
    {
      dispatch: false
    }
  );

  prepareImportantNotes$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CalibrationActions.ShowImportantNotesAction),
        withLatestFrom(this.store.select(getAgreeImportantNotes)),
        tap(([action, agreedImportantNotes]: [any, boolean]) => {
          if (agreedImportantNotes) {
            this.store.dispatch(
              AfterAgreedImportantNotesAction({ testType: action.testType })
            );
          } else {
            this.generalService.routeToNextScreen(
              RoutePathes.PreparationImportant_Notes
            );
          }
        })
      );
    },
    {
      dispatch: false
    }
  );

  closeImportantNotes$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CloseImportantNotesAction),
        tap((action) => {
          this.generalService.routeToNextScreen(RoutePathes.Home);
        })
      );
    },
    {
      dispatch: false
    }
  );

  sendResultsComplete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SendResultsCompleteAction),
      switchMap(() =>
        combineLatest([
          this.store.select(getMegaTestActive).pipe(take(1)),
          this.store.select(AmslerSelectors.isFinished).pipe(take(1))
        ])
      ),
      map(([megaTestActive, amslerFinished]) => {
        const finishedEntireCheck = amslerFinished ? true : false;
        if (megaTestActive && !finishedEntireCheck) {
          return GeneralActions.ShowMegaTestResultsAction();
        } else {
          return void 0;
        }
      })
    );
  });

  agreeImportandNote$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CalibrationActions.AgreeImportantNotesAction),
      map((action) => {
        // Set cookie
        this.appCookiesService.setAcceptDisclaimers();

        return AfterAgreedImportantNotesAction({ testType: action.testType });
      })
    );
  });

  // TODO: Rework later to make it later redux-conform
  routeToTest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AfterAgreedImportantNotesAction),
      map((action) => {
        // Route to tests
        switch (action.testType) {
          case TestTypesForImportantNotes.ALL:
            return StartAcuityMegaTestAction();
          case TestTypesForImportantNotes.ACUITY:
            return StartAcuitySingleCheckAction();
          case TestTypesForImportantNotes.CONTRAST:
            return StartContrastSingleCheckAction();
          case TestTypesForImportantNotes.COLOR:
            return StartColorSingleCheckAction();
          case TestTypesForImportantNotes.ASTIGMATISM:
            return StartAstigmatismSingleCheckAction();
          case TestTypesForImportantNotes.AMSLER:
            return StartAmslerSingleCheckAction();
        }
      })
    );
  });

  /**
   * TODO: *REDUX* The routing itself should be moved into its own effects over time!
   *
   * The routing effects should be moved to a general.effect.ts or routing.effect.ts later when Feature Stores
   * are properly implemented in OVS.
   */
  goToStoreLocator$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GeneralActions.goToStoreLocatorAction),
        map(() => {
          return this.locatorService.getLocatorData();
        }),
        tap((token: string) => {
          this.router.navigate(
            this.localize.translateRoute([
              `${RoutePathes.StoreLocator}/${token}`
            ])
          );
        })
      ),
    { dispatch: false }
  );

  showMegaTestResults$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ShowMegaTestResultsAction),
        tap(() => {
          this.generalService.routeToNextScreen(RoutePathes.DefaultCheckResult);
        })
      ),
    { dispatch: false }
  );

  saveAndGenerateResultCode$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SaveAndGenerateResultCodeAction),
        tap(()=>{
          this.analytics.createCustomEvent({
            event: 'event',
            eventName: `${GA4_EventName.CTA}`,
            eventAction: `${GA4_EventAction.Click}`,
            eventType: `${GA4_EventType.Outbound}`,
            eventValue: `${GA4_EventValue.SaveResultMyZeissVision}`,
            eventDetail: `${GA4_EventDetail.ResultPage}`
          });
        }),
        switchMap(()=>{
          return this.store.select(getResultCode);
        }),
        switchMap((resultCode) => {
          // just pass on the resultCode if it already exists
          if(!!resultCode){
            return of(resultCode);
          }
          // get appropriate resultData from store according to what tests have been completed
          return this.contentService.collectResultData$();
        }),
        switchMap((resultDataOrCode:[object|string]) => {
          let resultData = undefined;
          // if it's string it is the code already, so just pass it on as if it was freshly generated
          if(typeof resultDataOrCode === 'string'){
            return of(resultDataOrCode);
          }
          // just for legebility
          resultData = resultDataOrCode;

          this.logger.info(
            '### resultData for resultCode generation: ' +
            JSON.stringify(resultData)
          );
          // POST resultData to cHub API and get resultCode
          return this.contentService.getResultCode$(resultData).pipe(
            // in case the API call fails or similar issue we need to handle failure without killing the effect
            catchError((err, caught) => {
              this.store.dispatch(SaveAndGenerateResultCodeFailedAction());
              return of(null);
            })
          );
        }),
        map((resultCode: string) => {
          this.logger.info('### resultCode: ', resultCode);
          if (resultCode) {
            this.store.dispatch(
              SaveAndGenerateResultCodeSuccessAction({ resultCode })
            );
          }
        })
      ),
    { dispatch: false }
  );

  SaveAndGenerateResultCodeSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SaveAndGenerateResultCodeSuccessAction),
        switchMap(() => {
          return this.modalController.create({
            component: ChubResultCodeScreenComponent,
            cssClass: 'chub-code-modal'
          })
        }),
        switchMap((modal: HTMLIonModalElement) => {
         return modal.present();
        })
      ),
    { dispatch: false }
  );

  SaveAndGenerateResultCodeFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SaveAndGenerateResultCodeFailedAction),
        tap(() => {
          // TODO: have more elegant failure handling here for the enduser
          alert(
            'Sorry, we failed to generate a result code for you :(. Try again later.'
          );
        })
      ),
    { dispatch: false }
  );

  nextForBrightnessDone$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SetBrightnessCalibrationStateDone),
        withLatestFrom(
          this.store.select(getActiveTest).pipe(take(1)),
          this.store.select(getMegaTestActive).pipe(take(1)),
          this.store.select(isCalibrationDone).pipe(take(1))
        ),
        tap(
          ([action, activeTest, isMegaTestActive, calibrations]: [
            any,
            TEST_TYPES,
            boolean,
            Calibrations
          ]) => {
            if (calibrations.cardCalibration.calibrationState) {
              if (isMegaTestActive) {
                this.generalService.routeToNextScreen(
                  RoutePathes.Default +
                    TEST_TYPES.ACUITY +
                    RoutePathes.CheckInstructions
                );
              } else {
                this.generalService.routeToNextScreen(
                  activeTest + RoutePathes.CheckInstructions
                );
              }
            } else {
              this.generalService.routeToNextScreen(
                RoutePathes.CalibrateCredit_Card
              );
            }
          }
        )
      );
    },
    { dispatch: false }
  );

  calibrationBrightnessSuccesAction$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CalibrationBrightnessSuccesAction),
        withLatestFrom(this.store.select(getActiveTest)),
        tap((action: any) => {
          this.analytics.createCustomEvent({
            event: 'event',
            eventName: `${GA4_EventName.CTA}`,
            eventAction: `${GA4_EventAction.Click}`,
            eventType: `${GA4_EventType.Internal}`,
            eventValue: `${GA4_EventValue.Instructions}`,
            eventDetail: `${0}`
          });
        })
      );
    },
    { dispatch: false }
  );

  nextForCardCalibrationDone$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SetScreenCalibrationAction),
        tap((action: any) => {
          this.appCookiesService.setCalibrateScreen(
            action.calibDevice,
            action.screenCalibration
          );
        })
      );
    },
    { dispatch: false }
  );

  openAboutUsPage$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(OpenAboutUsPageAction),
        tap((action: any) => {
          this.generalService.routeToNextScreen(RoutePathes.AboutUs);
        })
      );
    },
    { dispatch: false }
  );

  openHomePage$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(OpenHomePageAction),
        tap((action: any) => {
          this.generalService.routeToNextScreen(RoutePathes.Home);
        })
      );
    },
    { dispatch: false }
  );

  openLegalDocumentsPage$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(OpenLegalDocumentsPageAction),
        tap((action: any) => {
          this.generalService.routeToNextScreen(
            RoutePathes.AboutUs_LegalDocuments
          );
        })
      );
    },
    { dispatch: false }
  );

  openFaqPage$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(OpenFaqPageAction),
        tap((action: any) => {
          this.generalService.routeToNextScreen(RoutePathes.AboutUs_Faq);
        })
      );
    },
    { dispatch: false }
  );

  openCheckSelectionPage$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(OpenCheckSelectionPageAction),
        tap((action: any) => {
          this.generalService.routeToNextScreen(
            RoutePathes.Home_CheckSelection
          );
        })
      );
    },
    { dispatch: false }
  );

  openAcuityDetailsPage$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(OpenAcuityDetailsPageAction),
        tap((action: any) => {
          this.generalService.routeToNextScreen(RoutePathes.AcuityDetails);
        })
      );
    },
    { dispatch: false }
  );

  openContrastDetailsPage$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(OpenContrastDetailsPageAction),
        tap((action: any) => {
          this.generalService.routeToNextScreen(RoutePathes.ContrastDetails);
        })
      );
    },
    { dispatch: false }
  );

  openColorDetailsPage$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(OpenColorDetailsPageAction),
        tap((action: any) => {
          this.generalService.routeToNextScreen(RoutePathes.ColorDetails);
        })
      );
    },
    { dispatch: false }
  );

  openAstigmatismDetailsPage$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(OpenAstigmatismDetailsPageAction),
        tap((action: any) => {
          this.generalService.routeToNextScreen(RoutePathes.AstigmatismDetails);
        })
      );
    },
    { dispatch: false }
  );

  openAmslerDetailsPage$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(OpenAmslerDetailsPageAction),
        tap((action: any) => {
          this.generalService.routeToNextScreen(RoutePathes.AmslerDetails);
        })
      );
    },
    { dispatch: false }
  );

  finishInstructions$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FinishInstructionsAction),
        withLatestFrom(
          this.store.select(getActiveTest),
          this.store.select(getMegaTestActive)
        ),
        tap(
          ([action, activeTest, isMegaTestActive]: [
            any,
            TEST_TYPES,
            boolean
          ]) => {
            let path = ``;

            path = `/${activeTest}${RoutePathes.CheckStart}`;

            if (
              activeTest === TEST_TYPES.ASTIGMATISM ||
              activeTest === TEST_TYPES.AMSLER
            ) {
              path = `${path}/${RoutePathes.Right}`;
            }

            if (isMegaTestActive) {
              path = `${RoutePathes.Default}/${path}`;
            }

            // set partition key for Acuity and Contrast checks
            if (
              activeTest === TEST_TYPES.ACUITY ||
              activeTest === TEST_TYPES.CONTRAST
            ) {
              const now = new Date();
              this.tableService.initAnalysisData(
                `${activeTest}.${now.getTime()}`
              );
            }

            this.generalService.routeToNextScreen(path);
          }
        )
      );
    },
    { dispatch: false }
  );

  startCheckIntroduktionsPage$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(StartCheckIntroductionsPageAction),
        withLatestFrom(
          this.store.select(getActiveTest),
          this.store.select(getMegaTestActive)
        ),
        tap(
          ([action, activeTest, isMegaTestActive]: [
            any,
            TEST_TYPES,
            boolean
          ]) => {
            let route: string = '';
            if (isMegaTestActive) {
              route = RoutePathes.Default + '/';
            }
            route += activeTest + RoutePathes.CheckInstructions;
            this.generalService.routeToNextScreen(route);
          }
        )
      );
    },
    { dispatch: false }
  );
}
