import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';

// Stuff for general
import { StoreLocatorRoutingModule } from './store-locator-routing.module';
import { LocatorPageComponent } from './locator-page/locator-page.component';

// From shared folder
import { AppSharedModule } from '../shared/app-shared.module';

@NgModule({
  declarations: [LocatorPageComponent],
  imports: [
    CommonModule,
    StoreLocatorRoutingModule,
    AppSharedModule,
    TranslocoModule,
  ]
})
export class StoreLocatorModule { }
