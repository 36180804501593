import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

// From shared folder
import { appChannelMap, DEFAULT_LOCALE } from '../shared/app-channels';

@Injectable({
  providedIn: 'root'
})
export class LocaleGuard  {
  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const locale = route.paramMap.get('locale');
    if (!locale) {
      // this will fallback to the default locale handled by AppRoutingModule
      return true;
    }

    // redirect to the default locale if the param is in an invalid pattern
    const isLocaleValid = this.validateLocale(locale);
    if (!isLocaleValid) {
      return this.router.navigate([`/${DEFAULT_LOCALE}`]);
    }

    // fix incorrect cases of language and country code in the locale.
    // this forces `en-us`, `EN-US`, or `eN-uS` to be `en-US`
    const [language, country] = locale.split('-');
    if (
      language !== language.toLowerCase() ||
      country !== country.toUpperCase()
    ) {
      return this.router.navigate([
        `/${language.toLowerCase()}-${country.toUpperCase()}`
      ]);
    }

    // the locale is properyly formatted,
    // but if it is not one of our supported locales & channel configurations,
    // then redirect to the default locale `en-INT`.
    if (!appChannelMap.has(locale)) {
      return this.router.navigate([`/${DEFAULT_LOCALE}`]);
    }

    return true;
  }

  private validateLocale(locale: string): boolean {
    // allowed: en-us, en-US, EN-US, eN-uS, en-INT
    const pattern = /^[a-zA-Z]{2}-[a-zA-Z]{2,3}$/;
    return pattern.test(locale);
  }
}
