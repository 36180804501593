import { Action, createReducer, on } from '@ngrx/store';

// Stuff for general
import { TestTypeActions } from '../../../store/actions';

// Special for checks
import { AstigmatismActions } from '../actions';
import { StartAstigmatismMegaTestSuccedAction, StartAstigmatismSingleCheckAction } from '../actions/astigmatism.actions';

// From shared folder
import { Answer, Eyes } from '../../../shared/enums';

export const astigmatismFeatureKey = 'astigmatism';

export interface AstigmatismState {
  tests: {
    activeEye: Eyes;
  };
  rightEyeAstigmatism: {
    finished: boolean;
    answer?: Answer;
  };
  leftEyeAstigmatism: {
    finished: boolean;
    answer?: Answer;
  };
}

export const initialState: AstigmatismState = {
  tests: {
    activeEye: Eyes.RIGHT
  },
  rightEyeAstigmatism: {
    finished: false
  },
  leftEyeAstigmatism: {
    finished: false
  }
};

const astigmatismReducer = createReducer(
  initialState,

  on(AstigmatismActions.ResetEyeAstigamatism, (state) => ({
    ...state,
    rightEyeAstigmatism: {
      finished: false,
      answer: undefined
    },
    leftEyeAstigmatism: {
      finished: false,
      answer: undefined
    },
  })),

  on(TestTypeActions.ResetAllChecks, (state) => ({
    ...initialState
  })),

  on(AstigmatismActions.ResetTests, (state) => ({
    ...state,
    tests: {
      activeEye: Eyes.RIGHT
    },
  })),

  on(AstigmatismActions.SubmitAnswer, (state, { answer }) => {
    if (state.tests.activeEye === Eyes.RIGHT) {
      return {
        ...state,
        rightEyeAstigmatism: {
          ...state.rightEyeAstigmatism,
          answer,
          finished: true
        }
      };
    } else {
      return {
        ...state,
        leftEyeAstigmatism: {
          ...state.leftEyeAstigmatism,
          answer,
          finished: true
        }
      };
    }
  }),

  on(AstigmatismActions.SetActiveEye, (state, { eye }) => ({
    ...state,
    tests: {
      ...state.tests,
      activeEye: eye
    }
  })),

  on(AstigmatismActions.SetAstiAnswersFromDate, (state, {leftEyeAnswer, rightEyeAnswer}) => ({
    ...state,
    rightEyeAstigmatism: {
      finished: true,
      answer: rightEyeAnswer,
    },
    leftEyeAstigmatism: {
      finished: true,
      answer: leftEyeAnswer,
    }
  })),

  // SingleStartActions
  on(StartAstigmatismSingleCheckAction, (state, action): AstigmatismState => {
    return {
      ...initialState
    };
  }),

  // Mega-Check-Actions
  on(StartAstigmatismMegaTestSuccedAction, (state, action): AstigmatismState => {
    return {
      ...initialState
    };
  })
);

export function reducer(state: AstigmatismState | undefined, action: Action): any {
  return astigmatismReducer(state, action);
}
