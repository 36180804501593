import { CUSTOM_ELEMENTS_SCHEMA, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from '../../environments/environment';
import 'img-comparison-slider';

@Component({
  selector: 'app-ovs-comparison-slider',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './ovs-comparison-slider.component.html',
  styleUrls: ['./ovs-comparison-slider.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})

export class OvsComparisonSliderComponent {
  @Input() srcImageNameOne: string = "";
  @Input() srcImageNameTwo: string = "";
  @Input() altTextOne: string = "";
  @Input() altTextTwo: string = "";

  public environment = environment;
}
