import { Action, createReducer, on } from '@ngrx/store';

// Stuff for general
import { TestTypeActions } from '../../../store/actions';

// Special for checks
import { getVisualAcuity, INITIAL_VA, MAX_ACUITY_DIRECTION_CHANGES } from '../../shared';
import { AcuityActions } from '../actions';
import { StartAcuityMegaTestAction, StartAcuitySingleCheckAction } from '../actions/acuity.actions';

// From shared folder
import { getNewLandoltCAngle } from '../../../shared/utils';
import { MAX_TEST_COUNT } from '../../../shared/config';
import { C_ANGLES, Eyes, ScaleDirections } from '../../../shared/enums';

export const acuityFeatureKey = 'acuity';

interface EyeAcuityModel {
  value: number;
  directionChangeCounter: number;
  highestStepCounter: number;
  smallestStepCounter: number;
  finished: boolean;
}

export interface AcuityState {
  tests: {
    visualAcuity: number;
    angle: number;
    count: number;
    activeEye: Eyes;
    lastScaleDirection?: ScaleDirections;
  };
  rightEyeAcuity: EyeAcuityModel;
  leftEyeAcuity: EyeAcuityModel;
}

export const initialState: AcuityState = {
  tests: {
    visualAcuity: INITIAL_VA,
    angle: C_ANGLES.TOP,
    count: 1,
    activeEye: Eyes.RIGHT
  },
  rightEyeAcuity: {
    value: undefined,
    finished: false,
    directionChangeCounter: 0,
    highestStepCounter: 0,
    smallestStepCounter: 0
  },
  leftEyeAcuity: {
    value: undefined,
    finished: false,
    directionChangeCounter: 0,
    highestStepCounter: 0,
    smallestStepCounter: 0
  }
};

const acuityReducer = createReducer(
  initialState,

  on(AcuityActions.IncreaseCount, (state) => {
    // should check here if the test is finished by this case?
    const updatedCount = state.tests.count + 1;
    const activeEye = state.tests.activeEye;

    let finished;
    if (activeEye === Eyes.RIGHT) {
      finished = updatedCount > MAX_TEST_COUNT || state.rightEyeAcuity.finished;
      return {
        ...state,
        tests: {
          ...state.tests,
          count: updatedCount
        },
        rightEyeAcuity: {
          ...state.rightEyeAcuity,
          finished
        }
      };
    }

    finished = updatedCount > MAX_TEST_COUNT || state.leftEyeAcuity.finished;
    return {
      ...state,
      tests: {
        ...state.tests,
        count: updatedCount
      },
      leftEyeAcuity: {
        ...state.leftEyeAcuity,
        finished
      }
    };
  }),

  on(AcuityActions.ResetTests, (state) => ({
    ...state,
    tests: {
      ...state.tests,
      visualAcuity: INITIAL_VA,
      count: 1,
      lastScaleDirection: undefined
    }
  })),

  on(AcuityActions.ResetsEyeAcuity, (state) => ({
    ...state,
    tests: {
      ...state.tests,
      activeEye: Eyes.RIGHT
    },
    rightEyeAcuity: {
      finished: false,
      value: undefined,
      directionChangeCounter: 0,
      highestStepCounter: 0,
      smallestStepCounter: 0
    },
    leftEyeAcuity: {
      finished: false,
      value: undefined,
      directionChangeCounter: 0,
      highestStepCounter: 0,
      smallestStepCounter: 0
    }
  })),
  on(TestTypeActions.ResetAllChecks, (state) => ({
    ...initialState
  })),

  on(AcuityActions.SetActiveEye, (state, { eye }) => ({
    ...state,
    tests: {
      ...state.tests,
      activeEye: eye
    }
  })),

  on(AcuityActions.Increase, (state) => {
    const newVA = getVisualAcuity(
      state.tests.visualAcuity,
      'up',
      state.tests.count
    );
    const angle = getNewLandoltCAngle(state.tests.angle);
    return {
      ...state,
      tests: {
        ...state.tests,
        angle,
        visualAcuity: newVA
      }
    };
  }),

  on(AcuityActions.Decrease, (state) => {
    const newVA = getVisualAcuity(
      state.tests.visualAcuity,
      'down',
      state.tests.count
    );
    const angle = getNewLandoltCAngle(state.tests.angle);
    return {
      ...state,
      tests: {
        ...state.tests,
        angle,
        visualAcuity: newVA
      }
    };
  }),

  on(AcuityActions.StoreValue, (state, { eye, value }) => {
    if (eye === Eyes.RIGHT) {
      return {
        ...state,
        rightEyeAcuity: {
          ...state.rightEyeAcuity,
          value
        }
      };
    }

    return {
      ...state,
      leftEyeAcuity: {
        ...state.leftEyeAcuity,
        value
      }
    };
  }),

  on(AcuityActions.RandomizeAngle, (state) => {
    const angle = getNewLandoltCAngle(state.tests.angle);
    return {
      ...state,
      tests: {
        ...state.tests,
        angle
      }
    };
  }),

  on(AcuityActions.SetScaleDirection, (state, { direction }) => ({
    ...state,
    tests: {
      ...state.tests,
      lastScaleDirection: direction
    }
  })),

  on(AcuityActions.IncreaseStepChangeCount, (state, { eye }) => {
    let newCounter;
    let finished;
    if (eye === Eyes.RIGHT) {
      newCounter = state.rightEyeAcuity.directionChangeCounter + 1;
      finished =
        newCounter >= MAX_ACUITY_DIRECTION_CHANGES ||
        state.rightEyeAcuity.finished;
      return {
        ...state,
        rightEyeAcuity: {
          ...state.rightEyeAcuity,
          directionChangeCounter: newCounter,
          finished
        }
      };
    }

    newCounter = state.leftEyeAcuity.directionChangeCounter + 1;
    finished =
      newCounter >= MAX_ACUITY_DIRECTION_CHANGES ||
      state.leftEyeAcuity.finished;
    return {
      ...state,
      leftEyeAcuity: {
        ...state.leftEyeAcuity,
        directionChangeCounter: newCounter,
        finished
      }
    };
  }),

  on(AcuityActions.IncreaseHighestStepCounter, (state, { eye }) => {
    const MAX_FAILURES = 3;

    let newCounter;
    let finished;
    if (eye === Eyes.RIGHT) {
      newCounter = state.rightEyeAcuity.highestStepCounter + 1;
      finished = state.rightEyeAcuity.finished || newCounter >= MAX_FAILURES;

      return {
        ...state,
        rightEyeAcuity: {
          ...state.rightEyeAcuity,
          highestStepCounter: newCounter,
          finished
        }
      };
    }

    newCounter = state.leftEyeAcuity.highestStepCounter + 1;
    finished = state.leftEyeAcuity.finished || newCounter >= MAX_FAILURES;
    return {
      ...state,
      leftEyeAcuity: {
        ...state.leftEyeAcuity,
        highestStepCounter: newCounter,
        finished
      }
    };
  }),

  on(AcuityActions.IncreaseSmallestStepCounter, (state, { eye }) => {
    const MAX_FAILURES = 3;

    let newCounter;
    let finished;
    if (eye === Eyes.RIGHT) {
      newCounter = state.rightEyeAcuity.smallestStepCounter + 1;
      finished = state.rightEyeAcuity.finished || newCounter >= MAX_FAILURES;

      return {
        ...state,
        rightEyeAcuity: {
          ...state.rightEyeAcuity,
          smallestStepCounter: newCounter,
          finished
        }
      };
    }

    newCounter = state.leftEyeAcuity.smallestStepCounter + 1;
    finished = state.leftEyeAcuity.finished || newCounter >= MAX_FAILURES;
    return {
      ...state,
      leftEyeAcuity: {
        ...state.leftEyeAcuity,
        smallestStepCounter: newCounter,
        finished
      }
    };
  }),

  on(AcuityActions.ResetSmallestStepCounter, (state, { eye }) => {
    if (eye === Eyes.RIGHT) {
      return {
        ...state,
        rightEyeAcuity: {
          ...state.rightEyeAcuity,
          smallestStepCounter: 0
        }
      };
    }

    return {
      ...state,
      leftEyeAcuity: {
        ...state.leftEyeAcuity,
        smallestStepCounter: 0
      }
    };
  }),

  // SingleStartActions
  on(StartAcuitySingleCheckAction, (state, action): AcuityState => {
    return {
      ...initialState
    };
  }),

  // Mega-Check-Actions
  on(StartAcuityMegaTestAction, (state, action): AcuityState => {
    return {
      ...initialState
    };
  })
);

export function reducer(state: AcuityState | undefined, action: Action): any {
  return acuityReducer(state, action);
}
