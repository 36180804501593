<div class="loading-page">
  <div class="loading-spinner">
    <div class="cubes-container" [style.width.px]="cubesContainerMap[size]" [style.height.px]="cubesContainerMap[size]">
      <div class="cubes-container__cube" [style.width.px]="cubeSizeMap[size]" [style.height.px]="cubeSizeMap[size]">
      </div>

      <div class="cubes-container__cube cubes-container__cube--delayed" [style.width.px]="cubeSizeMap[size]"
        [style.height.px]="cubeSizeMap[size]"></div>
    </div>

    <label class="label" [style.font-size.px]="fontSizeMap[size]" *ngIf="label">
      {{ label }}
    </label>
  </div>
</div>
