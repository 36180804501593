<div class="va-content--recommend" *transloco="let t" [class.is-us]="isUSChannel">
  <app-icon class="va-cta-image" id="callToAction" [name]="'call_to_action'" [width]="144" [height]="121"
    alt="">
  </app-icon>

  <div>
    <p class="va-description va-description-mb text-body" [innerHTML]="t('common.furtherConsultation')"></p>
    <div *ngIf="isFRChannel" class="is-fr">
    <span class="description-title">
      Le saviez-vous?
    </span>
    <p class="va-description">
      Le renouvellement et l’adaptation de prescription de lunettes et lentilles est possible par votre orthoptiste et/ou votre opticien*.
      <br/><br/>
      Pour vos lunettes, selon votre âge :<br/>
      - moins de 16 ans, durée de validité de l’ordonnance médicale : 1 an
      <br/>
      - 16 à 42 ans, durée de validité de l'ordonnance médicale : 5 ans (2 ans pour l'ordonnance orthoptique).
      <br/>
      - A partir de 43 ans, durée de validité de l’ordonnance médicale : 3 ans
      <br/><br/>
      Pour vos lentilles :<br/>
      - moins de 16 ans, durée de validité de l’ordonnance médicale : 1 an
      <br/>
      - 16 ans et plus : durée de validité d’une prescription médicale : 3 ans (2 ans pour une prescription orthoptique)
      <br/><br/>
      Si votre ordonnance n’est plus valable ou pour toute première prescription, prenez RDV chez un ophtalmologiste (ou un orthoptiste si vous avez entre 16-42 ans, sauf contre-indications).
      <br/>
      *sauf contre-indications notées sur votre ordonnance
    </p>
  </div>

  <div class="call-to-actions" *ngIf="isCurrentlyOnline()">
    <zat-action-button type="outline" size="small-medium" data-testid="result-go-to-store-locator-button"
      (click)="goToStoreLocator()">
      {{ t('button.showNearbyExpert') }}
    </zat-action-button>
  </div>
</div>
