<div class="close-dialog-page" *transloco="let t">
  <div class="close-dialog-content">
    <h2 class="headline" aria-label="hidden">{{ t('closeWarning.' + context + '.headline') }}</h2>
    <p class="message">{{ t('closeWarning.' + context + '.message') }}</p>

    <div class="close-dialog-buttons">
      <zat-action-button type="outline" data-testid="close-warning-close" (click)="goToHomePage()">
        {{ context == CloseDialogContext.Check ? t('button.close') : t('button.yes') }}
      </zat-action-button>
      <zat-action-button class="ovs-to-top" type="solid" data-testid="close-warning-continue" (click)="returnToPreviousPage()">
        {{ context == CloseDialogContext.Check ? t('button.continue') : t('button.no') }}
      </zat-action-button>
    </div>
  </div>
</div>
