import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { NgLetModule } from 'ng-let';

// Special for components & co
import { ColorTestRoutingModule } from './color-test-routing.module';
import { ColorDetailsPageComponent } from './color-details-page/color-details-page.component';
import { ColorTestPageComponent } from './color-test-page/color-test-page.component';
import { ColorTestComponent } from './color-test/color-test.component';
import { ColorResultPageComponent } from './color-result-page/color-result-page.component';
import { fromColor } from './store';
import { ColorEffects } from './store/effects/color.effect';
import { AppIconsModule } from '../app-icons/app-icons.module';
import { HowItWorksColorPageComponent } from './color-test-page/how-it-works-color-page/how-it-works-color-page.component';

// From shared folder
import { AppMaterialModule } from '../shared/app-material/app-material.module';
import { AppSharedModule } from '../shared/app-shared.module';

import { OvsComparisonSliderComponent } from '../ovs-comparison-slider/ovs-comparison-slider.component';

@NgModule({
  declarations: [
    ColorDetailsPageComponent,
    ColorTestPageComponent,
    ColorTestComponent,
    ColorResultPageComponent,
    HowItWorksColorPageComponent
  ],
  imports: [
    CommonModule,
    TranslocoModule,
    AppMaterialModule,
    ColorTestRoutingModule,
    AppSharedModule,
    StoreModule.forFeature('color', fromColor.reducer),
    EffectsModule.forFeature([ ColorEffects ]),
    NgLetModule,
    AppIconsModule,
    OvsComparisonSliderComponent
  ]})
export class ColorTestModule {}
