import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'zat-start-content-section',
  templateUrl: './start-content-section.component.html',
  styleUrls: ['./start-content-section.component.scss']
})
export class StartContentSectionComponent implements OnInit {

  windowWidth: number;

  ngOnInit(): void {
    this.windowWidth = window.innerWidth;
  }

}
