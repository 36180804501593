import { TEST_RESULT } from '../../../shared/enums';
import { EXCELLENT_ZONE, FINE_ZONE } from '../config/index';

export function getTestResult(value: number): TEST_RESULT {
  if (value <= EXCELLENT_ZONE) {
    return TEST_RESULT.EXCELLENT;
  }

  if (value <= FINE_ZONE) {
    return TEST_RESULT.FINE;
  }

  return TEST_RESULT.REDUCED;
}
