import { GeneralService } from '../../../../services/general.service';
import { Instruction } from '../../../enums/instructions.enum';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, map, Observable, Subject, takeUntil, tap } from 'rxjs';
import { Eyes } from '../../../enums';
import { AnimationOptions } from 'ngx-lottie';
import { LottieAnimationEnum } from '../../../enums/lottie.enum';

@Component({
  selector: 'app-general-instructions',
  templateUrl: './general-instructions.component.html',
  styleUrls: ['./general-instructions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeneralInstructionsComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input('instruction') instruction: Instruction = Instruction.COVER_LEFT_EYE;
  instructions = Instruction;
  isDesktop$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  destroy$: Subject<void> = new Subject();

  Eyes = Eyes;

  optionsAcuity: AnimationOptions = null;

  optionsContrast: AnimationOptions = null;

  // rootRef references this exact component
  constructor(
    private generalService: GeneralService,
    private rootRef: ElementRef,
    private zone: NgZone
  ) { }

  ngOnInit(): void {
    // Set the lottie-options
    this.optionsAcuity = this.generalService.getLottieOptions(LottieAnimationEnum.VaCheck);
    this.optionsContrast = this.generalService.getLottieOptions(LottieAnimationEnum.ContrastCheck);
  }

  ngAfterViewInit(): void {
    this.observeResize();
  }

  observeResize(): void {
    // resize observer to determine isDesktop
    // (previously done via break-point observer but that odes not work for embedded webComponent)
    let rootSize$: Observable<{ width: number; height: number }> =
      new Observable((observer) => {
        const resizeObserver = new ResizeObserver((entries) => {
          this.zone.run(() => {
            observer.next({
              width: entries[0].contentRect.width,
              height: entries[0].contentRect.height
            });
          });
        });
        resizeObserver.observe(this.rootRef?.nativeElement);
        return () => {
          resizeObserver.disconnect();
          observer.complete();
        };
      });

      // we can only instantiate this in ngAfterViewInit() because we need the view element for the resizeObs above first
      // but we also want to subscribe to isDesktop$ form the start (in html), thus we need to next on it
      rootSize$
        .pipe(
          map((elementSize) => {
            return elementSize.width >= 768;
          }),
          tap((val) => {
            this.isDesktop$.next(val);
          }),
          takeUntil(this.destroy$)
        )
        .subscribe();
  }

  // DO NOT DELETE
  // this method needs to be here for `untilDestroyed()` to work
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
