import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, delay, map, Observable, take, tap } from 'rxjs';
import { Location } from '@angular/common';

// Stuff for general
import { environment } from '../../../environments/environment';
import { AppSelectors } from '../../store/selectors';
import { SendResultsAction } from '../../store/actions/test-type.actions';
import { ShowMegaTestResultsAction } from '../../store/actions/general.actions';
import {
  getSpecialScreeningMode,
  getMegaTestActive
} from '../../store/selectors/app.selectors';

// Special for checks
import { AstigmatismActions, AstigmatismSelectors } from '../store';
import { AstigmatismLevel } from '../shared/enums/astigmatism-level.enum';
import { StartAmslerMegaTestAction } from '../../amsler-test/store/actions/amsler.actions';

// From shared folder
import { AppChannel } from '../../shared/enums';
import { CloseDialogContext } from '../../shared/components/close-dialog/close-dialog-context.enum';
import {
  GA4_EventDetail,
  GA4_EventAction,
  GA4_EventName,
  GA4_EventType,
  GA4_EventValue
} from '../../shared/enums/ga4.enum';
import { HeaderConfig } from '../../shared/interfaces/header-config.interface';
import {
  HeaderColorEnum,
  HeaderLabelEnum,
  HeaderEnum
} from '../../shared/enums/header.enum';
import { fadeInAnimation } from '../../shared/animations';
import { SpecialScreeningMode } from '../../shared/enums/special-screening-mode.enum';
import { TranslocoService } from '@ngneat/transloco';
import { AnalyticsService } from '../../services/analytics.service';
import { GeneralService } from '@app-services/general.service';
import { AppInsightsService } from '../../services/app-insights.service';

@Component({
  selector: 'zat-astigmatism-result-page',
  templateUrl: './astigmatism-result-page.component.html',
  styleUrls: ['./astigmatism-result-page.component.scss'],
  animations: [fadeInAnimation]
})
export class AstigmatismResultPageComponent implements OnInit, OnDestroy {
  specialScreeningMode$: Observable<SpecialScreeningMode> = this.store.select(
    getSpecialScreeningMode
  );
  SpecialScreeningMode = SpecialScreeningMode;

  resultString: string = null;

  public result$: Observable<AstigmatismLevel>;
  public image$: Observable<any>;
  locale$: Observable<string> = this.store.select(AppSelectors.getLocale);
  public AppChannel = AppChannel;
  public isWebcomponent: boolean = environment.webcomponent;
  public isChubGoLive:boolean = environment.chubGoLiveToggle;
  public showSaveFeature$ = this.generalService.showImportSaveOption$();
  public isUSMode$: Observable<boolean> = this.generalService.hideLastTest$().pipe(
    tap((isUS) => {
      let maxTestAmount = isUS ? 4 : 5;
      this.megaTestList$.next(maxTestAmount);
    }));
  public isMegaTestActive$: Observable<boolean> = this.store.select(getMegaTestActive);
  public megaTestList$: BehaviorSubject<number> = new BehaviorSubject<number>(undefined);

  headerConfig: HeaderConfig = {
    label: HeaderLabelEnum.SingleCheckResult,
    left: HeaderEnum.Empty,
    right: HeaderEnum.Close,
    theme: HeaderColorEnum.LightMode
  } as HeaderConfig;

  // trigger close warning dialog when browser back button is clicked
  @HostListener('window:popstate', ['$event'])
  onPopState() {
    if (!environment.webcomponent) {
      history.pushState(null, null, location.href);
      this.triggerCloseWarning();
    }
  }

  constructor(
    private store: Store,
    private logger: AppInsightsService,
    private locationService: Location,
    private transloco: TranslocoService,
    private analytics: AnalyticsService,
    private generalService: GeneralService
  ) {
    history.pushState(null, null, location.href);
  }

  ngOnInit(): void {
    this.result$ = this.store.select(AstigmatismSelectors.getTestResult);
    this.image$ = this.store.select(AstigmatismSelectors.getResultImage).pipe(
      take(1),
      tap((img) => {
        this.resultString = `Left: ${img.leftEye} - Right: ${img.rightEye}`;
      })
    );
  }

  ngAfterViewInit(): void {
    // Fire the event
    this.analytics.createCustomEvent({
      event: 'event',
      eventName: `${GA4_EventName.CTA}`,
      eventAction: `${GA4_EventAction.View}`,
      eventType: `${GA4_EventType.Astigmatism} Test`,
      eventValue: `${GA4_EventValue.Result}`,
      eventDetail: `${this.resultString}`
    });
  }

  ngOnDestroy(): void {}

  goToResultSummary(): void {
    this.analytics.createCustomEvent({
      event: 'event',
      eventName: `${GA4_EventName.CTA}`,
      eventAction: `${GA4_EventAction.Click}`,
      eventType: `${GA4_EventType.Internal}`,
      eventValue: `${GA4_EventValue.ShowResults}`,
      eventDetail: `${GA4_EventDetail.ResultPage} - ${GA4_EventDetail.Astigmatism}`
    });

    this.store.dispatch(ShowMegaTestResultsAction());
  }

  back(): void {
    this.triggerCloseWarning();
  }

  nextTest(): void {
    this.analytics.createCustomEvent({
      event: 'event',
      eventName: `${GA4_EventName.CTA}`,
      eventAction: `${GA4_EventAction.Click}`,
      eventType: `${GA4_EventType.Internal}`,
      eventValue: `${GA4_EventValue.ContinueTo}${GA4_EventValue.Amsler}`,
      eventDetail: `${GA4_EventDetail.ResultPage} - ${GA4_EventDetail.Astigmatism}`
    });

    this.store.dispatch(StartAmslerMegaTestAction());
  }

  sendResults(): void {
    this.analytics.createCustomEvent({
      event: 'event',
      eventName: `${GA4_EventName.CTA}`,
      eventAction: `${GA4_EventAction.Click}`,
      eventType: `${GA4_EventType.Internal}`,
      eventValue: `${GA4_EventValue.SaveResults}`,
      eventDetail: `${GA4_EventDetail.ResultPage} - ${GA4_EventDetail.Astigmatism}`
    });

    this.logger.info('### Send result', 'Astigmatism');
    this.store.dispatch(SendResultsAction());
  }

  triggerCloseWarning(): void {
    this.analytics.createCustomEvent({
      event: 'event',
      eventName: `${GA4_EventName.CTA}`,
      eventAction: `${GA4_EventAction.Click}`,
      eventType: `${GA4_EventType.Internal}`,
      eventValue: `${GA4_EventValue.ExitResultPage}`,
      eventDetail: `${GA4_EventDetail.ResultPage} - ${GA4_EventDetail.Astigmatism}`
    });

    this.specialScreeningMode$
      .pipe(delay(0), take(1))
      .subscribe((mode: SpecialScreeningMode) => {
        this.store.dispatch(
          AstigmatismActions.ShowCloseWarningAction({
            context:
              mode === SpecialScreeningMode.ECP
                ? CloseDialogContext.ResultECP
                : CloseDialogContext.ResultNotECP,
            previousPath: this.locationService.path()
          })
        );
      });
  }
}
