<img-comparison-slider class="image-comparison-slider" value="50">
  <img id="acuityNormalVision" slot="first"
    src="{{environment.serverUrl}}/assets/img/comparisons/{{srcImageNameOne}}" 
    [alt]="altTextOne"
    [width]="375" [height]="285" />
    <img id="acuityBlurredVision" slot="second"
     src="{{environment.serverUrl}}/assets/img/comparisons/{{srcImageNameTwo}}"
     [alt]="altTextTwo"
     [width]="375" [height]="285" />
     <div slot="handle" class="handle">
      <svg class="handle-arrow handle-arrow-left" xmlns="http://www.w3.org/2000/svg" width="15.557"
        height="28.293" viewBox="0 0 15.557 28.293">
        <path d="M1.414 12.737L15.557 26.88l-1.415 1.415L0 14.15z" />
        <path d="M0 14.142L14.142-.001l1.415 1.415L1.414 15.556z" />
      </svg>
      <svg class="handle-arrow handle-arrow-right" xmlns="http://www.w3.org/2000/svg" width="15.557"
        height="28.294" viewBox="0 0 15.557 28.294">
        <path d="M14.143 12.738L0 26.88l1.414 1.414 14.142-14.142z" />
        <path d="M15.556 14.142L1.414 0 0 1.414l14.142 14.142z" />
      </svg>
    </div>
</img-comparison-slider>