import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

// Stuff for general
import { AppLocalizeRouterService } from '../../services/app-localize-router.service';
import { OpenHomePageAction } from '../../store/actions/general.actions';

@Component({
  selector: 'zat-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotFoundPageComponent implements OnInit {
  constructor(
    private router: Router,
    private localize: AppLocalizeRouterService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.store.dispatch(OpenHomePageAction());
  }
}
