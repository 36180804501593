import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';

// Stuff for general
import { AppSelectors } from '../../store/selectors';
import { GeneralService } from '../../services/general.service';

// Special for checks
import { AmslerActions, AmslerSelectors } from '../store';
import { AmslerTests } from '../shared';

// From shared folder
import { TIMING_FUNCTION_BACKWARD_DELAYED, TIMING_FUNCTION_FORWARD } from '../../shared/config';
import { Eyes } from '../../shared/enums';
import { GA4_CheckAnswers, GA4_EventAction, GA4_EventName, GA4_EventType, GA4_EventValue } from '../../shared/enums/ga4.enum';
import { AnalyticsService } from '../../services/analytics.service';
import { AppInsightsService } from '../../services/app-insights.service';

@UntilDestroy()
@Component({
  selector: 'zat-amsler-test',
  templateUrl: './amsler-test.component.html',
  styleUrls: ['./amsler-test.component.scss'],
  animations: [
    trigger('controlAnimation', [
      state('init', style({ opacity: 0 })),
      state('idle', style({ opacity: 1 })),
      transition('init => idle', animate(TIMING_FUNCTION_FORWARD)),
      transition('idle => init', animate(TIMING_FUNCTION_BACKWARD_DELAYED))
    ])
  ]
})
export class AmslerTestComponent implements OnInit, OnDestroy {
  questions: ElementRef<HTMLDivElement>;
  debug$: Observable<boolean>;

  amslerTest$: Observable<AmslerTests>;

  public pixelPitch: number;
  public answerState: boolean;
  public activeEye: Eyes;
  public gapSize: number;
  public AmslerTests = AmslerTests;
  private correctAnswers: boolean[] = [true, false];
  private messages: GA4_EventValue[] = [GA4_EventValue.AmslerQ1, GA4_EventValue.AmslerQ2];
  private itteration: number = 0;

  @Output() done = new EventEmitter();

  constructor(
    private store: Store,
    private generalService: GeneralService,
    private logger: AppInsightsService,
    private analyticsService: AnalyticsService
  ) { }

  ngOnInit(): void {
    this.debug$ = this.store.select(AppSelectors.getDebug);

    this.amslerTest$ = this.store.select(AmslerSelectors.getCurrentTest);

    this.store
      .select(AmslerSelectors.getActiveEye)
      .pipe(untilDestroyed(this))
      .subscribe((activeEye) => (this.activeEye = activeEye));

    this.store
      .select(AmslerSelectors.isRightFinished)
      .pipe(untilDestroyed(this))
      .subscribe((isFinished) => {
        if (isFinished && this.activeEye === Eyes.RIGHT) {
          this.done.emit(Eyes.RIGHT);
        }
      });

    this.store
      .select(AmslerSelectors.isLeftFinished)
      .pipe(untilDestroyed(this))
      .subscribe((isFinished) => {
        if (isFinished && this.activeEye === Eyes.LEFT) {
          this.done.emit(Eyes.LEFT);
        }
      });

    let amslerGridSize = this.generalService.getAmslerGridSize();
    this.pixelPitch = amslerGridSize.pixelPitch;
    this.gapSize = amslerGridSize.gapSize;
  }

  ngOnDestroy(): void { }

  submitAnswer() {
    const answer = this.answerState;
    if (this.activeEye === Eyes.RIGHT) {
      this.store.dispatch(AmslerActions.SetRightEyeAmsler({ answer }));
    } else {
      this.store.dispatch(AmslerActions.SetLeftEyeAmsler({ answer }));
    }
    this.itteration = (this.itteration === 1) ? 0 : 1;
  }

  answer(answer: boolean) {
    this.answerState = answer;
    // TODO: Remove it on reimplement the animation

    let stateString: GA4_CheckAnswers = (this.correctAnswers[this.itteration] === answer) ? GA4_CheckAnswers.True : GA4_CheckAnswers.False;
    let eventDetail = `${answer} - ${stateString}`;

    // Fire the ga4-event
    this.analyticsService.createCustomEvent({
        'event': 'event',
        'eventName': `${GA4_EventName.CTA}`,
        'eventAction': `${GA4_EventAction.Click}`,
        'eventType': `${GA4_EventType.Amsler}`,
        'eventValue': `${this.messages[this.itteration]} - ${this.correctAnswers[this.itteration]}`,
        'eventDetail': eventDetail
      }
    );

    this.logger.info('answer',{
      answer: answer,
      test: answer === this.correctAnswers[this.itteration]
    })
    this.submitAnswer();
  }
}
