import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Special for checks
import { CheckSelectionComponent } from './../portal-page/check-selection/check-selection.component';
import { PortalPageComponent } from '../portal-page/portal-page.component';

// From shared folder
import { RoutePathes } from '../shared/enums/route-pathes.enum';

const routes: Routes = [
  {
    path: RoutePathes.Home,
    component: PortalPageComponent,
    data: {
      animation: 'PortalPage'
    }
  },
  {
    path: RoutePathes.Home_CheckSelection,
    component: CheckSelectionComponent,
    data: {
      animation: 'PortalPage'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PortalRoutingModule {}
