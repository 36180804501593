<div *transloco="let t" class="ovs-header" [ngClass]="{dark: (theme==='true')}">
  <div class="ovs-nav-icon" [ngClass]="{portalPage: (theme==='true')}">
    <ng-container *ngIf="!(left==='' && label===''); else emptyInfo">
      <div class="ovs-left-icon" [ngClass]="{wide: isCloseOrBackButton(left)}" (click)="clickOnHeaderButton(true)">
        <div class="title" [innerHTML]="t(label)"></div>
        <ng-container *ngIf="!(left==='')">
          <zat-action-button *ngIf="(theme==='true'); else blackIconLeft" type="focus" class="ovs-left"
            ariaLabel="{{ 'button.goBack' | transloco }}">
            <app-icon id="header_arrow_left_light" name="header_arrow_left_light" [height]="24"
              alt="{{ 'button.goBack' | transloco }}">
            </app-icon>
          </zat-action-button>
          <ng-template #blackIconLeft>
            <ng-container [ngSwitch]="left">
              <zat-action-button type="focus" class="ovs-left" *ngSwitchDefault
                ariaLabel="{{ 'button.goBack' | transloco }}">
                <app-icon id="header_arrow_left_dark" name="header_arrow_left_dark" [height]="24"
                  alt="{{ 'button.goBack' | transloco }}" data-testid="ovs-header-back-button">
                </app-icon>
              </zat-action-button>

              <zat-action-button type="focus" class="ovs-left" *ngSwitchCase="'info'"
                ariaLabel="{{ 'instructions.howTestWorks' | transloco }}">
                <app-icon id="header_info_black" name="header_info_black" [height]="24"
                  alt="{{ 'instructions.howTestWorks' | transloco }}">
                </app-icon>
              </zat-action-button>
            </ng-container>
          </ng-template>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="!(right===''); else emptyInfo">
      <div class="ovs-right-icon" [ngClass]="{wide: isCloseOrBackButton(right)}" (click)="clickOnHeaderButton(false)">
        <zat-action-button *ngIf="(theme==='true'); else blackIconRight" type="focus" class="ovs-right"
          ariaLabel="{{ 'home.about.ovs' | transloco }}">
          <app-icon id="header_info" name="header_info" [height]="24" alt="{{ 'home.about.ovs' | transloco }}">
          </app-icon>
        </zat-action-button>

        <ng-template #blackIconRight>
          <ng-container [ngSwitch]="right">
            <zat-action-button type="focus" class="ovs-right" ariaLabel="{{ 'button.goBack' | transloco }}"
              *ngSwitchDefault>
              <app-icon id="header_info_black" name="header_info_black" [height]="24"
                alt="{{ 'button.goBack' | transloco }}">
              </app-icon>
            </zat-action-button>

            <zat-action-button type="focus" class="ovs-right" ariaLabel="{{ 'button.cancel' | transloco }}"
              *ngSwitchCase="'cross'">
              <app-icon id="header_close" name="header_close" data-testid="header-cancel-button" [height]="24"
                alt="{{ 'button.cancel' | transloco }}">
              </app-icon>
            </zat-action-button>
          </ng-container>
        </ng-template>
      </div>
    </ng-container>

    <ng-template #emptyInfo>
      <div class="ovs-empty-icon">
        <!--QUICKFIX: for embedded webcomponent:
        In shopify the ovs-empty-icon-DIV somehow collapses to 0 width without a content.
        Tried fixing with !important but nothing works except adding some invisible content.
        A space ' ' would also work but might get optimized away somewhere so I prefer this empty div.
        DO NOT REMOVE THIS INNER DIV IF YOU DID NOT CHECK THE STYLING IN SHOPIFY EMBEDDING!!!
      -->
        <div></div>
      </div>
    </ng-template>
  </div>
</div>
