import { findIndex } from 'lodash-es';
import { contrastMap } from '../config';
import { ContrastMapItem } from '../interfaces';

// From shared folder
import { CHANGE_DIRECTION } from '../../../shared/enums';

export function getNewContrast(
  currentValue: number,
  direction: CHANGE_DIRECTION,
  testCount: number
): number {
  // first we find the current position of currentValue in the ContrastMap array
  const index = findIndex(contrastMap, { logCs: currentValue });

  if (index === -1) {
    // when currentValue does not exist in the Map,
    // return the same value
    console.warn(
      'ContrastTest: invalid step value of current logcs: ' + currentValue
    );
    return currentValue;
  }

  // get the previous or next item to currentValue from the Map
  // trial steps: contrast values jumps for bigger step
  // - 1st and 2nd answers: jump 4 steps
  // - 3rd and 4th answers: jump 2 steps
  // - 5th and later: jump 1 step
  // note: `testCount` is an index of the next test. it's +1 from the current answer.
  const FIRST_TRIAL_STEPS = 3;
  const FIRST_TRIAL_JUMP_STEPS = 4;
  const SECOND_TRIAL_STEPS = 5;
  const SECOND_TRIAL_JUMP_STEPS = 2;
  let newContrastItem: ContrastMapItem;

  if (direction === CHANGE_DIRECTION.NEXT) {
    if (testCount <= FIRST_TRIAL_STEPS) {
      newContrastItem = contrastMap[index + FIRST_TRIAL_JUMP_STEPS];
    } else if (testCount <= SECOND_TRIAL_STEPS) {
      newContrastItem = contrastMap[index + SECOND_TRIAL_JUMP_STEPS];
    } else {
      newContrastItem = contrastMap[index + 1];
    }
  } else {
    if (testCount <= FIRST_TRIAL_STEPS) {
      newContrastItem = contrastMap[index - FIRST_TRIAL_JUMP_STEPS];
    } else if (testCount <= SECOND_TRIAL_STEPS) {
      newContrastItem = contrastMap[index - SECOND_TRIAL_JUMP_STEPS];
    } else {
      newContrastItem = contrastMap[index - 1];
    }
  }

  // if it doesn't exist , return the same value
  if (!newContrastItem) {
    console.warn(
      'ContrastTest: cannot find next step of logcs: ' + currentValue
    );
    return currentValue;
  }

  return newContrastItem.logCs;
}
