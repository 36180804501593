import {
  Component,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation
} from '@angular/core';

// Stuff for general
import { environment } from '../../../../environments/environment';

// From shared folder
import {
  HeaderEnum,
  HeaderColorEnum,
  HeaderLabelEnum
} from '../../../shared/enums/header.enum';

@Component({
  selector: 'zat-ovs-header',
  templateUrl: './ovs-header.component.html',
  styleUrls: ['./ovs-header.component.scss'],
  //Experimental: without this the .title css would get some margin from global base.css in embedded webcomp in shopify!!!
  encapsulation: ViewEncapsulation.ShadowDom
})

export class OvsHeaderComponent implements OnDestroy {
  @Input('label') label: string = HeaderLabelEnum.Home;
  @Input('left') left: string = HeaderEnum.Empty;
  @Input('right') right: string = HeaderEnum.Empty;

  // need because portal page has dark BG and we want icons etc. to be selectively light color
  @Input('theme') theme: string = HeaderColorEnum.LightMode;

  @Output('clickRight') clickRight = new EventEmitter();
  @Output('clickLeft') clickLeft = new EventEmitter();

  environment = environment;

  ngOnDestroy(): void {}

  clickOnHeaderButton(isLeft: boolean): void {
    if (isLeft) {  
      this.clickLeft.emit();
    } else {
      this.clickRight.emit();
    }
  }

  isCloseOrBackButton(val: string) :boolean {
    if ((val==='back') || (val==='cross')) {
      return true;
    }
    return false;
  }
}
