// From shared folder
import { Answer, TEST_RESULT } from '../../shared/enums';

export function answerResultConverter(answer: Answer) {
    if(answer === Answer.YES) {
      return TEST_RESULT.EXCELLENT;
    } else {
      return TEST_RESULT.REDUCED;
    }
}
