import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

// Stuff for general
import { OpenAboutUsPageAction } from '../../../store/actions/general.actions';

// From shared folder
import { HeaderConfig } from '../../../shared/interfaces/header-config.interface';
import { HeaderColorEnum, HeaderEnum, HeaderLabelEnum } from '../../../shared/enums/header.enum';

@Component({
  selector: 'zat-help-page',
  templateUrl: './help-page.component.html',
  styleUrls: ['./help-page.component.scss']
})
export class HelpPageComponent implements OnInit {
  headerConfig: HeaderConfig = {
    label: HeaderLabelEnum.Faq,
    left: HeaderEnum.Back,
    right: HeaderEnum.Empty,
    theme: HeaderColorEnum.LightMode
  } as HeaderConfig;

  constructor(
    private store: Store
  ) { }

  ngOnInit(): void { }

  back(): void {
    this.store.dispatch(OpenAboutUsPageAction());
  }

}
