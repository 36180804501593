import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  private browserGlobals = {
    windowRef(): any {
      return window;
    }
  };

  constructor() {}

  /*
  example:
        'event': 'event',
        'eventName': `${GA4_EventName.CTA}`,
        'eventAction': `${GA4_EventAction.Click}`,
        'eventType': `${GA4_EventType.Internal}`,
        'eventValue': `${GA4_EventValue.Acuity}`,
        'eventDetail': `${GA4_EventDetail.HowItWorks}`,
  */
  public createCustomEvent(eventConfig: {
    eventValue: string,
    eventDetail: string,
    event?: string,
    eventName?: string,
    eventAction?:string,
    eventType?:string
  }): void {
    const nativeWindow = this.browserGlobals.windowRef();
    const dataLayer: any[] = nativeWindow.dataLayer || [];

    // null coalesing operator only assings if value not assinged
    eventConfig.event ??= 'event';
    eventConfig.eventName ??= 'cta';

    dataLayer.push(eventConfig);
  }

  public trackVirtualPage(pagePath: any, event: string = 'pageView') {
    const nativeWindow = this.browserGlobals.windowRef();
    const dataLayer: any[] = nativeWindow.dataLayer || [];

    dataLayer.push({
      event,
      page: pagePath
    });
  }
}
