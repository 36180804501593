import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';

// Stuff for general
import { TestTypeActions } from '../store/actions';

// From shared folder
import { TEST_TYPES } from '../shared/enums/test-types';

@Injectable({
  providedIn: 'root'
})
export class ActiveTestResolver  {
  constructor(private store: Store) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): TEST_TYPES {
    const activeTest: TEST_TYPES = route.data.activeTest;
    this.store.dispatch(TestTypeActions.SetActiveTest({ testType: activeTest }));
    return activeTest;
  }
}
