import { Injectable } from '@angular/core';
import { add } from 'date-fns';
import { CookieService } from 'ngx-cookie-service';

// From shared folder
import { DEFAULT_PIXEL_PITCH_IN_CM } from '../shared/config';

@Injectable({
  providedIn: 'root'
})
export class AppCookiesService {
  private keys = {
    acceptDisclaimers: 'ovs.acceptDisclaimers',
    oneTrustBannerClosed: 'ovs.hasClosedOneTrustBanner',
    screenMode: 'ovs.calibrations.screenMode',
    pixelPitch: 'ovs.calibrations.pixelPitch',
    locale: 'ovs.locale'
  };

  private options = {
    path: '/',
    expires: add(new Date(), { weeks: 1 })
  };

  constructor(private cookieService: CookieService) {}

  // transient fallback settings in case oneTrust not initialized (no CCM)
  acceptDisclaimer: boolean = false;
  screenMode: string;
  pixelPitch: number;
  locale: string = 'en-INT';

  isOneTrustAvailable(): boolean {
    let isOneTrustAvailable = !!window.OneTrust;
    console.log('isOneTrustAvailable: ', isOneTrustAvailable);
    return isOneTrustAvailable;
  }

  setAcceptDisclaimers(): void {
    if (this.isOneTrustAvailable()) {
      this.cookieService.set(this.keys.acceptDisclaimers, 'true', this.options);
    } else {
      this.acceptDisclaimer = true;
    }
  }

  hasAcceptedDisclaimers(): boolean {
    if (this.isOneTrustAvailable()) {
      return this.cookieService.check(this.keys.acceptDisclaimers);
    } else {
      return this.acceptDisclaimer;
    }
  }

  // will only be set if CCM pops up, so no need to check OneTrust avialability
  setOneTrustBannerClosed(): void {
    this.cookieService.set(
      this.keys.oneTrustBannerClosed,
      'true',
      this.options
    );
  }

  hasClosedOneTrustBanner(): boolean {
    return this.cookieService.check(this.keys.oneTrustBannerClosed);
  }

  setCalibrateScreen(screenMode: string, pixelPitch: number): void {
    if (this.isOneTrustAvailable()) {
      this.cookieService.set(this.keys.screenMode, screenMode, this.options);
      this.cookieService.set(
        this.keys.pixelPitch,
        `${pixelPitch}`,
        this.options
      );
    } else {
      console.log('no cookieConsent available -> hold calibration values as transient vars in service');
      this.screenMode = screenMode; // 'mobile'/'desktop'
      this.pixelPitch = pixelPitch;
    }
  }

  getScreenMode(): string {
    if (this.isOneTrustAvailable()) {
      return this.cookieService.get(this.keys.screenMode);
    } else {
      return this.screenMode;
    }
  }

  getPixelPitch(): number {
    if (this.isOneTrustAvailable()) {
      const pixelPitch = this.cookieService.get(this.keys.pixelPitch);
      if (pixelPitch) {
        return parseFloat(pixelPitch);
      }

      return DEFAULT_PIXEL_PITCH_IN_CM;
    } else {
      return this.pixelPitch;
    }
  }

  setLocale(locale: string) {
    if (this.isOneTrustAvailable()) {
      let cookieOptions = {
        path: '/',
        expires: add(new Date(), { months: 6 })
      };

      this.cookieService.set(this.keys.locale, locale, cookieOptions);
    } else {
      this.locale = locale;
    }
  }

  getLocale(): string {
    if (this.isOneTrustAvailable()) {
      return this.cookieService.get(this.keys.locale);
    } else {
      return this.locale;
    }
  }

  clear(): void {
    this.cookieService.delete(this.keys.acceptDisclaimers, this.options.path);
  }
}
