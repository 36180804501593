<svg class="controls"
  xmlns="http://www.w3.org/2000/svg" width="260.002" height="260.002"
  [@controlAnimations]="controlState"
  (@controlAnimations.start)="restorePizza($event)"
  (@controlAnimations.done)="onRoundAnimationDone($event)"
  >
  <path
    #topSlice
    id="TOP"
    [tabIndex]="0"
    [@sliceAnimation]="slices.TOP.state"
    (@sliceAnimation.start)="onSliceAnimationDone($event)"
    (@sliceAnimation.done)="onSliceAnimationDone($event)"
    (click)="clickOnSegment(angles.TOP)"
    (keyup)="checkTheKey($event, angles.TOP)"
    d="M158.934 57.545a77.47 77.47 0 00-13.211-3.957 78.642 78.642 0 00-31.437 0 77.47 77.47 0 00-13.211 3.957l-19.9-48.059a130.162 130.162 0 0197.674 0z"/>

  <path
    #topRightSlice
    id="TOPRIGHT"
    [tabIndex]="0"
    [@sliceAnimation]="slices.TOPRIGHT.state"
    (@sliceAnimation.start)="onSliceAnimationDone($event)"
    (@sliceAnimation.done)="onSliceAnimationDone($event)"
    (click)="clickOnSegment(angles.TOPRIGHT)"
    (keyup)="checkTheKey($event, angles.TOPRIGHT)"
    d="M201.693 99.226a78.216 78.216 0 00-40.914-40.914l19.906-48.059a130.414 130.414 0 0169.066 69.066l-48.059 19.907z"/>

  <path
    #rightSlice
    id="RIGHT"
    [tabIndex]="0"
    [@sliceAnimation]="slices.RIGHT.state"
    (@sliceAnimation.start)="onSliceAnimationDone($event)"
    (@sliceAnimation.done)="onSliceAnimationDone($event)"
    (click)="clickOnSegment(angles.RIGHT)"
    (keyup)="checkTheKey($event, angles.RIGHT)"
    d="M202.459 158.933a77.41 77.41 0 003.959-13.213 78.785 78.785 0 000-31.437 77.477 77.477 0 00-3.957-13.209l48.057-19.9a129.319 129.319 0 016.844 22.635 130.2 130.2 0 01-6.844 75.036z"/>

  <path
    #bottomRightSlice
    id="BOTTOMRIGHT"
    [tabIndex]="0"
    [@sliceAnimation]="slices.BOTTOMRIGHT.state"
    (@sliceAnimation.start)="onSliceAnimationDone($event)"
    (@sliceAnimation.done)="onSliceAnimationDone($event)"
    (click)="clickOnSegment(angles.BOTTOMRIGHT)"
    (keyup)="checkTheKey($event, angles.BOTTOMRIGHT)"
    d="M160.779 201.692a78.193 78.193 0 0024.379-16.535 78.8 78.8 0 009.525-11.545 77.993 77.993 0 007.008-12.833l48.061 19.907a130.393 130.393 0 01-69.068 69.064z"/>

  <path
    #bottomSlice
    id="BOTTOM"
    [tabIndex]="0"
    [@sliceAnimation]="slices.BOTTOM.state"
    (@sliceAnimation.start)="onSliceAnimationDone($event)"
    (@sliceAnimation.done)="onSliceAnimationDone($event)"
    (click)="clickOnSegment(angles.BOTTOM)"
    (keyup)="checkTheKey($event, angles.BOTTOM)"
    d="M130 260.001a130.826 130.826 0 01-26.2-2.641 129.332 129.332 0 01-22.639-6.846l19.906-48.058a77.615 77.615 0 0013.213 3.957 78.642 78.642 0 0031.438 0 77.47 77.47 0 0013.211-3.957l19.906 48.058a129.181 129.181 0 01-22.639 6.846A130.778 130.778 0 01130 260.001z"/>

  <path
    #bottomLeftSlice
    id="BOTTOMLEFT"
    [tabIndex]="0"
    [@sliceAnimation]="slices.BOTTOMLEFT.state"
    (@sliceAnimation.start)="onSliceAnimationDone($event)"
    (@sliceAnimation.done)="onSliceAnimationDone($event)"
    (click)="clickOnSegment(angles.BOTTOMLEFT)"
    (keyup)="checkTheKey($event, angles.BOTTOMLEFT)"
    d="M79.316 249.75a130.42 130.42 0 01-69.066-69.063l48.057-19.9a78.285 78.285 0 0028.084 33.9 77.645 77.645 0 0012.836 7.015z"/>

  <path
    #leftSlice
    id="LEFT"
    [tabIndex]="0"
    [@sliceAnimation]="slices.LEFT.state"
    (@sliceAnimation.start)="onSliceAnimationDone($event)"
    (@sliceAnimation.done)="onSliceAnimationDone($event)"
    (click)="clickOnSegment(angles.LEFT)"
    (keyup)="checkTheKey($event, angles.LEFT)"
    d="M9.486 178.84a130.148 130.148 0 010-97.673l48.055 19.9a78.1 78.1 0 000 57.865z"/>

  <path
    #topLeftSlice
    id="TOPLEFT"
    [tabIndex]="0"
    [@sliceAnimation]="slices.TOPLEFT.state"
    (@sliceAnimation.start)="onSliceAnimationDone($event)"
    (@sliceAnimation.done)="onSliceAnimationDone($event)"
    (click)="clickOnSegment(angles.TOPLEFT)"
    (keyup)="checkTheKey($event, angles.TOPLEFT)"
    d="M10.252 79.316a130.414 130.414 0 0169.064-69.064l19.908 48.059a77.664 77.664 0 00-12.834 7.014 78.166 78.166 0 00-28.084 33.9z"/>
</svg>

<div id="correctFeedbackImg" class="answer-feedback-icon correct" [@correctIconAnimation]="controlState">
  <svg xmlns="http://www.w3.org/2000/svg" width="23.404" height="22.446" viewBox="0 0 23.404 22.446">
    <path d="M0,0H2V26l-2-.46Z" transform="matrix(0.819, 0.574, -0.574, 0.819, 21.766, 0)"/>
    <path d="M0,0H2L1.992,11.463,0,12Z" transform="translate(0 13.961) rotate(-45)"/>
  </svg>
</div>

<div id="incorrectFeedbackImg" class="answer-feedback-icon incorrect" [@incorrectIconAnimation]="controlState">
  <svg xmlns="http://www.w3.org/2000/svg" width="22.629" height="22.628" viewBox="0 0 22.629 22.628">
    <rect width="2" height="30" transform="translate(21.215 0) rotate(45)"/>
    <rect width="2" height="30" transform="translate(0 1.414) rotate(-45)"/>
  </svg>
</div>
