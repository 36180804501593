import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

// Special for checks
import { AppIconsModule } from '../app-icons/app-icons.module';
import { AcuityDetailsPageComponent } from './acuity-details-page/acuity-details-page.component';
import { AcuityTestRoutingModule } from './acuity-test-routing.module';
import { AppMaterialModule } from '../shared/app-material/app-material.module';

// Special for checks
import { fromAcuity } from './store';
import { AcuityEffects } from './store/effects/acuity.effect';

// From shared folder
import { AppSharedModule } from '../shared/app-shared.module';

import { OvsComparisonSliderComponent } from '../ovs-comparison-slider/ovs-comparison-slider.component';

@NgModule({
  declarations: [AcuityDetailsPageComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    AcuityTestRoutingModule,
    AppMaterialModule,
    StoreModule.forFeature('acuity', fromAcuity.reducer),
    EffectsModule.forFeature([ AcuityEffects ]),
    AppSharedModule,
    AppIconsModule,
    OvsComparisonSliderComponent
  ]})
export class AcuityTestModule {}
