import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { delay, Observable, take, tap } from 'rxjs';
import { Location } from '@angular/common';

// Stuff for general
import { environment } from '../../../environments/environment';
import { AppSelectors } from '../../store/selectors';
import { ShowMegaTestResultsAction } from '../../store/actions/general.actions';
import { SendResultsAction } from '../../store/actions/test-type.actions';
import {
  getSpecialScreeningMode,
  getMegaTestActive
} from '../../store/selectors/app.selectors';

// Special for checks
import { AmslerActions, AmslerSelectors } from '../store';

// From shared folder
import { CloseDialogContext } from '../../shared/components/close-dialog/close-dialog-context.enum';
import { HeaderConfig } from '../../shared/interfaces/header-config.interface';
import {
  GA4_EventDetail,
  GA4_EventAction,
  GA4_EventName,
  GA4_EventType,
  GA4_EventValue
} from '../../shared/enums/ga4.enum';
import {
  HeaderColorEnum,
  HeaderLabelEnum,
  HeaderEnum
} from '../../shared/enums/header.enum';
import { fadeInAnimation } from '../../shared/animations';
import { SpecialScreeningMode } from '../../shared/enums/special-screening-mode.enum';
import { AnalyticsService } from '../../services/analytics.service';
import { AppInsightsService } from '../../services/app-insights.service';

@Component({
  selector: 'zat-amsler-result-page',
  templateUrl: './amsler-result-page.component.html',
  styleUrls: ['./amsler-result-page.component.scss'],
  animations: [fadeInAnimation]
})
export class AmslerResultPageComponent implements OnInit, OnDestroy {
  specialScreeningMode$: Observable<SpecialScreeningMode> = this.store.select(
    getSpecialScreeningMode
  );
  SpecialScreeningMode = SpecialScreeningMode;

  resultString: string = null;
  image$: Observable<{
    leftEye: any;
    rightEye: any;
  }>;
  resultSummary$: Observable<{
    sumResult: any;
    eyesResult: any;
  }>;

  public locale$: Observable<string>;

  public isWebcomponent: boolean;
  isMegaTestActive$: Observable<boolean> = this.store.select(getMegaTestActive);

  headerConfig: HeaderConfig = {
    label: HeaderLabelEnum.SingleCheckResult,
    left: HeaderEnum.Empty,
    right: HeaderEnum.Close,
    theme: HeaderColorEnum.LightMode
  } as HeaderConfig;

  // trigger close warning dialog when browser back button is clicked
  @HostListener('window:popstate', ['$event'])
  onPopState() {
    if (!environment.webcomponent) {
      history.pushState(null, null, location.href);
      this.triggerCloseWarning();
    }
  }

  constructor(
    private store: Store,
    private logger: AppInsightsService,
    private locationService: Location,
    private analytics: AnalyticsService
  ) {
    history.pushState(null, null, location.href);
  }

  ngOnInit(): void {
    this.isWebcomponent = environment.webcomponent;

    this.image$ = this.store.select(AmslerSelectors.getResults).pipe(
      take(1),
      tap((img) => {
        this.resultString = `Left: ${img.leftEye} - Right: ${img.rightEye}`;
      })
    );
    this.resultSummary$ = this.store.select(AmslerSelectors.getResultSummary);

    this.locale$ = this.store.select(AppSelectors.getLocale);
  }

  ngAfterViewInit(): void {
    // Fire the event
    this.analytics.createCustomEvent({
      event: 'event',
      eventName: `${GA4_EventName.CTA}`,
      eventAction: `${GA4_EventAction.View}`,
      eventType: `${GA4_EventType.Amsler} Test`,
      eventValue: `${GA4_EventValue.Result}`,
      eventDetail: `${this.resultString}`
    });
  }

  ngOnDestroy(): void {}

  goToResultPage(): void {
    this.analytics.createCustomEvent({
      event: 'event',
      eventName: `${GA4_EventName.CTA}`,
      eventAction: `${GA4_EventAction.Click}`,
      eventType: `${GA4_EventType.Internal}`,
      eventValue: `${GA4_EventValue.ShowResults}`,
      eventDetail: `${GA4_EventDetail.ResultPage} - ${GA4_EventDetail.Amsler}`
    });

    this.store.dispatch(ShowMegaTestResultsAction());
  }

  sendResults(): void {
    this.analytics.createCustomEvent({
      event: 'event',
      eventName: `${GA4_EventName.CTA}`,
      eventAction: `${GA4_EventAction.Click}`,
      eventType: `${GA4_EventType.Internal}`,
      eventValue: `${GA4_EventValue.SaveResults}`,
      eventDetail: `${GA4_EventDetail.ResultPage} - ${GA4_EventDetail.Amsler}`
    });

    this.logger.info('### Send result', 'Amsler');
    this.store.dispatch(SendResultsAction());
  }

  triggerCloseWarning(): void {
    this.analytics.createCustomEvent({
      event: 'event',
      eventName: `${GA4_EventName.CTA}`,
      eventAction: `${GA4_EventAction.Click}`,
      eventType: `${GA4_EventType.Internal}`,
      eventValue: `${GA4_EventValue.ExitResultPage}`,
      eventDetail: `${GA4_EventDetail.ResultPage} - ${GA4_EventDetail.Amsler}`
    });

    this.specialScreeningMode$
      .pipe(delay(0), take(1))
      .subscribe((mode: SpecialScreeningMode) => {
        this.store.dispatch(
          AmslerActions.ShowCloseWarningAction({
            context:
              mode === SpecialScreeningMode.ECP
                ? CloseDialogContext.ResultECP
                : CloseDialogContext.ResultNotECP,
            previousPath: this.locationService.path()
          })
        );
      });
  }
}
