import { LayoutModule } from '@angular/cdk/layout';
import { DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, Injector, NgModule } from '@angular/core';
import { createCustomElement, NgElementConfig } from '@angular/elements';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslocoModule } from '@ngneat/transloco';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { CookieService } from 'ngx-cookie-service';
import { ServiceWorkerModule } from '@angular/service-worker';

// Stuff for general
import { environment } from '../environments/environment';
import { MegaEffects } from './store/effects/mega.effect';
import { fromApp } from './store/reducers';
import { ErrorHandlerService } from './services/error-handler.service';
import { initGTM } from './init-gtm';
import { TranslocoRootModule } from './transloco/transloco-root.module';
import { extModules } from '../build-specifics';

// Special for checks
import { AppComponent } from './app.component';
import { AcuityTestModule } from './acuity-test/acuity-test.module';
import { NotFoundPageComponent } from './acuity-test/not-found-page/not-found-page.component';
import { AppRoutingModule } from './app-routing.module';

import * as acuityState from './acuity-test/store/reducers/acuity.reducer';
import * as contrastState from './contrast-test/store/reducers/contrast.reducer';
import * as colorState from './color-test/store/reducers/color.reducer';
import * as astigmatismState from './astigmatism-test/store/reducers/astigmatism.reducer';
import * as amslerState from './amsler-test/store/reducers/amsler.reducer';

import { ContrastTestModule } from './contrast-test/contrast-test.module';
import { ColorTestModule } from './color-test/color-test.module';
import { AstigmatismTestModule } from './astigmatism-test/astigmatism-test.module';
import { AmslerTestModule } from './amsler-test/amsler-test.module';

// From shared
import { LoadingSpinnerComponent } from './shared/components/loading-spinner/loading-spinner.component';
import { AppSharedModule } from './shared/app-shared.module';
import 'img-comparison-slider';
import { ChubResultCodeScreenComponent } from './chub-result-code-screen/chub-result-code-screen.component';
import { IonicModule } from '@ionic/angular';

export function localStorageSyncReducer(
  reducer: ActionReducer<any>
): MetaReducer<any, any> {
  return localStorageSync({
    keys: [
      {
        app: [
          'activeTest',
          'megaTest',
          'darkMode',
          'headerConfig',
          'testTypeCode',
          'instructionsSet'
        ]
      },
      {
        acuity: ['tests', 'rightEyeAcuity', 'leftEyeAcuity', 'questionnaire']
      },
      {
        astigmatism: ['tests', 'rightEyeAstigmatism', 'leftEyeAstigmatism']
      },
      {
        contrast: ['tests', 'rightEyeContrast', 'leftEyeContrast']
      },
      {
        color: [
          'tests',
          'currentTest',
          'answers',
          'currentTestIndex',
          'finished',
          'answersAccuracy'
        ]
      },
      {
        amsler: ['tests', 'rightEyeAmsler', 'leftEyeAmsler']
      }
    ],
    rehydrate: true
  })(reducer) as MetaReducer<any, any>;
}
// don't use localstorage if we don't have cookie consent initialized/avialable
export const metaReducers: Array<MetaReducer<any, any>> = !environment.webcomponent?[localStorageSyncReducer]:[];

@NgModule({
  declarations: [
    AppComponent,
    NotFoundPageComponent,
    LoadingSpinnerComponent,
  ],

  imports: [
    ChubResultCodeScreenComponent,
    BrowserModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.pwa,
      registrationStrategy: 'registerImmediately'
    }),
    BrowserAnimationsModule,
    HttpClientModule,
    TranslocoRootModule,
    AcuityTestModule,
    ContrastTestModule,
    ColorTestModule,
    AstigmatismTestModule,
    AmslerTestModule,
    LayoutModule,
    StoreModule.forRoot(
      {
        app: fromApp.reducer,
        acuity: acuityState.reducer,
        contrast: contrastState.reducer,
        color: colorState.reducer,
        astigmatism: astigmatismState.reducer,
        amsler: amslerState.reducer
      },
      { metaReducers }
    ),
    HammerModule,
    IonicModule.forRoot({}),
    extModules,
    TranslocoModule,
    EffectsModule.forRoot([MegaEffects]),
    AppSharedModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initGTM,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService
    },
    CookieService,
    DatePipe
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: environment.webcomponent ? [] : [AppComponent]
})
export class AppModule {
  constructor(private injector: Injector) {
    console.log('AppModule constructor called');
    const el = createCustomElement(AppComponent, {
      injector: this.injector
    } as NgElementConfig);
    customElements.define('zeiss-ovs', el);
  }

  ngDoBootstrap() {}
}
