import { createSelector } from '@ngrx/store';

// Special for checks
import { amslerTestItems, getResult, LAST_INDEX } from '../../shared';
import { fromAmsler } from '../reducers';

// From shared folder
import { Eyes, TEST_RESULT } from '../../../shared/enums';
import { resultImageConverter } from '../../../shared/utils';

export const selectAmsler = (state: { amsler: fromAmsler.AmslerState }) => state.amsler;
// TODO: *REDUX* create proper feature selector for amsler check
//export const selectAmsler = createFeatureSelector<AmslerState>('amslerState');

export const getActiveEye = createSelector(
  selectAmsler,
  (state: fromAmsler.AmslerState) => {
    // prevent errors when accessing this state object from ActiveEyeGuard
    if (!state) {
      return Eyes.RIGHT;
    }
    return state.tests.activeEye;
  }
);

export const getCurrentTest = createSelector(
  selectAmsler,
  (state: fromAmsler.AmslerState) => {
    const { tests, rightEyeAmsler, leftEyeAmsler } = state;
    const activeEye = tests.activeEye;
    const currentEyeAmsler = activeEye === Eyes.RIGHT ? rightEyeAmsler : leftEyeAmsler;
    const currentTestIndex = currentEyeAmsler?.count;

    if (currentTestIndex <= LAST_INDEX) {
      const currentTest = amslerTestItems[currentTestIndex];
      return currentTest;
    }

    return undefined;
  }
);

export const isFinished = createSelector(
  selectAmsler,
  (state: fromAmsler.AmslerState) => {
    const { rightEyeAmsler, leftEyeAmsler } = state;
    return rightEyeAmsler?.finished && leftEyeAmsler?.finished;
  }
);

export const isLeftFinished = createSelector(
  selectAmsler,
  (state: fromAmsler.AmslerState) => {
    const { leftEyeAmsler } = state;
    return leftEyeAmsler?.finished;
  }
);

export const isRightFinished = createSelector(
  selectAmsler,
  (state: fromAmsler.AmslerState) => {
    const { rightEyeAmsler } = state;
    return rightEyeAmsler?.finished;
  }
);

export const getResults = createSelector(
  selectAmsler,
  (state: fromAmsler.AmslerState) => {
    const { rightEyeAmsler, leftEyeAmsler } = state;
    const right = getResult(rightEyeAmsler?.answers);
    const left = getResult(leftEyeAmsler?.answers);
    const leftEye = resultImageConverter(left);
    const rightEye = resultImageConverter(right);

    return {
      leftEye, rightEye
    };
  }
);

// get the summarized result for displaying one icon (if there are two different results for
// both eyes, then take the lower result score)
export const getResultImageLowerScore = createSelector(
  selectAmsler,
  (state: fromAmsler.AmslerState) => {
    const { rightEyeAmsler, leftEyeAmsler } = state;
    const right = getResult(rightEyeAmsler?.answers);
    const left = getResult(leftEyeAmsler?.answers);

    let lowerScore;
    if(right < left) {
      lowerScore = right;
    } else {
      lowerScore = left;
    }

    const summarizedResult = resultImageConverter(lowerScore);
    return summarizedResult;
  }
);

export const getResultSummary = createSelector(
  selectAmsler,
  (state: fromAmsler.AmslerState) => {
    const { rightEyeAmsler, leftEyeAmsler } = state;
    const right = getResult(rightEyeAmsler?.answers);
    const left = getResult(leftEyeAmsler?.answers);
    const max = Math.max(right, left);
    const min = Math.min(right, left);
    let sumResult = max;
    let eyesResult;

    if (max === min) {
      eyesResult = TEST_RESULT.BOTHEYES;
    }
    else {
      eyesResult = TEST_RESULT.ONEEYE;
      sumResult = min;
    }

    return {
      eyesResult, sumResult
    };
  }
);

export const getAmslerAnswers = createSelector(
  selectAmsler,
  (state: fromAmsler.AmslerState) => {
    const { rightEyeAmsler, leftEyeAmsler } = state;
    return {
      answersLeft: leftEyeAmsler?.answers,
      answersRight: rightEyeAmsler?.answers
    };
  }
);
