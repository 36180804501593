<div class="calibrate-brightness" *transloco="let t">
  <div class="brightness-content">
    <div class="media-space">
      <app-icon id="preparation_brightness" name="preparation_brightness" alt="">
      </app-icon>
    </div>

    <div class="text">
      <div class="text-info">
        <span [innerHtml]="t('setup.brightness.text')"></span>
      </div>
    </div>
  </div>
</div>
