import { createAction, props } from '@ngrx/store';

export const SetCurrentURL = createAction(
  '[Config] SetCurrentURL',
  props<{
    currentURL: string;
  }>()
);

export const HideSpinner = createAction('[App] HideSpinner');
export const ShowSpinner = createAction('[App] ShowSpinner');

// TODO: *REDUX* rethink, if this is the right place for this action
export const SetCloseButton = createAction(
  '[Config] Set Close Button',
  props<{
    mode: boolean;
  }>()
);
