import { AnimationEvent } from '@angular/animations';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { delay, take } from 'rxjs/operators';
import { Location } from '@angular/common';

// Stuff for general
import { environment } from '../../../environments/environment';
import { AppSelectors } from '../../store/selectors';
import { TestTypeActions } from '../../store/actions';
import { AppCookiesService } from '../../services/app-cookies.service';
import { OpenHomePageAction } from '../../store/actions/general.actions';
import { GeneralService } from '../../services/general.service';

// Special for checks
import { DEFAULT_GAP_SIZE_IN_LOGMAR } from '../shared/config';
import { AstigmatismActions, AstigmatismSelectors } from '../store';

// From shared folder
import { Answer, Eyes, IconState } from '../../shared/enums';
import { TEST_TYPES } from '../../shared/enums/test-types';
import { calculateGapSize } from '../../shared/utils';
import { DEFAULT_DISTANCE_IN_CM } from '../../shared/config';
import { RoutePathes } from '../../shared/enums/route-pathes.enum';
import { HeaderConfig } from '../../shared/interfaces/header-config.interface';
import {
  HeaderColorEnum,
  HeaderLabelEnum,
  HeaderEnum
} from '../../shared/enums/header.enum';
import { CloseDialogContext } from '../../shared/components/close-dialog/close-dialog-context.enum';
import { fadeInAnimation } from '../../shared/animations';
import {
  GA4_CheckAnswers,
  GA4_EventDetail,
  GA4_EventAction,
  GA4_EventName,
  GA4_EventType,
  GA4_EventValue
} from '../../shared/enums/ga4.enum';
import { AnalyticsService } from '../../services/analytics.service';

const MAXIMUM_SCALE_RATIO: number = 0.788;

@UntilDestroy()
@Component({
  selector: 'zat-astigmatism-test-page',
  templateUrl: './astigmatism-test-page.component.html',
  styleUrls: ['./astigmatism-test-page.component.scss'],
  animations: [fadeInAnimation]
})
export class AstigmatismTestPageComponent implements OnInit, OnDestroy {
  megaTestActive$: Observable<boolean>;
  // assume static distance of 50cm
  distance = DEFAULT_DISTANCE_IN_CM;
  debug$: Observable<boolean>;

  public gapSize: number;
  public pixelPitch: number;

  public activeEye: Eyes;
  public answers = Answer;
  public gapSizeInLogMAR: number = DEFAULT_GAP_SIZE_IN_LOGMAR;
  public SVG_SCALE_FACTOR: number = 4;
  public svgScaleRatio: number = 1;
  public megaTestActive: boolean;

  public iconState = IconState.IDLE;

  isReady = false;

  private destroy$: Subject<void> = new Subject<void>();

  headerConfig: HeaderConfig = {
    label: HeaderLabelEnum.AstigmatismCheck,
    left: HeaderEnum.Info,
    right: HeaderEnum.Close,
    theme: HeaderColorEnum.LightMode
  } as HeaderConfig;
  toggleHint$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  Eyes = Eyes;

  // trigger close warning dialog for checks when browser back button is clicked
  @HostListener('window:popstate', ['$event'])
  onPopState() {
    if (!environment.webcomponent) {
      history.pushState(null, null, location.href);
      this.cross();
    }
  }

  constructor(
    private store: Store,
    private cookies: AppCookiesService,
    private generalService: GeneralService,
    private locationService: Location,
    private analytics: AnalyticsService
  ) {
    history.pushState(null, null, location.href);
  }

  ngOnInit(): void {
    this.megaTestActive$ = this.store.select(AppSelectors.getMegaTestActive);
    this.debug$ = this.store.select(AppSelectors.getDebug);

    this.store
      .select(AstigmatismSelectors.getActiveEye)
      .pipe(untilDestroyed(this))
      .subscribe((eye) => (this.activeEye = eye));

    this.megaTestActive$.pipe(untilDestroyed(this)).subscribe((active) => {
      this.megaTestActive = active;
      this.goToResultIfFinished(active);
    });

    this.setTestImageSize();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  cancelTest(): void {
    this.store.dispatch(OpenHomePageAction());
  }

  selectAnswer(answer: Answer): void {
    this.iconState = IconState.ANSWERED;

    // Fire the ga4-event
    let stateString: GA4_CheckAnswers =
      Answer.YES === answer ? GA4_CheckAnswers.True : GA4_CheckAnswers.False;
    this.analytics.createCustomEvent({
      event: 'event',
      eventName: `${GA4_EventName.CTA}`,
      eventAction: `${GA4_EventAction.Click}`,
      eventType: `${GA4_EventType.Astigmatism}`,
      eventValue: `${GA4_EventValue.AstigmatismQ} - ${Answer.YES}`,
      eventDetail: `${answer} - ${stateString}`
    });

    this.store.dispatch(AstigmatismActions.SubmitAnswer({ answer }));
  }

  submitAnswer(): void {
    // set next active eye
    if (this.activeEye === Eyes.RIGHT) {
      this.store.dispatch(AstigmatismActions.SetActiveEye({ eye: Eyes.LEFT }));
      let path = `${TEST_TYPES.ASTIGMATISM}${RoutePathes.CheckPrepare}`;
      if (this.megaTestActive) {
        path = `${RoutePathes.Default}/${path}`;
      }
      this.generalService.routeToNextScreen(path);
    } else {
      this.goToResults();
    }
  }

  private goToResults() {
    let path = `${TEST_TYPES.ASTIGMATISM}${RoutePathes.CheckResult}`;

    if (this.megaTestActive) {
      this.store.dispatch(
        TestTypeActions.SetTestInMegaTest({ testType: TEST_TYPES.ASTIGMATISM })
      );
      path = `${RoutePathes.Default}/${path}`;
    }
    this.generalService.routeToNextScreen(path);
  }

  onAnimationDone(event: AnimationEvent): void {
    if (event.toState === IconState.ANSWERED) {
      this.iconState = IconState.IDLE;
      return;
    }

    if (
      event.fromState === IconState.ANSWERED &&
      event.toState === IconState.IDLE
    ) {
      this.submitAnswer();
    }
  }

  private setTestImageSize(): void {
    this.pixelPitch = this.cookies.getPixelPitch();
    this.gapSize = calculateGapSize(
      DEFAULT_GAP_SIZE_IN_LOGMAR,
      this.pixelPitch,
      DEFAULT_DISTANCE_IN_CM
    );
    this.svgScaleRatio = this.gapSize / this.SVG_SCALE_FACTOR;
    this.svgScaleRatio =
      this.svgScaleRatio > MAXIMUM_SCALE_RATIO
        ? MAXIMUM_SCALE_RATIO
        : this.svgScaleRatio;
  }

  private goToResultIfFinished(megaTestActive: boolean): void {
    this.store
      .select(AstigmatismSelectors.getBothEyesFinished)
      .pipe(take(1))
      .subscribe((finished: boolean) => {
        if (!finished) {
          this.isReady = true;
          return;
        }

        let path = `${TEST_TYPES.ASTIGMATISM}${RoutePathes.CheckResult}`;

        if (megaTestActive) {
          path = `${RoutePathes.Default}/${path}`;
        }
        this.generalService.routeToNextScreen(path);
      });
  }

  openInfo(): void {
    this.toggleHint$.next(true);

    this.analytics.createCustomEvent({
      event: 'event',
      eventName: `${GA4_EventName.CTA}`,
      eventAction: `${GA4_EventAction.Click}`,
      eventType: `${GA4_EventType.Internal}`,
      eventValue: `${GA4_EventValue.Astigmatism}`,
      eventDetail: `${GA4_EventDetail.HowItWorks}`
    });
  }

  closeHint(): void {
    this.toggleHint$.next(false);
  }

  cross(): void {
    this.analytics.createCustomEvent({
      event: 'event',
      eventName: `${GA4_EventName.CTA}`,
      eventAction: `${GA4_EventAction.Click}`,
      eventType: `${GA4_EventType.Astigmatism}`,
      eventValue: `${GA4_EventValue.ExitCheck}`,
      eventDetail: `${GA4_EventDetail.Clear}`
    });

    of(null)
      .pipe(delay(0), take(1))
      .subscribe(() => {
        this.store.dispatch(
          AstigmatismActions.ShowCloseWarningAction({
            context: CloseDialogContext.Check,
            previousPath: this.locationService.path()
          })
        );
      });
  }
}
