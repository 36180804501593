import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LocatorPageComponent } from './locator-page/locator-page.component';

const routes: Routes = [
  {
    path: '',
    component: LocatorPageComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StoreLocatorRoutingModule { }
