import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';

// Special for checks
import { DummyComponent } from './mocks/dummy-component.component';

// From shared folder
import { AppMaterialModule } from '../../shared/app-material/app-material.module';
import { AppSharedModule } from '../../shared/app-shared.module';

@NgModule({
  declarations: [DummyComponent],
  imports: [
    CommonModule,
    RouterModule,
    TranslocoModule,
    AppMaterialModule,
    AppSharedModule
  ],
  exports: [CommonModule]
})
export class SharedModule {}
