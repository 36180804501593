import { Component, Input } from '@angular/core';

// Special for checks
import { AcuityValues } from '../../../acuity-test/shared/interfaces';

// From shared folder
import { TEST_RESULT } from '../../../shared/enums';
import { Store } from '@ngrx/store';
import { ExitEcpResultAction, SaveAndGenerateResultCodeAction } from '../../../store/actions/general.actions';
import { SpecialScreeningMode } from '../../enums/special-screening-mode.enum';
import { ecpResultButtonText, getMegaTestActive, getSpecialScreeningMode } from '../../../store/selectors/app.selectors';
import { Observable, map } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
import { environment } from '../../../../environments/environment';
import { GeneralService } from '@app-services/general.service';

@Component({
  selector: 'zat-acuity-test-result',
  templateUrl: './acuity-test-result.component.html',
  styleUrls: ['./acuity-test-result.component.scss']
})
export class AcuityTestResultComponent{
  @Input() results: any;
  @Input() image: any;
  @Input() vaValues: AcuityValues;
  @Input() isFRChannel: boolean;

  public RESULT = TEST_RESULT;
  public SpecialScreeningMode = SpecialScreeningMode;

  constructor(private store: Store, private transloco: TranslocoService, private generalService: GeneralService) {}

  public specialScreeningMode$ = this.store.select(getSpecialScreeningMode);
  public buttonText$ = this.store.select(ecpResultButtonText);
  public isMegaTestActive$: Observable<boolean> = this.store.select(getMegaTestActive);
  public showSaveFeature$ = this.generalService.showImportSaveOption$();
  public env = environment;

  handleResultCodeFlow(){
    //TODO: add some action parameter to signify what test(s) the user just did
    // so that we collect the apptopriate data (or already collect the data here and provide to action?)
    this.store.dispatch(SaveAndGenerateResultCodeAction());
  }

  emitExitEcpResult(){
    // dispatch action to notify app.component via store flag to emit Output (user clicked back)
    this.store.dispatch(ExitEcpResultAction());
  }
}
