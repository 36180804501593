import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';

// Stuff for general
import { GeneralService } from '../../../services/general.service';
import { AppSelectors } from '../../../store/selectors';

// Special for checks
import { ShowCloseWarningAction } from '../../store/actions/acuity.actions';

// From shared folder
import { RoutePathes } from '../../../shared/enums/route-pathes.enum';
import { HeaderLabelEnum } from '../../../shared/enums/header.enum';
import { CloseDialogContext } from '../../../shared/components/close-dialog/close-dialog-context.enum';
import { GA4_EventDetail } from '../../../shared/enums/ga4.enum';
import { TEST_TYPES } from '../../../shared/enums/test-types';

@UntilDestroy()
@Component({
  selector: 'zat-acuity-prepare-check-page',
  templateUrl: './acuity-prepare-check-page.component.html',
  styleUrls: ['./acuity-prepare-check-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AcuityPrepareCheckPageComponent implements OnInit {
  megaTestActive$: Observable<boolean>;

  headerLabel: HeaderLabelEnum = HeaderLabelEnum.Setup;
  checkType: GA4_EventDetail = GA4_EventDetail.Acuity;

  private isMegaTestActive: boolean;
  constructor(
    private generalService: GeneralService,
    private store: Store,
    private locationService: Location
  ) { }

  ngOnInit(): void {
    this.megaTestActive$ = this.store.select(AppSelectors.getMegaTestActive);
    this.megaTestActive$
      .pipe(untilDestroyed(this))
      .subscribe((isActive) => (this.isMegaTestActive = isActive));
  }

  next(): void {
    let path = `${TEST_TYPES.ACUITY}${RoutePathes.CheckStart}`;
    if (this.isMegaTestActive) {
      path = `${RoutePathes.Default}/${path}`;
    }
    this.generalService.routeToNextScreen(path);
  }

  cancelTest(): void {
    this.store.dispatch(ShowCloseWarningAction({ context: CloseDialogContext.Check, previousPath: this.locationService.path() }));
  }
}
