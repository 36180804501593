
import { createSelector } from '@ngrx/store';

// Special for checks
import { fromColor } from '../reducers';
import { ColorResultLevels, getResultLevel, getResultRow } from '../../shared';
import { getResultPercentage } from '../../shared/utils/get-result-percentage';

// From shared folder
import { TEST_RESULT_STRING } from '../../../shared/enums/test-result.enum';
import { resultImageConverter } from '../../../shared/utils';

export const selectColor =
  (state: { color: fromColor.ColorState }) => state.color;
// TODO: *REDUX* create proper feature selector for color check
//export const selectColor = createFeatureSelector<fromColor.ColorState>('colorState');

export const getCurrentTest = createSelector(
  selectColor,
  (state: fromColor.ColorState) => {
    return state.currentTest;
  }
);

export const getTests = createSelector(
  selectColor,
  (state: fromColor.ColorState) => {
    return state.tests;
  }
);

export const getTestCount = createSelector(
  selectColor,
  (state: fromColor.ColorState) => {
    return state.tests?.length;
  }
);

export const isFinished = createSelector(
  selectColor,
  (state: fromColor.ColorState) => {
    return state.finished;
  }
);

export const getDataSource = createSelector(
  selectColor,
  (state: fromColor.ColorState) => {
    const dataSource: any = state?.tests?.map((test, index) =>
      getResultRow(state.answers[index], test, index + 1)
    );
    return dataSource;
  }
);

export const getPercent = createSelector(
  [selectColor, getDataSource],
  (state: fromColor.ColorState, dataSource) => {
    if (!dataSource) { return; }

    const percent: number = getResultPercentage(dataSource);
    return percent;
  }
);

export const getLevel = createSelector(
  [selectColor, getPercent],
  (state: fromColor.ColorState, percent: number) => {
    const level: ColorResultLevels = getResultLevel(percent);
    return level;
  }
);

export const getResultImage = createSelector(
  [selectColor, getPercent],
  (state: fromColor.ColorState, percent: number) => {
    const level = getResultLevel(percent);
    const image: TEST_RESULT_STRING = resultImageConverter(level);
    return image;
  }
);

export const getAnswers = createSelector(
  selectColor,
  (state: fromColor.ColorState) => {
    return state.answers;
  }
);
