import { ColorTestItem } from '../interfaces';
import { random, shuffle, range, reduce } from 'lodash-es';

import { randomNumberChoice } from './random-number-choice';

export const getColorTests = (
  colorTestItems: ColorTestItem[][],
  minChoice: number
) => {
  const generatedTests = colorTestItems.map((tests: ColorTestItem[]) => {
    // random 1 test
    const maxIndex = tests.length - 1;
    const randomIndex = random(0, maxIndex);
    const randomTest: ColorTestItem = {...tests[randomIndex]};

    // add choice left with random number
    const choiceLeft = minChoice - randomTest.choices.length;
    reduce(range(choiceLeft), (prevRandomChoices, n) => {
      const exceptChoices = [randomTest.correctAnswer, randomTest.confusionNumber, ...prevRandomChoices];
      const choice = randomNumberChoice(randomTest, exceptChoices);
      randomTest.choices.push(choice);
      prevRandomChoices.push(choice);
      return prevRandomChoices;
    }, []);

    // suffle choices
    randomTest.choices = shuffle(randomTest.choices);

    //put 'nothing' to end of array
    let i = randomTest.choices.indexOf('nothing', 0);
    randomTest.choices.splice(i, 1);
    randomTest.choices.push('nothing');

    return randomTest;
  });

  return generatedTests;
};
