<div class="legal-documents" *transloco="let t">
  <zat-ovs-header [label]="headerConfig.label" [left]="headerConfig.left" [right]="headerConfig.right"
    [theme]="headerConfig.theme" (clickLeft)="back()"></zat-ovs-header>
  <div class="info-sets" [ngClass]="{'web-c-height': environment.webcomponent}">
    <!-- General Information -->
    <!-- TODO: Probably replace the licenceUrl with localiciced once, currently its redirecting to home-screen -->
    <div class="info-set" *ngLet="{
      imprintUrl: t('menu.imprint.url'),
      legalUrl: t('menu.legal.url'),
      privacyUrl: t('menu.privacy.url'),
      licenceUrl: environment.licenseUrl
    } as obs">
      <!-- Imprint -->
      <zat-action-button type="focus" (click)="openDocument(obs.imprintUrl)"
        ariaLabel="{{ 'menu.imprint.text' | transloco }}" alt="">
        <div class="info-set-content">
          <div class="icon-tile">
            <app-icon id="document" name="document" [width]="24" [height]="17" alt="">
            </app-icon>
          </div>
          <div class="tile-text">
            <label>{{ t('menu.imprint.text') }}</label>
          </div>
          <div class="icon-tile right">
            <app-icon id="arrow_right" name="arrow_right" [width]="7" [height]="11" alt="">
            </app-icon>
          </div>
        </div>
      </zat-action-button>

      <!-- Legal Notice -->
      <zat-action-button type="focus" (click)="openDocument(obs.legalUrl)"
        ariaLabel="{{ 'home.about.legalDocuments.legalNotice.label' | transloco }}" alt="">
        <div class="info-set-content info-line not-first-entry">
          <div class="icon-tile">
            <app-icon id="document" name="document" [width]="24" [height]="17" alt="">
            </app-icon>
          </div>
          <div class="tile-text">
            <label>{{ t('home.about.legalDocuments.legalNotice.label') }}</label>
          </div>
          <div class="icon-tile right">
            <app-icon id="arrow_right" name="arrow_right" [width]="7" [height]="11" alt="">
            </app-icon>
          </div>
        </div>
      </zat-action-button>

      <!-- Data Protection -->
      <zat-action-button type="focus" (click)="openDocument(obs.privacyUrl)"
        ariaLabel="{{ 'home.about.legalDocuments.privacyNotice.title' | transloco }}">
        <div class="info-set-content info-line not-first-entry">
          <div class="icon-tile">
            <app-icon id="document" name="document" [width]="24" [height]="17" alt="">
            </app-icon>
          </div>
          <div class="tile-text">
            {{ t('home.about.legalDocuments.privacyNotice.title') }}
          </div>
          <div class="icon-tile right">
            <app-icon id="arrow_right" name="arrow_right" [width]="7" [height]="11" alt="">
            </app-icon>
          </div>
        </div>
      </zat-action-button>

      <!-- Licences -->
      <zat-action-button type="focus" (click)="openDocument(obs.licenceUrl)"
        ariaLabel="t{{ 'home.about.legalDocuments.licence' | transloco }}">
        <div class="info-set-content info-line not-first-entry">
          <div class="icon-tile">
            <app-icon id="document" name="document" [width]="24" [height]="17" alt="">
            </app-icon>
          </div>
          <div class="tile-text">
            {{ t('home.about.legalDocuments.licence') }}
          </div>
          <div class="icon-tile right">
            <app-icon id="arrow_right" name="arrow_right" [width]="7" [height]="11" alt="">
            </app-icon>
          </div>
        </div>
      </zat-action-button>

    </div>
  </div>
</div>
