<zat-ovs-header [label]="headerConfig.label" [left]="headerConfig.left" [right]="headerConfig.right"
  [theme]="headerConfig.theme" (clickRight)="triggerCloseWarning()"></zat-ovs-header>
<ng-container content *transloco="let t">
  <!-- For Results or not native -->
  <div class="result-wrapper">
    <div class="va-content no-bottom-padding" #content>
      <div class="va-result-content">
        <div class="result-head">
          <span> {{ t('defaultTest.result.title') }}</span>

          <span class="show-all-link" [tabIndex]="0" *ngIf="!panelState" (keyup)="checkTheKey($event)"
            (click)="openAll()">
            {{ t('button.showAll') }}
          </span>
          <span class="show-all-link" [tabIndex]="0" *ngIf="panelState" (keyup)="checkTheKey($event)"
            (click)="closeAll()">
            {{ t('button.hideAll') }}
          </span>
        </div>
        <div class="va-content-container">
          <mat-accordion class="va-accordion" #accordion="matAccordion" [multi]="true">
            <div>
              <!-- ACUITY -->
              <mat-expansion-panel *ngIf="showAcuity$ | async" hideToggle #acuityPanel #panel="matExpansionPanel">
                <mat-expansion-panel-header>
                  <mat-panel-title class="panel-title">
                    <zat-result-panel-title [image]="'acuity_test_small'" [imageId]="'acuityTestSmall'"
                      [testName]="t('acuityTest.result.testName')" [expended]="acuityPanel.opened"
                      [result]="imageAcuitySummarized$ | async">
                    </zat-result-panel-title>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <zat-acuity-test-result class="individual-result" [results]="vaResult$ | async"
                  [image]="imageAcuity$ | async" [vaValues]="vaValues$ | async">
                </zat-acuity-test-result>
              </mat-expansion-panel>

              <!-- CONTRAST -->
              <mat-expansion-panel *ngIf="showContrast$ | async" hideToggle #contrastPanel #panel="matExpansionPanel">
                <mat-expansion-panel-header>
                  <mat-panel-title class="panel-title">
                    <zat-result-panel-title [image]="'contrast_test_small'" [imageId]="'contrastTestSmall'"
                      [testName]="t('contrastTest.result.testName')" [expended]="contrastPanel.opened"
                      [result]="imageContrastSummarized$ | async">
                    </zat-result-panel-title>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <zat-contrast-test-result class="individual-result" [results]="contrastResults$ | async"
                  [image]="imageContrast$ | async">
                </zat-contrast-test-result>
              </mat-expansion-panel>

              <!-- COLOR -->
              <mat-expansion-panel *ngIf="showColor$ | async" hideToggle #colorPanel #panel="matExpansionPanel">
                <mat-expansion-panel-header>
                  <mat-panel-title class="panel-title">
                    <zat-result-panel-title [image]="'color_test_small'" [imageId]="'colorTestSmall'"
                      [testName]="t('colorTest.result.testName')" [expended]="colorPanel.opened"
                      [result]="imageColor$ | async">
                    </zat-result-panel-title>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <zat-color-test-result class="individual-result" [image]="imageColor$ | async" [level]="level$ | async">
                </zat-color-test-result>
              </mat-expansion-panel>

              <!-- ASTIGMATISM -->
              <mat-expansion-panel *ngIf="showAstigmatism$ | async" hideToggle #astigmatismPanel
                #panel="matExpansionPanel">
                <mat-expansion-panel-header>
                  <mat-panel-title class="panel-title">
                    <zat-result-panel-title [image]="'astigmatism_test_small'" [imageId]="'astigmatismTestSmall'"
                      [testName]="t('astigmatismTest.result.testName')" [expended]="astigmatismPanel.opened"
                      [result]="imageAstigmatismSummarized$ | async">
                    </zat-result-panel-title>
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <zat-astigmatism-test-result class="individual-result" [results]="resultAstigmatism$ | async"
                  [image]="imageAstigmatism$ | async">
                </zat-astigmatism-test-result>
              </mat-expansion-panel>

              <!-- AMSLER -->
              <div *ngIf="showAmsler$ | async">
                <mat-expansion-panel hideToggle #amslerPanel *ngIf="!(isUSMode$|async)"
                  #panel="matExpansionPanel">
                  <mat-expansion-panel-header>
                    <mat-panel-title class="panel-title">
                      <zat-result-panel-title [image]="'amsler_test_small'" [imageId]="'amslerTestSmall'"
                        [testName]="t('amslerTest.result.testName')" [expended]="amslerPanel.opened"
                        [result]="imageAmslerSummarized$ | async">
                      </zat-result-panel-title>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ng-container>
                    <zat-amsler-test-result class="individual-result" [results]="resultSummary$ | async"
                      [image]="resultAmsler$ | async">
                    </zat-amsler-test-result>
                  </ng-container>
                </mat-expansion-panel>
              </div>
            </div>
          </mat-accordion>
          <zat-action-button
            *ngIf="(specialScreeningMode$ | async) === SpecialScreeningMode.ECP"
            class="ecp-result-exit-btn" type="outline" size="small-medium" (click)="emitExitEcpResult()">
            {{ (buttonText$ | async) ?? t('button.ecpResultExit') }}
          </zat-action-button>

          <div class="save-result-cta" *ngIf="(showSaveFeature$|async) && (isMegaTestActive$ | async)">
            {{t('result.generateYourCode')}}
            <zat-action-button (click)="handleResultCodeFlow()" [type]="'solid'">{{t('button.saveResults')}}</zat-action-button>
          </div>

          <!-- Send Result-Button -- Only for Webcomponent -->
          <div class="result-btn" *ngIf="
              environment.webcomponent &&
              ((specialScreeningMode$ | async) === SpecialScreeningMode.MY_ZEISS) &&
              (isMegaTestActive$ | async)
            ">
            <zat-action-button color="primary" type="solid" (click)="sendResults()"
              data-testid="button-mega-result-save">
              {{ t('button.save') }}
            </zat-action-button>
          </div>
        </div>
      </div>

      <div class="individual-result sticky">
        <zat-result-call-to-action *ngIf="!isWebcomponent && ((specialScreeningMode$ | async) === SpecialScreeningMode.MY_ZEISS)"
          (goToHomepage)="goToHomePage()" [isUSChannel]="(appChannel$ | async) === AppChannel.US"
          [isFRChannel]="(locale$ | async) === 'fr-FR'">
        </zat-result-call-to-action>
      </div>
    </div>
  </div>
</ng-container>
