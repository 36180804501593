import { SpecialScreeningMode } from '@app-shared/enums/special-screening-mode.enum';
import { createAction, props } from '@ngrx/store';
import { CountrySettings } from '../../shared/interfaces/my-zeiss-country-setting.interface';

const General = '[General]';

export const goToStoreLocatorAction = createAction(`${General} Go to Store Locator`);
export const ShowMegaTestResultsAction = createAction(`${General} Show mega-test results`);

export const RouteToParentComponentAction = createAction(`${General} Route to parent component`);
export const RouteToParentComponentSucceededAction = createAction(`${General} Routing to parent component succeeded`);

export const SaveAndGenerateResultCodeAction = createAction(`${General} Generate result code`);
export const SaveAndGenerateResultCodeFailedAction = createAction(`${General} Generate result code failed`);
export const SaveAndGenerateResultCodeSuccessAction = createAction(`${General} Generate result code succeeded`, props<{resultCode:string}>());

export const ExitEcpResultAction = createAction(`${General} Exit ECP result`);
export const ExitEcpResultSuccessAction = createAction(`${General} Exit ECP result succeeded`);

export const SetResultButtonTextAction = createAction(`${General} Set text of the button on the ecp result screen`, props<{text:string}>());

// set country setting from app-component input
export const SetMyZeissCountryAction = createAction(`${General} Set myZeissCountry Input`, props<{country:string}>());
// set country setting from backend app-config data fetched at app-init
export const SetMyZeissCountrySettingsAction = createAction(`${General} Set myZeissCountry settings`, props<{countrySettings:CountrySettings}>());

export const SelectScreeningModeAction = createAction(`${General} Select custom mode`,
  props<{screeningMode: SpecialScreeningMode}>()
);

export const SetScreenCalibrationAction = createAction(`${General} Set screen calibration`, props<{
  screenCalibration: number;
  calibDevice: string;
}>());

export const OpenAboutUsPageAction = createAction(`${General} Open about us page`);
export const OpenHomePageAction = createAction(`${General} Open home page`);
export const OpenLegalDocumentsPageAction = createAction(`${General} Open legal documents page`);
export const OpenFaqPageAction = createAction(`${General} Open faq page`);
export const OpenCheckSelectionPageAction = createAction(`${General} Open check-selection page`);

export const FinishInstructionsAction = createAction(`${General} Finish instructions`);
export const StartCheckIntroductionsPageAction = createAction(`${General} Start check-introductions page`);

export const CalibrationBrightnessSuccesAction = createAction(`${General} Calibration brightness succes`);
export const CalibrationWearYourGlassesSuccesAction = createAction(`${General} Calibration wear your glasses succes`);
export const NextInstructionAction = createAction(`${General} Next instruction`);
export const PreviousInstructionAction = createAction(`${General} Previous instruction`,props<{shouldResetCalibration:boolean}>());
