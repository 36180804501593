import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

/**
 * TODO: only used in one location currently.
 * should we get rid of this or use it in more places?
 */
@Component({
  selector: 'zat-close-button',
  templateUrl: './close-button.component.html',
  styleUrls: ['./close-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CloseButtonComponent {
  @Input() showBorder:boolean = true;
  @Output() clickClose = new EventEmitter();
}
