<zat-ovs-header [label]="headerConfig.label" [left]="headerConfig.left" [right]="headerConfig.right"
  [theme]="headerConfig.theme" (clickRight)="triggerCloseWarning()"></zat-ovs-header>
<div *ngLet="(isMegaTestActive$ | async) as isMegaTestActive" class="result-content">
  <ng-container content *transloco="let t">
    <div class="va-content" #content>

      <div class="va-content-container"
        [ngClass]="{ 'single-row': isMegaTestActive, 'webcomponent': isWebcomponent }">

        <div class="va-result-content">
          <zat-amsler-test-result [results]="resultSummary$ | async" [image]="image$ | async"
            [isFRChannel]="((locale$ | async) === 'fr-FR') && !isMegaTestActive">
          </zat-amsler-test-result>

          <div *ngIf="isMegaTestActive" class="result-status-mega-test text-body">
            <div class="result-status-mega-test-stats">
              <span class="stats-title">5/5</span>
              <span class="va-description">{{ t('result.checksCompleted') }}</span>
            </div>
            <div class="result-status-mega-test-stats">
              <span class="stats-title">0</span>
              <span class="va-description">{{ t('result.minutesLeft') }}</span>
            </div>
          </div>

          <div *ngIf="isMegaTestActive">
            <div class="va-content--mega-test">
              <zat-action-button class="result-button" color="primary" type="solid"
                (click)="goToResultPage()" data-testid="button-amsler-result-show">
                {{ t('button.showAllResults') }}
              </zat-action-button>
            </div>
          </div>

          <!-- Send Result-Button -- Only for Webcomponent Single Journey -->
          <div
            *ngIf="isWebcomponent && ((specialScreeningMode$ | async) === SpecialScreeningMode.MY_ZEISS) && !isMegaTestActive"
            class="result-btn">
            <zat-action-button class="result-button" color="primary" type="solid"
              (click)="sendResults()" data-testid="button-amsler-result-save">
              {{ t('button.save') }}
            </zat-action-button>
          </div>
        </div>

        <zat-result-call-to-action
          *ngIf="!isWebcomponent && ((specialScreeningMode$ | async) === SpecialScreeningMode.MY_ZEISS) && !isMegaTestActive"
          [isFRChannel]="(locale$ | async) === 'fr-FR'">
        </zat-result-call-to-action>
      </div>
    </div>

  </ng-container>
</div>
