import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, tap } from 'rxjs';

// From shared folder
import { TEST_TYPES } from '../../../shared/enums/test-types';
import { GeneralService } from '../../../services/general.service';

@UntilDestroy()
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'zat-test-group',
  templateUrl: './test-group.component.html',
  styleUrls: ['./test-group.component.scss']
})
export class TestGroupComponent implements OnInit, OnDestroy {

  @Input() title: string;

  private allTests = [
    TEST_TYPES.ACUITY,
    TEST_TYPES.CONTRAST,
    TEST_TYPES.COLOR,
    TEST_TYPES.ASTIGMATISM,
    TEST_TYPES.AMSLER
  ];

  public availableTests$:BehaviorSubject<TEST_TYPES[]>= new BehaviorSubject(this.allTests);

  itemPerView: number = 1.15;

  constructor(
    private store: Store,
    private generalService: GeneralService
  ) {}

  ngOnInit(): void {
    // if we are in US mode don't show AMSLER test
    this.generalService.hideLastTest$().pipe(
      tap((isUSMode)=>{
        console.log('#### isUSMode in test-group: ', isUSMode);
        if (isUSMode) {
          let testsWithoutAmser = this.allTests.filter(
            (t) => t !== TEST_TYPES.AMSLER
          );
          this.availableTests$.next(testsWithoutAmser);
        }else{
          this.availableTests$.next(this.allTests);
          console.log('#### available tests: ',this.availableTests$.getValue());
        }
      }),
      untilDestroyed(this)
    ).subscribe();
  }

  ngOnDestroy() {}

  updateIndex() {
    // keep updateIndex()-function so that the slider listens for event changes
  }
}
