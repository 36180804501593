import {
  ecpResultButtonText,
  getSpecialScreeningMode
} from './../../store/selectors/app.selectors';
import {
  Component,
  OnInit,
  ViewChildren,
  QueryList,
  ChangeDetectionStrategy,
  HostListener,
  AfterViewInit,
  ViewChild
} from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';
import { UntilDestroy } from '@ngneat/until-destroy';
import { delay, filter, map, take, tap } from 'rxjs/operators';
import { Location } from '@angular/common';

// Stuff for general
import { environment } from '../../../environments/environment';
import {
  ExitEcpResultAction,
  OpenHomePageAction,
  SaveAndGenerateResultCodeAction
} from '../../store/actions/general.actions';
import { TestTypeActions } from '../../store/actions';
import { AppSelectors } from '../../store/selectors';
import { GeneralService } from '../../services/general.service';

// Special for checks
import { AcuityValues } from '../../acuity-test/shared/interfaces';
import { AstigmatismSelectors } from '../../astigmatism-test/store';
import { AmslerSelectors } from '../../amsler-test/store';
import { AstigmatismLevel } from '../../astigmatism-test/shared/enums/astigmatism-level.enum';
import { AcuitySelectors } from '../../acuity-test/store';
import { ColorResultLevels } from '../../color-test/shared';
import { ColorActions, ColorSelectors } from '../../color-test/store';
import { ContrastSelectors } from '../../contrast-test/store';

// From shared folder
import { fadeInAnimation } from '../../shared/animations';
import { CloseDialogContext } from '../../shared/components/close-dialog/close-dialog-context.enum';
import { SendResultsAction } from '../../store/actions/test-type.actions';
import {
  AppChannel,
  TEST_RESULT,
  TEST_RESULT_STRING
} from '../../shared/enums';
import { RoutePathes } from '../../shared/enums/route-pathes.enum';
import { TEST_TYPES } from '../../shared/enums/test-types';
import { HeaderConfig } from '../../shared/interfaces/header-config.interface';
import {
  HeaderColorEnum,
  HeaderLabelEnum,
  HeaderEnum
} from '../../shared/enums/header.enum';
import {
  GA4_EventDetail,
  GA4_EventAction,
  GA4_EventName,
  GA4_EventType,
  GA4_EventValue
} from '../../shared/enums/ga4.enum';
import { SpecialScreeningMode } from '../../shared/enums/special-screening-mode.enum';
import { TranslocoService } from '@ngneat/transloco';
import { AnalyticsService } from '../../services/analytics.service';
import { AppInsightsService } from '../../services/app-insights.service';

@UntilDestroy()
@Component({
  selector: 'zat-result-page',
  templateUrl: './result-page.component.html',
  styleUrls: ['./result-page.component.scss'],
  animations: [fadeInAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResultPageComponent implements OnInit, AfterViewInit {
  specialScreeningMode$: Observable<SpecialScreeningMode> = this.store.select(
    getSpecialScreeningMode
  );
  public SpecialScreeningMode = SpecialScreeningMode;
  public environment = environment;

  public isWebcomponent: boolean = environment.webcomponent;
  public vaResult$: Observable<{ sumResult: number; eyesResult: TEST_RESULT }> =
    this.store.select(AcuitySelectors.getVisualAcuityEyesResult);
  public vaValues$: Observable<AcuityValues> = this.store.select(
    AcuitySelectors.getVisualAcuityValues
  );
  public imageAcuity$: Observable<{
    leftEye: TEST_RESULT_STRING;
    rightEye: TEST_RESULT_STRING;
  }> = this.store.select(AcuitySelectors.getResultImage);
  public imageAcuitySummarized$: Observable<TEST_RESULT_STRING> =
    this.store.select(AcuitySelectors.getResultImageLowerScore);

  public contrastResults$: Observable<{
    sumResult: number;
    eyesResult: TEST_RESULT;
    right: TEST_RESULT;
    left: TEST_RESULT;
  }> = this.store.select(ContrastSelectors.getEyeContrastsResult);
  public imageContrast$: Observable<{
    leftEye: TEST_RESULT_STRING;
    rightEye: TEST_RESULT_STRING;
  }> = this.store.select(ContrastSelectors.getResultImage);
  public imageContrastSummarized$: Observable<TEST_RESULT_STRING> =
    this.store.select(ContrastSelectors.getResultImageLowerScore);

  public percent$: Observable<number> = this.store.select(
    ColorSelectors.getPercent
  );
  public level$: Observable<ColorResultLevels> = this.store.select(
    ColorSelectors.getLevel
  );
  public imageColor$: Observable<TEST_RESULT_STRING> = this.store.select(
    ColorSelectors.getResultImage
  );

  public resultAstigmatism$: Observable<AstigmatismLevel> = this.store.select(
    AstigmatismSelectors.getTestResult
  );
  public resultAstigmatismSmiley$: Observable<AstigmatismLevel> =
    this.store.select(AstigmatismSelectors.getTestResultSmiley);
  public imageAstigmatism$: Observable<{
    leftEye: TEST_RESULT_STRING;
    rightEye: TEST_RESULT_STRING;
  }> = this.store.select(AstigmatismSelectors.getResultImage);
  public imageAstigmatismSummarized$: Observable<TEST_RESULT_STRING> =
    this.store.select(AstigmatismSelectors.getResultImageLowerScore);

  public resultAmsler$: Observable<{
    leftEye: TEST_RESULT_STRING;
    rightEye: TEST_RESULT_STRING;
  }> = this.store.select(AmslerSelectors.getResults);
  public resultSummary$: Observable<{
    sumResult: number;
    eyesResult: TEST_RESULT;
  }> = this.store.select(AmslerSelectors.getResultSummary);
  public imageAmslerSummarized$: Observable<TEST_RESULT_STRING> =
    this.store.select(AmslerSelectors.getResultImageLowerScore);

  appChannel$: Observable<AppChannel> = this.store.select(
    AppSelectors.getChannel
  );
  locale$: Observable<string> = this.store.select(AppSelectors.getLocale);

  public TEST_TYPES = TEST_TYPES;
  public isMegaTestActive$: Observable<boolean> = this.store.select(
    AppSelectors.getMegaTestActive
  );
  public buttonText$ = this.store.select(ecpResultButtonText);
  public showSaveFeature$ = this.generalService.showImportSaveOption$();
  public isUSMode$: Observable<boolean> = this.generalService.hideLastTest$();

  public env = environment;

  showAmsler$: Observable<boolean> = this.store.select(
    AmslerSelectors.isFinished
  );
  showAstigmatism$: Observable<boolean> = this.store.select(
    AstigmatismSelectors.getBothEyesFinished
  );
  showColor$: Observable<boolean> = this.store.select(
    ColorSelectors.isFinished
  );
  showContrast$: Observable<boolean> = this.store.select(
    ContrastSelectors.getBothEyesFinished
  );
  showAcuity$: Observable<boolean> = this.store.select(
    AcuitySelectors.getBothEyesFinished
  );

  public ColorResultLevels = ColorResultLevels;
  public levels = AstigmatismLevel;
  public AppChannel = AppChannel;

  headerConfig: HeaderConfig = {
    label: HeaderLabelEnum.CheckResult,
    left: HeaderEnum.Empty,
    right: HeaderEnum.Close,
    theme: HeaderColorEnum.LightMode
  } as HeaderConfig;

  @ViewChildren('panel') panels: QueryList<MatExpansionPanel>;
  @ViewChild('accordion') accordion: MatAccordion;
  accordionState: boolean = false;

  // trigger close warning dialog when browser back button is clicked
  @HostListener('window:popstate', ['$event'])
  onPopState() {
    if (!environment.webcomponent) {
      history.pushState(null, null, location.href);
      this.triggerCloseWarning();
    }
  }

  constructor(
    private store: Store,
    private logger: AppInsightsService,
    private generalService: GeneralService,
    private locationService: Location,
    private transloco: TranslocoService,
    private analytics: AnalyticsService
  ) {
    history.pushState(null, null, location.href);
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    /* If only one result is shown it should be automatically expanded,
     * otherwise the screen looks oddly compressed.
     */
    this.panels.changes
      .pipe(
        tap((changes) => {
          if (this.panels && this.panels.length === 1) {
            for (let panel of this.panels) {
              panel.expanded = true;
            }
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {}

  get panelState(): boolean {
    if (this.panels === undefined) {
      return;
    }

    if (this.panels.find((panel) => panel.expanded === true)) {
      this.accordionState = true;
      return true;
    } else {
      this.accordionState = false;
      return false;
    }
  }

  handleResultCodeFlow() {
    // result data will be collected from store and resultCode generated
    this.store.dispatch(SaveAndGenerateResultCodeAction());
  }

  emitExitEcpResult() {
    // dispatch action to notify app.component via store flag to emit Output (user clicked back)
    this.store.dispatch(ExitEcpResultAction());
  }

  triggerCloseWarning(): void {
    this.analytics.createCustomEvent({
      event: 'event',
      eventName: `${GA4_EventName.CTA}`,
      eventAction: `${GA4_EventAction.Click}`,
      eventType: `${GA4_EventType.Internal}`,
      eventValue: `${GA4_EventValue.ExitResultPage}`,
      eventDetail: `${GA4_EventDetail.ResultPage} - ${GA4_EventDetail.Summary}`
    });

    this.specialScreeningMode$
      .pipe(delay(0), take(1))
      .subscribe((mode: SpecialScreeningMode) => {
        this.store.dispatch(
          ColorActions.ShowCloseWarningAction({
            context:
              mode === SpecialScreeningMode.ECP
                ? CloseDialogContext.ResultECP
                : CloseDialogContext.ResultNotECP,
            previousPath: this.locationService.path()
          })
        );
      });
  }

  goToHomePage(): void {
    this.store.dispatch(OpenHomePageAction());
  }

  startTest(): void {
    this.store.dispatch(TestTypeActions.ClearAllTests());
    this.store.dispatch(TestTypeActions.RunMegaTest());

    // set test type to acuity-check
    this.store.dispatch(
      TestTypeActions.SetActiveTest({ testType: TEST_TYPES.ACUITY })
    );

    this.store
      .select(AppSelectors.getActiveTest)
      .pipe(
        filter((testType) => testType === TEST_TYPES.ACUITY),
        take(1)
      )
      .subscribe(() => {
        // route to calibrations
        this.generalService.routeToNextScreen(RoutePathes.CalibrateBrightness);
      });
  }

  sendResults(): void {
    this.analytics.createCustomEvent({
      event: 'event',
      eventName: `${GA4_EventName.CTA}`,
      eventAction: `${GA4_EventAction.Click}`,
      eventType: `${GA4_EventType.Internal}`,
      eventValue: `${GA4_EventValue.SaveResults}`,
      eventDetail: `${GA4_EventDetail.ResultPage} - ${GA4_EventDetail.Summary}`
    });

    this.logger.info('### Send result', 'Mega-test');
    this.store.dispatch(SendResultsAction());
  }

  openAll(): void {
    this.accordion.openAll();
  }

  closeAll(): void {
    this.accordion.closeAll();
  }

  checkTheKey(event: any): void {
    console.log('### this.panelState', this.panelState);
    if (event.code === 'Space' || event.code === 'Enter') {
      if (!this.panelState) {
        this.openAll();
      } else {
        this.closeAll();
      }
    }
  }
}
