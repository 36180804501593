import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

// Stuff for general
import { environment } from '../../environments/environment';
import {
  Observable,
  catchError,
  combineLatest,
  map,
  of,
  switchMap,
  take
} from 'rxjs';
import { AstigmatismSelectors } from '../astigmatism-test/store';
import { ColorSelectors } from '../color-test/store';
import { ContrastSelectors } from '../contrast-test/store';
import { AcuitySelectors } from '../acuity-test/store';
import { Store } from '@ngrx/store';
import { EyeResult } from '../shared/interfaces/send-check-results.interface';
import { AmslerSelectors } from '../amsler-test/store';
import { WebResultsService } from './web-results.service';
import { CountrySettings } from '../shared/interfaces/my-zeiss-country-setting.interface';

@Injectable({
  providedIn: 'root'
})
export class ContentDownloadService {
  constructor(
    private http: HttpClient,
    private store: Store,
    private resultService: WebResultsService
  ) {}

  getMyZeissCountrySetting$(): Observable<CountrySettings> {
    const url = `${environment.baseUrl}/content`;
    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'EsbApi-Subscription-Key': environment.subscriptionKey
        }),
        params: new HttpParams().set('resource-name', 'myZeissCountry'),
        responseType: 'json'
      })
      .pipe(
        map((result) => {
          console.log('country settings result from content service: ', result);
          return result as CountrySettings;
        }),
        catchError((error, caught) => {
          console.error('error during myZeissCountry requrest: ', error);
          return of(undefined);
        })
      );
  }

  /**
   * this is currently used to get the whitelist from the content service
   * @returns
   */
  getContent<T>(resourceName: string) {
    const url = `${environment.baseUrl}/content`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'EsbApi-Subscription-Key': environment.subscriptionKey
      }),
      params: new HttpParams().set('resource-name', resourceName)
    };

    return this.http.get<T>(url, httpOptions);
  }

  collectResultData$(): Observable<any> {
    // exclude Amsler for now, since this is intended for en-US
    return of(null).pipe(
      switchMap(() => {
        return combineLatest([
          this.store.select(AcuitySelectors.getResultImage).pipe(take(1)),
          this.store.select(ContrastSelectors.getResultImage).pipe(take(1)),
          this.store.select(ColorSelectors.getResultImage).pipe(take(1)),
          this.store.select(AstigmatismSelectors.getResultImage).pipe(take(1)),
          this.store.select(AmslerSelectors.getResults).pipe(take(1)),
          // Finish-Flags
          this.store.select(AcuitySelectors.getBothEyesFinished).pipe(take(1)),
          this.store
            .select(ContrastSelectors.getBothEyesFinished)
            .pipe(take(1)),
          this.store.select(ColorSelectors.isFinished).pipe(take(1)),
          this.store
            .select(AstigmatismSelectors.getBothEyesFinished)
            .pipe(take(1))
          // keep for later since this feature might get ported outside US
          //this.store.select(AmslerSelectors.isFinished).pipe(take(1))
        ]);
      }),
      map(
        ([
          acuityImage,
          contrastImage,
          colorImage,
          astigmatismImage,
          amslerImage,
          // Finish-Flags
          isAcuityFinish,
          isContrastFinish,
          isColorFinish,
          isAstigmatismFinish
          //isAmslerFinish
        ]) => {
          let result = this.resultService.generateResultSet(
            acuityImage,
            contrastImage,
            { leftEye: colorImage, rightEye: colorImage } as EyeResult,
            astigmatismImage,
            amslerImage,
            // Finish-Flags
            isAcuityFinish,
            isContrastFinish,
            isColorFinish,
            isAstigmatismFinish,
            false //isAmslerFinish
          );
          return result;
        }
      )
    );

    //example, keep for reference and future debugging
    /*
    const dummyResultData = {
      datetime: '2023-11-07T11:37:19.071Z',
      results: [
        {
          type: 'acuity-check',
          eyeResults: {
            left: 3,
            right: 3
          }
        }
      ]
    };
    return of(dummyResultData);
    */
  }

  /**
   * generate resultCode from provided resultData via cHubs API
   * @returns will return a result code or throw an error
   */
  getResultCode$(resultData: any): Observable<string> {
    const chubBasePath = environment.chubBaseUrl;
    const options = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    return this.http
      .post(chubBasePath + '/api/ovs-session', resultData, options)
      .pipe(
        map((requestAnswer) => {
          return (requestAnswer as any).key;
        })
      );
  }
}
