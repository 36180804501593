<div class="root" *transloco="let t">
  <div class="toast-container" [ngClass]="{'fade-inOut-toast':isToastVisible}">
    <div class="toast-overlay">
      <!-- checkmark in circle -->
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M18.8777 8.07989L18.1302 7.41553L10.504 15.9951L6.87766 11.9155L6.13025 12.5799L10.504 17.5003L18.8777 8.07989Z"
          fill="#1E8565" />
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M24.5039 12.7476C24.5039 19.375 19.1313 24.7476 12.5039 24.7476C5.87649 24.7476 0.503906 19.375 0.503906 12.7476C0.503906 6.12014 5.87649 0.747559 12.5039 0.747559C19.1313 0.747559 24.5039 6.12014 24.5039 12.7476ZM23.5039 12.7476C23.5039 18.8227 18.579 23.7476 12.5039 23.7476C6.42877 23.7476 1.50391 18.8227 1.50391 12.7476C1.50391 6.67243 6.42877 1.74756 12.5039 1.74756C18.579 1.74756 23.5039 6.67243 23.5039 12.7476Z"
          fill="#1E8565" />
      </svg>
      <span>{{t('resultCodePage.toastMessage')}}</span>
    </div>
  </div>
  <zat-close-button (clickClose)="handleClose()" [showBorder]="false"></zat-close-button>
  <h1>{{t('resultCodePage.header')}}</h1>
  <div class="code-container">
    <span class="code-header">{{t('resultCodePage.resultCodeHeader')}}</span>
    <span>{{ resultCode$ | async }}
      <!--copy-to-clipboard icon-->
      <button class="clipboard-button" (click)="handleCopyToClipboard()">
        <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M4.95703 2.1888H12.957V12.8555H4.95703V2.1888ZM3.6237 2.1888C3.6237 1.45242 4.22065 0.855469 4.95703 0.855469H12.957C13.6934 0.855469 14.2904 1.45242 14.2904 2.1888V12.8555C14.2904 13.5918 13.6934 14.1888 12.957 14.1888H4.95703C4.22065 14.1888 3.6237 13.5918 3.6237 12.8555V2.1888ZM0.957031 4.85547V14.8555C0.957031 15.96 1.85246 16.8555 2.95703 16.8555H10.2904V15.5221H2.95703C2.58884 15.5221 2.29036 15.2237 2.29036 14.8555V4.85547H0.957031Z"
            fill="#0072EF" />
        </svg>
      </button>
    </span>
  </div>
  <h3>{{t('resultCodePage.subHeader')}}</h3>
  <span class="body-text">
    {{t('resultCodePage.codeExplanation')}}
  </span>
  <zat-action-button type="outline" (click)="handleSignUp()">
    {{t('resultCodePage.signInButton')}}
    <!-- forward arrow icon on button after text -->
    <svg class="svg-arrow" width="17" height="9" viewBox="0 0 17 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.3613 4.00885L11.2129 0.860428L11.92 0.15332L16.2736 4.50687L11.92 8.86043L11.2129 8.15332L14.3574 5.00885H0.566406V4.00885H14.3613Z" fill="#0072EF"/>
    </svg>
  </zat-action-button>
  <span class="account-cta">
    {{t('resultCodePage.signInCtaText')}}
    <a [href]="signUpUrl" target="_blank" (click)="handleSignInAnalytics()">{{t('resultCodePage.signInLink')}}</a>
  </span>
  <span class="footnote">
    {{t('resultCodePage.footnote')}}
  </span>
</div>
