import { transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';

// From shared folder
import { RouteAnimations } from '../../../shared/animations';

/**
 * TODO: *REFACTOR* try to integrate the functionality of this component that still gets used
 *                  (probably only animation handling) into the AppComponent.
 *                  That way we don't have to wrap a router-outlet into a router-outlet
 */
@Component({
  selector: 'zat-app-wrapper',
  templateUrl: './app-wrapper.component.html',
  styleUrls: ['./app-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('routeAnimation', [
      transition(`* <=> *`, RouteAnimations.CrossFade)
    ])
  ]
})
export class AppWrapperComponent { }
