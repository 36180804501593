import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, switchMap } from 'rxjs';

// Stuff for general
import { environment } from '../../../environments/environment';
import { StoreLocatorService } from '../../services/store-locator.service';
import { OpenHomePageAction } from '../../store/actions/general.actions';
import { AppSelectors } from '../../store/selectors';

// From shared folder
import { HeaderConfig } from '../../shared/interfaces/header-config.interface';
import { HeaderColorEnum, HeaderLabelEnum, HeaderEnum } from '../../shared/enums/header.enum';

@Component({
  selector: 'zat-locator-page',
  templateUrl: './locator-page.component.html',
  styleUrls: ['./locator-page.component.scss']
})
export class LocatorPageComponent implements OnInit {
  isWebcomponent: boolean;

  headerConfig: HeaderConfig = {
    label: HeaderLabelEnum.StoreLocator,
    left: HeaderEnum.Back,
    right: HeaderEnum.Empty,
    theme: HeaderColorEnum.LightMode
  } as HeaderConfig;

  constructor(
    private location: Location,
    private store: Store,
    private locatorService: StoreLocatorService
  ) { }

  ngOnInit(): void {
    this.loadStoreLocatorFromJS$().subscribe();
    this.isWebcomponent = environment.webcomponent;
  }

  loadStoreLocatorFromJS$(): Observable<void> {
    return of(null).pipe(
      switchMap(() => this.locatorService.loadScript$('https://lf.o-c.io/static/js-plugin/store-locator.js')),
      switchMap(() => this.locatorService.loadScript$('./assets/scripts/store-locator.js'))
    );
  }

  goToPreviousScreen(): void {
    this.store
      .select(AppSelectors.getPreviousURL)
      .subscribe(url => {
        const previousIsResultPage = url?.indexOf('check/result') > -1;
        if (previousIsResultPage) {
          this.location.back();
        } else {
          this.goToHomePage();
        }
      })
      .unsubscribe();
  }

  goToHomePage(): void {
    this.store.dispatch(OpenHomePageAction());
  }
}
