import { createSelector } from '@ngrx/store';

// Special for checks
import { AstigmatismLevel } from '../../shared/enums/astigmatism-level.enum';
import { fromAstigmatism } from '../reducers';

// From shared folder
import { Answer, Eyes, TEST_RESULT } from '../../../shared/enums';
import { resultImageConverter } from '../../../shared/utils';
import { answerResultConverter } from '../../../shared/utils/answer-result-converter';

export const selectAstigmatism = (state: {
  astigmatism: fromAstigmatism.AstigmatismState;
}) => state.astigmatism;

export const getActiveEye = createSelector(
  selectAstigmatism,
  (state: fromAstigmatism.AstigmatismState) => {
    // prevent errors when accessing this state object from ActiveEyeGuard
    if (!state) {
      return Eyes.RIGHT;
    }

    return state.tests.activeEye;
  }
);

export const getTestResult = createSelector(
  selectAstigmatism,
  (state: fromAstigmatism.AstigmatismState) => {
    const rAnswer = state.rightEyeAstigmatism.answer;
    const lAnswer = state.leftEyeAstigmatism.answer;

    if (rAnswer === Answer.YES && lAnswer === Answer.YES) {
      return AstigmatismLevel.EXCELLENT;
    }

    if (
      (rAnswer === Answer.YES && lAnswer === Answer.NO) ||
      (rAnswer === Answer.NO && lAnswer === Answer.YES)
    ) {
      return AstigmatismLevel.FINE;
    }

    return AstigmatismLevel.REDUCED;
  }
);

export const getTestResultSmiley = createSelector(
  selectAstigmatism,
  (state: fromAstigmatism.AstigmatismState) => {
    const rAnswer = state.rightEyeAstigmatism.answer;
    const lAnswer = state.leftEyeAstigmatism.answer;

    if (rAnswer === Answer.YES && lAnswer === Answer.YES) {
      return AstigmatismLevel.EXCELLENT;
    }

    return AstigmatismLevel.REDUCED;
  }
);

export const getResultImage = createSelector(
  selectAstigmatism,
  (state: fromAstigmatism.AstigmatismState) => {
    const left = state.leftEyeAstigmatism.answer;
    const right = state.rightEyeAstigmatism.answer;

    const leftResult = answerResultConverter(left);
    const rightResult = answerResultConverter(right);

    const leftEye = resultImageConverter(leftResult);
    const rightEye = resultImageConverter(rightResult);
    return {
      leftEye,
      rightEye
    };
  }
);

// get the summarized result for displaying one icon (if there are two different results for
// both eyes, then take the lower result score)
export const getResultImageLowerScore = createSelector(
  selectAstigmatism,
  (state: fromAstigmatism.AstigmatismState) => {
    const left = answerResultConverter(state.leftEyeAstigmatism.answer);
    const right = answerResultConverter(state.rightEyeAstigmatism.answer);

    let lowerScore;
    if (right < left) {
      lowerScore = right;
    } else {
      lowerScore = left;
    }

    const summarizedResult = resultImageConverter(lowerScore);
    return summarizedResult;
  }
);

export const getAstigmatismAnswer = createSelector(
  selectAstigmatism,
  (state: fromAstigmatism.AstigmatismState) => {
    const answerRight = state.rightEyeAstigmatism.answer;
    const answerLeft = state.leftEyeAstigmatism.answer;

    return {
      answerLeft,
      answerRight
    };
  }
);

export const getBothEyesFinished = createSelector(
  selectAstigmatism,
  (state: fromAstigmatism.AstigmatismState) => {
    const rightEyeFinished = state.rightEyeAstigmatism.finished;
    const leftEyeFinished = state.leftEyeAstigmatism.finished;

    return rightEyeFinished && leftEyeFinished;
  }
);
