export const environment = {
  pageDevEnvironment: 'live',
  production: true,
  debugMode: false,
  pwa: false,
  webcomponent: true,
  semanticVersion: '3.12.0',
  // chub will have a funnel from our web version to their login (result-code -> sign in)
  // for now this isn't live for PROD
  chubGoLiveToggle: false,
  chubBaseUrl: 'https://myzeissvision-stg.zeiss.com',
  gtmIdOVS: 'GTM-WWQQS7V',
  callbackURL:
    'https://test-jouwopticien.zeiss.nl/visual-acuity-results?features=vaCheckJourney',
  contactURL: 'https://jouwopticien.zeiss.nl/contact',
  serverUrl: 'https://stovsprodwe01.azureedge.net',
  licenseUrl: 'https://visionscreening.zeiss.com/fds.txt',
  baseUrl: 'https://esb.zeiss.com/public/api/ovs/v1',
  subscriptionKey: '1eb492da48ff4015aede68c2e7ac2c27',
  logging: {
    logLevels: [
      {
        loggerName: 'root',
        logLevel: 'DEBUG'
      }
    ],
    browserConsoleAppender: {
      threshold: 'ERROR'
    },
    localStorageAppender: {
      localStorageKey: 'app.logging',
      threshold: 'ALL',
      maxMessages: 300
    }
  },
  appInsights: {
    instrumentationKey: 'a9e02fb1-ea05-4c9f-bec1-49167f22907e'
  },
  emailEndpoint: '',
  emailSubkey: '',
  animationDuration: 0.5,
  azureAccount: 'stovsprodwe01',
  azureSas:
    '?sv=2022-11-02&ss=t&srt=co&sp=wac&se=2024-05-24T18:35:09Z&st=2023-05-24T10:35:09Z&spr=https&sig=JKcS2WqymT2OtzJvkd%2FA4L06yHIdbQpHzM7Jm0S8TAQ%3D'
};

