import { createSelector } from '@ngrx/store';

// Stuff for general
import { fromApp } from '../reducers';

// From shared folder
import { appChannelMap } from '../../shared/app-channels';
import { CountrySettings, MyZeissCountrySetting } from '../../shared/interfaces/my-zeiss-country-setting.interface';

export const selectApp = (state: { app: fromApp.AppState }) => state.app;
// TODO: *REDUX* properly implement feature selector for the general reducer
//export const selectApp = createFeatureSelector<AppState>('generalState');

export const getDebug = createSelector(selectApp, (state: fromApp.AppState) => {
  return state.debug;
});

export const getMegaTestActive = createSelector(
  selectApp,
  (state: fromApp.AppState) => {
    return state.megaTest.active;
  }
);

export const getMegaTestList = createSelector(
  selectApp,
  (state: fromApp.AppState) => {
    return state.megaTest.tests;
  }
);

export const getActiveTest = createSelector(
  selectApp,
  (state: fromApp.AppState) => {
    return state.activeTest;
  }
);

export const getLocale = createSelector(
  selectApp,
  (state: fromApp.AppState) => {
    return state.locale;
  }
);

export const getChannel = createSelector(
  selectApp,
  (state: fromApp.AppState) => {
    return appChannelMap.get(state.locale);
  }
);

export const isEnChannel = createSelector(
  selectApp,
  (state: fromApp.AppState) => {
    return state.locale.startsWith('en-');
  }
)

export const getCookiesPreferencesText = createSelector(
  selectApp,
  (state: fromApp.AppState) => {
    return state.cookiesPreferencesText;
  }
);

export const getPreviousURL = createSelector(
  selectApp,
  (state: fromApp.AppState) => {
    return state.previousURL;
  }
);

export const getLoadingState = createSelector(
  selectApp,
  (state: fromApp.AppState) => state.isLoading
);

export const isCalibrationDone = createSelector(
  selectApp,
  (state: fromApp.AppState) => state.allCalibrationsDone
);

export const getCloseButton = createSelector(
  selectApp,
  (state: fromApp.AppState) => state.displayCloseButton
);

export const getTestTypesForDate = createSelector(
  selectApp,
  (state: fromApp.AppState) => {
    return state.testsFromDate;
  }
);

export const getPermissionState = createSelector(
  selectApp,
  (state: fromApp.AppState) => {
    return state.permission;
  }
);

export const getContinueWithoutCheck = createSelector(
  selectApp,
  (state: fromApp.AppState) => {
    return state.continueWithoutCheck;
  }
);

export const getAgreeImportantNotes = createSelector(
  selectApp,
  (state: fromApp.AppState) => {
    return state.agreeImportantNotes;
  }
);

export const getTestTypeCode = createSelector(
  selectApp,
  (state: fromApp.AppState) => {
    return state.testTypeCode;
  }
);

export const getSendResult = createSelector(
  selectApp,
  (state: fromApp.AppState) => {
    return state.sendResult;
  }
);

export const getSpecialScreeningMode = createSelector(
  selectApp,
  (state: fromApp.AppState) => {
    return state.specialScreeningMode;
  }
);

export const getScreenCalibration = createSelector(
  selectApp,
  (state: fromApp.AppState) => {
    return state.screenCalibration;
  }
);

export const shouldRouteToParentComponent = createSelector(
  selectApp,
  (state: fromApp.AppState) => {
    return state.routeToParentComponent;
  }
);

export const shouldEmitEcpResultCallToAction = createSelector(
  selectApp,
  (state: fromApp.AppState) => {
    return state.emitEcpResultEvent;
  }
);

export const ecpResultButtonText = createSelector(
  selectApp,
  (state: fromApp.AppState) => {
    return state.ecpResultButtonText;
  }
);

export const instructionsSet = createSelector(
  selectApp,
  (state: fromApp.AppState) => {
    return state.instructionsSet.instructions;
  }
);

export const getActiveInstruction = createSelector(
  selectApp,
  (state: fromApp.AppState) => {
    return state.instructionsSet.activeInstruction;
  }
);

export const getResultCode = createSelector(
  selectApp,
  (state: fromApp.AppState) => {
    return state.resultCode;
  }
);

// we want to return the setting for the input country
export const getMyZeissCountrySetting = createSelector(
  selectApp,
  (state: fromApp.AppState) => {
    let selectedCountry:string = state.myZeissCountryInput?.toUpperCase()??'INT';
    let countrySettings:CountrySettings = state.myZeissCountrySettings;
    const defaultSetting:MyZeissCountrySetting = {
      isAmslerTestEnabled: true,
      isSaveWebFeatureEnabled: false
    };

    let selectedSetting = undefined;
    // try to find setting for country
    try{
      selectedSetting = countrySettings[selectedCountry];
      console.log(' found setting for myZeissCountry: ', selectedCountry);
    }catch(err){
      // fallback to 'INT' setting
      console.error(`could not find ${selectedCountry} in settings`);
      console.log('trying to default to INT setting...');
      try{
        selectedSetting = countrySettings['INT'];
      }
      // fallback to hardcoded default setting
      catch(err){
        console.log('failed to default to INT -> using internal default setting');
        selectedSetting = defaultSetting;
      }
    }

    return selectedSetting;
  }
);
