<div class="amsler-check" *transloco="let t">
  <!-- ! The check-screen -->
  <ng-container *ngLet="(amslerTest$ | async) as test">
    <div class="amsler-check-content" *ngIf="!(toggleHint$ | async)">
      <zat-ovs-header [label]="headerConfig.label" [left]="headerConfig.left" [right]="headerConfig.right"
        [theme]="headerConfig.theme" (clickLeft)="openInfo()" (clickRight)="cross()"></zat-ovs-header>

      <div class="check-introduction">
        <ng-container *ngIf="activeEye===Eyes.LEFT; else rightEyeText">
          <label class="number">1) </label><label [innerHtml]="t('check.hint.eyeCover.right')"></label><br>
        </ng-container>
        <ng-template #rightEyeText>
          <label class="number">1) </label><label [innerHtml]="t('check.hint.eyeCover.left')"></label><br>
        </ng-template>
        <label class="number">2) </label><label [innerHtml]="t('check.hint.keep30cmDistance')"></label><br>
        <label class="number">3) </label><label class="number"
          [innerHtml]="t('amslerTest.focusOnBlackDot') + ' '"></label>
        <label class="number" [innerHtml]="t('amslerTest.doYouSeeAllSquaresRegular')"
          *ngIf="test === AmslerTests.ARE_ALL_REGULAR_AND_PERFECTLY; else secondQuestion"></label>
        <ng-template #secondQuestion>
          <label class="number fade-in-question" [innerHtml]="t('amslerTest.doYouSeeMissingParts')"></label>
        </ng-template>
      </div>

      <div class="check-card-spacing">
        <zat-amsler-test (done)="onComplete($event)"></zat-amsler-test>
      </div>
    </div>

    <!-- ! The check-hint-screen -->
    <ng-container *ngIf="toggleHint$ | async">
      <zat-how-it-works-amsler-page [question]="'amslerTest.doYouSeeAllSquaresRegular'" class="the-check-hint"
        *ngIf="test === AmslerTests.ARE_ALL_REGULAR_AND_PERFECTLY; else secondHint" (clickClose)="closeHint()">
      </zat-how-it-works-amsler-page>
      <ng-template #secondHint>
        <zat-how-it-works-amsler-page [question]="'amslerTest.doYouSeeMissingParts'" class="the-check-hint"
          (clickClose)="closeHint()">
        </zat-how-it-works-amsler-page>
      </ng-template>
    </ng-container>
  </ng-container>

  <div class="debug" *ngIf="debug$ | async">
    <span>Current eye (Test): <strong>{{ activeEye }}</strong></span><br>
    <span>Current eye (Cover): <strong>{{ activeEye !== Eyes.LEFT ? Eyes.LEFT : Eyes.RIGHT }}</strong></span><br>
    <span>Pixel pitch: <strong>{{ pixelPitch }}cm</strong></span>
  </div>
</div>
