import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Special for checks
import { AcuityCheckPageComponent } from '../acuity-check-page/acuity-check-page.component';
import { AcuityPrepareCheckPageComponent } from '../acuity-prepare-check-page/acuity-prepare-check-page.component';
import { AcuityResultPageComponent } from '../acuity-result-page/acuity-result-page.component';

// From shared folder
import { InstructionsPageComponent } from '../../../shared/pages/instructions-page/instructions-page.component';

const routes: Routes = [
  {
    path: 'instructions',
    component: InstructionsPageComponent,
    data: {
      animation: 'AcuityTestInstructionsPage'
    }
  },
  {
    path: 'start',
    component: AcuityCheckPageComponent,
    data: {
      animation: 'AcuityTestTestPage'
    }
  },
  {
    path: 'result',
    component: AcuityResultPageComponent,
    data: {
      animation: 'AcuityTestResultPage'
    }
  },
  {
    path: 'prepare',
    component: AcuityPrepareCheckPageComponent,
    data: {
      animation: 'AcuityTestPrepareCheckPage'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AcuityCheckRoutingModule { }
