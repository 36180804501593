import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { AnimationOptions } from 'ngx-lottie';

// Stuff for general
import { environment } from '../../environments/environment';
import { AppLocalizeRouterService } from '../services/app-localize-router.service';
import { AppCookiesService } from '../services/app-cookies.service';
import { AppSelectors } from '../store/selectors';
import { GeneralService } from '../services/general.service';
import {
  RouteToParentComponentAction,
  OpenAboutUsPageAction,
  OpenCheckSelectionPageAction
} from '../store/actions/general.actions';

// From shared folder
import { LottieAnimationEnum } from '../shared/enums/lottie.enum';
import { fadeInAnimation } from '../shared/animations';
import { HeaderConfig } from '../shared/interfaces/header-config.interface';
import { AppChannel } from '../shared/enums';
import {
  HeaderEnum,
  HeaderColorEnum,
  HeaderLabelEnum
} from '../shared/enums/header.enum';
import {
  GA4_EventDetail,
  GA4_EventAction,
  GA4_EventName,
  GA4_EventType,
  GA4_EventValue
} from '../shared/enums/ga4.enum';
import { SpecialScreeningMode } from '../shared/enums/special-screening-mode.enum';
import { TestTypesForImportantNotes } from '../shared/enums/test-types-important-notes.enum';
import { ShowImportantNotesAction } from '../store/actions/check-calibration.actions';
import { TranslocoService } from '@ngneat/transloco';
import { AnalyticsService } from '../services/analytics.service';

@UntilDestroy()
@Component({
  selector: 'zat-portal-page',
  templateUrl: './portal-page.component.html',
  styleUrls: ['./portal-page.component.scss'],
  animations: [fadeInAnimation]
})
export class PortalPageComponent implements OnInit, OnDestroy {
  appChannel = AppChannel;

  ch$: Observable<string>;
  specialScreeningMode$: Observable<SpecialScreeningMode>;
  SpecialScreeningMode = SpecialScreeningMode;

  environment = environment;

  headerConfig: HeaderConfig = {
    label: HeaderLabelEnum.Home,
    left: environment.webcomponent
      ? HeaderEnum.Back
      : HeaderEnum.Empty,
    right: HeaderEnum.AboutUs,
    theme: HeaderColorEnum.DarkMode
  } as HeaderConfig;

  options: AnimationOptions = null;

  constructor(
    protected store: Store,
    protected router: Router,
    protected localize: AppLocalizeRouterService,
    protected appCookiesService: AppCookiesService,
    protected generalService: GeneralService,
    protected transloco: TranslocoService,
    protected analytics: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.specialScreeningMode$ = this.store.select(
      AppSelectors.getSpecialScreeningMode
    );

    // Vertical center of CCM
    this.generalService.centerCCM();

    // Set the lottie-options
    this.options = this.generalService.getLottieOptions(
      LottieAnimationEnum.AnimatedEye
    );

    console.log('>>> [PORTAL PAGE] ngOnInit() -- end');
  }

  ngOnDestroy(): void {}

  clickCheckYourVision(): void {
    // specialScreeningMode: checks for the webcomponent whether only the
    // Acuity Check for the NL-Campaign should be started here
    this.specialScreeningMode$
      .pipe(
        tap((screeningMode: SpecialScreeningMode) => {
          if (screeningMode === SpecialScreeningMode.NL_CAMPAIGN) {
            this.store.dispatch(
              ShowImportantNotesAction({
                testType: TestTypesForImportantNotes.ACUITY
              })
            );
          } else {
            this.store.dispatch(
              ShowImportantNotesAction({
                testType: TestTypesForImportantNotes.ALL
              })
            );
          }

          this.analytics.createCustomEvent({
            event: 'event',
            eventName: `${GA4_EventName.CTA}`,
            eventAction: `${GA4_EventAction.Click}`,
            eventType: `${GA4_EventType.Internal}`,
            eventValue: `${GA4_EventValue.CheckYourVision}`,
            eventDetail: `${GA4_EventDetail.Homepage}`
          });
        })
      )
      .subscribe();
  }

  clickSelectASpecificCheck(): void {
    this.store.dispatch(OpenCheckSelectionPageAction());

    this.analytics.createCustomEvent({
      event: 'event',
      eventName: `${GA4_EventName.CTA}`,
      eventAction: `${GA4_EventAction.Click}`,
      eventType: `${GA4_EventType.Internal}`,
      eventValue: `${GA4_EventValue.SelectASpecificCheck}`,
      eventDetail: `${GA4_EventDetail.Homepage}`
    });
  }

  openInfo(): void {
    this.store.dispatch(OpenAboutUsPageAction());

    this.analytics.createCustomEvent({
      event: 'event',
      eventName: `${GA4_EventName.CTA}`,
      eventAction: `${GA4_EventAction.Click}`,
      eventType: `${GA4_EventType.Internal}`,
      eventValue: `${GA4_EventValue.InfoButton}`,
      eventDetail: `${GA4_EventDetail.Homepage}`
    });
  }

  back(): void {
    // route back externally on the parent of the webcomponent
    this.store.dispatch(RouteToParentComponentAction());
  }
}
