import { isOnline } from './shared/utils/general-utils';
import { of } from 'rxjs';
import { environment } from '../environments/environment';

// make `window.dataLayer` and `window.OneTrust` known to typescript
declare global {
  interface Window {
    dataLayer: any[];
    OneTrust: any;
  }
}

function setDataLayer(): Promise<void> {
  return new Promise((resolve) => {
    const pathname = location.pathname;
    const dataLayer = {
      pagePool: 'vis',
      pageIdentifier: 'onlinevisionscreening',
      pageCountry: 'int',
      pageArea: 'app',
      pageLanguage: 'en',
      pageDevEnvironment: environment.pageDevEnvironment
    };

    if (pathname && pathname !== '/') {
      // get language and country from locale path param.
      const [language, country] = pathname.split('/')[1].split('-');
      dataLayer.pageCountry = country;
      dataLayer.pageLanguage = language;

      document.querySelector('html').setAttribute('lang', language);
    }
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(dataLayer);
    resolve();
  });
}

function injectContainerScript(gtmId: string): Promise<void> {
  return new Promise((resolve) => {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.textContent = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl+'&ngsw-bypass';f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${gtmId}');`;
    console.log('#### [INIT GTM] inject container script: ', s);
    document.head.appendChild(s);
    resolve();
  });
}

function injectOVSContainerScript(): Promise<void> {
  const gtmId = environment.gtmIdOVS;
  return injectContainerScript(gtmId);
}

export function initGTM() {
  return () => {
    // exclude on localhost to prevent error spam during development
    let isLocalhost = location.hostname === 'localhost' || location.hostname === '127.0.0.1';
    if (isOnline() && !isLocalhost) {
      return setDataLayer()
        .then(injectOVSContainerScript)
        //.then(injectOneTrustScripts);
    } else {
      console.log('offline, skip GTM initialisation');
      return of(null);
    }
  };
}

/*function injectOneTrustScripts(): Promise<void> {
  return new Promise((resolve) => {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
    s.setAttribute('data-document-language', 'true');
    s.setAttribute('data-domain-script', '8993cba0-8683-43f1-9904-d63a3e023a9c');
    document.head.appendChild(s);

    const optanon = document.createElement('script');
    optanon.type = 'text/javascript';
    optanon.textContent = 'function OptanonWrapper() { }';
    document.head.appendChild(s);
    resolve();
  });
}

export function initOneTrust() {
  injectOneTrustScripts();
}*/
