<div class="help-checks-general" *transloco="let t">
  <!-- Cover a eye or not -->
  <div class="hint">
    <div class="media-space">
      <ng-container [ngSwitch]="coverEye">
        <app-icon id="help_cover_eye_left" name="help_cover_eye_left" [width]="96" [height]="34"
          alt="" *ngSwitchCase="instructions.COVER_LEFT_EYE">
        </app-icon>

        <app-icon id="help_cover_eye_right" name="help_cover_eye_right" [width]="96" [height]="34"
          alt="" *ngSwitchCase="instructions.COVER_RIGHT_EYE">
        </app-icon>

        <app-icon id="help_both_eyes" name="help_both_eyes" [width]="96" [height]="34"
          alt="" *ngSwitchCase="instructions.KEEP_BOTH_EYES">
        </app-icon>
      </ng-container>
    </div>
    <div class="text-space">
      <label class="text-highlight">1) </label>
      <ng-container [ngSwitch]="coverEye">
        <label *ngSwitchCase="instructions.COVER_LEFT_EYE" [innerHtml]="t('check.hint.eyeCover.left')">
        </label>

        <label *ngSwitchCase="instructions.COVER_RIGHT_EYE" [innerHtml]="t('check.hint.eyeCover.right')">
        </label>

        <label *ngSwitchCase="instructions.KEEP_BOTH_EYES" [innerHtml]="t('check.hint.bothEyesOpen')">
        </label>
      </ng-container>
    </div>
  </div>

  <!-- Keep distace -->
  <div class="hint">
    <div class="media-space">
      <app-icon id="keep_distance_desktop" name="keep_distance_desktop" [width]="96" [height]="63"
        alt="">
      </app-icon>
    </div>
    <div class="text-space">
      <label class="text-highlight">2) </label>
      <ng-container [ngSwitch]="distance">
        <label *ngSwitchCase="instructions.KEEP_AN_ARM" [innerHtml]="t('check.hint.keepArmsLength')">
        </label>

        <label *ngSwitchCase="instructions.KEEP_30_CM" [innerHtml]="t('check.hint.keep30cmDistance')">
        </label>
      </ng-container>
    </div>
  </div>
</div>
