import { ColorResults } from '../enums';
import { ColorTestItem } from '../interfaces';

export const getResultRow = (answer: string | number, test: ColorTestItem, no: number) => {
  let result: ColorResults;

  if (test.correctAnswer === answer) {
    result = ColorResults.CORRECT;
  } else if (test.confusionNumber === answer) {
    result = ColorResults.PARTLY_CORRECT;
  } else {
    result = ColorResults.INCORRECT;
  }

  return {
    no,
    answer,
    correct: test.correctAnswer,
    weakCVD: test.confusionNumber,
    result,
  };
};
