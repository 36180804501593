import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgLetModule } from 'ng-let';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TranslocoModule } from '@ngneat/transloco';

// Special for components & co
import { AstigmatismTestRoutingModule } from './astigmatism-test-routing.module';
import { AstigmatismDetailsPageComponent } from './astigmatism-details-page/astigmatism-details-page.component';
import { fromAstigmatism } from './store';
import { AstigmatismTestPageComponent } from './astigmatism-test-page/astigmatism-test-page.component';
import { PrepareAstigmatismTestComponent } from './prepare-astigmatism-test/prepare-astigmatism-test.component';
import { AstigmatismResultPageComponent } from './astigmatism-result-page/astigmatism-result-page.component';
import { AstigmatismEffects } from './store/effects/astigmatism.effect';
import { AppIconsModule } from '../app-icons/app-icons.module';
import { HowItWorksAstigmatismPageComponent } from './how-it-works-astigmatism-page/how-it-works-astigmatism-page.component';

// From shared folder
import { AppSharedModule } from '../shared/app-shared.module';
import { AppMaterialModule } from '../shared/app-material/app-material.module';

import { OvsComparisonSliderComponent } from '../ovs-comparison-slider/ovs-comparison-slider.component';

@NgModule({
  declarations: [
    AstigmatismDetailsPageComponent,
    AstigmatismTestPageComponent,
    PrepareAstigmatismTestComponent,
    AstigmatismResultPageComponent,
    HowItWorksAstigmatismPageComponent
  ],
  imports: [
    CommonModule,
    AstigmatismTestRoutingModule,
    AppMaterialModule,
    AppSharedModule,
    TranslocoModule,
    StoreModule.forFeature('astigmatism', fromAstigmatism.reducer),
    EffectsModule.forFeature([ AstigmatismEffects ]),
    NgLetModule,
    AppIconsModule,
    OvsComparisonSliderComponent
  ]})
export class AstigmatismTestModule {}
