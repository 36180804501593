<div class="how-it-works" *transloco="let t">
  <zat-ovs-header [label]="headerConfig.label" [left]="headerConfig.left" [right]="headerConfig.right"
    [theme]="headerConfig.theme" (clickLeft)="closeHint()"></zat-ovs-header>

  <div #scrollableElement class="how-it-works-content" tabindex="0">
    <div class="headline text-headline">
      <span class="headline-label">
        {{ t('howItWorks.title') }}
      </span>
    </div>

    <div class="check-hint">
      <zat-how-it-works-checks-general [coverEye]="instructions.KEEP_BOTH_EYES" [distance]="instructions.KEEP_AN_ARM">
      </zat-how-it-works-checks-general>

      <!-- Specify for aciuty-check -->
      <div class="hint">
        <div class="media-space">
          <app-icon id="ishihara_1" name="ishihara_1" [width]="96" [height]="96" alt=""></app-icon>
        </div>
        <div class="text-space">
          <span class="text-highlight">3) </span>
          <span>
            {{ t('colorTest.selectNumberYouSee') }}
          </span>
        </div>
      </div>
    </div>

    <zat-how-it-works-general></zat-how-it-works-general>
  </div>
</div>
