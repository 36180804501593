import { Location } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { AnimationEvent } from '@angular/animations';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { delay, filter, take } from 'rxjs/operators';

// Stuff for general
import { environment } from '../../../environments/environment';
import { AppCookiesService } from '../../services/app-cookies.service';
import { AnalyticsService } from '../../services/analytics.service';
import { AppSelectors } from '../../store/selectors';
import { TestTypeActions } from '../../store/actions';
import { AzureTableClientService } from '../../services/azure-table-client.service';
import { GeneralService } from '../../services/general.service';
import { OpenHomePageAction } from '../../store/actions/general.actions';

// Special for checks
import { LANDOLT_C_SIZE_IN_LOGMAR, MAX_CS, MIN_CS } from '../shared';
import { ContrastActions, ContrastSelectors } from '../store';

// From shared folder
import { C_ANGLES, Eyes } from '../../shared/enums';
import { fadeInAnimation, landoltCAnimation } from '../../shared/animations';
import { HeaderConfig } from '../../shared/interfaces/header-config.interface';
import { TEST_TYPES } from '../../shared/enums/test-types';
import { calculateLandoltCWidth } from '../../shared/utils';
import { RoutePathes } from '../../shared/enums/route-pathes.enum';
import {
  HeaderColorEnum,
  HeaderLabelEnum,
  HeaderEnum
} from '../../shared/enums/header.enum';
import { CloseDialogContext } from '../../shared/components/close-dialog/close-dialog-context.enum';
import {
  GA4_EventDetail,
  GA4_EventAction,
  GA4_EventName,
  GA4_EventType,
  GA4_EventValue
} from '../../shared/enums/ga4.enum';
import { AppInsightsService } from '../../services/app-insights.service';

@UntilDestroy()
@Component({
  selector: 'zat-contrast-test-page',
  templateUrl: './contrast-test-page.component.html',
  styleUrls: ['./contrast-test-page.component.scss'],
  animations: [fadeInAnimation, landoltCAnimation]
})
export class ContrastTestPageComponent implements OnInit, OnDestroy {
  megaTestActive$: Observable<boolean>;
  debug$: Observable<boolean>;

  width: number;
  rotationAngle$: Observable<C_ANGLES>;
  opacity$: Observable<number>;
  contrast$: Observable<number>;
  activeEyeFinished$: Observable<boolean>;
  directionChanges$: Observable<number>;

  activeEye: Eyes;
  testCount: number;
  contrast: number;
  activeEyeFinished: boolean;
  megaTestActive: boolean;

  // projected values to use in the view
  Eyes = Eyes;
  cAngle: C_ANGLES;
  checkType: GA4_EventType = GA4_EventType.Contrast;

  public lastIsCorrectValue: boolean;
  private currentIsCorrectValue: boolean;

  private isInitialPageTracked = false;

  public landoltCAnimationState = 'init';
  public landoltCSize = LANDOLT_C_SIZE_IN_LOGMAR; // for debugging

  private destroy$: Subject<void> = new Subject<void>();

  headerConfig: HeaderConfig = {
    label: HeaderLabelEnum.ContrastCheck,
    right: HeaderEnum.Close,
    left: HeaderEnum.Info,
    theme: HeaderColorEnum.LightMode
  } as HeaderConfig;
  toggleHint$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  // trigger close warning dialog for checks when browser back button is clicked
  @HostListener('window:popstate', ['$event'])
  onPopState() {
    if (!environment.webcomponent) {
      history.pushState(null, null, location.href);
      this.cross();
    }
  }

  constructor(
    private store: Store,
    private locationService: Location,
    private cookies: AppCookiesService,
    private logger: AppInsightsService,
    private analytics: AnalyticsService,
    private tableService: AzureTableClientService,
    private generalService: GeneralService
  ) {
    history.pushState(null, null, location.href);
  }

  ngOnInit(): void {
    this.megaTestActive$ = this.store.select(AppSelectors.getMegaTestActive);
    this.debug$ = this.store.select(AppSelectors.getDebug);

    this.store.dispatch(ContrastActions.ChangeCAngle());

    combineLatest([
      this.store.select(ContrastSelectors.getActiveEye),
      this.store.select(ContrastSelectors.getTestCount)
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([activeEye, testCount]) => {
        this.activeEye = activeEye;
        this.testCount = testCount;
        this.trackInitialVirtualPage();
      });

    this.rotationAngle$ = this.store.select(
      ContrastSelectors.getLandoltCRotationAngle
    );
    this.rotationAngle$.pipe(untilDestroyed(this)).subscribe((angle) => {
      this.cAngle = angle;
    });

    this.contrast$ = this.store.select(ContrastSelectors.getLandoltCContrast);
    this.contrast$
      .pipe(untilDestroyed(this))
      .subscribe((value) => (this.contrast = value));

    this.activeEyeFinished$ = this.store.select(
      ContrastSelectors.getActiveEyeFinished
    );
    this.activeEyeFinished$
      .pipe(untilDestroyed(this))
      .subscribe((isFinished) => (this.activeEyeFinished = isFinished));

    this.megaTestActive$.pipe(untilDestroyed(this)).subscribe((active) => {
      this.megaTestActive = active;
      this.goToResultWhenFinished(active);
    });

    const pixelPitch = this.cookies.getPixelPitch();
    this.width = calculateLandoltCWidth(LANDOLT_C_SIZE_IN_LOGMAR, pixelPitch);

    this.opacity$ = this.store.select(ContrastSelectors.getLandoltCOpacity);

    this.directionChanges$ = this.store.select(
      ContrastSelectors.getActiveEyeDirectionChanges
    );

    this.tableService.resetInteractionStartTime();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  cancelTest(): void {
    // Clear visual check analysis data to avoid cluttered entities with half finished test data
    this.tableService.clearVisualCheckData();

    this.store.dispatch(OpenHomePageAction());
  }

  onComplete(isCorrect: boolean): void {
    this.store.dispatch(ContrastActions.IncreaseTestCount());

    this.checkDirectionChanges(isCorrect);
    this.currentIsCorrectValue = isCorrect;

    if (isCorrect) {
      if (this.contrast === MIN_CS) {
        this.store.dispatch(
          ContrastActions.IncreaseMinContrastStepCounter({
            eye: this.activeEye
          })
        );
      }
      this.store.dispatch(
        ContrastActions.StoreValue({
          eye: this.activeEye,
          value: this.contrast
        })
      );
      this.store.dispatch(
        ContrastActions.ResetMaxContrastStepCounter({ eye: this.activeEye })
      );
      this.store.dispatch(ContrastActions.Decrease());
    } else {
      if (this.contrast === MAX_CS) {
        this.store.dispatch(
          ContrastActions.IncreaseMaxContrastStepCounter({
            eye: this.activeEye
          })
        );
      }
      this.store.dispatch(
        ContrastActions.ResetMinContrastStepCounter({ eye: this.activeEye })
      );

      this.store.dispatch(ContrastActions.Increase());
    }

    this.store.dispatch(ContrastActions.ChangeCAngle());

    this.lastIsCorrectValue = isCorrect;
    this.next();
  }

  private next(): void {
    this.tableService.updateAnalysisData(
      this.activeEye,
      this.currentIsCorrectValue,
      undefined,
      this.contrast
    );

    if (this.activeEyeFinished) {
      this.finishTest();
    } else {
      this.trackNewVirtualPage();
    }
  }

  private finishTest(): void {
    this.trackFinishVirtualPage();
    // finish right eye
    if (this.activeEye === Eyes.RIGHT) {
      this.store.dispatch(ContrastActions.SetActiveEye({ eye: Eyes.LEFT }));

      let rightPath = `${TEST_TYPES.CONTRAST}${RoutePathes.CheckPrepare}`;
      if (this.megaTestActive) {
        rightPath = `${RoutePathes.Default}/${rightPath}`;
      }

      this.generalService.routeToNextScreen(rightPath);
      this.store.dispatch(ContrastActions.ResetTests());

      return;
    }

    this.goToResults();
  }

  private goToResults(): void {
    this.tableService.storeAnalysisData$().subscribe((response) => {
      if (response !== null) {
        this.logger.info('#### [CONTRAST] response: ', response);
      }
    });

    // save in mega test
    if (this.megaTestActive) {
      this.store.dispatch(
        TestTypeActions.SetTestInMegaTest({ testType: TEST_TYPES.CONTRAST })
      );
    }

    // finish left eye
    let leftPath = `/${TEST_TYPES.CONTRAST}${RoutePathes.CheckResult}`;
    if (this.megaTestActive) {
      leftPath = `${RoutePathes.Default}/${leftPath}`;
    }

    this.generalService.routeToNextScreen(leftPath);
    this.store.dispatch(ContrastActions.ResetTests());
  }

  private checkDirectionChanges(isCorrect: boolean): void {
    if (
      this.lastIsCorrectValue !== undefined &&
      this.lastIsCorrectValue !== isCorrect
    ) {
      // increase stepchange counter
      this.store.dispatch(
        ContrastActions.IncreaseDirectionChangeCounter({ eye: this.activeEye })
      );
    }
  }

  // initial tracking on the first load only.
  private trackInitialVirtualPage(): void {
    if (this.isInitialPageTracked) {
      return;
    }

    this.trackVirtualPage(this.activeEye, this.testCount);
    this.isInitialPageTracked = true;
  }

  // tracking when a new landolt-c is displayed.
  private trackNewVirtualPage(): void {
    this.trackVirtualPage(this.activeEye, this.testCount);
  }

  // tracking when the test finishes.
  private trackFinishVirtualPage(): void {
    // we have to do `testCount - 1` because the count number
    // was already increase _before_ it can know if the test is finished.
    // the number we want is from the last test's testCount.
    this.trackVirtualPage(this.activeEye, this.testCount - 1, '_end');
  }

  private trackVirtualPage(
    activeEye: Eyes,
    testCount: number,
    suffix: string = ''
  ): void {
    const basePath = this.locationService.path();
    const pagePath = `${basePath}/${activeEye}_${testCount}${suffix}`;

    this.analytics.trackVirtualPage(pagePath);
  }

  onLandoltCAnimationDone(event: AnimationEvent): void {
    // always set the state to 'idle' right after the 'init' state
    if (event.toState === 'init') {
      this.landoltCAnimationState = 'idle';
    }
  }

  private goToResultWhenFinished(megaTestActive: boolean): void {
    // #646214: redirect back the result screen if the user has finished tests for both eyes
    this.store
      .select(ContrastSelectors.getBothEyesFinished)
      .pipe(filter((finished) => !!finished))
      .subscribe(() => {
        let path = `${TEST_TYPES.CONTRAST}${RoutePathes.CheckResult}`;
        if (megaTestActive) {
          path = `${RoutePathes.Default}/${path}`;
        }
        this.generalService.routeToNextScreen(path);
      })
      .unsubscribe();
  }

  openInfo(): void {
    this.toggleHint$.next(true);

    this.analytics.createCustomEvent({
      event: 'event',
      eventName: `${GA4_EventName.CTA}`,
      eventAction: `${GA4_EventAction.Click}`,
      eventType: `${GA4_EventType.Internal}`,
      eventValue: `${GA4_EventValue.Contrast}`,
      eventDetail: `${GA4_EventDetail.HowItWorks}`
    });
  }

  closeHint(): void {
    this.toggleHint$.next(false);
  }

  cross(): void {
    this.analytics.createCustomEvent({
      event: 'event',
      eventName: `${GA4_EventName.CTA}`,
      eventAction: `${GA4_EventAction.Click}`,
      eventType: `${GA4_EventType.Contrast}`,
      eventValue: `${GA4_EventValue.ExitCheck}`,
      eventDetail: `${GA4_EventDetail.Clear}`
    });

    of(null)
      .pipe(delay(0), take(1))
      .subscribe(() => {
        this.store.dispatch(
          ContrastActions.ShowCloseWarningAction({
            context: CloseDialogContext.Check,
            previousPath: this.locationService.path()
          })
        );
      });
  }
}
