import { Action, createReducer, on } from '@ngrx/store';

// Stuff for general
import { TestTypeActions } from '../../../store/actions';

// Special for checks
import { AmslerActions } from '../actions';
import { StartAmslerMegaTestSuccedAction, StartAmslerSingleCheckAction } from '../actions/amsler.actions';
import { TOTAL_TESTS } from '../../shared';

// From shared folder
import { Eyes } from '../../../shared/enums';

export const amslerFeatureKey = 'amsler';

export interface AmslerState {
  tests: {
    activeEye: Eyes;
  };
  rightEyeAmsler: {
    count: number,
    answers: boolean[],
    finished: boolean;
  };
  leftEyeAmsler: {
    count: number,
    answers: boolean[],
    finished: boolean;
  };
}

export const initialState: AmslerState = {
  tests: {
    activeEye: Eyes.RIGHT
  },
  rightEyeAmsler: {
    count: 0,
    answers: undefined,
    finished: undefined,
  },
  leftEyeAmsler: {
    count: 0,
    answers: undefined,
    finished: undefined,
  },
};

const amslerReducer = createReducer(
  initialState,

  on(AmslerActions.SetActiveEye, (state, { eye }) => ({
    ...state,
    tests: {
      ...state.tests,
      activeEye: eye,
    }
  })),

  on(AmslerActions.SetRightEyeAmsler, (state, { answer }) => {
    const answers = state.rightEyeAmsler.answers;
    const next: number = state.rightEyeAmsler.count + 1;
    const finished = TOTAL_TESTS === next;
    return {
      ...state,
      rightEyeAmsler: {
        count: next,
        answers: answers ? [...answers, answer] : [answer],
        finished,
      },
    };
  }),

  on(AmslerActions.SetLeftEyeAmsler, (state, { answer }) => {
    const answers = state.leftEyeAmsler.answers;
    const next = state.leftEyeAmsler.count + 1;
    const finished = TOTAL_TESTS === next;
    return {
      ...state,
      leftEyeAmsler: {
        count: next,
        answers: answers ? [...answers, answer] : [answer],
        finished,
      },
    };
  }),

  on(AmslerActions.SetActiveEye, (state, { eye }) => ({
    ...state,
    tests: {
      ...state.tests,
      activeEye: eye,
    }
  })),

  on(AmslerActions.ResetEyeAmsler, () => ({
    ...initialState,
  })),

  on(TestTypeActions.ResetAllChecks, () => ({
    ...initialState,
  })),

  on(AmslerActions.SetAmslerAnswersFromDate, (state, {leftEyeAnswer, rightEyeAnswer}) => ({
    ...state,
    rightEyeAmsler: {
      count: 0,
      answers: rightEyeAnswer,
      finished: true,
    },
    leftEyeAmsler: {
      count: 0,
      answers: leftEyeAnswer,
      finished: true,
    },
  })),

  // SingleStartActions
  on(StartAmslerSingleCheckAction, (state, action): AmslerState => {
    return {
      ...initialState
    };
  }),

  // Mega-Check-Actions
  on(StartAmslerMegaTestSuccedAction, (state, action): AmslerState => {
    return {
      ...initialState
    };
  })
);

export function reducer(state: AmslerState | undefined, action: Action): any {
  return amslerReducer(state, action);
}
