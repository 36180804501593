import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StoreLocatorService {

constructor(private translocoService: TranslocoService) { }

  /**
   * This function compiles all necessary data that we need to insert into
   * the store-locator URL to be able to read them out with our store-locator.js.
   *
   * @returns store locator parameters for initialization as one string
   */
   getLocatorData(): string {
    const token = 'e3ba1bc5d0bfb79a3c8f67e8330caf67';
    const activeLang = this.translocoService.getActiveLang();
    const langs = activeLang.split('-');
    const language = langs[0];
    const country = langs[1];

    const urlParameters = `token=${token}&version=production&country=${country}&language=${language}`;

    return urlParameters;
  }

   /**
    * This functions loads the store locator JS code, so that we can inject it
    * in the html at runtime.
    */
   loadScript$(url: string): Observable<any> {
    // TODO: consider extending this implementation with a member variable to
    // TODO: make the loadedLibraries cachable/reusable during the session
    let loadedLibraries: { [url: string]: ReplaySubject<any> } = {};

    loadedLibraries[url] = new ReplaySubject();
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = url;
    script.crossOrigin = undefined;
    script.onload = () => {
      loadedLibraries[url].next(script);
      loadedLibraries[url].complete();
    };
    script.onerror = (e) => {
      loadedLibraries[url].error(e);
    };

    document.getElementById('oc-store-locator').appendChild(script);

    return loadedLibraries[url].asObservable();
  }
}
