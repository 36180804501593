import { contrastMap, MAX_CS } from '../config';
import { find } from 'lodash-es';

// convert contrast in logCS unit to decimal unit to use with `opacity` value
// reference: `210128_Leu_Bre_CS_norms.xlsx` document
export function logcs2decimal(logcs: number = MAX_CS): number {
  const value = find(contrastMap, { logCs: logcs });
  if (!value) {
    console.warn('ContrastTest: invalid logCS value. fallback to the 0logCS');
    return contrastMap[0].opacity;
  }

  return value.opacity;
}
