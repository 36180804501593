export enum C_ANGLES {
  TOP = 0,
  TOPRIGHT = 45,
  RIGHT = 90,
  BOTTOMRIGHT = 135,
  BOTTOM = 180,
  BOTTOMLEFT = 225,
  LEFT = 270,
  TOPLEFT = 315
}
