export enum TEST_RESULT {
  EXCELLENT = 3,
  FINE = 2,
  REDUCED = 1,
  BOTHEYES = 1,
  ONEEYE = 2
}

export enum TEST_RESULT_STRING {
  EXCELLENT = 'excellent',
  FINE = 'fine',
  REDUCED = 'reduced',
}
