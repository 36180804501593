import { animate, animateChild, group, query, style, AnimationMetadata } from '@angular/animations';

// Stuff for general
import { environment } from '../../../environments/environment';

const timingInMs = 700 * environment.animationDuration;
const animationTiming = `${timingInMs}ms ease-in-out`;

const crossFade: AnimationMetadata[] = [
  style({ position: 'relative' }),
  query(
    ':enter, :leave',
    [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: '100%',
        height: '100%',
        overflow: 'hidden'
      })
    ],
    { optional: true }
  ),
  query(
    ':enter',
    [
      style({
        opacity: 0
      })
    ],
    { optional: true }
  ),
  query(':leave', animateChild(), { optional: true }),
  group([
    query(
      ':leave',
      [
        animate(
          animationTiming,
          style({
            opacity: 0
          })
        )
      ],
      { optional: true }
    ),
    query(
      ':enter',
      [
        animate(
          animationTiming,
          style({
            opacity: 1
          })
        )
      ],
      { optional: true }
    )
  ]),
  query(':enter', animateChild(), { optional: true })
];

export namespace RouteAnimations {
  export const CrossFade = crossFade;
}
