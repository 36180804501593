import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Special for components & co
import { ResultPageComponent } from './result-page/result-page.component';

// From shared folder
import { TEST_TYPES } from '../shared/enums/test-types';

const routes: Routes = [
  {
    path: 'check/result',
    component: ResultPageComponent,
    data: {
      animation: 'MegaTestResult'
    }
  },
  {
    path: TEST_TYPES.ACUITY,
    loadChildren: () =>
      import('../acuity-test/acuity-test.module').then(
        (m) => m.AcuityTestModule
      )
  },
  {
    path: TEST_TYPES.CONTRAST,
    loadChildren: () =>
      import('../contrast-test/contrast-test.module').then(
        (m) => m.ContrastTestModule
      )
  },
  {
    path: TEST_TYPES.COLOR,
    loadChildren: () =>
      import('../color-test/color-test.module').then((m) => m.ColorTestModule)
  },
  {
    path: TEST_TYPES.ASTIGMATISM,
    loadChildren: () =>
      import('../astigmatism-test/astigmatism-test.module').then(
        (m) => m.AstigmatismTestModule
      )
  },
  {
    path: TEST_TYPES.AMSLER,
    loadChildren: () =>
      import('../amsler-test/amsler-test.module').then(
        (m) => m.AmslerTestModule
      )
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MegaTestRoutingModule {}
