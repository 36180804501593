export enum LottieAssetsEnum {
  AnimatedEye = '/assets/lottie/eye-animate.json',
  VaCheck = '/assets/lottie/va-check.json',
  ContrastCheck = '/assets/lottie/contrast.json',
}

export enum LottieAnimationEnum {
  AnimatedEye = 'eye-animation',
  VaCheck = 'va-check',
  ContrastCheck = 'contrast',
}
