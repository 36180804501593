import { createAction } from '@ngrx/store';

/**
 * Actions to define brightness calibration state
 * If the brightness has already been set, then brightness
 * calibration screens will be skipped.
 */
export const SetBrightnessCalibrationStateDone = createAction(
  '[Distance] Set Brightness Calibration State Done'
);

export const SetBrightnessCalibrationStateActive = createAction(
  '[Distance] Set Brightness Calibration State Active'
);
