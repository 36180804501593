import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Stuff for general
import { AppLocalizeRouterService } from '../services/app-localize-router.service';
import { AppSelectors } from '../store/selectors';

// Special for checks
import { AmslerSelectors } from '../amsler-test/store';
import { AstigmatismSelectors } from '../astigmatism-test/store';

// From shared folder
import { RoutePathes } from '../shared/enums/route-pathes.enum';
import { TEST_TYPES } from '../shared/enums/test-types';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class ActiveEyeGuard  implements OnDestroy {
  constructor(
    private router: Router,
    private localize: AppLocalizeRouterService,
    private store: Store
  ) {}
  ngOnDestroy() {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const eye = route.paramMap.get('eye');
    return combineLatest([
      this.store.select(AppSelectors.getMegaTestActive),
      this.store.select(AppSelectors.getActiveTest),
      this.store.select(AstigmatismSelectors.getActiveEye),
      this.store.select(AmslerSelectors.getActiveEye)
    ]).pipe(
      untilDestroyed(this),
      map(([isMegaTest, activeTest, activeEyeAstigmatism, activeEyeAmsler]) => {
        if (activeTest === TEST_TYPES.AMSLER) {
          if (eye === activeEyeAmsler) {
            return true;
          }

          let path = `${activeTest}${RoutePathes.CheckStart}/${activeEyeAmsler}`;
          if (isMegaTest) {
            path = `${RoutePathes.Default}/${path}`;
          }

          const newUrl = this.localize.translateRoute([path]);

          return this.router.parseUrl(newUrl[0]);
        }

        if (activeTest === TEST_TYPES.ASTIGMATISM) {
          if (eye === activeEyeAstigmatism) {
            return true;
          }

          let path = `${activeTest}${RoutePathes.CheckStart}/${activeEyeAstigmatism}`;
          if (isMegaTest) {
            path = `${RoutePathes.Default}/${path}`;
          }

          const newUrl = this.localize.translateRoute([path]);

          return this.router.parseUrl(newUrl[0]);
        }

        return true;
      })
    );
  }
}
