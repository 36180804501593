import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  OnChanges,
  Optional,
  SimpleChanges
} from '@angular/core';

import { AppIconsRegistryService } from './app-icons-registry.service';

@Component({
  selector: 'app-icon',
  template: `<ng-content></ng-content>`,
  styles: [":host {display: inline-block;}"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppIconComponent implements OnChanges {
  private svgIcon!: SVGElement;

  @HostBinding('style.width') styleWidth = this.width + 'px';
  @HostBinding('style.height') styleHeight = this.height + 'px';

  @Input() alt: string;

  _width: number;
  _height: number;

  @Input()
  set name(iconName: string) {
    if (this.svgIcon) {
      this.element.nativeElement.removeChild(this.svgIcon);
    }
    const svgData = this.appIconsRegistry.getIcon(iconName)!;

    this.svgIcon = this.svgElementFromString(svgData);

    this.element.nativeElement.appendChild(this.svgIcon);
  }

  @Input() set width(w: number) {
    this._width = w;
    this.styleWidth = `${w}px`;

    if (this.width) {
      this.svgIcon.setAttribute('width', this.styleWidth);
    } else {
      this.svgIcon.setAttribute('width', 'auto');
    }
  }

  get width() {
    return this._width;
  }

  @Input() set height(h: number) {
    this._height = h;
    this.styleHeight = `${h}px`;

    if (this.height) {
      this.svgIcon.setAttribute('height', this.styleHeight);

    } else {
      this.svgIcon.setAttribute('height', '100%');
    }
  }

  get height() {
    return this._height;
  }

  constructor(
    private element: ElementRef,

    private appIconsRegistry: AppIconsRegistryService,
    @Optional() @Inject(DOCUMENT) private document: any
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.alt) {
      this.svgIcon.setAttribute('alt', changes.alt.currentValue);
    }
  }

  private svgElementFromString(svgContent: string): SVGElement {
    const div = this.document.createElement('DIV');
    div.innerHTML = svgContent;

    const svg = (
      div.querySelector('svg') ||
      this.document.createElementNS('http://www.w3.org/2000/svg', 'path')
    );
    svg.setAttribute('viewBox', `0 0 ${svg.getAttribute("width")} ${svg.getAttribute("height")}`);

    return svg;
  }
}
