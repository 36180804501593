<div class="help">
  <zat-ovs-header [label]="headerConfig.label" [left]="headerConfig.left" [right]="headerConfig.right"
    [theme]="headerConfig.theme" (clickLeft)="back()"></zat-ovs-header>
  <div *transloco="let t;" class="accordion-body">
    <ng-container content>
      <mat-accordion [displayMode]="'flat'" [multi]="true" class="help-content" [@.disabled]="true">
        <mat-expansion-panel>
          <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*">
            <mat-panel-title class="accordion-title">
              {{ t('faq.section1.question') }}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="images">
            <app-icon id="keep_distance_mobile" name="keep_distance_mobile" [width]="160" [height]="106" alt="">
            </app-icon>
          </div>
          <p [innerHTML]="t('faq.section1.paragraph1')" class="accordion-text"></p>

          <div class="images">
            <app-icon id="wear_your_glasses" name="wear_your_glasses" [width]="200" [height]="60" alt="">
            </app-icon>
          </div>

          <p [innerHTML]="t('faq.section1.paragraph2')" class="accordion-text"></p>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*">
            <mat-panel-title class="accordion-title">
              {{ t('faq.section2.question') }}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="text-center" style="margin-top: 28px; margin-bottom: 16px">
            <app-icon id="credit_card_front_landscape" name="credit_card_front_landscape" [width]="300" [height]="190"
              alt="">
            </app-icon>
          </div>
          <p [innerHTML]="t('faq.section2.paragraph1')" class="accordion-text"></p>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*">
            <mat-panel-title class="accordion-title">
              {{ t('faq.section3.question') }}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <p [innerHTML]="t('faq.section3.paragraph1')" class="accordion-text"></p>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*">
            <mat-panel-title class="accordion-title">
              {{ t('faq.section4.question') }}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <p [innerHTML]="t('faq.section4.paragraph1')" class="accordion-text"></p>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*">
            <mat-panel-title class="accordion-title">
              {{ t('faq.section5.question') }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p [innerHTML]="t('faq.section5.paragraph1')" class="accordion-text"></p>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*">
            <mat-panel-title class="accordion-title">
              {{ t('faq.section6.question') }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p [innerHTML]="t('faq.section6.paragraph1')" class="accordion-text"></p>
        </mat-expansion-panel>

      </mat-accordion>
    </ng-container>
  </div>
</div>
