import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

// Stuff for general
import { environment } from './environments/environment';
import { AppModule } from './app/app.module';

// TODO: only needed for webcomponent....maybe better move to extra main.ts?
// TODO: either make two main.ts files (normal/webcomponent) or include zone.js dynamically
// needed to have zone.js included in the bundle for (for non-angular projects)
import 'zone.js';


declare const window: any;

if (window?.Capacitor) {
  window.Capacitor.DEBUG = false;
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
