import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';

// Special for components & co
import { MegaTestRoutingModule } from './mega-test-routing.module';
import { ResultPageComponent } from './result-page/result-page.component';

// From shared folder
import { AppSharedModule } from '../shared/app-shared.module';
import { AppMaterialModule } from '../shared/app-material/app-material.module';

@NgModule({
  declarations: [ResultPageComponent],
  imports: [
    CommonModule,
    MegaTestRoutingModule,
    AppSharedModule,
    AppMaterialModule,
    TranslocoModule
  ],
  providers: [
  ]
})
export class MegaTestModule { }
