import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { LottieModule } from 'ngx-lottie';
import { LottieOptions } from 'ngx-lottie/lib/symbols';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgLetModule } from 'ng-let';

// Special for components & co
import { ContrastTestRoutingModule } from './contrast-test-routing.module';
import { ContrastDetailsPageComponent } from './contrast-details-page/contrast-details-page.component';
import { ContrastTestPageComponent } from './contrast-test-page/contrast-test-page.component';
import { PrepareContrastTestComponent } from './prepare-contrast-test/prepare-contrast-test.component';
import { ContrastResultPageComponent } from './contrast-result-page/contrast-result-page.component';
import { fromContrast } from './store/reducers';
import { ContrastEffects } from './store/effects/contrast.effect';
import { AppIconsModule } from '../app-icons/app-icons.module';
import { HowItWorksContrastPageComponent } from './how-it-works-contrast-page/how-it-works-contrast-page.component';

// From shared folder
import { AppMaterialModule } from '../shared/app-material/app-material.module';
import { AppSharedModule } from '../shared/app-shared.module';

import { OvsComparisonSliderComponent } from '../ovs-comparison-slider/ovs-comparison-slider.component';

export function playerFactory() {
  return import('lottie-web');
}

@NgModule({
  declarations: [
    ContrastDetailsPageComponent,
    ContrastTestPageComponent,
    PrepareContrastTestComponent,
    ContrastResultPageComponent,
    HowItWorksContrastPageComponent
  ],
  imports: [
    CommonModule,
    TranslocoModule,
    ContrastTestRoutingModule,
    AppMaterialModule,
    AppSharedModule,
    StoreModule.forFeature('contrast', fromContrast.reducer),
    EffectsModule.forFeature([ContrastEffects]),
    AppIconsModule,
    NgLetModule,
    LottieModule.forRoot({
      player: playerFactory,
      useCache: true
    } as LottieOptions),
    OvsComparisonSliderComponent
  ]
})
export class ContrastTestModule {}
