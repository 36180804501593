import { AppChannel } from './enums';
/**
 * definitions of all available locales for OVS -
 * allLocales is used to set locales in the transloco-root.module
 */
export const DEFAULT_LOCALE = 'en-INT';

export const appChannelMap = new Map<string, AppChannel>();

// locale format: `${ISO_639-1}-${ISO_3166-2}`

// e.g. 20/20, 20/32
const CHANNEL_FOOT = [
  'en-INT', // International
  'en-AU', // Australia
  'en-GB', // UK
  'en-IL', // Israel
  'en-SG', // Singapore
  'en-ZA', // South Africa
  'en-MY', // Malaysia
];

CHANNEL_FOOT.forEach((locale) => appChannelMap.set(locale, AppChannel.FOOT));

// e.g. 6/6,  6/9
const CHANNEL_METRIC = [
  'en-CA', // Canada
  'fr-CA', // Canada
  'es-CO', // Colombia
  'es-MX', // Mexico
  'en-HK', // Hong Kong
  'zh-HK', // Hong Kong
  'es-PA', // Panama
  'en-AE', // UAE
  'en-IN', // India
];
CHANNEL_METRIC.forEach((locale) =>
  appChannelMap.set(locale, AppChannel.METRIC)
);

// e.g. 20/20, 20/32 (same as FOOT)
const CHANNEL_US = ['en-US'];
CHANNEL_US.forEach((locale) => appChannelMap.set(locale, AppChannel.US));

// e.g. 100%, 63%
const CHANNEL_DECIMAL = [
  'nl-BE', // België (Dutch-Belgium)
  'fr-BE', // Belgique (French-Belgium)
  'cs-CZ', // Čeština (Czech Republic)
  'da-DK', // Dansk (Denmark)
  'de-DE', // Deutsch
  'es-ES', // Español
  'fr-FR', // France
  'hr-HR', // Hrvatska (Croatian-Croatia)
  'hu-HU', // Magyar (Hungary)
  'nl-NL', // Netherlands
  'no-NO', // Norge (Norway)
  'de-AT', // Österreich (Austria)
  'pl-PL', // Polska (Poland)
  'pt-PT', // Portugal (Portugese)
  'ro-RO', // Romania
  'de-CH', // Switzerland
  'sk-SK', // Slovenský Jazyk (Slovakia)
  'fr-CH', // Swiss-french
  'fi-FI', // Suomi (Finland)
  'sv-SE', // Svenska (Swedish)
  'tr-TR', // Türkçe
  'bg-BG', // български (Bulgaria)
  'ru-RU', // Русский язык (Russia)
  'uk-UA', // українська мова (Ukraine)
  'zh-CN', // 简体中文 (Simplified Chinese, China)
  'ko-KR', // 한국어 (Korea)
  'ja-JP', // 日本語 (Japan)
  'es-AR', // Argentina
  'pt-BR', // Brazil
  'es-PE', // Peru
  'zh-TW', // 中国 (Taiwan)
  'es-VE', // Venzuela
  'es-EC', // Ecuador
  'it-IT', // Italy
];
CHANNEL_DECIMAL.forEach((locale) =>
  appChannelMap.set(locale, AppChannel.DECIMAL)
);

export const allLocales = Array.from(appChannelMap.keys());
