import { sample } from 'lodash-es';

// From shared folder
import { C_ANGLES } from '../../shared/enums';

/**
 * return an angle from C_ANGLE excluding the `currentAngle` to prevent duplicates
 */
export function getNewLandoltCAngle(currentAngle: C_ANGLES): C_ANGLES {
  const angles = (Object.values(C_ANGLES).filter(
    (value) => typeof value === 'number'
  ) as C_ANGLES[]).filter((d) => d !== currentAngle);
  const newAngle = sample(angles);

  return newAngle;
}
