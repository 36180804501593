import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { delay, take, tap } from 'rxjs/operators';
import { Location } from '@angular/common';

// Stuff for general
import { environment } from '../../../environments/environment';
import { ShowMegaTestResultsAction } from '../../store/actions/general.actions';
import { AppSelectors } from '../../store/selectors';
import { SendResultsAction } from '../../store/actions/test-type.actions';
import {
  getSpecialScreeningMode,
  getMegaTestActive
} from '../../store/selectors/app.selectors';

// Special for checks
import { ContrastActions, ContrastSelectors } from '../store';
import { StartColorMegaTestAction } from '../../color-test/store/actions/color.actions';

// From shared folder
import {
  GA4_EventDetail,
  GA4_EventAction,
  GA4_EventName,
  GA4_EventType,
  GA4_EventValue
} from '../../shared/enums/ga4.enum';
import { CloseDialogContext } from '../../shared/components/close-dialog/close-dialog-context.enum';
import {
  AppChannel,
  TEST_RESULT,
  TEST_RESULT_STRING
} from '../../shared/enums';
import { HeaderConfig } from '../../shared/interfaces/header-config.interface';
import {
  HeaderColorEnum,
  HeaderLabelEnum,
  HeaderEnum
} from '../../shared/enums/header.enum';
import { fadeInAnimation } from '../../shared/animations';
import { SpecialScreeningMode } from '../../shared/enums/special-screening-mode.enum';
import { AnalyticsService } from '../../services/analytics.service';
import { GeneralService } from '@app-services/general.service';
import { AppInsightsService } from '../../services/app-insights.service';

@Component({
  selector: 'zat-contrast-result-page',
  templateUrl: './contrast-result-page.component.html',
  styleUrls: ['./contrast-result-page.component.scss'],
  animations: [fadeInAnimation]
})
export class ContrastResultPageComponent implements OnInit, OnDestroy {
  specialScreeningMode$: Observable<SpecialScreeningMode> = this.store.select(
    getSpecialScreeningMode
  );
  SpecialScreeningMode = SpecialScreeningMode;

  contrasts$: Observable<{ right: number; left: number }> = this.store.select(
    ContrastSelectors.getRawEyeContrasts
  );

  contrastResults$: Observable<{
    sumResult: number;
    eyesResult: TEST_RESULT;
    right: TEST_RESULT;
    left: TEST_RESULT;
  }> = this.store.select(ContrastSelectors.getEyeContrastsResult);

  resultString: string = null;
  image$: Observable<{
    leftEye: TEST_RESULT_STRING;
    rightEye: TEST_RESULT_STRING;
  }> = this.store.select(ContrastSelectors.getResultImage).pipe(
    take(1),
    tap((img) => {
      this.resultString = `Left: ${img.leftEye} - Right: ${img.rightEye}`;
    })
  );

  RESULT = TEST_RESULT;
  AppChannel = AppChannel;
  public isWebcomponent: boolean = environment.webcomponent;
  public isChubGoLive:boolean = environment.chubGoLiveToggle;
  locale$: Observable<string> = this.store.select(AppSelectors.getLocale);
  public showSaveFeature$ = this.generalService.showImportSaveOption$();
  public isUSMode$: Observable<boolean> = this.generalService.hideLastTest$().pipe(
    tap((isUS) => {
      let maxTestAmount = isUS ? 4 : 5;
      this.megaTestList$.next(maxTestAmount);
    }));
  public isMegaTestActive$: Observable<boolean> = this.store.select(getMegaTestActive);
  public megaTestList$: BehaviorSubject<number> = new BehaviorSubject<number>(undefined);

  headerConfig: HeaderConfig = {
    label: HeaderLabelEnum.SingleCheckResult,
    left: HeaderEnum.Empty,
    right: HeaderEnum.Back,
    theme: HeaderColorEnum.LightMode
  } as HeaderConfig;

  // trigger close warning dialog when browser back button is clicked
  @HostListener('window:popstate', ['$event'])
  onPopState() {
    if (!environment.webcomponent) {
      history.pushState(null, null, location.href);
      this.triggerCloseWarning();
    }
  }

  constructor(
    private store: Store,
    private logger: AppInsightsService,
    private locationService: Location,
    private analytics: AnalyticsService,
    private generalService: GeneralService
  ) {
    history.pushState(null, null, location.href);
  }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    // Fire the event
    this.analytics.createCustomEvent({
      event: 'event',
      eventName: `${GA4_EventName.CTA}`,
      eventAction: `${GA4_EventAction.View}`,
      eventType: `${GA4_EventType.Contrast} Test`,
      eventValue: `${GA4_EventValue.Result}`,
      eventDetail: `${this.resultString}`
    });
  }

  ngOnDestroy(): void {}

  goToResultSummary(): void {
    this.analytics.createCustomEvent({
      event: 'event',
      eventName: `${GA4_EventName.CTA}`,
      eventAction: `${GA4_EventAction.Click}`,
      eventType: `${GA4_EventType.Internal}`,
      eventValue: `${GA4_EventValue.ShowResults}`,
      eventDetail: `${GA4_EventDetail.ResultPage} - ${GA4_EventDetail.Contrast}`
    });

    this.store.dispatch(ShowMegaTestResultsAction());
  }

  nextTest(): void {
    this.analytics.createCustomEvent({
      event: 'event',
      eventName: `${GA4_EventName.CTA}`,
      eventAction: `${GA4_EventAction.Click}`,
      eventType: `${GA4_EventType.Internal}`,
      eventValue: `${GA4_EventValue.ContinueTo}${GA4_EventValue.Color}`,
      eventDetail: `${GA4_EventDetail.ResultPage} - ${GA4_EventDetail.Contrast}`
    });

    this.store.dispatch(StartColorMegaTestAction());
  }

  sendResults(): void {
    this.analytics.createCustomEvent({
      event: 'event',
      eventName: `${GA4_EventName.CTA}`,
      eventAction: `${GA4_EventAction.Click}`,
      eventType: `${GA4_EventType.Internal}`,
      eventValue: `${GA4_EventValue.SaveResults}`,
      eventDetail: `${GA4_EventDetail.ResultPage} - ${GA4_EventDetail.Contrast}`
    });

    this.logger.info('### Send result', 'Contrast');
    this.store.dispatch(SendResultsAction());
  }

  triggerCloseWarning(): void {
    this.analytics.createCustomEvent({
      event: 'event',
      eventName: `${GA4_EventName.CTA}`,
      eventAction: `${GA4_EventAction.Click}`,
      eventType: `${GA4_EventType.Internal}`,
      eventValue: `${GA4_EventValue.ExitResultPage}`,
      eventDetail: `${GA4_EventDetail.ResultPage} - ${GA4_EventDetail.Contrast}`
    });

    this.specialScreeningMode$
      .pipe(delay(0), take(1))
      .subscribe((mode: SpecialScreeningMode) => {
        this.store.dispatch(
          ContrastActions.ShowCloseWarningAction({
            context:
              mode === SpecialScreeningMode.ECP
                ? CloseDialogContext.ResultECP
                : CloseDialogContext.ResultNotECP,
            previousPath: this.locationService.path()
          })
        );
      });
  }
}
