import { Component, Input, OnInit } from '@angular/core';

// From shared folder
import { Eyes } from '../../../shared/enums';

@Component({
  selector: 'zat-animate-cover-eye',
  templateUrl: './animate-cover-eye.component.html',
  styleUrls: ['./animate-cover-eye.component.scss']
})
export class AnimateCoverEyeComponent {
  @Input() coverEye: Eyes;

  public Eyes = Eyes;
}
