import { Action, createReducer, on } from '@ngrx/store';

// Stuff for general
import { TestTypeActions } from '../../../store/actions';

// Special for checks
import { ColorActions } from '../actions';
import { StartColorMegaTestAction, StartColorSingleCheckAction } from '../actions/color.actions';
import { ColorTestItem, colorTestItems, getColorTests, MIN_CHOICE, START_INDEX } from '../../shared';

export const colorFeatureKey = 'color';

export interface ColorState {
  tests: ColorTestItem[];
  currentTest: ColorTestItem;
  answers: (number | string)[];
  currentTestIndex: number;
  finished: boolean;
}

export const initialState: ColorState = {
  tests: undefined,
  currentTest: undefined,
  answers: undefined,
  currentTestIndex: undefined,
  finished: undefined
};

const colorReducer = createReducer(
  initialState,

  on(ColorActions.GenerateTests, (state) => {
    const generatedTests = getColorTests(colorTestItems, MIN_CHOICE);
    return {
      ...state,
      tests: [...generatedTests],
      currentTest: generatedTests[START_INDEX],
      answers: [],
      currentTestIndex: START_INDEX,
      finished: false
    };
  }),

  on(ColorActions.ResetTests, (state) => ({
    tests: undefined,
    currentTest: undefined,
    answers: undefined,
    currentTestIndex: undefined,
    finished: undefined
  })),

  on(TestTypeActions.ResetAllChecks, (state) => ({
    ...initialState
  })),

  on(ColorActions.Answer, (state, { answer }) => {
    if (!state.tests) {
      return;
    }

    const answers = state.answers ? [...state.answers, answer] : [answer];
    const currentIndex: number = state.currentTestIndex;
    const lastIndex: number = state.tests.length - 1;
    // on last test we want to stay on the same index; clear will be in a followup action
    // since otherwise we clear the state before the animation is done and we navigated of the component
    const nextIndex = currentIndex < lastIndex ? currentIndex + 1 : currentIndex;
    const nextTest = nextIndex ? state.tests[nextIndex] : undefined;

    const lastTest = state.tests[lastIndex];
    const currentTest = state.currentTest;
    const isLastTest = lastTest.no === currentTest.no;

    return {
      ...state,
      currentTest: nextTest,
      answers,
      currentTestIndex: nextIndex, //to clear: set index and currentTest
      finished: isLastTest
    };
  }),

  on(ColorActions.ColorTestFinishedAction, (state) => {
    if (!state.tests) {
      return;
    }

    return {
      ...state,
      currentTest: undefined,
      currentTestIndex: undefined, //to clear: set index and currentTest
    };
  }),

  on(ColorActions.SetTestItemsFromDate, (state, { testItems, testAnswers }) => {
    return {
      ...state,
      tests: testItems,
      answers: testAnswers
    }
  }),

  // SingleStartActions
  on(StartColorSingleCheckAction, (state, action): ColorState => {
    return {
      ...initialState
    };
  }),

  // Mega-Check-Actions
  on(StartColorMegaTestAction, (state, action): ColorState => {
    return {
      ...initialState
    };
  })
);

export function reducer(state: ColorState | undefined, action: Action): any {
  return colorReducer(state, action);
}
