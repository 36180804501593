import { animate, AnimationEvent, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

// From shared folder
import { IconState } from '../../../shared/enums';
import { TIMING_FUNCTION_FORWARD, TIMING_FUNCTION_BEFORE_BACKWARD } from '../../../shared/config';

enum ButtonStates {
  SELECTED = 'selected',
  UNSELECTED = 'unselected',
  ANSWERED_AND_UNSELECTED = 'answered_and_unselected',
  ANSWERED_AND_SELECTED = 'answered_and_selected'
}

@Component({
  selector: 'zat-action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.scss'],
  animations: [
    trigger('iconAnimation', [
      state('*', style({ opacity: 0, zIndex: 0 })),
      state('answered', style({ opacity: 1 })),
      transition('idle => answered', animate(TIMING_FUNCTION_FORWARD)),
      transition('answered => idle', animate(TIMING_FUNCTION_BEFORE_BACKWARD))
    ]),
    trigger('buttonAnimation', [
      state(ButtonStates.SELECTED, style({ opacity: 1 })),
      state(ButtonStates.UNSELECTED, style({ opacity: 1 })),
      state(
        ButtonStates.ANSWERED_AND_UNSELECTED,
        style({ opacity: 0.1 })
      ),
      state(
        ButtonStates.ANSWERED_AND_SELECTED,
        style({
          color: 'white',
          backgroundColor: 'var(--primary-hover-blue)',
          borderColor: 'var(--primary-hover-blue)'
        })
      ),
      transition(
        'unselected => answered_and_unselected',
        animate(TIMING_FUNCTION_FORWARD)
      ),
      transition(
        'answered_and_unselected => unselected',
        animate(TIMING_FUNCTION_BEFORE_BACKWARD)
      )
    ])
  ]
})
export class ActionButtonComponent{
  @ViewChild('buttonElement', { read: ElementRef }) buttonElement: ElementRef<HTMLButtonElement>;
  public iconState = IconState.IDLE;
  public buttonState = ButtonStates.UNSELECTED;
  public ButtonStates = ButtonStates;

  @Input('tabValue') tabValue: number = 0;
  @Input() disabled = false;
  @Input() color: string;
  @Input() ariaLabel: string;
  @Input() type: 'outline' | 'solid' | 'inverted' | 'inverted-outline' | 'inverted-light' | 'check-button' | 'outline-white' | 'focus';
  @Input() size:
    | 'big'
    | 'medium'
    | 'small'
    | 'tiny'
    | 'small-medium'
    | 'small-big'
    | 'medium-big' = 'medium';
  @Input() heightFitContent:boolean = false;
  @Input() iconType: 'correct' | 'incorrect';
  @Input() width: number;
  @Input() set animationState(value: IconState) {
    const isAnswered = value === IconState.ANSWERED;

    if (this.isUnselected && isAnswered) {
      this.buttonState = ButtonStates.ANSWERED_AND_UNSELECTED;
      return;
    }

    if (this.isSelected && isAnswered) {
      this.iconState = IconState.ANSWERED;
      this.buttonState = ButtonStates.ANSWERED_AND_SELECTED;
      return;
    }

    this.iconState = value;
    this.buttonState = ButtonStates.UNSELECTED;
  }

  get animationState(): IconState {
    return this.iconState;
  }

  @Output() action = new EventEmitter();
  @Output() animationStart = new EventEmitter();
  @Output() animationDone = new EventEmitter();

  get isSelected(): boolean {
    return this.buttonState === ButtonStates.SELECTED;
  }

  get isUnselected(): boolean {
    return this.buttonState === ButtonStates.UNSELECTED;
  }

  onAnimationStart(event: AnimationEvent) {
    this.animationStart.emit(event);
  }

  onAnimationDone(event: AnimationEvent) {
    this.animationDone.emit(event);
  }

  onAction() {
    this.buttonState = ButtonStates.SELECTED;
    this.action.emit();
    this.buttonElement.nativeElement.blur();
  }
}
