import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, tap } from 'rxjs';

// Stuff for general
import { OpenHomePageAction } from '../../../../../store/actions/general.actions';
import { getTestTypeCode } from '../../../../../store/selectors/app.selectors';
import { AgreeImportantNotesAction } from '../../../../../store/actions/check-calibration.actions';

// From shared folder
import { TestTypesForImportantNotes } from '../../../../../shared/enums/test-types-important-notes.enum';
import {
  GA4_EventDetail,
  GA4_EventAction,
  GA4_EventName,
  GA4_EventType,
  GA4_EventValue
} from '../../../../../shared/enums/ga4.enum';
import { AnalyticsService } from '../../../../../services/analytics.service';

@UntilDestroy()
@Component({
  selector: 'zat-important-note-page',
  templateUrl: './important-note-page.component.html',
  styleUrls: ['./important-note-page.component.scss']
})
export class ImportantNotePageComponent implements OnInit, OnDestroy {
  testCode$: Observable<TestTypesForImportantNotes> =
    this.store.select(getTestTypeCode);
  testCode: TestTypesForImportantNotes;

  constructor(private store: Store, private analytics: AnalyticsService) {}

  ngOnInit(): void {
    this.testCode$
      .pipe(
        tap((value) => {
          this.testCode = value;
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  ngOnDestroy(): void {}

  agree(): void {
    this.store.dispatch(AgreeImportantNotesAction({ testType: this.testCode }));

    this.analytics.createCustomEvent({
      event: 'event',
      eventName: `${GA4_EventName.CTA}`,
      eventAction: `${GA4_EventAction.Click}`,
      eventType: `${GA4_EventType.Internal}`,
      eventValue: `${GA4_EventValue.AcceptDisclaimer}`,
      eventDetail: `${GA4_EventDetail.OnlineVisionScreeningDisclaimer}`
    });
  }

  handleCancel(): void {
    this.store.dispatch(OpenHomePageAction());

    this.analytics.createCustomEvent({
      event: 'event',
      eventName: `${GA4_EventName.CTA}`,
      eventAction: `${GA4_EventAction.Click}`,
      eventType: `${GA4_EventType.Internal}`,
      eventValue: `${GA4_EventValue.RejectDisclaimer}`,
      eventDetail: `${GA4_EventDetail.OnlineVisionScreeningDisclaimer}`
    });
  }
}
