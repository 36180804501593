import { AmslerTests } from './enums';

export const amslerTestItems: AmslerTests[] = [
  AmslerTests.ARE_ALL_REGULAR_AND_PERFECTLY,
  AmslerTests.ARE_THERE_ANY_HOLES_OR_DARK_AREA,
];

export const TOTAL_TESTS = amslerTestItems.length;
export const LAST_INDEX = TOTAL_TESTS - 1;
export const AMSLER_GRID_SIZE_IN_CM = 10;
