import { round } from 'lodash-es';
import { MAX_VA, MIN_VA } from '../config';

export function getVisualAcuity(
  currentValue: number,
  direction: 'up' | 'down',
  count: number
): number {
  let step = 0.1;
  let newVA: number;
  const FIRST_TWO_RESPONSES = 4;

  if (count < FIRST_TWO_RESPONSES) {
    step = 0.2;
  }

  if (direction === 'down') {
    newVA = currentValue - step;
  } else {
    newVA = currentValue + step;
  }

  if (newVA > MAX_VA) {
    return MAX_VA;
  }

  if (newVA < MIN_VA) {
    return MIN_VA;
  }

  return round(newVA, 1);
}
