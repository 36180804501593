import { animate, style, transition, trigger } from '@angular/animations';
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  combineLatest,
  filter,
  Observable,
  of,
  switchMap,
  tap,
  take,
  race,
  timer
} from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { environment } from '../environments/environment';

// For general
import {
  ExitEcpResultSuccessAction,
  RouteToParentComponentSucceededAction,
  SetMyZeissCountryAction,
  SetResultButtonTextAction
} from './store/actions/general.actions';
import {
  getSpecialScreeningMode,
  shouldEmitEcpResultCallToAction,
  shouldRouteToParentComponent
} from './store/selectors/app.selectors';
import { SendResultsCompleteAction } from './store/actions/test-type.actions';
import {
  AppActions,
  SetDebugActions,
  SetLocaleActions,
  TestTypeActions
} from './store/actions';
import { WebResultsService } from './services/web-results.service';
import { GeneralService } from './services/general.service';
import { AuthorizationService } from './services/authorization.service';
import { AppInitializerService } from './services/app-initializer.service';
import { AppSelectors } from './store/selectors';
import { AppCookiesService } from './services/app-cookies.service';

// Special for checks
import { AcuitySelectors } from './acuity-test/store';
import { ContrastSelectors } from './contrast-test/store';
import { ColorSelectors } from './color-test/store';
import { AstigmatismSelectors } from './astigmatism-test/store';
import { AmslerSelectors } from './amsler-test/store';

// From shared folder
import { EyeResult } from './shared/interfaces/send-check-results.interface';
import { SpecialScreeningMode } from './shared/enums/special-screening-mode.enum';
import { AnalyticsService } from './services/analytics.service';
import { AppInsightsService } from './services/app-insights.service';

let validUrl = require('valid-url');

@UntilDestroy()
@Component({
  selector: 'zat-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],

  animations: [
    trigger('fade', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('150ms ease-in', style({ opacity: 1 }))
      ]),
      transition(':leave', [animate('150ms ease-out', style({ opacity: 0 }))])
    ])
  ],
  encapsulation: environment.webcomponent
    ? ViewEncapsulation.ShadowDom
    : ViewEncapsulation.Emulated
})
export class AppComponent implements OnInit, OnDestroy {
  isLoading$: Observable<boolean>;

  public todayDate: Date;

  // WebComponent Inputs
  private _locale: string = 'en-INT';

  @Input('testpath') testpath: string = 'entry';

  @Input('locale') set locale(value: string) {
    this._locale = this.generalService.checkInputLocale(value);
    this.translate.setActiveLang(this._locale);
    this.store.dispatch(SetLocaleActions.SetLocale({ locale: this._locale }));
  }
  get locale() {
    return this._locale;
  }

  /**
   * mode that defines the available UI and workflow functionality
   */
  @Input('specialScreeningMode') set specialScreeningMode(value: string) {
    console.log('#### screeningMode in setter:', value);
    this.generalService.checkInputSpecialScreeningMode(value);
  }

  @Input('customerId') customerId: string = 'deployment';

  /**
   * newer naming; this will still be result-button-text in html but in js we can write
   * elem.resultButtonText and elem.setAttribute('result-button-text','bla) still works
   * since HTML attribute is result-button-text
   */
  @Input('resultButtonText') set resultButtonText(value: string) {
    this.store.dispatch(SetResultButtonTextAction({ text: value }));
  }

  // the user clicks back button and CTA button in the result screen of any test if they are not using EventEmitter
  @Input('back-url') onBackClicked: string = '';
  @Input('result-url') onclickfindappointment: string = '';

  @Input('my-zeiss-country') set onMyZeissCountry(value:string){
    console.log('#### my-zeiss-country SETTER called, value: ', value);
    this.store.dispatch(SetMyZeissCountryAction({ country: value }));
  }

  // WebComponent output
  @Output('result') result = new EventEmitter();
  // user clicks back-button
  @Output('route-back') routeBack = new EventEmitter();
  // user clicks call-to-action in ecp-mode result screen of any test
  @Output('result-screen-btn-clicked') resultScreenBtnClicked =
    new EventEmitter();

  environment = environment;

  constructor(
    private router: Router,
    private store: Store,
    private metaTagService: Meta,
    private translate: TranslocoService,
    private title: Title,
    private actions$: Actions,
    private cookies: AppCookiesService,
    private resultService: WebResultsService,
    private logger: AppInsightsService,
    private generalService: GeneralService,
    private authorizationService: AuthorizationService,
    private appInitializerService: AppInitializerService,
    private analyticsService: AnalyticsService
  ) {
    // in webcomponent we try to inject styles.css dynamically into the document where bundle gets loaded
    if (this.environment.webcomponent) {
      const head = document.head || document.getElementsByTagName('head')[0];
      const scriptTags: HTMLCollection = head.getElementsByTagName('script');
      for (let i = 0; i < scriptTags.length; i++) {
        let element: HTMLScriptElement = scriptTags.item(
          i
        ) as HTMLScriptElement;

        //validate the correct url
        const srcUrl = element.src;
        // literal regex expression
        const regexp = /^.*vis.ovs.frontend-.*.bundle.js$/;
        const isBundleLoadUrl: boolean = regexp.test(srcUrl);

        if (isBundleLoadUrl) {
          // use lastIndexOf() to find the position of the last slash
          // and get the part before the slash with substring()
          let bundleBaseUrl = srcUrl.substring(0, srcUrl.lastIndexOf('/'));
          // keep for debugging
          console.log('bundleBaseUrl: ', bundleBaseUrl);
          const stylesCssPath = `${bundleBaseUrl}/typography.css`;
          this.addCssFileToDom(stylesCssPath);
          // found what we were looking for, so no need to continue the for loop
          break;
        }
      }
    }
    else{
      // in web we want to listen to language and pass the language-locale as Country setting to store
      this.translate.langChanges$.pipe(
        tap((lang)=>{
          let country = lang.split('-')[1];
          console.log('detected country setting from locale: ', country);
          this.store.dispatch(SetMyZeissCountryAction({ country: country }));
        })
      ).subscribe();
    }

    // move this to here from app.modules provider init
    // since it was not initializing properly for webcomponent
    // and this way it same for web and webcomponent
    this.appInitializerService.initApp$().pipe(take(1)).subscribe();

    of(null)
      .pipe(
        switchMap(() => {
          return combineLatest([this.store.select(AppSelectors.getSendResult)]);
        }),
        filter((values: boolean[]) => {
          return values[0] === true;
        }),
        switchMap(() => {
          return combineLatest([
            this.store.select(AcuitySelectors.getResultImage).pipe(take(1)),
            this.store.select(ContrastSelectors.getResultImage).pipe(take(1)),
            this.store.select(ColorSelectors.getResultImage).pipe(take(1)),
            this.store
              .select(AstigmatismSelectors.getResultImage)
              .pipe(take(1)),
            this.store.select(AmslerSelectors.getResults).pipe(take(1)),
            // Finish-Flags
            this.store
              .select(AcuitySelectors.getBothEyesFinished)
              .pipe(take(1)),
            this.store
              .select(ContrastSelectors.getBothEyesFinished)
              .pipe(take(1)),
            this.store.select(ColorSelectors.isFinished).pipe(take(1)),
            this.store
              .select(AstigmatismSelectors.getBothEyesFinished)
              .pipe(take(1)),
            this.store.select(AmslerSelectors.isFinished).pipe(take(1))
          ]);
        }),
        tap(
          ([
            acuityImage,
            contrastImage,
            colorImage,
            astigmatismImage,
            amslerImage,
            // Finish-Flags
            isAcuityFinish,
            isContrastFinish,
            isColorFinish,
            isAstigmatismFinish,
            isAmslerFinish
          ]) => {
            let result = this.resultService.generateResultSet(
              acuityImage,
              contrastImage,
              { leftEye: colorImage, rightEye: colorImage } as EyeResult,
              astigmatismImage,
              amslerImage,
              // Finish-Flags
              isAcuityFinish,
              isContrastFinish,
              isColorFinish,
              isAstigmatismFinish,
              isAmslerFinish
            );
            // for now
            this.store
              .select(getSpecialScreeningMode)
              .pipe(
                tap((mode) => {
                  console.log('#### screeningMode: ', mode);
                }),
                filter((mode) => mode == SpecialScreeningMode.MY_ZEISS),
                tap(() => {
                  this.result.emit(result);
                  this.logger.info('### result emit: ', result);
                })
              )
              .subscribe();

            this.store.dispatch(SendResultsCompleteAction());
          }
        )
      )
      .subscribe();

    // Listen to whether we should route to previous screen on parent website or not
    of(null)
      .pipe(
        switchMap(() => this.store.select(shouldRouteToParentComponent)),
        tap((shouldRouteExternally) => {
          console.log(
            '[app.comp] shouldRouteToParentComponent before shouldRouteExternally: ',
            shouldRouteExternally
          );
        }),
        filter((shouldRouteExternally: boolean) => {
          return shouldRouteExternally === true;
        }),
        tap((shouldRouteExternally) => {
          console.log(
            '[app.comp] shouldRouteToParentComponent: past filter -> validate url'
          );
          if (validUrl.is_web_uri(this.onBackClicked)) {
            try {
              window.open(this.onBackClicked, '_top');
            } catch (e) {
              console.log('the back button could not be navigated,', e);
            }
          }else{
            console.log(`url '${this.onBackClicked}' is not valid`);
          }

          let screen = { routeBack: shouldRouteExternally };
          this.routeBack.emit(screen);
          console.log('[app.comp] emitted event: route-back');
          this.store.dispatch(RouteToParentComponentSucceededAction());
        })
      )
      .subscribe();

    // Listen to whether we should emit ecp-result screen call-to-action (probably routing)
    of(null)
      .pipe(
        switchMap(() => this.store.select(shouldEmitEcpResultCallToAction)),
        tap((shouldRouteExternally) => {
          console.log(
            '[app.comp] shouldEmitEcpResultCallToAction before shouldRouteExternally: ',
            shouldRouteExternally
          );
        }),
        filter((shouldRouteExternally) => shouldRouteExternally),
        tap(() => {
          console.log(
            '[app.comp] shouldEmitEcpResultCallToAction: past filter -> validate url'
          );
          if (validUrl.is_web_uri(this.onclickfindappointment)) {
            try {
              window.open(this.onclickfindappointment, '_top');
            } catch (e) {
              console.log(
                'the CTA button in the result page could not be navigated,',
                e
              );
            }
          }else{
            console.log(`url '${this.onclickfindappointment}' is not valid`);
          }

          this.resultScreenBtnClicked.emit();
          console.log('[app.comp] emitted event: result-screen-btn-clicked');
          this.store.dispatch(ExitEcpResultSuccessAction());
        })
      )
      .subscribe();
  }

  addCssFileToDom(cssFilePath: string): void {
    // Create a new <link> element
    const linkElement = document.createElement('link');
    linkElement.rel = 'stylesheet';
    linkElement.type = 'text/css';
    linkElement.href = cssFilePath;

    // Append the <link> element to the <head> section of the document
    const head = document.head || document.getElementsByTagName('head')[0];
    head.appendChild(linkElement);
  }

  // in browser console call via document.getElementById('zeiss-ovs').isHome
  // so far this looks like the best option for a getter/function call for webComponent
  @Input()
  public get isHome(): boolean {
    // router url is something like '/en-INT'
    return (
      ('/' + this.translate.getActiveLang()).toLowerCase() ==
      this.router.url.toLowerCase()
    );
  }

  ngOnInit(): void {
    this.cookies.clear();
    this.isLoading$ = this.store.select(AppSelectors.getLoadingState);
    this.store.dispatch(AppActions.ShowSpinner());

    // translation already loaded because cached
    // -> translationLoadSuccess will never trigger so we hide Spinner right away
    if (this.translate.getTranslation().size != 0) {
      console.log('>>> translation available -> hiding spinner');
      this.store.dispatch(AppActions.HideSpinner());
    } else {
      of(null)
        .pipe(
          switchMap(() => {
            // either translation event triggers or we eventually hide the spinner due to fallback
            return race([
              this.translate.events$.pipe(
                filter((e) => e.type === 'translationLoadSuccess')
              ),
              timer(1000).pipe(
                tap(() =>
                  console.log('>>> translation event fallback -> hide spinner')
                )
              )
            ]);
          }),
          tap(() => this.store.dispatch(AppActions.HideSpinner())),
          untilDestroyed(this)
        )
        .subscribe();
    }

    this.store.dispatch(
      SetDebugActions.SetDebugMode({ debug: environment.debugMode })
    );

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        //analytics page change
        this.analyticsService.trackVirtualPage(event.urlAfterRedirects);

        const currentURL = event.urlAfterRedirects;
        this.store.dispatch(AppActions.SetCurrentURL({ currentURL }));
      });

    this.actions$
      .pipe(untilDestroyed(this), ofType(SetLocaleActions.SetLocale))
      .subscribe(() => {
        this.updateTitleAndMetaTags();
      });

    // needed for webcomponent to show first page

    if (environment.webcomponent) {
      this.store.dispatch(TestTypeActions.ResetAllChecks());

      this.authorizationService.customerId = this.customerId;

      // Check testpath-input
      this.testpath = this.generalService.checkInputTestpath(this.testpath);
      this.router.navigate([this.locale + '/' + this.testpath]);
    }
    console.log('>>> [APP COMP] ngOnInit() -- end');
  }

  ngOnDestroy(): void {}

  @HostListener('document:click', ['$event.target'])
  closeCookiesPreferences(target: any): void {
    const oneTrustTarget =
      target.id === 'close-pc-btn-handler' ||
      target.id === 'onetrust-accept-btn-handler' ||
      target.id === 'onetrust-pc-btn-handler';
    if (target && oneTrustTarget && window.OneTrust) {
      if (target.id === 'close-pc-btn-handler') {
        window.OneTrust.Close();
      }
      // workaround to make sure that we don't check for the onetrust-banner on page reload
      console.log('>>> [APP COMP] closeCookiesPreferences()');
      this.cookies.setOneTrustBannerClosed();
    }
  }

  private updateTitleAndMetaTags(): void {
    if (!environment.webcomponent) {
      this.translate
        .selectTranslateObject('common.appName')
        .pipe(untilDestroyed(this))
        .subscribe((appName) => {
          const appNameWithoutHTML = appName.replace(/<[^>]*>/g, '');
          this.title.setTitle(appNameWithoutHTML);
        });
    }

    this.translate
      .selectTranslateObject('metaTags')
      .pipe(untilDestroyed(this))
      .subscribe((metaTags) => {
        const locale = this.translate.getActiveLang();
        this.metaTagService.updateTag({
          property: 'og:url',
          content: location.href
        });
        this.metaTagService.updateTag({
          property: 'og:locale',
          content: locale
        });

        // TODO: if webcomponent, update locale based in Input
        /*
        console.log('#### LOCALE: ', this.locale);
        if(environment.webcomponent) {
          this.metaTagService.updateTag({
            property: 'og:url',
            content: location.href
          });
          this.metaTagService.updateTag({
            property: 'og:locale',
            content: this.locale
          });
        }
        */

        // title
        this.metaTagService.updateTag({
          property: 'og:title',
          content: metaTags.title
        });
        this.metaTagService.updateTag({
          property: 'og:site_name',
          content: metaTags.title
        });
        this.metaTagService.updateTag({
          name: 'twitter:title',
          content: metaTags.title
        });

        // description
        this.metaTagService.updateTag({
          property: 'og:description',
          content: metaTags.description
        });
        this.metaTagService.updateTag({
          name: 'twitter:description',
          content: metaTags.description
        });

        // image
        this.metaTagService.updateTag({
          property: 'og:image',
          content: metaTags.image.url
        });
        this.metaTagService.updateTag({
          name: 'twitter:image',
          content: metaTags.image.url
        });

        this.metaTagService.updateTag({
          property: 'og:image:alt',
          content: metaTags.image.alt
        });
        this.metaTagService.updateTag({
          property: 'og:image:width',
          content: metaTags.image.width
        });
        this.metaTagService.updateTag({
          property: 'og:image:height',
          content: metaTags.image.height
        });
      });
  }
}
