import { createAction, props } from '@ngrx/store';

// Special for checks
import { ColorTestItem } from '../../shared';

// From shared folder
import { CloseDialogContext } from '../../../shared/components/close-dialog/close-dialog-context.enum';
import { Instruction } from '../../../shared';

const ColorTest = 'Color Test';

export const GenerateTests = createAction(
  `[${ColorTest}] Generate Tests`,
);

export const ResetTests = createAction(
  `[${ColorTest}] Reset Tests`,
);

export const Answer = createAction(
  `[${ColorTest}] Answer`,
  props<{
    answer: number | string;
  }>(),
);

/**
 * This action sets the color test items and answers with values taken
 * from the storage service
 */
export const SetTestItemsFromDate = createAction(
  `[${ColorTest}] SetTestItemsFromDate`,
  props<{
    testItems: ColorTestItem[];
    testAnswers: (number | string)[];
  }>()
);

export const ColorTestFinishedAction = createAction(`[${ColorTest}] finished color test and navigated to resultscreen`);

export const OpenColorDetailsPageAction = createAction(`[${ColorTest}] Open color details page`);

export const ShowCloseWarningAction = createAction(
  `[${ColorTest}] ShowCloseWarning`,
  props<{
    context: CloseDialogContext;
    previousPath?: string;
  }>()
);

export const StartColorSingleCheckAction = createAction(
  `[${ColorTest}] Start color single check`
);

export const StartColorSingleCheckSuccededAction = createAction(
  `[${ColorTest}] Start color single check succeded`,
  props<{
    instructions: Array<Instruction>;
  }>()
);

export const StartColorMegaTestAction = createAction(
  `[${ColorTest}] Start color mega test`
);

export const StartColorMegaTestSuccedAction = createAction(
  `[${ColorTest}] Start color mega test succed`,
  props<{
    instructions: Array<Instruction>;
  }>()
);
