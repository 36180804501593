import { ErrorHandler, Injectable, Injector } from '@angular/core';

import { AppInitializerService } from './app-initializer.service';
import { AppInsightsService } from './app-insights.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService extends ErrorHandler {
  constructor(private appInsightsService: AppInsightsService,
    private injector:Injector
    ) {
    super();
  }

  private appInitializerService:AppInitializerService;

  handleError(error: Error) {
    if(!this.appInitializerService){
      this.appInitializerService = this.injector.get<AppInitializerService>(AppInitializerService);
    }

    if(this.appInitializerService.isAppInitalized()){
      this.appInsightsService.error('[ErrorHandlerService] ',error);
    }
    else{
      // [ErrorHandlerService] app not fully initiallized yet. using console.error...'
      console.error('[ErrorHandlerService] ',error);
    }
  }
}
