import { createAction, props } from '@ngrx/store';

// From shared folder
import { TestTypesForImportantNotes } from '../../shared/enums/test-types-important-notes.enum';

/**
 * Actions for covering the user interactions of the calibration screens and the disclaimer
 */
export const ContinueWithoutCardCheckAction = createAction('[Dialog] Continue without card check');

export const ShowImportantNotesAction = createAction('[Dialog] Show important notes',
props<{
  testType: TestTypesForImportantNotes;
}>());
export const CloseImportantNotesAction = createAction('[Dialog] Close important notes');
export const AgreeImportantNotesAction = createAction('[Dialog] Agree important notes',
props<{
  testType: TestTypesForImportantNotes;
}>());
export const AfterAgreedImportantNotesAction = createAction('[Dialog] After agreed important notes',
props<{
  testType: TestTypesForImportantNotes;
}>());
export const ResetImportantNotesAction = createAction('[Dialog] Reset important notes');
