export function calculateGapSize(
  visualAcuity: number,
  pixelPitch: number,
  workingDistance: number
): number {
  const gapSize =
    (Math.tan((Math.pow(10, visualAcuity) * Math.PI) / 180 / 60) / pixelPitch) *
    workingDistance;

  return gapSize;
}
