import { MAX_VA } from '../config';
import { EyeAcuityValue } from '../interfaces';

export function logmar2metric(logmar: number = MAX_VA): EyeAcuityValue {
  const map: { [value: number]: number } = {
    '-0.3': 3,
    '-0.2': 3.8,
    '-0.1': 4.8,
    0: 6,
    0.1: 7.5,
    0.2: 9.5,
    0.3: 12,
    0.4: 15,
    0.5: 19,
    0.6: 24,
    0.7: 30,
    0.8: 38,
    0.9: 48,
    1: 60,
    1.1: 75,
    1.2: 90
  };

  return {
    x: 6,
    y: map[logmar]
  };
}
