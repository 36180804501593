import { Component, OnInit, OnDestroy, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AnimationOptions } from 'ngx-lottie';

// Stuff for general
import { environment } from '../../../environments/environment';
import { GeneralService } from '../../services/general.service';

// Special for checks
import { getActiveEye } from '../store/selectors/contrast.selectors';

// From shared folder
import { Eyes, Instruction } from '../../shared/enums';
import { HeaderColorEnum, HeaderLabelEnum, HeaderEnum } from '../../shared/enums/header.enum';
import { TEST_TYPES } from '../../shared/enums/test-types';
import { HeaderConfig } from '../../shared/interfaces/header-config.interface';
import { LottieAnimationEnum } from '../../shared/enums/lottie.enum';

@Component({
  selector: 'zat-how-it-works-contrast-page',
  templateUrl: './how-it-works-contrast-page.component.html',
  styleUrls: ['./how-it-works-contrast-page.component.scss']
})
export class HowItWorksContrastPageComponent implements OnInit, OnDestroy {
  @ViewChild('scrollableElement') scrollableElement: ElementRef;

  @Output('clickClose') clickClose = new EventEmitter();

  Eyes = Eyes;
  TEST_TYPES = TEST_TYPES;

  activeEye$: Observable<Eyes> = this.store.select(getActiveEye);

  environment = environment;

  instructions = Instruction;

  headerConfig: HeaderConfig = {
    left: HeaderEnum.Back,
    right: HeaderEnum.Empty,
    label: HeaderLabelEnum.ContrastCheck,
    theme: HeaderColorEnum.LightMode,
  } as HeaderConfig;

  optionsContrast: AnimationOptions = null;

  constructor(
    private store: Store,
    private generalService: GeneralService,
  ) { }

  ngOnInit(): void {
    // Set the lottie-options
    this.optionsContrast = this.generalService.getLottieOptions(LottieAnimationEnum.ContrastCheck);
  }

  ngOnDestroy(): void { }

  ngAfterViewInit() {
    this.scrollableElement.nativeElement.focus();
  }

  closeHint(): void {
    this.clickClose.emit();
  }
}
