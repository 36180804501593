export enum HeaderColorEnum {
  DarkMode = 'true',
  LightMode = 'false',
}

export enum HeaderLabelEnum {
  // Near by home
  Home = '',
  AboutUs = 'home.about.ovs',
  LegalDocuments = 'home.about.legalDocuments.title',
  Faq = 'home.about.faqs.header',
  // Details-of the checks
  CheckSelection = 'home.selectCheck.header',
  AcuityDetails = 'acuityTest.startPage.name',
  ContrastDetails = 'contrastTest.startPage.name',
  ColorDetails = 'colorTest.startPage.name',
  AstigmatismDetails = 'astigmatismTest.startPage.name',
  AmslerDetails = 'amslerTest.startPage.name',
  // Preparation/instructions
  CardWarning = '',
  Setup = '',
  // Checks
  AcuityCheck = 'acuityTest.startPage.name',
  ContrastCheck = 'contrastTest.startPage.name',
  ColorCheck = 'colorTest.startPage.name',
  AstigmatismCheck = 'astigmatismTest.startPage.name',
  AmslerCheck = 'amslerTest.startPage.name',
  CheckResult = 'result.header',
  SingleCheckResult = '',
  // Store Locator
  StoreLocator='storeLocator.title'
}

// the initial values of 'Back' and 'Close' must be the same ('cross')
// TODO: make each value with its own initial value, so the initial value of Back needs to be changed to 'back'
export enum HeaderEnum {
  Empty = '',
  Info = 'info',
  Back = 'cross',
  Close = 'cross',
  AboutUs = 'about us'
}