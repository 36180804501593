import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';

// Stuff for general
import { GeneralService } from '../../services/general.service';
import { AppSelectors } from '../../store/selectors';

// Special for checks
import { ShowCloseWarningAction } from '../store/actions/contrast.actions';

// From shared folder
import { CloseDialogContext } from '../../shared/components/close-dialog/close-dialog-context.enum';
import { GA4_EventDetail } from '../../shared/enums/ga4.enum';
import { HeaderLabelEnum } from '../../shared/enums/header.enum';
import { RoutePathes } from '../../shared/enums/route-pathes.enum';
import { TEST_TYPES } from '../../shared/enums/test-types';

@UntilDestroy()
@Component({
  selector: 'zat-prepare-contrast-test',
  templateUrl: './prepare-contrast-test.component.html',
  styleUrls: ['./prepare-contrast-test.component.scss']
})
export class PrepareContrastTestComponent implements OnInit, OnDestroy {
  megaTestActive$: Observable<boolean>;

  headerLabel: HeaderLabelEnum = HeaderLabelEnum.Setup;
  checkType: GA4_EventDetail = GA4_EventDetail.Contrast;

  private isMegaTestActive: boolean;
  constructor(
    private store: Store,
    private generalService: GeneralService,
    private locationService: Location
  ) { }

  ngOnInit(): void {
    this.megaTestActive$ = this.store.select(AppSelectors.getMegaTestActive);
    this.megaTestActive$
      .pipe(untilDestroyed(this))
      .subscribe((isActive) => (this.isMegaTestActive = isActive));
  }

  ngOnDestroy(): void { }

  next(): void {
    let path = `${TEST_TYPES.CONTRAST}${RoutePathes.CheckStart}`;
    if (this.isMegaTestActive) {
      path = `${RoutePathes.Default}/${path}`;
    }
    this.generalService.routeToNextScreen(path);
  }

  cancelTest(): void {
    this.store.dispatch(ShowCloseWarningAction({ context: CloseDialogContext.Check, previousPath: this.locationService.path() }));
  }
}
